import { Permission } from '../constants/permission.enum'
import { useAppSelector } from './useRedux'

export const useSession = () => {
    const session = useAppSelector((state) => state.session)

    const hasPermission = (permission: Permission | undefined | null): boolean => {
        if (permission === null || permission === undefined) {
            return true
        } else if (session.data) {
            return (session.data.permissions & permission) !== 0
        } else {
            return false
        }
    }

    return {
        hasPermission,
        data: session.data,
        userId: session.data?.userId,
    }
}
