/**
 * function return sum of numbers in array
 * @param array - array of number
 * @returns - sum numbers in array
 */
export const sumArray = (array: number[]) => {
    return array.reduce((sum, a) => Number(sum) + Number(a), 0)
}
export const swapArrayElements = function (arr: any[], indexA: number, indexB: number) {
    if (indexA > -1 && indexB > -1 && indexA < arr.length && indexB < arr.length) {
        const temp = arr[indexA]
        arr[indexA] = arr[indexB]
        arr[indexB] = temp
        return arr
    } else {
        return arr
    }
}
