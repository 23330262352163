import { Box, Container, Grid } from '@mui/material'
import { t } from 'i18next'
import HeroLogo from '../Hero/HeroLogo'
import HeroBannerChristmas from '../Hero/HeroBannerChristmas'
import HeroBannerGeneral from '../Hero/HeroBannerGeneral'
import config from '../../config'

interface Props {
    children: React.ReactElement
}

const LoginLayout = ({ children }: Props) => {
    const activeBanner: string = config.heroBanner

    return (
        <Container component="main">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={'100vh'}>
                <Grid container spacing={6} justifyContent="center" alignItems="center">
                    <Grid item md={6} xs={12}>
                        <Box
                            sx={{
                                paddingBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <HeroLogo />
                        </Box>
                        {
                            activeBanner === "christmas" ?
                                <HeroBannerChristmas
                                    title={t('hero:bannerTemp.title')}
                                    description={t('hero:bannerTemp.description')}
                                /> :
                                <HeroBannerGeneral
                                    title={t('hero:bannerGeneral.title')}
                                    description={t('hero:bannerGeneral.description')}
                                />
                        }
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
export default LoginLayout
