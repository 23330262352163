import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material'
interface Props {
    title: string
    handleSelect: (e: any) => void
}
const DataSourceNode = ({ title, handleSelect }: Props) => {
    const theme = useTheme()
    return (
        <Box sx={{ paddingLeft: 2, width: '16rem' }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginBottom: 1,
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid
                    item
                    onClick={handleSelect}
                    sx={{
                        ml: 1,
                        my: 1,
                        cursor: 'pointer',
                        width: '100%',
                    }}
                >
                    <Tooltip title={title}>
                        <Typography
                            align="left"
                            variant="body1"
                            color={theme.palette.common.white}
                            noWrap={true}
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DataSourceNode
