import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StateT = {
    value: string | null
    selected: string[]
}

const initialState: StateT = {
    value: null,
    selected: [],
}

type PayloadT = {
    id: string
}

export const activeObjectSlice = createSlice({
    name: 'activeObject',
    initialState,
    reducers: {
        setActiveObjectAction: (state, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.id
        },
        addObjectToSelectedObjectsAction: (state, action: PayloadAction<PayloadT>) => {
            if (
                state.selected.findIndex((s: string) => {
                    return s === action.payload.id
                }) === -1
            ) {
                state.value && state.selected.length === 0
                    ? state.selected.push(state.value, action.payload.id) //if is activeObjectId and selected is empty then push to selected activeObjectId and selected object with ctrlCmdKey
                    : state.selected.push(action.payload.id) //if you don`t have active object and selected by ctrlCmdKey then push only selected object
            }
        },
        clearObjectFromSelectedObjectsAction: (state, action: PayloadAction<PayloadT>) => {
            var index: number
            if (
                (index = state.selected.findIndex((s: string) => {
                    return s === action.payload.id
                })) !== -1
            ) {
                state.selected.splice(index, 1)
            }
            if (state.selected.length === 1) {
                state.value = state.selected[0]
            }
        },
        clearAllSelectedObjectsAction: (state) => {
            state.selected = []
        },
        removeActiveObjectAction: (state) => {
            state.value = null
        },
    },
})

export const {
    setActiveObjectAction,
    addObjectToSelectedObjectsAction,
    clearObjectFromSelectedObjectsAction,
    clearAllSelectedObjectsAction,
    removeActiveObjectAction,
} = activeObjectSlice.actions

export default activeObjectSlice.reducer
