export const getItem = <T>(key: string) => {
    const item = sessionStorage.getItem(key)
    return item ? (JSON.parse(item) as T) : undefined
}

export const setItem = <T>(key: string, value: T) => {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeItem = (key: string) => {
    sessionStorage.removeItem(key)
}
