import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

const CopyrightItem = () => {
    return (
        <Box sx={{ my: 1, ml: 1 }} textAlign="left">
            <Typography variant="caption" ml={1}>
                {`© ${new Date().getFullYear().toString()}, ${t('copyrightTitle')} | ${
                    process.env.REACT_APP_VERSION
                } beta`}
            </Typography>
        </Box>
    )
}
export default CopyrightItem
