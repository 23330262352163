import { useCallback, useEffect, useState } from 'react'

function useInfinityScroll(length: number, total: number) {
    const [scrollState, setScrollState] = useState<{ skip: number; limit: number }>({
        skip: 0,
        limit: 8,
    })

    const setFirstPage = useCallback(() => {
        setScrollState((prev) => ({ ...prev, skip: 0 }))
    }, [])

    const setNextPage = useCallback(() => {
        if (scrollState.skip > total) {
            return
        }
        setScrollState((prev) => ({ ...prev, skip: prev.skip + 8 }))
    }, [scrollState.skip, total])

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight
        ) {
            if (length < total) {
                setNextPage()
            } else {
                return null
            }
        }
    }, [length, setNextPage, total])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll])

    return { scrollState, setFirstPage, setNextPage }
}

export default useInfinityScroll
