import Grid from '@mui/material/Grid'
import React from 'react'
import ExpandableSection from '../../../common/ExpandableSection'
import NumberInput from '../../../common/PropertyTools/NumberInput'

interface Props {
    object: AnySceneObjectT
    handleSetValue: (property: any, value: any) => void
}

const MarginAndPadding = ({ object, handleSetValue }: Props) => {

    const marginTop: SimpleStyleT | undefined = object.styles.find(style => style.property === "marginTop")
    const marginLeft: SimpleStyleT | undefined = object.styles.find(style => style.property === "marginLeft")
    const marginRight: SimpleStyleT | undefined = object.styles.find(style => style.property === "marginRight")
    const marginBottom: SimpleStyleT | undefined = object.styles.find(style => style.property === "marginBottom")

    const paddingTop: SimpleStyleT | undefined = object.styles.find(style => style.property === "paddingTop")
    const paddingLeft: SimpleStyleT | undefined = object.styles.find(style => style.property === "paddingLeft")
    const paddingRight: SimpleStyleT | undefined = object.styles.find(style => style.property === "paddingRight")
    const paddingBottom: SimpleStyleT | undefined = object.styles.find(style => style.property === "paddingBottom")


    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => handleSetValue(e.target.name, e.target.value)

    if (!marginTop && !marginBottom && !marginRight && !marginBottom && !paddingTop && !paddingBottom && !paddingRight && !paddingBottom) return <></>

    return (
        <ExpandableSection
            expanded={false}
            title={"Margin & padding"}
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item>
                        {
                            marginTop &&
                            <NumberInput
                                styleProperty={marginTop}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            marginLeft &&
                            <NumberInput
                                styleProperty={marginLeft}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            marginRight &&
                            <NumberInput
                                styleProperty={marginRight}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            marginBottom &&
                            <NumberInput
                                styleProperty={marginBottom}
                                handleOnChange={handleOnChange}
                            />
                        }
                    </Grid>
                    <Grid item>
                        {
                            paddingTop &&
                            <NumberInput
                                styleProperty={paddingTop}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            paddingLeft &&
                            <NumberInput
                                styleProperty={paddingLeft}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            paddingRight &&
                            <NumberInput
                                styleProperty={paddingRight}
                                handleOnChange={handleOnChange}
                            />
                        }
                        {
                            paddingBottom &&
                            <NumberInput
                                styleProperty={paddingBottom}
                                handleOnChange={handleOnChange}
                            />
                        }
                    </Grid>
                </Grid>
            }
        />
    )
}

export default MarginAndPadding
