import { PopoverOrigin } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { setPreferencesAsync } from '../store/slices/editor.slice'
import { useAppDispatch, useAppSelector } from './useRedux'

export interface Step {
    title: string
    description: string
    key: string
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    pathname: string
    isLastStep?: boolean
}

export const useWizard = (callback: (nextStep: string) => void) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [localStep, setLocalStep] = useState<{
        step: Step | undefined
        currentRef: HTMLElement | null
    }>()
    const steps: Step[] = useMemo(() => t('wizard:steps', { returnObjects: true }), [t])
    const lastStep = steps[steps.length - 1]
    const currentStep = useAppSelector((state) => state.editor.value.settings.currentStep)

    useEffect(() => {
        const step = steps.find((step) => step.key === currentStep)

        if (step && step.pathname === location.pathname) {
            setTimeout(() => {
                setLocalStep({ step: step, currentRef: document.getElementById(step.key) })
            }, 500)
        }
    }, [currentStep, location.pathname, steps])

    const getCurrentIndex = () => {
        return steps.findIndex((step) => step.key === currentStep)
    }

    const navigate = useNavigate()

    const handleEndWizard = (endString: string) => {
        setLocalStep({ step: undefined, currentRef: null })
        dispatch(setPreferencesAsync('currentStep', endString))
    }
    const next = () => {
        const currentIndex = getCurrentIndex()
        const nextStep = steps[currentIndex + 1]
        setLocalStep({ step: undefined, currentRef: null })
        if (nextStep && nextStep.pathname !== localStep?.step?.pathname) {
            navigate(nextStep?.pathname)
        }

        dispatch(setPreferencesAsync('currentStep', nextStep?.key))
        callback(nextStep.key)
    }

    const dismiss = () => {
        handleEndWizard('_DISMISSED')
    }
    const finish = () => {
        handleEndWizard('_FINISHED')
    }

    return {
        step: localStep?.step,
        currentRef: localStep?.currentRef,
        next,
        dismiss,
        finish,
        lastStep,
    }
}
