import RestoreIcon from '@mui/icons-material/Restore'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import {
    ARCHIVED_QUERY_OPTIONS,
    getArchivedGraphicsAsync,
} from '../../../store/slices/archivedGraphics.slice'
import { setOpenSaveForm, setOpenVersionHistoryDetail } from '../../../store/slices/editor.slice'

interface Prop {
    label: string
    disabled: boolean
    onClick: () => void
}
function ShowVersionHistory({ onClick, disabled, label }: Prop) {
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic.data)

    const handleShowVersionHistory = () => {
        onClick()
        if (graphic._id) dispatch(getArchivedGraphicsAsync(graphic._id, ARCHIVED_QUERY_OPTIONS))
        dispatch(setOpenSaveForm(false))
        dispatch(setOpenVersionHistoryDetail(true))
    }

    return (
        <MenuItem key="showVersionHistory" onClick={handleShowVersionHistory} disabled={disabled}>
            <ListItemIcon>
                <RestoreIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

export default ShowVersionHistory
