import * as yup from 'yup'

declare module 'yup' {
    interface ArraySchema<T> {
        unique(message: string, mapper?: (a: T) => any): ArraySchema<T>
    }
}

yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list) {
        const { path, createError } = this
        if (!Array.isArray(list)) return true
        const values = list.map(mapper)
        const uniqueValues = new Set(values)
        if (values.length === uniqueValues.size) return true

        const idx = values.findIndex((value, index) => values.indexOf(value) !== index)
        return createError({
            path: `${path}[${idx}].name`,
            message,
        })
    })
})
