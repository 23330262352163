import Box from '@mui/material/Box'
import ExpandableSection from '../../../common/ExpandableSection'
import NumberInput from '../../../common/PropertyTools/NumberInput'

interface Props {
    object: ItemI | ImageI | SequenceI
    handleSetValue: (property: any, value: any) => void
}

const BorderRadius = ({ object, handleSetValue }: Props) => {
    const borderTopLeftRadius: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'borderTopLeftRadius'
    )
    const borderTopRightRadius: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'borderTopRightRadius'
    )
    const borderBottomLeftRadius: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'borderBottomLeftRadius'
    )
    const borderBottomRightRadius: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'borderBottomRightRadius'
    )

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleSetValue(e.target.name, e.target.value)

    if (
        !borderTopLeftRadius &&
        !borderTopRightRadius &&
        !borderBottomLeftRadius &&
        !borderBottomRightRadius
    )
        return <></>

    return (
        <ExpandableSection
            expanded={false}
            title={'Corner radius'}
            component={
                <Box>
                    {borderTopLeftRadius && (
                        <NumberInput
                            styleProperty={borderTopLeftRadius}
                            handleOnChange={handleOnChange}
                        />
                    )}
                    {borderTopRightRadius && (
                        <NumberInput
                            styleProperty={borderTopRightRadius}
                            handleOnChange={handleOnChange}
                        />
                    )}
                    {borderBottomLeftRadius && (
                        <NumberInput
                            styleProperty={borderBottomLeftRadius}
                            handleOnChange={handleOnChange}
                        />
                    )}
                    {borderBottomRightRadius && (
                        <NumberInput
                            styleProperty={borderBottomRightRadius}
                            handleOnChange={handleOnChange}
                        />
                    )}
                </Box>
            }
        />
    )
}

export default BorderRadius
