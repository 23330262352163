import { TextField as MuiTextField } from '@mui/material'
import React from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { dateToString } from '../../../helpers/date.helpers'

interface Props {
    id?: string
    control: Control<any, any>
    name: string
    required?: boolean
    autoFocus?: boolean
    type?: string
    autoComplete?: string
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    fullWidth?: boolean
    label?: string
    disabled?: boolean
    multiline?: boolean
    rows?: number
    format?: 'date'
    inputProps?: {}
}

const TextField = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            id,
            control,
            name,
            required,
            type,
            autoComplete,
            autoFocus,
            error,
            fullWidth,
            label,
            disabled,
            multiline,
            rows,
            format,
            inputProps,
        },
        ref
    ) => {
        const processValue = (value?: string) => {
            if (value === undefined) {
                return ''
            }

            return format === 'date' ? dateToString(new Date(value)) : value
        }

        const { t } = useTranslation()
        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <MuiTextField
                        {...field}
                        id={id}
                        value={processValue(field.value)}
                        required={required}
                        fullWidth={fullWidth}
                        label={label || t(name)}
                        type={type || 'text'}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        helperText={
                            error && error.message ? t(error.message.toString()) : undefined
                        }
                        error={Boolean(error)}
                        disabled={disabled}
                        multiline={multiline || false}
                        rows={rows || 1}
                        InputProps={inputProps}
                        ref={ref}
                    />
                )}
            />
        )
    }
)

export default TextField
