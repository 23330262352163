import {
    addObjectToSelectedObjectsAction,
    clearAllSelectedObjectsAction,
    clearObjectFromSelectedObjectsAction,
    removeActiveObjectAction,
    setActiveObjectAction,
} from '../store/slices/activeObject.slice'

import {
    addChildIdAction,
    addObjAction,
    addObjectAction,
    checkIndexes,
    delChildIdAction,
    delObjectAction,
    getAllDescendantIds,
    pushTmpIds,
    updIndexAction,
    updObjectAction,
    updParentIdAction,
} from '../store/slices/objects.slice'
import { AppStateT } from '../store/store'
import { useAppDispatch, useAppSelector } from './useRedux'

import * as tweenly from 'tweenly'
import { Asset } from 'tweenly/dist/processField'
import { v4 as uuidv4 } from 'uuid'
import {
    defaultImage,
    defaultItem,
    defaultSequence,
    defaultText,
} from '../data/defaults/objects.types.defaults'
import { defaultEase } from '../data/styles/ease.styles'
import { sequence } from '../data/styles/tweens.styles'
import { FileResponse, postFile } from '../helpers/ajax.helpers'
import { createAnimation, createObject } from '../helpers/creators.helpers'
import { getImageMetadata, S3_PREFIX } from '../helpers/file.helpers'
import { swapChildIds } from '../helpers/hooks.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import { useObjectGroupActions } from '../hooks/useObjectGroupActions'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
    removeActiveAnimationAction,
} from '../store/slices/activeAnimation.slice'
import { delAnimationsAction, selectAnimationById } from '../store/slices/animations.slice'
import { createAssetAsync, CreateAssetT } from '../store/slices/assets.slice'
import { clear } from '../store/slices/editor.slice'
import { setPercentLoading } from '../store/slices/loading.slice'
import { updateMasterTimeline } from '../store/slices/masterTimeline.slice'
import {
    filterAndSortSiblings,
    getObjectByTitle,
    selectObjectById,
    selectObjects,
} from '../store/slices/objects.slice'
import { useAnimationActions } from './useAnimationActions'
import { useEditorSettings } from './useEditorSettings'
import useFramerate from './useFramerate'
import { useTimelineActions } from './useTimelineActions'

export const useObjectActions = () => {
    const { updateObjectGroups } = useObjectGroupActions()

    const state: AppStateT = useAppSelector((state: AppStateT) => state)
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)
    const assets = useAppSelector((state) => state.assets.data)

    const graphic = useAppSelector((state) => state.graphic.data)

    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )
    const activeObjectId: string | null = useAppSelector(
        (state: AppStateT) => state.activeObject.value
    )
    const activeAnimationId: string | null = useAppSelector(
        (state: AppStateT) => state.activeAnimation.value
    )

    const dispatch = useAppDispatch()

    const { addAnimations } = useAnimationActions()

    /* ACTIVE OBJECT HANDLERS */

    const setActiveObjectId = (id: string, animationIds: string[]) => {
        dispatch(setActiveObjectAction({ id }))

        if (activeAnimationId && !animationIds.includes(activeAnimationId))
            dispatch(removeActiveAnimationAction())
    }

    const removeActiveObjectId = (object: AnySceneObjectT) => {
        dispatch(removeActiveObjectAction())

        if (activeAnimationId && object.animationIds.includes(activeAnimationId))
            dispatch(removeActiveAnimationAction())
    }

    const addObjectToSelection = (object: AnySceneObjectT) => {
        dispatch(addObjectToSelectedObjectsAction(object))
    }
    const clearObjectFromSelection = (object: AnySceneObjectT) => {
        dispatch(clearObjectFromSelectedObjectsAction(object))
    }
    const clearAllObjectFromSelection = () => {
        dispatch(clearAllSelectedObjectsAction())
    }

    /* HELPER HANDLERS */

    const handleActiveObjectWhenCreating = (object: AnySceneObjectT) => {
        setActiveObjectId(object.id, object.animationIds)
    }

    const handleActiveObjectWhenDeleting = (object: AnySceneObjectT) => {
        // pokud je mazany objekt akttivni
        if (object.id === activeObjectId)
            // deaktivuj ho
            removeActiveObjectId(object)
        // jinak
        else {
            // najdi vsechny potomky
            const ids: string[] = getAllDescendantIds(selectUndoable(state).objects, object.id)
            // a pripadneho aktivniho potomka deaktivuj
            ids.forEach((id) => {
                if (id === activeObjectId) removeActiveObjectId(object)
            })
        }
        // zkontroluj, zda neni aktivni nektery animation, ktery pod objekt patri
        object.animationIds.forEach((animationId) => {
            if (animationId === activeAnimationId) dispatch(removeActiveAnimationAction())
        })
    }

    // const addToParentsChildIds = (object: AnySceneObjectT) => {
    const addToParentsChildIds = (parentId: string | null, objectId: string) => {
        // pokud ma novy objekt odkaz na rodice
        if (parentId)
            // pridej k rodici id na novy objekt, ktery je jeho potomkem
            dispatch(addChildIdAction({ id: parentId, childId: objectId }))
    }

    const deleteFromParentsChildIds = (object: AnySceneObjectT) => {
        // pokud je mazany objekt potomkem nektereho objektu a jeho rodic existuje
        if (object.parentId && selectObjectById(selectUndoable(state).objects, object.parentId))
            // smaz jeho referenci u daneho rodice
            dispatch(delChildIdAction({ id: object.parentId, childId: object.id }))
    }

    //delete all animations by animationIds on one step
    const handleTweensWhenDeleting = (animationIds: string[]) => {
        dispatch(delAnimationsAction({ animationIds }))
    }

    const isParentObjectType = (type: string): boolean => {
        return type === 'item' || type === 'image'
    }

    /* MAIN HANDLERS */
    const addObject = (object: AnySceneObjectT): AnySceneObjectT | undefined => {
        if (object.parentId) {
            const parentobject = selectObjectById(selectUndoable(state).objects, object.parentId)
            if (!isParentObjectType(parentobject.type)) return undefined
        }
        dispatch(addObjAction({ object: object }))
        addToParentsChildIds(object.parentId, object.id)
        //clear all selected animations
        dispatch(clearSelectedAnimationsAction())
        handleActiveObjectWhenCreating(object)
        dispatch(updateMasterTimeline(timeToSeek))
        return object
    }

    const addItem = (): AnySceneObjectT | undefined =>
        addObject(createObject(objects, defaultItem, null))
    const addText = (): AnySceneObjectT | undefined =>
        addObject(createObject(objects, defaultText, null))
    const addImage = (id?: string): AnySceneObjectT | undefined => {
        const object = createObject(objects, defaultImage, null, id)
        return addObject(object)
    }
    const addSequence = (id?: string): AnySceneObjectT | undefined => {
        const object = createObject(objects, defaultSequence, null, id)
        return addObject(object)
    }

    const addChildItem = (parentId: string): AnySceneObjectT | undefined => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined
        return addObject(createObject(objects, defaultItem, parentId))
    }

    const addChildText = (parentId: string) => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined

        return addObject(createObject(objects, defaultText, parentId))
    }

    const addChildImage = (parentId: string, type: 'image' | 'sequence', id?: string) => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined

        return addObject(
            createObject(objects, type === 'image' ? defaultImage : defaultSequence, parentId, id)
        )
    }

    const defaultObject = (type: string): AnySceneObjectT => {
        return type === 'text' ? defaultText : type === 'image' ? defaultImage : defaultItem
    }

    //TO DO - problem pri niektorom z dispatch() dobiehaju async => dispatch(updateMasterTimeline(timeToSeek)) || dispatch(addObjAction({ object: object }))
    //logika importu presunuta do useEditor
    const uploadObject = (object: AnySceneObjectT) => {
        //add all missing styles and attributes that are defined by a default object ... the object to be uploaded may miss some
        //properties which were defined by further development

        const defaultobject = createObject([], defaultObject(object.type), null)
        let upgraded = false
        defaultobject.attributes.forEach((attribute) => {
            if (
                !object.attributes.find((attr) => {
                    return attr.property === attribute.property
                })
            ) {
                object.attributes.push(attribute)
                upgraded = true
            }
        })
        defaultobject.styles.forEach((style) => {
            if (
                !object.styles.find((stl) => {
                    return stl.property === style.property
                })
            ) {
                object.styles.push(style)
                upgraded = true
            }
        })

        if (object.fill.length === 0) {
            defaultobject.fill.forEach((fill) => {
                if (
                    !object.fill.find((fll) => {
                        return fll.property === fill.property
                    })
                ) {
                    object.fill.push(fill)
                    upgraded = true
                }
            })
        }
        defaultobject.filters.forEach((filter) => {
            if (
                !object.filters.find((flt) => {
                    return flt.property === filter.property
                })
            ) {
                object.filters.push(filter)
                upgraded = true
            }
        })
        defaultobject.transforms.forEach((transform) => {
            if (
                !object.transforms.find((tran) => {
                    return tran.property === transform.property
                })
            ) {
                object.transforms.push(transform)
                upgraded = true
            }
        })

        dispatch(addObjAction({ object: object }))
        handleActiveObjectWhenCreating(object)
        dispatch(updateMasterTimeline(timeToSeek))
        return upgraded
    }

    const duplicateObjectsAnimations = (objectId: string, sampleAnimationIds: string[]) => {
        const animations: AnimationI[] = sampleAnimationIds.map((id) => {
            const sampleAnimation: AnimationI = selectAnimationById(
                selectUndoable(state).animations,
                id
            )
            return createAnimation(objectId, sampleAnimation.delay, sampleAnimation)
        })
        if (animations.length > 0) addAnimations(animations)
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const duplicateObject = async (sampleObject: AnySceneObjectT, parentId: string | null) => {
        const id: string = 'i' + uuidv4()
        const index: number = filterAndSortSiblings(objects, sampleObject.parentId).length
        let titleindex: number = 0
        let title: string =
            `${sampleObject.title.replace(/( )([c][o][p][y])( )(\d+)$/, '')} copy ` + titleindex

        while (getObjectByTitle(objects, title) !== undefined) {
            title =
                `${sampleObject.title.replace(/( )([c][o][p][y])( )(\d+)$/, '')} copy ` +
                ++titleindex
        }

        const newObject: AnySceneObjectT = {
            ...sampleObject,
            id,
            index,
            title,
            parentId,
            childIds: [],
            animationIds: [],
        }
        await dispatch(addObjectAction(newObject))

        duplicateObjectsAnimations(newObject.id, sampleObject.animationIds)
        sampleObject.childIds.forEach((childId) => {
            duplicateObject(selectObjectById(selectUndoable(state).objects, childId), newObject.id)
        })
        addToParentsChildIds(newObject.parentId, newObject.id)
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const updateObject = (id: string, property: any, value: any) => {
        dispatch(updObjectAction({ id: id, property: property, value: value }))
    }

    const updateObjectAttribute = (object: AnySceneObjectT, property: string, value: boolean) => {
        const attribute: AttributeT | undefined = object.attributes.find(
            (style) => style.property === property
        )
        if (!attribute) return

        const restAttributes: AttributeT[] = object.attributes.filter(
            (style) => style.property !== property
        )
        const updatedAttributes: AttributeT[] = [
            ...restAttributes,
            {
                ...attribute,
                value: value,
            },
        ]
        updateObject(object.id, 'attributes', updatedAttributes)
    }

    const updateObjectStyle = (object: AnySceneObjectT, property: string | number, value: any) => {
        const style: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === property
        )
        if (!style) return

        const restStyles: SimpleStyleT[] = object.styles.filter(
            (style) => style.property !== property
        )
        const updatedStyles: SimpleStyleT[] = [
            ...restStyles,
            {
                ...style,
                value: value,
            },
        ]
        updateObject(object.id, 'styles', updatedStyles)
    }

    const updateObjectStyles = (object: AnySceneObjectT, properties: Record<string, any>) => {
        let newStyles: SimpleStyleT[] = object.styles.slice()

        Object.entries(properties).forEach(([property, value]) => {
            const style: SimpleStyleT | undefined = newStyles.find(
                (style) => style.property === property
            )
            if (style) {
                newStyles = newStyles.map((style) => {
                    if (style.property === property) {
                        return {
                            ...style,
                            value: value,
                        }
                    } else {
                        return style
                    }
                })
            }
        })

        updateObject(object.id, 'styles', newStyles)
    }

    const deleteObject = (object: AnySceneObjectT) => {
        handleActiveObjectWhenDeleting(object)
        deleteFromParentsChildIds(object)
        handleTweensWhenDeleting(object.animationIds)

        // smaz objekt
        dispatch(delObjectAction({ id: object.id }))
        dispatch(checkIndexes(object.parentId))
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const clearScene = () => {
        dispatch(clear())
    }

    const addNodeUnderParent = (objectId: string, parent: AnySceneObjectT | null) => {
        // pokud se jedna o stejny objekt
        if (parent && objectId === parent.id) return
        if (parent && parent.type !== 'item') return

        const object: AnySceneObjectT = selectObjectById(selectUndoable(state).objects, objectId)
        const prevParentId: string | null = object.parentId

        // pokus o presun root objektu do rootu
        if (!parent && !object.parentId) return

        // odeber ho z childIds u jeho puvodniho rodice
        deleteFromParentsChildIds(object)

        // zarad ho do seznamu childIds u jeho noveho rodice
        if (parent) addToParentsChildIds(parent.id, object.id)

        // dej mu parentId stejne jako ma jeho rodic
        const parentId: string | null = parent ? parent.id : null!
        dispatch(updParentIdAction({ id: objectId, parentId: parentId }))

        // updatni index objektu
        const siblings = filterAndSortSiblings(objects, parentId)
        dispatch(updIndexAction({ id: object.id, index: siblings.length }))

        // updatni indexy puvodnich sourozencu
        if (prevParentId) dispatch(checkIndexes(prevParentId))

        // updatni indexy novych sourozencu
        dispatch(checkIndexes(parentId))
    }

    const moveObjectUp = (object: AnySceneObjectT) => moveObject(object, -1)
    const moveObjectDown = (object: AnySceneObjectT) => moveObject(object, +1)

    const { refreshMasterTimeline } = useTimelineActions()

    const moveObject = (object: AnySceneObjectT, i: number) => {
        const siblings = filterAndSortSiblings(objects, object.parentId)
        const index = objects.filter((x) => x.parentId === object.parentId).indexOf(object) + i
        if (index >= 0 && index < siblings.length) {
            const siblingIds: string[] = siblings.map((s) => s.id)
            const siblingId: string = siblingIds[index]

            if (object.parentId) {
                const childIds: string[] = swapChildIds(siblingIds, object.id, siblingId)
                // updatni childIds v nadrazenem objektu
                updateObject(object.parentId, 'childIds', childIds)
            }
            dispatch(updIndexAction({ id: object.id, index: index }))
            dispatch(updIndexAction({ id: siblingId, index: object.index }))
        }
        //refresh timeline with animation after move up/down,
        //setTimeout because update index of object is async function
        setTimeout(() => refreshMasterTimeline(), 100)
    }

    const createImage = (parentid: string | null, id?: string) =>
        parentid ? addChildImage(parentid, 'image', id) : addImage(id)

    const createSequence = (parentid: string | null, id?: string) =>
        parentid ? addChildImage(parentid, 'sequence', id) : addSequence(id)

    const setFillProperty = (object: ImageI, index: number, property: string, value: any) => {
        const updatedGroups = updateObjectGroups(object['fill'], index, property, value)
        updateObject(object.id, 'fill', updatedGroups)
    }

    const setDimensions = (object: ImageI, width: number, height: number) => {
        const properties = { width, height }
        updateObjectStyles(object, properties)
    }

    const processImage = (parentObjectId: string | null, url: string, attribute?: string) => {
        const object = createImage(parentObjectId, undefined)
        if (!object) {
            console.log('Cannot create an object')
            return
        }
        const img = new Image()
        img.addEventListener('load', function () {
            setDimensions(object as ImageI, img.width, img.height)
            setFillProperty(
                object as ImageI,
                0,
                'value',
                attribute ? attribute : `url("${img.src}")`
            )
        })
        img.src = url
        dispatch(pushTmpIds([object.id]))
    }

    const { companyId } = useEditorSettings()

    const readImageFile = async (file: File, parentObjectId: string | null) => {
        const objectId = 'i' + uuidv4()

        const graphicId = graphic._id ?? graphic.tmpId
        const fileMetadata = await postFile(file, {
            storage: 's3',
            key: `${S3_PREFIX}/${graphicId}/images/${objectId}`,
            isPublic: true,
            hasFilename: true,
        })

        const object = createImage(parentObjectId, objectId)
        if (!object) {
            console.log('Cannot create an object')
            return
        }

        const img = new Image()
        img.addEventListener('load', function () {
            setDimensions(object as ImageI, img.width, img.height)
            setFillProperty(object as ImageI, 0, 'value', `url("${img.src}")`)
        })

        img.src = fileMetadata.publicUrl!
        const asset: CreateAssetT = {
            name: file.name,
            slug: object.id,
            companyId,
            attributes: [],
            url: fileMetadata.publicUrl!,
            type: 'image',
            size: fileMetadata.length,
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))

        dispatch(pushTmpIds([object.id]))
    }

    const readImageFileFromURL = async (url: string, parentObjectId: string | null) => {
        processImage(parentObjectId, url)
    }

    const readImageFileFromAsset = async (
        attributeImgUrl: string,
        parentObjectId: string | null
    ) => {
        const url = await tweenly.processField(attributeImgUrl, assets as Asset[])
        processImage(parentObjectId, url!, attributeImgUrl)
    }

    const replaceImageSource = async (file: File, object: AnySceneObjectT) => {
        const graphicId = graphic._id ?? graphic.tmpId
        const fileMetadata = await postFile(file, {
            storage: 's3',
            key: `${S3_PREFIX}/${graphicId}/images/${object.id}`,
            isPublic: true,
            hasFilename: true,
        })
        const asset: CreateAssetT = {
            name: object.title,
            slug: object.id,
            companyId,
            attributes: [],
            url: fileMetadata.publicUrl!,
            type: 'image',
            size: fileMetadata.length,
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))

        setFillProperty(object as ImageI, 0, 'value', `url("${fileMetadata.publicUrl!}")`)
    }

    const replaceImageSourceFromUrl = async (url: string, object: AnySceneObjectT) => {
        setFillProperty(object as ImageI, 0, 'value', `url("${url}")`)
    }
    const replaceImageSourceFromAsset = async (attribute: string, object: AnySceneObjectT) => {
        setFillProperty(object as ImageI, 0, 'value', attribute)
    }

    const { setFramerateValue } = useFramerate()
    const readImageSequence = async (files: FileList, parentObjectId: string | null) => {
        const step = setFramerateValue(1)

        const item = createSequence(parentObjectId)
        if (!item) {
            return
        }

        const graphicId = graphic._id ?? graphic.tmpId
        const { width } = await getImageMetadata(files[0])

        let length = 0
        const filesMetadata: FileResponse[] = []
        for (let i = 0; i < files.length; i++) {
            const metadata = await postFile(files[i], {
                storage: 's3',
                key: `${S3_PREFIX}/${graphicId}/sequences/${item.id}`,
                isPublic: true,
                thumbnailWidth: Math.round(width / 2),
                hasFilename: true,
            })
            length += metadata.length
            filesMetadata.push(metadata)
            dispatch(setPercentLoading((i / files.length) * 100))
        }

        const metadata = filesMetadata[Math.floor(files.length / 2)]
        const asset: CreateAssetT = {
            name: metadata.filename,
            slug: item.id,
            companyId,
            attributes: [],
            type: 'sequence',
            size: length,
            url: metadata.publicUrl,
            urls: filesMetadata.map((file) => file.publicUrl!),
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))

        const animations = filesMetadata
            .sort((f1, f2) => f1.filename.localeCompare(f2.filename))
            .map((f, index) => {
                const animation: AnimationI = {
                    id: uuidv4(),
                    target: null,
                    title: f.filename,
                    objectId: item.id,
                    duration: step,
                    delay: index * step,
                    repeat: 0,
                    tween: sequence(f.publicUrl!),
                    relativeTo: null,
                    ease: defaultEase,
                    onInitCode: null,
                    onUpdateCode: null,
                }
                return animation
            })

        setSequenceDimensions(filesMetadata[0].publicUrl!, item as ImageI)
        addAnimations(animations)
        dispatch(addSelectedAnimationsAction(animations.map((animation) => animation.id)))
        dispatch(pushTmpIds([item.id]))
    }

    const setSequenceDimensions = (url: string, item: ImageI) => {
        const img = new Image()
        img.addEventListener('load', function () {
            setDimensions(item as ImageI, img.width, img.height)
        })
        img.src = url
    }

    const deselectObjects = () => {
        dispatch(removeActiveAnimationAction())
        dispatch(clearSelectedAnimationsAction())
        dispatch(removeActiveObjectAction())
        dispatch(clearAllSelectedObjectsAction())
    }

    return {
        isParentObjectType,
        addItem,
        addText,
        addImage,
        addObject,
        uploadObject,
        addChildItem,
        addChildText,
        addChildImage,
        duplicateObject,
        updateObject,
        updateObjectAttribute,
        updateObjectStyle,
        updateObjectStyles,
        deleteObject,
        clearScene,
        setActiveObjectId,
        addObjectToSelection,
        clearObjectFromSelection,
        clearAllObjectFromSelection,
        removeActiveObjectId,
        addNodeUnderParent,
        moveObjectUp,
        moveObjectDown,
        addToParentsChildIds,
        duplicateObjectsAnimations,
        readImageFile,
        readImageFileFromURL,
        readImageFileFromAsset,
        readImageSequence,
        replaceImageSource,
        replaceImageSourceFromUrl,
        replaceImageSourceFromAsset,
        deselectObjects,
        createSequence,
        setSequenceDimensions,
    }
}
