import { useEffect, useState } from 'react'

export function useController(defaultValue = '') {
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    return { value, onChange }
}
