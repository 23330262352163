import { useMemo } from 'react'
import { License } from '../constants/license.enum'
import { Permission } from '../constants/permission.enum'
import { Role } from '../constants/role.enum'
import { CompanyT } from '../store/slices/company.slice'
import { useAppSelector } from './useRedux'
import { useSession } from './useSession'

export const useWorkspacePermission = (selectedCompany?: CompanyT | null) => {
    const user = useAppSelector((state) => state.user.value)
    const companies = useAppSelector((state) => state.companies)
    const companiesStats = useAppSelector((state) => state.companiesStats)
    const { hasPermission } = useSession()

    const userInWorkspaceIsNotActive = selectedCompany?.usersRef.find(
        (u) => u.id === user._id && u.isDisabled === true
    )
    const workspaceIsExpired =
        selectedCompany?.expired && selectedCompany.expired.getTime() < Date.now()

    const userHaveExpiredLicense =
        user.license?.expired && new Date(user.license.expired).getTime() < Date.now()

    const companyUserRole = selectedCompany?.usersRef.find((u) => u.id === user._id)?.role

    //number of active members, it means with isDisabled: undefined || false
    const numberOfWorkspacesMember =
        selectedCompany?.usersRef.filter((u) => !u.isDisabled).length ?? 0

    const workspacesWhereIsUserOwner = companiesStats.data?.owned

    const isOwner = selectedCompany?.userId === user._id

    //company permission

    //create workspace can super admin
    //user with not expired license
    const canCreateWorkspace =
        hasPermission(Permission.Companies) ||
        (!userHaveExpiredLicense &&
            (user.license?.paidLicense === License.Enterprise ||
                (user.license?.paidLicense === License.Business &&
                    workspacesWhereIsUserOwner < 1) ||
                (user.license?.paidLicense === License.Individual &&
                    workspacesWhereIsUserOwner < 1)))

    //update workspace can Super Admin, workspace is not expired
    //and workspace have Enterprise | Business | Individual license
    //and user is part of workspace and have Role high as Collaborator
    const canUpdateWorkspace =
        !companies.status ||
        !companiesStats.status ||
        hasPermission(Permission.Companies) ||
        (!workspaceIsExpired &&
            (selectedCompany?.license === License.Enterprise ||
                selectedCompany?.license === License.Business ||
                selectedCompany?.license === License.Individual) &&
            selectedCompany?.usersRef.some((u) => u.id === user._id) &&
            companyUserRole &&
            companyUserRole > Role.Collaborator)

    const canModifiedWorkspace = useMemo(() => {
        if ((selectedCompany?._id && canUpdateWorkspace) || canCreateWorkspace) {
            return true
        } else {
            return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany])

    //canPushUser
    const canInviteUser =
        hasPermission(Permission.Companies) ||
        (!workspaceIsExpired &&
            companyUserRole &&
            companyUserRole > Role.Collaborator &&
            (selectedCompany?.license === License.Enterprise ||
                (selectedCompany?.license === License.Business && numberOfWorkspacesMember < 2) ||
                (selectedCompany?.license === License.Individual && numberOfWorkspacesMember < 1)))

    const canRemoveUser =
        hasPermission(Permission.Companies) ||
        (!workspaceIsExpired &&
            companyUserRole &&
            companyUserRole > Role.Collaborator &&
            (selectedCompany?.license === License.Enterprise ||
                selectedCompany?.license === License.Business ||
                selectedCompany?.license === License.Individual))

    const canDeleteWorkspace = hasPermission(Permission.Companies) || isOwner

    return {
        userHaveExpiredLicense,
        canCreateWorkspace,
        canInviteUser,
        canUpdateWorkspace,
        userInWorkspaceIsNotActive,
        workspaceIsExpired,
        canRemoveUser,
        canDeleteWorkspace,
        canModifiedWorkspace,
    }
}
