import { useMemo } from 'react'
import { setTimelinePreferencesAsync } from '../store/slices/timelinePreferences.slice'
import { useAppDispatch, useAppSelector } from './useRedux'
import { useTimelineActions } from './useTimelineActions'

const useTimeStepsDensity = () => {
    const dispatch = useAppDispatch()
    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const timelineTweensPerSec: number = timelinePreferences.timelineTweensPerSec
    const framerate: number = timelinePreferences.framerate

    const duration: number = useTimelineActions().masterTimelineDuration

    const timeStepsDensity: number = useMemo(() => {
        //to do move to own custom hook what care about correct modified after load framerate or zoom timeline
        return Math.ceil(duration * timelineTweensPerSec) > 0
            ? Math.ceil(duration * timelineTweensPerSec)
            : 1
    }, [duration, timelineTweensPerSec])

    const setDensityOfTimeline = (timelineZoom: number) => {
        let tweensPerSec: number = 0
        if (timelineZoom > 1600) {
            tweensPerSec = framerate === 50 ? 50 : framerate === 25 ? 25 : 20
        } else if (timelineZoom > 1000) {
            tweensPerSec = framerate === 50 ? 25 : framerate === 25 ? 25 : 10
        } else if (timelineZoom > 750) {
            tweensPerSec = framerate === 50 ? 25 : framerate === 25 ? 12.5 : 10
        } else if (timelineZoom > 500) {
            tweensPerSec = framerate === 50 ? 12.5 : framerate === 25 ? 12.5 : 10
        } else if (timelineZoom > 400) {
            tweensPerSec = framerate === 50 ? 10 : framerate === 25 ? 10 : 10
        } else if (timelineZoom > 300) {
            tweensPerSec = 5
        } else if (timelineZoom > 200) {
            tweensPerSec = 2
        } else {
            tweensPerSec = 1
        }
        if (tweensPerSec !== timelineTweensPerSec) {
            dispatch(setTimelinePreferencesAsync('timelineTweensPerSec', tweensPerSec))
        } else {
            return
        }
    }

    return { timeStepsDensity, setDensityOfTimeline }
}

export default useTimeStepsDensity
