import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    settings: JSX.Element
    autoClose: boolean
    mt?: number
}

const PopupSettings = ({ settings, autoClose, mt }: Props) => {
    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event: any) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Tooltip title={t('menu:settingsContextMenu')}>
                <IconButton onClick={handleClick}>
                    <TuneRoundedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ mt }}
                onClick={autoClose ? () => handleClose() : () => {}}
            >
                {settings}
            </Popover>
        </>
    )
}

export default PopupSettings
