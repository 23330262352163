import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { fileDownload } from '../../../helpers/file.helpers'
import useHTMLFileContent from '../../../hooks/useHTMLFileContent'

import saveAs from 'file-saver'
import { useAppDispatch } from '../../../hooks/useRedux'
import { useToast } from '../../../hooks/useToast'
import { GraphicT, exportGraphicAsync } from '../../../store/slices/graphic.slice'
import { setLoading } from '../../../store/slices/loading.slice'

interface Props {
    label: string
    graphic: GraphicT
    onClick: () => void
    disabled: boolean
    pack?: boolean
}

const DownloadFile = ({ label, graphic, onClick, disabled, pack }: Props) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const { success } = useToast()
    const { processFile, processZip } = useHTMLFileContent()

    // Process and download HTML + JSON file
    const handleHTMLfileDownload = async () => {
        if (graphic._id && !graphic.isExported) {
            dispatch(
                exportGraphicAsync(
                    graphic._id,
                    graphic.company?.isPrivate
                        ? t('graphics:infoMessage.onlyTwoExportsFromPrivate')
                        : undefined
                )
            )
        }

        if (pack) {
            dispatch(setLoading(true))
            saveAs(await processZip(), `${graphic.name}.zip`)
            dispatch(setLoading(false))
        } else {
            fileDownload(`${graphic.name}.html`, processFile())
        }

        onClick()
        success('graphics:successMessage.graphicExported')
    }

    return (
        <MenuItem key="download" onClick={handleHTMLfileDownload} disabled={disabled}>
            <ListItemIcon>
                <FileDownloadRoundedIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

export default DownloadFile
