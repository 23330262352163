import SignupForm from '../components/forms/SignupForm'
import LoginLayout from '../components/layouts/LoginLayout'

export default function Signup() {
    return (
        <LoginLayout>
            <SignupForm />
        </LoginLayout>
    )
}
