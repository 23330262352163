import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { resetAsync } from '../../store/slices/session.slice'
import Link from '../common/Link'
import LoadingButton from '../common/LoadingButton'
import TextField from '../common/form/TextField'

const forgottenPasswordSchema = yup
    .object({
        email: yup.string().email('emailValidation').max(255).required('emailRequired'),
    })
    .required()

type ForgottenPasswordT = yup.InferType<typeof forgottenPasswordSchema>

export default function ForgottenPasswordForm() {
    const theme = useTheme()
    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(forgottenPasswordSchema),
        defaultValues: {
            email: '',
        },
    })

    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const onSubmit = async (data: ForgottenPasswordT) => {
        dispatch(
            resetAsync(data.email, () => {
                navigate(`/new-password?email=${data.email}`)
            })
        )
    }

    const session = useAppSelector((state) => state.session)
    if (session.data) {
        return <Navigate to="/" />
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
                padding: 4,
                borderRadius: 1,
            }}
        >
            <Typography component="h1" variant="h3" mt={2}>
                {t('user:forgottenPasswordTitle')}
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 4, width: '100%' }}
            >
                <TextField
                    control={control}
                    required
                    fullWidth
                    name="email"
                    type="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email}
                />
                <Box
                    sx={{
                        marginTop: 2,
                        marginbottom: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="end"
                        endIcon={<></>}
                    >
                        {t('user:forgottenPasswordSubmit')}
                    </LoadingButton>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        mb={3}
                        mt={1}
                    >
                        <Grid item>
                            <Typography variant="body2">{t('user:signupToLogin')}</Typography>
                        </Grid>
                        <Grid item>
                            <Link href="/login" variant="body2" mb={3} mt={1}>
                                {t('user:signupToLoginButton')}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
