import { Grid, Typography } from "@mui/material"

interface Props {
    heading: string
    description: string
    descriptionAction?: JSX.Element
    section: JSX.Element
}

const PageSection = ({ heading, description, descriptionAction, section }: Props) => {
    return (
        <Grid container p={0} my={4} width={'100%'} justifyContent="space-between">
            <Grid item md={3} xs={12} mb={1}>
                <Typography variant="h5">{heading}</Typography>
                <Typography variant="body1" mb={1}>{description}</Typography>
                {descriptionAction}
            </Grid>
            <Grid item md={8} xs={12}>
                {section}
            </Grid>
        </Grid>
    )
}
export default PageSection