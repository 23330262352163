import { getHeight, getWidth } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { selectObjectById } from '../../store/slices/objects.slice'
import { AppStateT } from '../../store/store'
import { useAppSelector } from '../useRedux'

const useAlignment = () => {
    const state: AppStateT = useAppSelector((state) => state)
    const canvasResolution = useAppSelector((state) => state.editor.value.settings.canvasResolution)

    const parentObject = (object: AnySceneObjectT) => {
        return selectObjectById(selectUndoable(state).objects, object.parentId ?? null)
    }

    const horizontalLeft = 0

    const horizontalCenter = (object: AnySceneObjectT) => {
        return object.parentId
            ? Number(getWidth(parentObject(object))?.value) / 2 -
                  Number(getWidth(object)?.value) / 2
            : canvasResolution[0] / 2 - Number(getWidth(object)?.value) / 2
    }
    const horizontalRight = (object: AnySceneObjectT) => {
        return object.parentId
            ? Number(getWidth(parentObject(object))?.value) - Number(getWidth(object)?.value)
            : canvasResolution[0] - Number(getWidth(object)?.value)
    }
    const verticalTop = 0

    const verticalCenter = (object: AnySceneObjectT) => {
        return object.parentId
            ? Number(getHeight(parentObject(object))?.value) / 2 -
                  Number(getHeight(object)?.value) / 2
            : canvasResolution[1] / 2 - Number(getHeight(object)?.value) / 2
    }
    const verticalBottom = (object: AnySceneObjectT) => {
        return object.parentId
            ? Number(getHeight(parentObject(object))?.value) - Number(getHeight(object)?.value)
            : canvasResolution[1] - Number(getHeight(object)?.value)
    }

    const result = (object: AnySceneObjectT, value: string) => {
        switch (value) {
            case 'horizontalLeft':
                return horizontalLeft
            case 'horizontalRight':
                return horizontalRight(object)
            case 'horizontalCenter':
                return horizontalCenter(object)
            case 'verticalTop':
                return verticalTop
            case 'verticalCenter':
                return verticalCenter(object)
            case 'verticalBottom':
                return verticalBottom(object)
            default:
                return 0
        }
    }

    const resultAlignment = (object: AnySceneObjectT, value: string) => {
        return result(object, value)
    }

    return {
        resultAlignment,
    }
}

export default useAlignment
