import _ from 'lodash'
import * as yup from 'yup'
import { ajax } from '../helpers/ajax.helpers'
import { AssetT } from '../store/slices/assets.slice'
import { useEditorSettings } from './useEditorSettings'

/**
 * TO DO refactor DataSourceDialog and move every const here (validationSchema, type, ...)
 * AFTER Merge delete asset from list, not now because conflict
 */
const DEBOUNCE_TIME = 500

const useValidationDataSource = () => {
    const { companyId } = useEditorSettings()

    const customSlugValidation = async (
        selectedAsset: AssetT,
        slug: string | undefined,
        resolve: (
            value: boolean | yup.ValidationError | PromiseLike<boolean | yup.ValidationError>
        ) => void
    ) => {
        try {
            if (selectedAsset === undefined && slug) {
                const similarAsset = await ajax<AssetT[]>('GET', 'assets/similar', undefined, {
                    slug,
                    companyId,
                })
                resolve(!similarAsset.some((asset) => asset.slug === slug))
            } else {
                resolve(true)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
            resolve(true)
        }
    }

    const debounceVerify = _.debounce(
        (selectedAsset, slug, resolve) => customSlugValidation(selectedAsset, slug, resolve),
        DEBOUNCE_TIME
    )

    return { debounceVerify }
}

export default useValidationDataSource
