import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Box, useTheme } from '@mui/material'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { useDraggable } from '../../../hooks/editor/useDraggable'
import useMultiselectObjectAction from '../../../hooks/editor/useMultiselectObjectAction'
import useWrapSelectedObjects from '../../../hooks/editor/useWrapSelectedObjects'
import useDynamicRefs from '../../../hooks/useDynamicRefs'
import { useAppSelector } from '../../../hooks/useRedux'
import { CanvasCursor } from '../../../store/slices/canvasCursor.slice'
import { AppStateT } from '../../../store/store'
import RightClickContextMenu from '../../common/ContextMenu/RightClickContextMenu'
import CanvasItem from './CanvasItem'
type Props = {
    wrapperObjects: AnySceneObjectT[]
    borderCoef: number
    canvasScaleCoef: number
}
const ObjectsWrapper = ({ wrapperObjects, borderCoef, canvasScaleCoef }: Props) => {
    const { t } = useTranslation()

    const theme = useTheme()
    const state: AppStateT = useAppSelector((state) => state)
    const editor = state.editor.value
    const { handleDuplicateObjects, handleDeleteObjects } = useMultiselectObjectAction()
    const { wrapperObject, selectedObjects, allObjectsInWrapper } = useWrapSelectedObjects()
    const [, setRef] = useDynamicRefs<HTMLDivElement>()
    const {
        handleOnStartDrag,
        handleDragEnd,
        handleDragging,
        dragging,
        draggableAxis,
        defaultMultiWrapperState,
        setCursorWhenDragging,
    } = useDraggable(undefined, canvasScaleCoef, selectedObjects)
    const canvasCursor = useAppSelector((state) => state.canvasCursor.value)
    const draggableScale = (canvasScaleCoef * editor.settings.canvasZoom) / 100

    const getModifiedObject = (object: AnySceneObjectT) => {
        const left = object.styles.find((style) => style.property === 'left')
        const top = object.styles.find((style) => style.property === 'top')
        const absoluteStyles = allObjectsInWrapper.find((x) => x.objectId === object.id)

        if (left && top && absoluteStyles) {
            return {
                ...object,
                styles: [
                    ...object.styles,
                    ...[{ ...left, value: absoluteStyles.left - wrapperObject.left }],
                    ...[{ ...top, value: absoluteStyles.top - wrapperObject.top }],
                ],
            }
        } else {
            return object
        }
    }

    return (
        <RightClickContextMenu
            scaleCoef={canvasScaleCoef}
            menuLabel={
                <Box
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <Draggable
                        position={{
                            x: 0,
                            y: 0,
                        }}
                        axis={draggableAxis}
                        scale={draggableScale}
                        onStop={handleDragEnd}
                        onDrag={handleDragging}
                        onStart={handleOnStartDrag}
                        disabled={canvasCursor === CanvasCursor.GrabCursorBySpace}
                    >
                        {defaultMultiWrapperState || dragging ? (
                            <Box>
                                <div
                                    className="objects-wrapper"
                                    id={'objects-wrapper'}
                                    ref={setRef('objects-wrapper')}
                                    style={{
                                        backgroundColor: defaultMultiWrapperState
                                            ? 'rgba(0,0,0,0.1)'
                                            : 'rgba(0,0,0,0.2)',
                                        transformOrigin: 'top left',
                                        width: wrapperObject?.width,
                                        height: wrapperObject?.height,
                                        top: wrapperObject?.top,
                                        left: wrapperObject?.left,
                                        position: 'absolute',
                                        outline: defaultMultiWrapperState
                                            ? `${borderCoef}px dotted ${theme.palette.warning.light}`
                                            : `${borderCoef}px dotted ${theme.palette.warning.main}`,
                                        cursor: setCursorWhenDragging,
                                        zIndex: 2500,
                                    }}
                                >
                                    <>
                                        {wrapperObjects.map(
                                            (object: AnySceneObjectT, index: number) => {
                                                return (
                                                    <CanvasItem
                                                        key={index}
                                                        object={getModifiedObject(object)}
                                                        canvasScaleCoef={canvasScaleCoef}
                                                        isHiding={false}
                                                        borderCoef={borderCoef}
                                                    />
                                                )
                                            }
                                        )}
                                    </>
                                </div>
                            </Box>
                        ) : (
                            <Box>
                                <div
                                    className="objects-wrapper"
                                    onClick={(e) => {}}
                                    style={{
                                        transformOrigin: 'top left',
                                        top: wrapperObject?.top,
                                        left: wrapperObject?.left,
                                    }}
                                ></div>
                            </Box>
                        )}
                    </Draggable>
                </Box>
            }
            data={[
                {
                    icon: <DeleteRoundedIcon />,
                    title: t('editor:deleteSelected'),
                    onClick: handleDeleteObjects,
                },
                {
                    icon: <ContentCopyIcon />,
                    title: t('editor:duplicateSelected'),
                    onClick: (event) => {
                        event.preventDefault()
                        event.cancelable = true
                        if (event.stopPropagation) event.stopPropagation()
                        handleDuplicateObjects()
                    },
                },
            ]}
        />
    )
}

export default ObjectsWrapper
