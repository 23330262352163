import Grid from '@mui/material/Grid'
import { useState } from "react"
import { useTranslation } from "react-i18next"
import DeleteButton from "../../DeleteButton"
import GroupFieldProperty from "./GroupFieldProperty"
import GroupFieldValue from "./GroupFieldValue"

interface Props {
    object: AnySceneObjectT
    groupStyle: GroupStyleI
    properties: GroupStyleT[]
    handleSetValue: (index: number, property: "property" | "value", value: any) => void
    handleDeleteGroup: (index: number) => void
    handleSetStyleValue?: (property: any, value: any) => void
    handleSetAttributeValue?: (property: any, value: any) => void
}

const GroupFieldRow = ({ object, groupStyle, properties, handleSetValue, handleDeleteGroup, handleSetStyleValue, handleSetAttributeValue }: Props) => {

    const { t } = useTranslation()
    const [fullWidthValue, setFullWidthValue] = useState<boolean>(false)

    const handleOnChange = (field: "property" | "value", value: any) => {
        handleSetValue(groupStyle.index, field, value)
    }

    const handleDelete = () =>
        handleDeleteGroup(groupStyle.index)

    if (!fullWidthValue)
        return (
            <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="space-between" my={1}>
                <Grid item>
                    <GroupFieldProperty
                        groupStyleProperty={groupStyle.property}
                        properties={properties}
                        handleOnChange={handleOnChange}
                    />
                    <GroupFieldValue
                        groupStyle={groupStyle}
                        handleOnChange={handleOnChange}
                        handleSetStyleValue={handleSetStyleValue}
                        handleSetFullWidthValue={setFullWidthValue}
                        handleSetAttributeValue={handleSetAttributeValue}
                    />
                </Grid>
                <Grid item>
                    <Grid item>
                        <DeleteButton
                            text=""
                            tooltipTitle={`${t('editor:remove')} ${groupStyle.property}`}
                            handleOnChange={handleDelete}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    else return (
        <>
            <Grid container direction="row" wrap="nowrap" alignItems="center" my={1}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <GroupFieldProperty
                            groupStyleProperty={groupStyle.property}
                            properties={properties}
                            handleOnChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <DeleteButton
                                text=""
                                tooltipTitle={`${t('editor:remove')} ${groupStyle.property}`}
                                handleOnChange={handleDelete}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <GroupFieldValue
                    groupStyle={groupStyle}
                    handleOnChange={handleOnChange}
                    handleSetStyleValue={handleSetStyleValue}
                    handleSetFullWidthValue={setFullWidthValue}
                    handleSetAttributeValue={handleSetAttributeValue}
                />
            </Grid>
        </>
    )
}

export default GroupFieldRow
