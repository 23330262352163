import * as React from 'react'

const map = new Map<string, React.RefObject<unknown>>()

function setRef<T>(key: string): React.RefObject<T> {
    const ref = React.createRef<T>()
    map.set(key, ref)
    return ref
}
function getRef<T>(key: string): React.RefObject<T> | undefined {
    return map.get(key) as React.RefObject<T>
}

export default function useDynamicRefs<T>(): [
    (key: string) => React.RefObject<T> | undefined,
    (key: string) => React.RefObject<T>
] {
    return [getRef, setRef]
}
