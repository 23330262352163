import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { updateProfileAsync } from '../../store/slices/user.slice'
import LoadingButton from '../common/LoadingButton'
import TextField from '../common/form/TextField'

const myProfileSchema = yup
    .object({
        firstName: yup.string().max(255),
        lastName: yup.string().max(255),
        email: yup.string().email('emailValidation').max(255).required('emailRequired'),
        permissions: yup.number(),
        _id: yup.string().max(25),
    })
    .required()

export type MyProfileT = yup.InferType<typeof myProfileSchema>

const MyProfile = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(myProfileSchema),
        defaultValues: {
            firstName: user.value.firstName,
            lastName: user.value.lastName,
            email: user.value.email,
            permissions: user.value.permissions,
            _id: user.value._id,
        },
    })

    const dispatch = useAppDispatch()
    const onSave = async (data: MyProfileT) => {
        await dispatch(
            updateProfileAsync({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                permissions: data.permissions ?? 0,
                _id: data._id ?? '',
            })
        )
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSave)}
            noValidate
            sx={{
                display: 'auto',
                flexDirection: 'column',
                alignItems: 'left',
                backgroundColor: theme.palette.background.paper,
                padding: 4,
                width: '100%',
                borderRadius: 1,
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <TextField
                        control={control}
                        fullWidth
                        name="firstName"
                        error={errors.firstName}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        control={control}
                        fullWidth
                        name="lastName"
                        error={errors.lastName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        control={control}
                        required
                        fullWidth
                        name="email"
                        type="email"
                        autoComplete="email"
                        error={errors.email}
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    marginTop: 3,
                }}
            >
                <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    loadingPosition="end"
                    endIcon={<></>}
                >
                    {t('user:settingsSaveProfile')}
                </LoadingButton>
            </Box>
        </Box>
    )
}
export default MyProfile
