import { Button, Grid } from '@mui/material'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

interface Props {
    disabledCreateNewOnSelect?: boolean
    onSelect?: (id: string | null) => void
    hideCreateButtonInToolbar?: boolean
}

export function getGridToolbar({
    onSelect,
    disabledCreateNewOnSelect,
    hideCreateButtonInToolbar,
}: Props) {
    return () => {
        const { t } = useTranslation()

        return (
            <GridToolbarContainer>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        {!hideCreateButtonInToolbar && (
                            <Button
                                disabled={!!disabledCreateNewOnSelect}
                                onClick={() => onSelect?.(null)}
                                variant="contained"
                            >
                                {t('newButton')}
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <GridToolbarColumnsButton variant="text" />
                        <GridToolbarFilterButton
                            componentsProps={{ button: { variant: 'text' } }}
                        />
                        <GridToolbarDensitySelector variant="text" />
                        {/* <GridToolbarExport /> */}
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        )
    }
}
