import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Link from '../Link'

interface Props {
    actionName: string
}

const LegalConsent = ({ actionName }: Props) => {
    const { t } = useTranslation()

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" mb={3} mt={1}>
            <Typography variant="caption" sx={{ textAlign: 'center' }}>
                {actionName}
                <Link target="_blank" rel="noreferrer" href={t('user:signupConsentTerms.link')}>
                    {t('user:signupConsentTerms.label')}
                </Link>
                ,{t('user:signupConsentAnd')}&nbsp;
                <Link target="_blank" rel="noreferrer" href={t('user:signupConsentPrivacy.link')}>
                    {t('user:signupConsentPrivacy.label')}
                </Link>
                .
            </Typography>
        </Grid>
    )
}
export default LegalConsent
