import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import * as React from 'react'
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../hooks/useRedux'
interface Props {
    handleChange: (attribute: string) => void
}
const SelectAttributes = ({ handleChange }: Props) => {
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event | SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    const assetsData = useAppSelector((state) => state.assets.data)

    const attributes = assetsData.flatMap((asset) =>
        asset.attributes.map((attribute) => ({
            name: attribute.name,
            path: attribute.path,
            slug: asset.slug,
        }))
    )
    const handleSelect = (e: Event | SyntheticEvent, attribute: string) => {
        handleChange(attribute)
        handleClose(e)
    }

    return (
        <>
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                variant="text"
                color="inherit"
                sx={{
                    maxWidth: 40,
                    height: 45,
                    backgroundColor: (theme) => theme.palette.grey[600],
                }}
                onClick={handleToggle}
            >
                <ElectricalServicesIcon />
                <ArrowDropDownIcon />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                sx={{ zIndex: 1500 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            maxHeight: 300,
                            overflowY: 'scroll',
                            paddingRight: 2,
                            paddingLeft: 2,
                            backgroundColor: theme.palette.grey[500],
                            borderRadius: 2,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {attributes.map((attribute) => (
                                        <MenuItem
                                            onClick={(e) =>
                                                handleSelect(
                                                    e,
                                                    `{${attribute.slug}/${attribute.name}}`
                                                )
                                            }
                                            value={`{${attribute.slug}/${attribute.name}}`}
                                        >{`${attribute.slug}.${attribute.name}`}</MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default SelectAttributes
