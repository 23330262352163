import { styled } from '@mui/material/styles'

export const AppContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
}))

export const ScrollableContainer = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    padding: '0.75rem 0.75rem 0.75rem 0.75rem',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
}))

export const Container = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '0.75rem 0.75rem 0 0.85rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-top',
    overflow: 'hidden',
}))
