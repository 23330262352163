import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

interface Props {
    text: string
}

const EmptyObject = ({ text }: Props) => {
    const theme = useTheme()
    return (
        <Grid container>
            <Grid item>
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.disabled}
                    sx={{ marginLeft: 1 }}>
                    {text}
                </Typography>
            </Grid>
        </Grid>
    )
}
export default EmptyObject
