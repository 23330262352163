import { useCallback, useEffect, useRef } from 'react'
import { CanvasCursor, setCanvasCursor } from '../store/slices/canvasCursor.slice'
import { useAppDispatch, useAppSelector } from './useRedux'

const useHandTool = (enable: boolean) => {
    const dispatch = useAppDispatch()
    const canvasCursor = useAppSelector((state) => state.canvasCursor.value)

    const ref = useRef<HTMLDivElement>(null)

    //handle to grab canvas by `space` key or switch by `h | H` key to grab canvas
    const handleOnKeyDown = useCallback(
        (e: KeyboardEvent) => {
            //prevent default behavior of scroll when press space
            if (e.key === ' ') {
                e.preventDefault()
            }
            //when cursor is default and keyDown is space then change cursor to grab
            if (canvasCursor !== CanvasCursor.GrabCursor && !enable && e.key === ' ') {
                e.preventDefault()
                dispatch(setCanvasCursor(CanvasCursor.GrabCursorBySpace))
            }
            //switch cursor to grab with short key H || h
            if (!enable && (e.key === 'h' || e.key === 'H')) {
                dispatch(setCanvasCursor(CanvasCursor.GrabCursor))
            }
        },
        [canvasCursor, dispatch, enable]
    )

    //handle to clear `space` key or `v | V` to return on default cursor and select item in canvas
    const handleOnKeyUp = useCallback(
        (e: KeyboardEvent) => {
            //prevent default behavior of scroll when press space
            if (e.key === ' ') {
                e.preventDefault()
            }
            //return when is cursor: grab and space is pressed
            if (canvasCursor === CanvasCursor.GrabCursor && e.key === ' ') {
                return
            }
            //when is enable to grab with space then return to default cursor after space keyUp
            if (enable && e.key === ' ') {
                e.preventDefault()
                dispatch(setCanvasCursor(CanvasCursor.DefaultCursor))
            }
            //switch cursor to default with short key V || v
            if (enable && (e.key === 'v' || e.key === 'V')) {
                dispatch(setCanvasCursor(CanvasCursor.DefaultCursor))
            }
        },
        [canvasCursor, dispatch, enable]
    )

    //focus whenever is clicked on div with ref
    //and set tabIndex: -1 for preventDefault behavior on press 'space'
    const handleFocusOnClick = () => {
        ref.current?.setAttribute('tabIndex', '-1')
        ref.current?.focus()
    }

    //TO DO if will be more keyShort then move to own custom Hook
    useEffect(() => {
        const element = ref.current
        element?.setAttribute('tabIndex', '-1') //set tabIndex: -1 for preventDefault behavior on press 'space'
        element?.focus() // focus on first load

        element?.addEventListener('keydown', handleOnKeyDown)
        element?.addEventListener('keyup', handleOnKeyUp)

        return () => {
            element?.removeEventListener('keydown', handleOnKeyDown)
            element?.removeEventListener('keyup', handleOnKeyUp)
        }
    }, [handleOnKeyDown, handleOnKeyUp])

    return { ref, handleFocusOnClick }
}

export default useHandTool
