import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

interface CompaniesStatsT {
    //can be expanded with another properties
    owned: number
}

export interface CompaniesStatsState {
    data: CompaniesStatsT
    status?: string
}

const initialState: CompaniesStatsState = {
    data: {
        owned: 0,
    },
}

export const CompaniesStatsSlice = createSlice({
    name: 'companiesStats',
    initialState,
    reducers: {
        getCompaniesStatsPending: (state: CompaniesStatsState) => {
            state.status = undefined
            state.data = initialState.data
        },
        getCompaniesStatsFulfilled: (
            state: CompaniesStatsState,
            action: PayloadAction<CompaniesStatsT>
        ) => {
            state.status = Status.OK
            state.data = action.payload
        },
        getCompaniesStatsRejected: (state: CompaniesStatsState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        incrementOwned: (state: CompaniesStatsState, action: PayloadAction<number>) => {
            state.data = { ...state.data, owned: state.data.owned + action.payload }
        },
    },
})

export const {
    getCompaniesStatsFulfilled,
    getCompaniesStatsPending,
    getCompaniesStatsRejected,
    incrementOwned,
} = CompaniesStatsSlice.actions

export const getCompaniesStatsAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(getCompaniesStatsPending())
        const data = await get<CompaniesStatsT>(`companies/stats`)
        dispatch(getCompaniesStatsFulfilled(data))
    } catch (error: any) {
        dispatch(getCompaniesStatsRejected(error.message))
    }
}

export default CompaniesStatsSlice.reducer
