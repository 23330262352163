import { useElementSize } from 'usehooks-ts'
import useHtmlForPreview from '../../hooks/useHtmlForPreview'
import PreviewScreen from './PreviewScreen'

interface Props {
    fileContent: Blob | undefined
}

const PreviewScreenWrapper = ({ fileContent }: Props) => {
    const [ref, { width, height }] = useElementSize()
    const { objectsMeta, animationsMeta, htmlSrc, JSONParamsMeta } = useHtmlForPreview(fileContent)

    if (!htmlSrc) {
        return null
    }

    return (
        <div ref={ref}>
            <PreviewScreen
                htmlSrc={htmlSrc}
                objects={objectsMeta}
                animations={animationsMeta}
                jsonParams={JSONParamsMeta}
                width={width}
                height={height}
            />
        </div>
    )
}

export default PreviewScreenWrapper
