import { defaultEase } from "../styles/ease.styles"
import { defaultTween } from "../styles/tweens.styles"

export const defaultAnimation: AnimationI = {
	id: "",
	target: null, // unused property, to be deleted in next major version
	title: "Animation",
	objectId: "",
	duration: 1,
	delay: 0,
	repeat: 0,
	tween: defaultTween,
	relativeTo: null,
	ease: defaultEase,
	onInitCode: null,
	onUpdateCode: null
}
