import ColorizeIcon from '@mui/icons-material/Colorize'
import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useCallback } from 'react'
import { ColorResult } from 'react-color'
import useEyeDropper from 'use-eye-dropper'
import { hexToHSLColor, hexToRGBColor } from '../../../helpers/color.helpers'
type DropperError = {
    message: string
    canceled?: boolean
}

const isError = <T,>(err: DropperError | T): err is DropperError =>
    !!err && err instanceof Error && !!err.message

const isNotCanceled = <T,>(err: DropperError | T): err is DropperError =>
    isError(err) && !err.canceled

type Props = {
    disabled?: boolean
    onPickColor: (color: ColorResult) => void
}

const EyeDropper = ({ disabled, onPickColor }: Props) => {
    const { open, isSupported } = useEyeDropper()

    // useEyeDropper will reject/cleanup the open() promise on unmount,
    // so setState never fires when the component is unmounted.

    const pickColor = useCallback(async () => {
        // Using async/await (can be used as a promise as-well)
        try {
            const color = await open()
            onPickColor({
                rgb: hexToRGBColor(color.sRGBHex),
                hex: color.sRGBHex,
                hsl: hexToHSLColor(color.sRGBHex),
            })
        } catch (e) {
            // Ensures component is still mounted
            // before calling setState
            if (isNotCanceled(e)) console.error(e)
        }
    }, [onPickColor, open])
    return (
        <>
            {isSupported() ? (
                <Tooltip title={t('editor:colorDropperTooltip')}>
                    <span>
                        <IconButton
                            disabled={disabled}
                            onClick={pickColor}
                            sx={{
                                '&.Mui-disabled': { color: (theme) => theme.palette.primary.light },
                            }}
                        >
                            <ColorizeIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : (
                <span>EyeDropper API not supported in this browser</span>
            )}
            {/* {!!error && <div>{error.message}</div>} */}
        </>
    )
}

export default EyeDropper
