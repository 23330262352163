import CloseIcon from '@mui/icons-material/Close'
import { Button, CircularProgress, Grid, IconButton, List, Typography } from '@mui/material'
import { t } from 'i18next'
import { useMemo } from 'react'
import { dateToStringWithTime, getDateFromId } from '../../helpers/date.helpers'
import { getFullNameString, getShortcutFromFullNameString } from '../../helpers/string.helpers'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
    ARCHIVED_QUERY_OPTIONS,
    ArchivedGraphicT,
    getArchivedGraphicsAsync,
} from '../../store/slices/archivedGraphics.slice'
import { setOpenVersionHistoryDetail } from '../../store/slices/editor.slice'
import { GraphicT, getGraphicVersionFileAsync } from '../../store/slices/graphic.slice'
import VersionHistoryItem from './VersionHistoryItem'

const VersionHistoryForm = () => {
    const dispatch = useAppDispatch()
    const archivedGraphics = useAppSelector((state) => state.archivedGraphics)
    const actualGraphic: GraphicT = useAppSelector((state) => state.graphic.data as GraphicT)
    const actualGraphicStatus = useAppSelector((state) => state.graphic.status)
    const file = useAppSelector((state) => state.file.data)

    const concatData: ArchivedGraphicT[] = useMemo(() => {
        const actualGraphicData: ArchivedGraphicT[] =
            actualGraphic._id && actualGraphic.fileId
                ? [
                      {
                          _id: actualGraphic._id,
                          graphicId: actualGraphic._id,
                          fileId: actualGraphic.fileId,
                          updatedById: actualGraphic.updatedById,
                          updatedBy: actualGraphic.updatedBy,
                          updated: actualGraphic.updated,
                      },
                  ]
                : []
        return [...actualGraphicData, ...archivedGraphics.data]
    }, [actualGraphic, archivedGraphics.data])

    const newArchivedGraphicsData = concatData.map((data: ArchivedGraphicT, index: number) => {
        let anonymous = !data?.updatedBy?.firstName && !data.updatedBy?.lastName
        let fullName = anonymous
            ? t('graphics:anonymous')
            : getFullNameString(data.updatedBy?.firstName, data.updatedBy?.lastName)
        let shortAvatar = anonymous
            ? t('graphics:shortAnonymous')
            : getShortcutFromFullNameString(data.updatedBy?.firstName, data.updatedBy?.lastName)
        return {
            ...data,
            shortAvatar: shortAvatar,
            fullName: fullName,
        }
    })

    const onClose = () => {
        dispatch(setOpenVersionHistoryDetail(false))
    }
    const handleLoadMore = () => {
        if (actualGraphic._id)
            dispatch(
                getArchivedGraphicsAsync(actualGraphic._id, {
                    ...ARCHIVED_QUERY_OPTIONS,
                    skip: archivedGraphics.data.length,
                })
            )
    }
    const handleLoadOldVersion = (fileId: string) => {
        dispatch(getGraphicVersionFileAsync(fileId))
    }

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid container pt={1} justifyContent="space-between" alignItems="center">
                <Grid item pl={1}>
                    <Typography variant="h6">{t('editor:versionHistory.title')}</Typography>
                </Grid>
                <Grid item>
                    <IconButton key={'cancel-ikon'} onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <List sx={{ width: '100%' }}>
                {!archivedGraphics.status || !actualGraphicStatus ? (
                    <Grid container justifyContent="center" alignItems="center" md={12}>
                        <CircularProgress key={'circular-progress'} />
                    </Grid>
                ) : (
                    newArchivedGraphicsData.map((x, index) => {
                        return (
                            <>
                                <VersionHistoryItem
                                    id={x._id}
                                    shortAvatar={x.shortAvatar}
                                    updated={`${
                                        x.updated
                                            ? dateToStringWithTime(x.updated)
                                            : dateToStringWithTime(getDateFromId(x._id))
                                    } ${
                                        index === 0
                                            ? t('editor:versionHistory.titleActualVersion')
                                            : ''
                                    }`}
                                    fullName={x.fullName}
                                    onClick={() => handleLoadOldVersion(x.fileId)}
                                    isActive={file?.id === x.fileId}
                                />
                            </>
                        )
                    })
                )}
            </List>
            {archivedGraphics.data.length < archivedGraphics.total && (
                <>
                    {!archivedGraphics.status ? (
                        <Grid container justifyContent="center" alignItems="center" md={12}>
                            <CircularProgress color="primary" />
                        </Grid>
                    ) : (
                        <Grid item md={12}>
                            <Button variant="text" color="primary">
                                <Typography variant="body1" onClick={handleLoadMore}>
                                    <b>{t('editor:versionHistory.buttonLoadMore')}</b>
                                </Typography>
                            </Button>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

export default VersionHistoryForm
