import { useCallback, useEffect, useState } from 'react'
import { sumArray } from '../../helpers/array.helpers'
import { ObjectStyles, getObjectPositioning } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { selectObjects } from '../../store/slices/objects.slice'
import { AppStateT } from '../../store/store'
import { useAnimationActions } from '../useAnimationActions'
import useObject from '../useObject'
import { useObjectActions } from '../useObjectActions'
import { useAppSelector } from '../useRedux'
import { DEFAULT_OBJECT_STYLES } from './useWrapSelectedObject'

export type ObjectStylesWithId = ObjectStyles & {
    objectId: string
}

/**
 * custom hook for wrapping selected objects
 * @returns boundaries for all selected object include every child objects in selected object
 */
const useWrapSelectedObjects = () => {
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const [wrapperObject, setWrapperObject] = useState<ObjectStyles>(DEFAULT_OBJECT_STYLES)

    const [wrapperExist, setWrapperExist] = useState<boolean>(false)
    const [selectedObjects, setSelectedObjects] = useState<AnySceneObjectT[]>([])
    const [allObjectsInWrapper, setAllObjectsInWrapper] = useState<ObjectStylesWithId[]>([])
    const [activeChildObjectsIds, setActiveChildObjectsIds] = useState<string[]>([])
    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )
    const { findObjectById } = useObject()

    /**
     * find root object for current object - recursive function
     */
    const handleFindRootObject = useCallback(
        (object: AnySceneObjectT): AnySceneObjectT | undefined => {
            if (object.parentId) {
                const parentObject = findObjectById(object.parentId)
                if (parentObject) return handleFindRootObject(parentObject)
            } else {
                return object
            }
        },
        [findObjectById]
    )

    /**
     * Get root objects for every selectedObjectIds
     */
    const getRootObjectsForSelectedObject = useCallback(() => {
        let rootObjects: AnySceneObjectT[] = []
        selectedObjectIds.forEach((id) => {
            const object = findObjectById(id)
            if (object) {
                const rootObject = handleFindRootObject(object)
                if (rootObject) {
                    rootObjects.push(rootObject)
                }
            }
        })
        return rootObjects
    }, [findObjectById, handleFindRootObject, selectedObjectIds])

    const { removeActiveObjectId, clearObjectFromSelection } = useObjectActions()
    const { removeSelectedAnimations } = useAnimationActions()
    /**
     * @param object current object
     * @param ids
     * @returns object.id and every childIds in object,
     */
    const getSelectedObjectIds = (object: AnySceneObjectT, ids: string[] = []) => {
        ids.push(object.id)

        object.childIds.forEach((id) => {
            const childObject = objects.find((o) => o.id === id)
            if (childObject) {
                //if child already exist in selectedObjectIds then remove from selectedObjectIds
                if (selectedObjectIds.includes(id)) {
                    removeActiveObjectId(childObject)
                    clearObjectFromSelection(childObject)
                    removeSelectedAnimations(childObject.animationIds)
                }
                getSelectedObjectIds(childObject, ids)
            }
        })

        return ids
    }

    /**
     * Get absolute position for object,
     * recursive find root parent and set absolute position of top, left, edgeRight, edgeBottom
     * @param parentSize { height: number; width: number } - the size of the object for which you need an absolute position
     * @param objectId :string - current object ID,
     * @param top - local param for store values
     * @param left - local param for store values
     * @returns
     */
    const getAbsoluteObjectPosition = (
        currentObjectSize: { height: number; width: number },
        objectId: string,
        top: number[] = [],
        left: number[] = []
    ) => {
        const object = findObjectById(objectId)
        if (object) {
            const actualStyles = getObjectPositioning(object)
            top.push(Number(actualStyles.top))
            left.push(Number(actualStyles.left))

            if (object.parentId) {
                getAbsoluteObjectPosition(currentObjectSize, object.parentId, top, left)
            }
        }
        return {
            top: sumArray(top),
            left: sumArray(left),
            edgeRight: sumArray(left) + Number(currentObjectSize.width),
            edgeBottom: sumArray(top) + Number(currentObjectSize.height),
        }
    }

    /**
     * grouping absolute position in canvas for every object in array of Ids
     * @param ids array of Ids for which want absolute position in canvas
     * @returns absolute position for every object in Ids
     */
    const handleGroupAbsolutePosition = (ids: string[]) => {
        let finalTop: number[] = []
        let finalLeft: number[] = []
        let finalEdgeRight: number[] = []
        let finalEdgeBottom: number[] = []
        let objectsInWrapper: ObjectStylesWithId[] = []

        ids.forEach((id) => {
            const object = findObjectById(id)
            if (object) {
                const objectStyles = getObjectPositioning(object)
                const currentObjectSize = {
                    height: objectStyles.height,
                    width: objectStyles.width,
                }
                const absolutePosition = getAbsoluteObjectPosition(currentObjectSize, object.id)
                finalTop = [...finalTop, absolutePosition.top]
                finalLeft = [...finalLeft, absolutePosition.left]
                finalEdgeRight = [...finalEdgeRight, absolutePosition.edgeRight]
                finalEdgeBottom = [...finalEdgeBottom, absolutePosition.edgeBottom]
                objectsInWrapper = [
                    ...objectsInWrapper,
                    {
                        top: absolutePosition.top,
                        left: absolutePosition.left,
                        width: absolutePosition.edgeRight - absolutePosition.left,
                        height: absolutePosition.edgeBottom - absolutePosition.top,
                        objectId: object.id,
                    },
                ]
            }
        })
        return {
            top: finalTop,
            left: finalLeft,
            edgeRight: finalEdgeRight,
            edgeBottom: finalEdgeBottom,
            objectsInWrapper: objectsInWrapper,
        }
    }

    /**
     * Wrapping all selected object,
     * function return wrap object, when selectedObjectIds.length > 1, else return empty empty wrapper
     */
    const handleWrapSelectedObject = useCallback(() => {
        let arrayTop: number[] = []
        let arrayLeft: number[] = []
        let arrayEdgeRight: number[] = []
        let arrayEdgeBottom: number[] = []
        let selectedObjects: AnySceneObjectT[] = []
        let allObjectsInWrapper: ObjectStylesWithId[] = []
        let allChildObjectsIds: string[] = []
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)

            if (selectedObject) {
                const allObjectIds = getSelectedObjectIds(selectedObject)
                allChildObjectsIds.push(...allObjectIds)
                const valuesAbsoluteObjectsPosition = handleGroupAbsolutePosition(allObjectIds)

                arrayTop = [...arrayTop, ...valuesAbsoluteObjectsPosition.top]
                arrayLeft = [...arrayLeft, ...valuesAbsoluteObjectsPosition.left]
                arrayEdgeRight = [...arrayEdgeRight, ...valuesAbsoluteObjectsPosition.edgeRight]
                arrayEdgeBottom = [...arrayEdgeBottom, ...valuesAbsoluteObjectsPosition.edgeBottom]
                selectedObjects = [...selectedObjects, selectedObject]
                allObjectsInWrapper = [
                    ...allObjectsInWrapper,
                    ...valuesAbsoluteObjectsPosition.objectsInWrapper,
                ]
            }
        })
        setActiveChildObjectsIds(allChildObjectsIds)

        const wrapperObjectsStyles: ObjectStyles = {
            top: Number(Math.min(...arrayTop)),
            left: Number(Math.min(...arrayLeft)),
            width: Number(Math.max(...arrayEdgeRight)) - Number(Math.min(...arrayLeft)),
            height: Number(Math.max(...arrayEdgeBottom) - Number(Math.min(...arrayTop))),
        }

        if (selectedObjectIds.length > 1) {
            setWrapperObject(wrapperObjectsStyles)
            setSelectedObjects(selectedObjects)
            setWrapperExist(true)
            setAllObjectsInWrapper(allObjectsInWrapper)
        } else {
            setWrapperObject(DEFAULT_OBJECT_STYLES)
            setWrapperExist(false)
            setAllObjectsInWrapper([])
            setActiveChildObjectsIds([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedObjectIds, findObjectById])

    //render wrapper every time selectedObjectIds change or objects was changed
    //TO DO - why glitching old values of wrapper after move wrapper object
    useEffect(() => {
        handleWrapSelectedObject()
    }, [handleWrapSelectedObject])

    return {
        activeChildObjectsIds,
        wrapperObject,
        wrapperExist,
        selectedObjects,
        allObjectsInWrapper,
        handleWrapSelectedObject,
        getAbsoluteObjectPosition,
        getRootObjectsForSelectedObject,
    }
}

export default useWrapSelectedObjects
