// listenerMiddleware.ts
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { camelCase } from 'lodash'
import toast from 'react-hot-toast'
import { Status } from '../constants/status'
import i18n from '../i18n'
import { sessionFulfilled } from './slices/session.slice'

export const errorHandler = createListenerMiddleware()

const ERROR_ACTIONS = ['error/showError']

errorHandler.startListening({
    predicate: (action) => {
        return action.type?.endsWith('Rejected') || ERROR_ACTIONS.includes(action.type)
    },
    effect: (action, listenerApi) => {
        const message = i18n.t(`status:${camelCase(action.payload)}`)
        toast.error(message)
        if (action.payload === Status.UNAUTHORIZED) {
            listenerApi.dispatch(sessionFulfilled())
        }
    },
})
