import { useEffect } from 'react'
import * as tweenly from 'tweenly'
import { Asset } from 'tweenly/dist/processField'
import { useAppSelector } from '../useRedux'

export function useCanvasTimer(objects: AnySceneObjectT[]) {
    const assets = useAppSelector((state) => state.assets.data)

    useEffect(() => {
        const intervalId = setInterval(() => {
            tweenly.setDynamicTexts(objects, assets as Asset[])
            tweenly.setDynamicImageUrls(objects, assets as Asset[])
        }, 500)

        return () => {
            clearInterval(intervalId)
        }
    }, [objects, assets])

    return {}
}
