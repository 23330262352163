import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultCanvas } from '../../data/defaults/objects.types.defaults'

type StateT = {
    value: CanvasI | null
}

const initialState: StateT = {
    value: defaultCanvas,
}

type PayloadT = {
    canvas: CanvasI
}

export const canvasSlice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        addCanvasAction: (state, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.canvas
        },
    },
})

export const { addCanvasAction } = canvasSlice.actions
export default canvasSlice.reducer
