import { Box, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />
})

interface Props {
    open: boolean
    title: string
    content?: string
    onClose: () => void
    buttonArray: {
        key: string
        onclick: () => void | Promise<void>
        txt: string
        variant: 'contained' | 'outlined' | 'text'
        color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
        disabled?: boolean
        tooltipTitle?: string
    }[]
}

const AlertMoreChoiceDialog = ({ open, title, content, buttonArray, onClose }: Props) => {
    const theme = useTheme()
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-3-dialog-slide-description"
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    minWidth: { lg: 500, sm: 'auto' },
                    pt: 1,
                    pl: 1,
                    pb: 2,
                    pr: 2,
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-3-dialog-slide-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttonArray.map((x) => {
                        return (
                            <span key={x.key}>
                                <Tooltip title={x.tooltipTitle ?? ''}>
                                    <span>
                                        <Button
                                            onClick={x.onclick}
                                            variant={x.variant}
                                            color={x.color}
                                            disabled={x.disabled}
                                        >
                                            {x.txt}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </span>
                        )
                    })}
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default AlertMoreChoiceDialog
