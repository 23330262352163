import { useTheme } from '@mui/material/styles'
import {
    GridColDef,
    GridSelectionModel,
    GridValueGetterParams,
    DataGrid as MuiDataGrid,
} from '@mui/x-data-grid'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getGridNoResultsOverlay from './grid/GridNoResultsOverlay'

interface Props {
    columns: GridColDef[]
    total: number
    data: any[]
    onChange: () => void
    disableSelectionOnClick?: boolean
    onSelect?: (id: string | null) => void
    canCreateNewOnSelect?: boolean
    noResultsEl?: ReactNode
    loading: boolean
    hideFooterPagination?: boolean
}

export default function SimpleDataGrid({
    total,
    data,
    onChange,
    disableSelectionOnClick,
    columns,
    onSelect,
    noResultsEl,
    loading,
    hideFooterPagination,
}: Props) {
    const theme = useTheme()

    const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
        if (onSelect) {
            onSelect(selectionModel[0] as string)
        }
    }

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = useState(total || 0)
    useEffect(() => {
        setRowCountState((prevRowCountState) => (total !== undefined ? total : prevRowCountState))
    }, [total, setRowCountState])

    const { t } = useTranslation()

    const columnsWithHeaders = useMemo(() => {
        return columns.map((c) => {
            const headerName = c.headerName || t(c.field)

            let valueGetter = c.valueGetter
            if (c.type === 'singleSelect' && c.valueOptions) {
                valueGetter = ({ value }: GridValueGetterParams) => {
                    const valueOptions = c.valueOptions as any[]
                    const v = valueOptions.find((x) => x.value === value)
                    return v ? v.label : value
                }
            }
            return { ...c, headerName, valueGetter }
        })
    }, [columns, t])

    return (
        <div style={{ width: '100%' }}>
            <MuiDataGrid
                autoHeight
                getRowId={(row) => row._id}
                rows={data}
                columns={columnsWithHeaders}
                disableSelectionOnClick={disableSelectionOnClick}
                loading={loading}
                rowCount={rowCountState}
                rowsPerPageOptions={[5, 25]}
                onSelectionModelChange={onSelectionModelChange}
                pagination
                disableColumnMenu
                hideFooterPagination={hideFooterPagination}
                getRowClassName={(param) => param.row.className}
                sx={{
                    padding: 4,
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    '& .MuiDataGrid-overlay': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    '& .MuiPaper-root-MuiDataGrid-paper': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        color: theme.palette.text.primary,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        minHeight: 0,
                    },

                    '& .pointer:hover': {
                        cursor: 'pointer',
                    },
                }}
                components={{
                    NoResultsOverlay: getGridNoResultsOverlay({ noResultsEl }),
                }}
            />
        </div>
    )
}
