import { ToggleButton, Tooltip } from '@mui/material'
import { ReactElement } from 'react'

type ButtonProps = {
    value: string, tooltip: string, icon: ReactElement
}

interface Props {
    selectedButton: ButtonProps,
    unselectedButton: ButtonProps,
    property: string,
    value: string,
    handleOnChange: (property: any, value: any) => void
}

const ButtonSwitch = ({ selectedButton, unselectedButton, value, property, handleOnChange }: Props) => {

    const isSelected: boolean = value === selectedButton.value
    const b: ButtonProps = isSelected ? selectedButton : unselectedButton

    const handleToggleButtonChange = () => {
        if (isSelected)
            handleOnChange(property, unselectedButton.value)
        else
            handleOnChange(property, selectedButton.value)
    }

    return (
        <ToggleButton
            selected={isSelected}
            value={b.value}
            onChange={handleToggleButtonChange}
            sx={{ verticalAlign: "top", margin: 0.5 }}>
            <Tooltip title={b.tooltip}>
                {b.icon}
            </Tooltip>
        </ToggleButton>
    )
}

export default ButtonSwitch