import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { post } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

export type GraphicStatusT = {
    isPurchased: boolean
}

type GraphicStatusStateT = {
    data: GraphicStatusT
    status?: string
}

const initialState: GraphicStatusStateT = {
    data: {
        isPurchased: false,
    },
    status: Status.OK,
}

export const graphicStatusSlice = createSlice({
    name: 'graphicStatus',
    initialState,
    reducers: {
        getGraphicStatusPending: (state: GraphicStatusStateT) => {
            state.status = undefined
            state.data = initialState.data
        },
        getGraphicStatusFulfilled: (
            state: GraphicStatusStateT,
            action: PayloadAction<GraphicStatusT>
        ) => {
            state.status = Status.OK
            state.data = action.payload
        },
        getGraphicStatusRejected: (state: GraphicStatusStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getGraphicStatusPending, getGraphicStatusFulfilled, getGraphicStatusRejected } =
    graphicStatusSlice.actions

export const getGraphicStatusAsync =
    (graphicId: string, companyId: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicStatusPending())
            const graphicStatus = await post<{ _id: string }>(`companies/${companyId}/status`, {
                graphicId,
            })
            if (graphicStatus) dispatch(getGraphicStatusFulfilled({ isPurchased: true }))
        } catch (error: any) {
            dispatch(getGraphicStatusFulfilled(initialState.data))
        }
    }

export default graphicStatusSlice.reducer
