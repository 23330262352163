import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'
import { useAnimationActions } from '../../../../../hooks/useAnimationActions'
import RightClickContextMenu from '../../../../common/ContextMenu/RightClickContextMenu'

interface Props {
    objectId: string
    index: number
    TimelineTimeStepWidth: number
    timelineStepX: number
}

const TimelineTimeStep = forwardRef<HTMLDivElement, Props>(
    ({ objectId, index, TimelineTimeStepWidth, timelineStepX }, ref) => {
        const theme = useTheme()
        const { addNewAnimation } = useAnimationActions()
        const handleCreateTween = () => addNewAnimation(objectId, index)

        return (
            <Grid
                ref={ref}
                sx={{
                    position: 'absolute',
                    left: index * timelineStepX + 2 + 'px',
                    width: TimelineTimeStepWidth + 'px',
                    height: '100%',
                    backgroundColor: theme.palette.background.paper,
                    cursor: 'pointer',
                }}
            >
                <RightClickContextMenu
                    menuLabel={
                        <Grid sx={{ height: '100%', color: theme.palette.background.paper }}>
                            .
                        </Grid>
                    }
                    data={[
                        {
                            icon: <AddRoundedIcon />,
                            title: 'Add animation',
                            onClick: handleCreateTween,
                        },
                    ]}
                />
            </Grid>
        )
    }
)

export default TimelineTimeStep
