import BusinessIcon from '@mui/icons-material/Business'
import EditIcon from '@mui/icons-material/Edit'
import LogoutIcon from '@mui/icons-material/Logout'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import PhotoLibrary from '@mui/icons-material/PhotoLibrary'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SchoolIcon from '@mui/icons-material/School'
import { Badge } from '@mui/material'
import { Navigate } from 'react-router-dom'
import MainLayout from './components/layouts/MainLayout'
import config from './config'
import { Permission } from './constants/permission.enum'
import { useAppSelector } from './hooks/useRedux'
import AdminUsers from './pages/AdminUsers'
import CompaniesAdmin from './pages/Companies'
import CreatedAccount from './pages/CreatedAccount'
import Editor from './pages/Editor'
import ForgottenPassword from './pages/ForgottenPassword'
import GraphicStore from './pages/GraphicStore'
import Graphics from './pages/Graphics'
import LogOut from './pages/LogOut'
import Login from './pages/Login'
import NewPassword from './pages/NewPassword'
import NotFound from './pages/NotFound'
import Profile from './pages/Profile'
import Signup from './pages/Signup'
import Companies from './pages/UserCompanies'
import Wizard from './pages/Wizard'

const WorkspaceIcon = () => {
    const companiesStats = useAppSelector((state) => state.companiesStats.data)
    return (
        <Badge variant="dot" invisible={companiesStats.owned >= 1} color="error">
            <BusinessIcon />
        </Badge>
    )
}

export const routes = [
    {
        path: 'admin',
        element: <MainLayout />,
        children: [
            {
                path: 'users',
                element: <AdminUsers />,
                permission: Permission.SuperAdmin,
                icon: <PeopleIcon />,
            },
            {
                path: 'workspaces',
                element: <CompaniesAdmin />,
                permission: Permission.Companies,
                icon: <BusinessIcon />,
            },
        ],
    },
    {
        path: 'app',
        element: <MainLayout />,
        children: [
            {
                path: 'editor',
                element: <Editor />,
                icon: <EditIcon />,
            },
            {
                path: 'graphics',
                element: <Graphics />,
                icon: <PhotoLibrary />,
                disabled: false,
            },
            {
                path: 'store',
                element: <GraphicStore />,
                icon: <PublicRoundedIcon />,
                disabled: false,
            },
            {
                path: 'workspaces',
                element: <Companies />,
                icon: <WorkspaceIcon />,
            },
        ],
    },
    {
        path: 'settings',
        element: <MainLayout />,
        children: [
            {
                path: 'wizard',
                element: <Wizard />,
                icon: <SchoolIcon />,
            },
        ],
    },
    {
        path: 'account',
        element: <MainLayout />,
        children: [
            {
                path: 'profile',
                element: <Profile />,
                icon: <PersonIcon />,
            },
        ],
    },
    {
        path: '/',
        children: [
            { path: 'logout', element: <LogOut />, icon: <LogoutIcon /> },
            { path: 'login', element: <Login />, icon: <LogoutIcon /> },
            { path: 'forgotten-password', element: <ForgottenPassword /> },
            { path: 'signup', element: config.signUpDisabled ? <NotFound /> : <Signup /> },
            { path: 'new-password', element: <NewPassword /> },
            { path: 'created-account', element: <CreatedAccount /> },
            { path: '404', element: <NotFound /> },
            { path: '/', element: <Navigate to="/app/graphics" /> },
            { path: '*', element: <Navigate to="/404" /> },
        ],
    },
]
