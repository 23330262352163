import { FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandableSection from '../../../common/ExpandableSection'
import NumberInput from '../../../common/PropertyTools/NumberInput'
import LoadImageButton from '../../ObjectsTree/LoadImageButton'

interface Props {
    object: ItemI | ImageI | SequenceI
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const ImageProperties = ({
    object,
    handleSetStyleValue,
    handleSetObjectValue,
    handleSetAttributeValue,
}: Props) => {
    const fill: GroupStyleI[] | undefined = object.fill
    const opacity: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'opacity'
    )
    const isImageEditable: boolean = object?.attributes.find(
        (attr) => attr.property === 'isImageEditable'
    )?.value

    const isImageSequence: boolean = object?.type === 'sequence'
    const { t } = useTranslation()

    const handleChangeEditable = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleSetAttributeValue('isImageEditable', !isImageEditable)
    }
    const handleOnChange = (e: any) => handleSetStyleValue(e.target.name, e.target.value)

    if (!fill && !opacity) return null

    return (
        <ExpandableSection
            expanded={true}
            title="Image"
            secondaryActionComponent={
                <Tooltip title={t('editor:imageCanBeChanged')}>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={isImageEditable}
                                name="isImageEditable"
                                onClick={handleChangeEditable}
                            />
                        }
                        label={<Typography variant="body2">{t('editor:editable')}</Typography>}
                    />
                </Tooltip>
            }
            component={
                <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                        {opacity && (
                            <Grid item>
                                <NumberInput
                                    styleProperty={opacity}
                                    handleOnChange={handleOnChange}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>{!isImageSequence && <LoadImageButton object={object} />}</Grid>
                </Grid>
            }
        />
    )
}

export default ImageProperties
