import { Grid } from '@mui/material'
import { RGBColor } from 'react-color'
import { useAppSelector } from '../../../hooks/useRedux'

type Props = {
    styleProperty: SimpleStyleT
    handleOnChange: (e: { target: { name: string; value: RGBColor } }) => void
}

const RecentColorInput = ({ styleProperty, handleOnChange }: Props) => {
    const recentColor = useAppSelector((state) => state.editor.value.settings.recentColor)

    return (
        <Grid container>
            {recentColor.map((x) => (
                <Grid
                    key={x.hex}
                    item
                    sx={{
                        mb: 0.5,
                        mr: 0.5,
                        width: 30,
                        height: 30,
                        borderRadius: 0.5,
                        breakInside: 'avoid',
                        backgroundColor: `rgba(${x.color.r}, ${x.color.g}, ${x.color.b}, ${x.color.a})`,
                        '&:hover': {
                            cursor: 'pointer',
                            boxShadow: '',
                        },
                    }}
                    onClick={() =>
                        handleOnChange({
                            target: { name: styleProperty.property, value: x.color },
                        })
                    }
                ></Grid>
            ))}
        </Grid>
    )
}

export default RecentColorInput
