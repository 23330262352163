import { Box, useTheme } from '@mui/material'
import { RefObject, forwardRef, useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppSelector } from '../../../hooks/useRedux'

interface PositionType {
    position: 'relative' | 'absolute'
    left: number
    top: number
    width: number
    height: number
}

export type Axis = 'x' | 'y' | 'both' | 'none'

interface Props {
    position: PositionType
    object: AnySceneObjectT
    canvasScaleCoef: number
    direction: Direction
    axis?: Axis
    borderCoef: number
}

export enum Direction {
    TopLeft = 'topLeft',
    Top = 'top',
    TopRight = 'topRight',
    Right = 'right',
    Bottom = 'bottom',
    BottomRight = 'bottomRight',
    BottomLeft = 'bottomLeft',
    Left = 'left',
}

export const DRAGGABLE_ITEMS: { direction: Direction; axis: Axis }[] = [
    { direction: Direction.Right, axis: 'x' },
    { direction: Direction.Left, axis: 'x' },
    { direction: Direction.Top, axis: 'y' },
    { direction: Direction.Bottom, axis: 'y' },
    { direction: Direction.BottomRight, axis: 'both' },
    { direction: Direction.BottomLeft, axis: 'both' },
    { direction: Direction.TopLeft, axis: 'both' },
    { direction: Direction.TopRight, axis: 'both' },
]

const DraggableItem = forwardRef<HTMLDivElement, Props>(
    ({ object, canvasScaleCoef, direction, axis, position, borderCoef }, ref) => {
        const draggableRef = useRef(null)
        const theme = useTheme()
        const editor = useAppSelector((state) => state.editor.value)
        const draggableScale = (canvasScaleCoef * editor.settings.canvasZoom) / 100
        const canvasSnapToGrid = editor.settings.canvasSnapToGridOn
        const canvasSnapGrid = canvasSnapToGrid ? 20 : 1
        const { updateObjectStyles } = useObjectActions()
        const [resizing, setResizing] = useState<boolean>(false)

        //to do position must be also depended on update object from input !!!
        const resultStyles = () => {
            switch (direction) {
                case Direction.Top:
                    return {
                        top: Number(position.top) + 'px',
                        left: Number(position.left) + 'px',
                        width: Number(position.width) + 'px',
                        height: '20px',
                        cursor: 'ns-resize',
                    }

                case Direction.TopRight:
                    return {
                        top: Number(position.top) + 'px',
                        left: Number(position.left) + Number(position.width) - Number(10) + 'px',
                        width: '20px',
                        height: '20px',
                        cursor: 'nesw-resize',
                    }
                case Direction.Right:
                    return {
                        top: Number(position.top) + 'px',
                        left: Number(position.left) + Number(position.width) - Number(10) + 'px',
                        width: '20px',
                        height: Number(position.height) + 'px',
                        cursor: 'ew-resize',
                    }
                case Direction.BottomRight:
                    return {
                        top: Number(position.top) + Number(position.height) - Number(10) + 'px',
                        left: Number(position.left) + Number(position.width) - Number(10) + 'px',
                        width: '20px',
                        height: '20px',
                        cursor: 'nwse-resize',
                    }
                case Direction.Bottom:
                    return {
                        top: Number(position.top) + Number(position.height) - 10 + 'px',
                        left: Number(position.left) + 'px',
                        width: Number(position.width) + 'px',
                        height: '20px',
                        cursor: 'ns-resize',
                    }
                case Direction.BottomLeft:
                    return {
                        top: Number(position.top) + Number(position.height) - Number(10) + 'px',
                        left: Number(position.left) + 'px',
                        width: '20px',
                        height: '20px',
                        cursor: 'nesw-resize',
                    }
                case Direction.Left:
                    return {
                        top: Number(position.top) + 'px',
                        left: Number(position.left) + 'px',
                        width: '20px',
                        height: Number(position.height) + 'px',
                        cursor: 'ew-resize',
                    }
                case Direction.TopLeft:
                    return {
                        top: Number(position.top) + 'px',
                        left: Number(position.left) + 'px',
                        width: '20px',
                        height: '20px',
                        cursor: 'nwse-resize',
                    }
            }
        }
        const resultStylesValues = resultStyles()

        const calculateValue = (dataAxis: number, objectPositionValue: number) => {
            return (
                Math.round((Number(objectPositionValue) + Number(dataAxis)) / canvasSnapGrid) *
                canvasSnapGrid
            )
        }

        const resultResize = (direction: Direction, data: DraggableData, shiftKey?: boolean) => {
            let newWidth = position.width
            let newHeight = position.height
            let newLeft = position.left
            let newTop = position.top

            const condition = data.x > data.y
            const shiftDataY = (position.height / position.width) * data.x
            const shiftDataX = (position.width / position.height) * data.y

            if (shiftKey) {
                switch (direction) {
                    case Direction.TopLeft:
                        newHeight = calculateValue(
                            position.height,
                            condition ? -data.y : -shiftDataY
                        )
                        newWidth = calculateValue(position.width, condition ? -shiftDataX : -data.x)
                        newTop = calculateValue(position.top, condition ? data.y : shiftDataY)
                        newLeft = calculateValue(position.left, condition ? shiftDataX : data.x)
                        break

                    case Direction.TopRight:
                        newHeight = calculateValue(
                            position.height,
                            condition ? shiftDataY : -data.y
                        )
                        newTop = calculateValue(position.top, condition ? -shiftDataY : data.y)
                        newWidth = calculateValue(position.width, condition ? data.x : -shiftDataX)
                        break

                    case Direction.BottomRight:
                        newHeight = calculateValue(position.height, condition ? shiftDataY : data.y)
                        newWidth = calculateValue(position.width, condition ? data.x : shiftDataX)
                        break

                    case Direction.BottomLeft:
                        newHeight = calculateValue(
                            position.height,
                            condition ? -shiftDataY : data.y
                        )
                        newWidth = calculateValue(position.width, condition ? -data.x : shiftDataX)
                        newLeft = calculateValue(position.left, condition ? data.x : -shiftDataX)
                        break

                    //#region keep other direction active when shift is pressed
                    case Direction.Top:
                        newHeight = calculateValue(position.height, -data.y)
                        newTop = calculateValue(position.top, data.y)
                        break
                    case Direction.Right:
                        newWidth = calculateValue(position.width, data.x)
                        break
                    case Direction.Bottom:
                        newHeight = calculateValue(position.height, data.y)
                        break
                    case Direction.Left:
                        newWidth = calculateValue(position.width, -data.x)
                        newLeft = calculateValue(position.left, data.x)
                        break
                    //#endregion

                    default:
                        break
                }
            } else {
                switch (direction) {
                    case Direction.TopLeft:
                        newHeight = calculateValue(position.height, -data.y)
                        newTop = calculateValue(position.top, data.y)
                        newWidth = calculateValue(position.width, -data.x)
                        newLeft = calculateValue(position.left, data.x)
                        break

                    case Direction.Top:
                        newHeight = calculateValue(position.height, -data.y)
                        newTop = calculateValue(position.top, data.y)
                        break

                    case Direction.TopRight:
                        newHeight = calculateValue(position.height, -data.y)
                        newTop = calculateValue(position.top, data.y)
                        newWidth = calculateValue(position.width, data.x)
                        break

                    case Direction.Right:
                        newWidth = calculateValue(position.width, data.x)
                        break

                    case Direction.BottomRight:
                        newHeight = calculateValue(position.height, data.y)
                        newWidth = calculateValue(position.width, data.x)
                        break

                    case Direction.Bottom:
                        newHeight = calculateValue(position.height, data.y)
                        break

                    case Direction.BottomLeft:
                        newHeight = calculateValue(position.height, data.y)
                        newWidth = calculateValue(position.width, -data.x)
                        newLeft = calculateValue(position.left, data.x)
                        break

                    case Direction.Left:
                        newWidth = calculateValue(position.width, -data.x)
                        newLeft = calculateValue(position.left, data.x)
                        break

                    default:
                        break
                }
            }

            return {
                width: newWidth,
                height: newHeight,
                left: newLeft,
                top: newTop,
            }
        }

        const handleResize = (e: DraggableEvent, data: DraggableData, direction: Direction) => {
            const newPosition = resultResize(direction, data, e.shiftKey)

            updateObjectStyles(object, {
                width: Number(newPosition.width),
                height: Number(newPosition.height),
                left: Number(newPosition.left),
                top: Number(newPosition.top),
            })
            setResizing(false)
        }

        const handleResizing = (e: DraggableEvent, data: DraggableData, direction: Direction) => {
            const retypedRef = ref as RefObject<HTMLDivElement>
            const box = retypedRef.current
            if (box) {
                const newPosition = resultResize(direction, data, e.shiftKey)
                box.style.width = newPosition.width + 'px'
                box.style.height = newPosition.height + 'px'
                box.style.top = newPosition.top + 'px'
                box.style.left = newPosition.left + 'px'
            }
        }
        return (
            <Box
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <Draggable
                    nodeRef={draggableRef}
                    scale={draggableScale}
                    position={{ x: 0, y: 0 }}
                    axis={axis}
                    onStart={() => setResizing(true)}
                    onStop={(e, data) => {
                        handleResize(e, data, direction)
                    }}
                    onDrag={(e, data) => {
                        handleResizing(e, data, direction)
                    }}
                >
                    <Box
                        ref={draggableRef}
                        component="div"
                        zIndex="1000"
                        style={{
                            backgroundColor: 'rgba(0,0,0,0)',
                            position: position.position,
                            top: resultStylesValues.top,
                            left: resultStylesValues.left,
                            width: resultStylesValues.width,
                            height: resultStylesValues.height,
                            cursor: resultStylesValues.cursor,
                        }}
                    />
                </Draggable>
                {/* Div initialized new position while resizing*/}
                {resizing && (
                    <div
                        ref={ref}
                        style={{
                            position: position.position,
                            top: position.top + 'px',
                            left: position.left + 'px',
                            border: `${borderCoef}px dotted ${theme.palette.secondary.light}`,
                        }}
                    ></div>
                )}
            </Box>
        )
    }
)

export default DraggableItem
