import BorderRadius from './ExpandableSections/BorderRadius'
import Color from './ExpandableSections/Color'
import Fill from './ExpandableSections/Fill'
import Filters from './ExpandableSections/Filters'
import MarginAndPadding from './ExpandableSections/MarginAndPadding'
import SizeAndPosition from './ExpandableSections/SizeAndPosition'
import Transforms from './ExpandableSections/Transforms'

interface Props {
    object: ItemI
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const ItemTools = ({
    object,
    handleSetStyleValue,
    handleSetObjectValue,
    handleSetAttributeValue,
}: Props) => {
    return (
        <div>
            <SizeAndPosition object={object} handleSetValue={handleSetStyleValue} />

            <Color title="Color" object={object} handleSetValue={handleSetStyleValue} />

            <Fill
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />
            <MarginAndPadding object={object} handleSetValue={handleSetStyleValue} />

            <BorderRadius object={object} handleSetValue={handleSetStyleValue} />

            <Filters object={object} handleSetObjectValue={handleSetObjectValue} />

            <Transforms
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
            />
        </div>
    )
}

export default ItemTools
