import { renderGroupStyles } from "./groups/groups.styles"
import { renderSimpleStyles } from "./simple/simple.styles"

export const renderStyles = (object: AnySceneObjectT, type: "inline" | "css") => {
	const styles = renderSimpleStyles(object.styles, type)
	const fill = renderGroupStyles(object.fill.filter(f => object.type !==  'image' || f.property !== 'backgroundImage'), type)
	const filters = renderGroupStyles(object.filters, type)
	const transforms = renderGroupStyles(object.transforms, type)

	return type === "inline"
		? {
				...styles,
				...fill,
				...filters,
				...transforms,
		  }
		: styles + "; " + fill + "; " + filters + "; " + transforms
}
