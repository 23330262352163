import { Backdrop, CircularProgress, useTheme } from '@mui/material'
interface Props {
    open: boolean
}
const LoadingBackdrop = ({ open }: Props) => {
    const theme = useTheme()
    return (
        <Backdrop
            sx={{
                color: theme.palette.primary.main,
                zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default LoadingBackdrop
