import { useToast } from './useToast'

export const useClipboard = () => {
    const toast = useToast()

    const share = (successMsg: string) => {
        navigator.clipboard.writeText(window.location.href)
        toast.info(successMsg)
    }
    return { share }
}
