import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'
import { actionsEnum } from './transactions.slice'

type TransactionStatsT = {
    _id: string
    coins: number
}

export type TransactionStatsState = {
    data: TransactionStatsT[]
    accountBalance: number
    earn: number
    purchase: number
    renewal: number
    status?: string
}

const initialState: TransactionStatsState = {
    data: [],
    accountBalance: 0,
    earn: 0,
    purchase: 0,
    renewal: 0,
}

export const transactionStatsSlice = createSlice({
    name: 'transactionStats',
    initialState,
    reducers: {
        getTransactionStatsPending: (state: TransactionStatsState) => {
            state = initialState
        },
        getTransactionStatsResetFulfilled: (state: TransactionStatsState) => {
            state.status = Status.OK
            state = initialState
        },
        getTransactionStatsFulfilled: (
            state: TransactionStatsState,
            action: PayloadAction<TransactionStatsState>
        ) => {
            state.status = Status.OK
            state.data = action.payload.data
            state.accountBalance = action.payload.accountBalance
            state.earn = action.payload.earn
            state.purchase = action.payload.purchase
            state.renewal = action.payload.renewal
        },
        getTransactionStatsRejected: (
            state: TransactionStatsState,
            action: PayloadAction<string>
        ) => {
            state.status = action.payload
        },
    },
})

export const {
    getTransactionStatsFulfilled,
    getTransactionStatsPending,
    getTransactionStatsRejected,
    getTransactionStatsResetFulfilled,
} = transactionStatsSlice.actions

export const getTransactionStatsAsync =
    (companyId: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getTransactionStatsPending())
            const transactionStats = await get<TransactionStatsT[]>(
                `companies/${companyId}/transactions-stats`
            )

            const earn =
                transactionStats?.find((x: TransactionStatsT) => x._id === actionsEnum.earn)
                    ?.coins ?? 0

            const purchase =
                transactionStats?.find((x: TransactionStatsT) => x._id === actionsEnum.purchase)
                    ?.coins ?? 0
            const renewal =
                transactionStats?.find((x: TransactionStatsT) => x._id === actionsEnum.renewal)
                    ?.coins ?? 0

            const accountBalance = earn + purchase + renewal

            dispatch(
                getTransactionStatsFulfilled({
                    data: transactionStats,
                    earn: earn,
                    purchase: purchase,
                    renewal: renewal,
                    accountBalance: accountBalance,
                })
            )
        } catch (error: any) {
            dispatch(getTransactionStatsRejected(error.message))
        }
    }

export default transactionStatsSlice.reducer
