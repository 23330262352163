import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultTimeline } from '../../data/defaults/timeline.types.defaults'

type StateT = {
    value: TimelineI
}

const initialState: StateT = {
    value: defaultTimeline,
}

type PayloadT = {
    timeline?: TimelineI
}
type PayloadUpdT = {
    property: string
    value?: any
}

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        uplTimelineAction: (state, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.timeline ?? defaultTimeline
        },
        updTimelineAction: (state, action: PayloadAction<PayloadUpdT>) => {
            state.value = {
                ...state.value,
                [action.payload.property]: action.payload.value,
            }
        },
        clearTimelineAction: (state) => {
            state.value = defaultTimeline
        },
    },
})

export const filterTimelineLabelsByTime = (timeline: TimelineI, from: number, to: number) : TimelineLabelI[] => {
    return (timeline.labels)
    ? timeline.labels.filter((lbl) => (lbl.time > from) && (lbl.time <= to))
    : []
}

export const filterTimelineLabelsInTime = (
    timeline: TimelineI,
    time: number
): TimelineLabelI[] => {
    return (timeline.labels)
    ? timeline.labels.filter((lbl) => lbl.time === time)
    : []
}

export const { uplTimelineAction, updTimelineAction, clearTimelineAction } = timelineSlice.actions

export default timelineSlice.reducer
