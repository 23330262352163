import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonWithTooltip from './ButtonWithTooltip'

interface Props {
    text: string
    handleAddObject: (props?: any) => void
    disabled?: boolean
    customTooltipTitle?: string
    disabledTooltipTitle?: boolean
    icon?: JSX.Element
}

const AddButton = ({
    text,
    handleAddObject,
    disabled,
    customTooltipTitle,
    disabledTooltipTitle,
    icon
}: Props) => {

    const theme = useTheme()

    return (
        <ButtonWithTooltip
            tooltipTitle={
                !disabledTooltipTitle
                    ? customTooltipTitle
                        ? customTooltipTitle
                        : `Add new ${text.toLowerCase()}`
                    : ''
            }
            buttonComponent={
                <Button
                    variant="text"
                    sx={{ color: theme.palette.success.light }}
                    onClick={handleAddObject}
                    disabled={disabled}
                    startIcon={icon ? icon : <AddRoundedIcon />}
                >
                    {text}
                </Button>
            }
            disabled={disabled ?? false}
        />
    )
}

export default AddButton
