import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as yup from 'yup'
import { Status } from '../../constants/status'
import { del, get, post, put } from '../../helpers/ajax.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { incrementOwned } from './companiesStats.slice'
import { UsersT } from './users.slice'

export const inviteUserSchema = yup.object({
    id: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    role: yup.number().required('company:roleRequired'),
    user: yup.object().required('company:userRequired').nullable(true),
})

export interface UserRef {
    id: string
    role: number
    isDisabled?: boolean
    firstName?: string
    lastName?: string
}

export interface CompanyUserT extends UserRef {
    user: UsersT | null
}

export const companySchema = yup
    .object({
        _id: yup.string().nullable(true),
        name: yup.string().required('nameRequired'),
        isPrivate: yup.boolean().required('isPrivateRequired'),
    })
    .required()

export interface CompanyT extends yup.InferType<typeof companySchema> {
    _id: string
    usersRef: UserRef[]
    userId: string | null
    users: UsersT[]
    user: UsersT | null
    license: 'free' | 'individual' | 'business' | 'enterprise'
    coins: number
    name: string
    description?: string
    expired?: Date
    isPrivate: boolean
    disabled: boolean
}

export interface CompanyStateT {
    data: CompanyT
    // separate property, as we have an another form for users
    users: CompanyUserT[]
    status?: string
}

const initialState: CompanyStateT = {
    data: {
        _id: '',
        usersRef: [],
        userId: null,
        users: [],
        user: null,
        license: 'free',
        coins: 0,
        name: '',
        description: '',
        expired: new Date(),
        isPrivate: false,
        disabled: false,
    },
    users: [],
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        getCompanyPending: (state: CompanyStateT) => {
            state.status = undefined
        },
        getCompanyResetFulfilled: (state: CompanyStateT) => {
            state.status = Status.OK
            state.data = initialState.data
        },
        getCompanyFulfilled: (state: CompanyStateT, action: PayloadAction<CompanyT>) => {
            state.status = Status.OK
            state.data = action.payload
            state.users = action.payload.usersRef.map((u, index) => {
                const user = action.payload.users.find((x) => x._id === u.id) || {
                    _id: u.id,
                    email: `${u.id}@streamcircle.com`,
                }
                return { ...u, index, isDisabled: u.isDisabled, user }
            })
        },
        getCompanyRejected: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        submitCompanyFulfilled: (state: CompanyStateT, action: PayloadAction<CompanyT>) => {
            state.status = Status.OK
            state.data = action.payload
        },
        addCompanyUser: (state: CompanyStateT) => {
            state.users = [
                ...state.users,
                {
                    user: null,
                    role: 1,
                    id: '',
                },
            ]
        },
        inviteUserInCompanyFulfilled: (
            state: CompanyStateT,
            action: PayloadAction<CompanyUserT>
        ) => {
            if (state.data.users.find((x) => x._id === action.payload.id)) {
                state.data.users = state.data.users.filter((x) => x._id !== action.payload.id)
                state.data.usersRef = state.data.usersRef.filter((x) => x.id !== action.payload.id)
            }
            let newUserObj: UsersT = {
                _id: action.payload.id,
                firstName: action.payload.user?.firstName,
                lastName: action.payload.user?.lastName,
                email: action.payload.user?.email ? action.payload.user?.email : '',
                lastLogged: action.payload.user?.lastLogged,
            }
            let newUserRefObj: UserRef = {
                id: action.payload.id,
                role: action.payload.role,
                isDisabled: action.payload.isDisabled,
            }
            state.status = Status.OK
            state.data.users = [...state.data.users, newUserObj]
            state.data.usersRef = [...state.data.usersRef, newUserRefObj]
        },
        inviteUserInCompanyPending: (state: CompanyStateT) => {
            state.status = undefined
        },
        inviteUserInCompanyRejected: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        removeUserFulfilled: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = Status.OK
            state.data.users = state.data.users.filter((x) => x._id !== action.payload)
            state.data.usersRef = state.data.usersRef.filter((x) => x.id !== action.payload)
        },
        submitCompanyPending: (state: CompanyStateT) => {
            state.status = undefined
        },
        verifyCompanyPending: (state: CompanyStateT) => {
            state.status = undefined
        },
        activeCompanyPending: (state: CompanyStateT) => {
            state.status = undefined
        },

        submitCompanyRejected: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        verifyCompanyRejected: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        activeCompanyRejected: (state: CompanyStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    getCompanyPending,
    getCompanyFulfilled,
    getCompanyRejected,
    addCompanyUser,
    submitCompanyPending,
    inviteUserInCompanyFulfilled,
    removeUserFulfilled,
    inviteUserInCompanyRejected,
    submitCompanyFulfilled,
    submitCompanyRejected,
    verifyCompanyRejected,
    activeCompanyRejected,
    inviteUserInCompanyPending,
    verifyCompanyPending,
    activeCompanyPending,
    getCompanyResetFulfilled,
} = companySlice.actions

export const getCompanyAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getCompanyPending())
            const company = await get<CompanyT>(`companies/${id}`)
            dispatch(getCompanyFulfilled(company))
        } catch (error: any) {
            dispatch(
                getCompanyRejected(
                    error.statusCode === 403 ? Status.NO_COMPANY_PERMISSION : error.message
                )
            )
        }
    }

export const submitCompanyAsync =
    (data: CompanyT, onCreate?: (id: string) => void): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(submitCompanyPending())
            //data object in put and post is from swagger {name*, description, isPrivate}
            if (data._id) {
                const company = await put<CompanyT>(`companies/${data._id}`, {
                    name: data.name,
                    description: data.description,
                    isPrivate: data.isPrivate,
                })
                dispatch(submitCompanyFulfilled(company))
            } else {
                const company = await post<CompanyT>(`companies`, {
                    name: data.name,
                    description: data.description,
                    isPrivate: data.isPrivate,
                })
                dispatch(submitCompanyFulfilled(company))
                onCreate?.(company._id)
                //update companiesStats after create new workspace
                await dispatch(incrementOwned(1))
            }
        } catch (error: any) {
            dispatch(submitCompanyRejected(error.message))
        }
    }

export const inviteUserInCompanyAsync =
    (data: CompanyUserT, companyId: string): AppThunkT =>
    async (dispatch) => {
        const { success, info } = useToast()
        try {
            dispatch(inviteUserInCompanyPending())
            if (data.user) {
                if (!data.user._id) {
                    const user = await post<UsersT>('users/inv', data.user)
                    info('company:userInvMsg', { email: data.user.email })
                    data.user = user
                    data.id = user._id
                } else {
                    data.id = data.user._id
                }
            }
            await put<CompanyUserT>(`companies/${companyId}/users`, data)

            dispatch(inviteUserInCompanyFulfilled(data))
            success(
                !data.isDisabled
                    ? 'company:successMsg.membershipActivate'
                    : 'company:successMsg.membershipDeactivate',
                { email: data.user?.email! }
            )
        } catch (error: any) {
            dispatch(inviteUserInCompanyRejected(error.message))
        }
    }

export const deleteCompanyAsync =
    (idCompany: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getCompanyPending())
            await del(`companies/${idCompany}`, undefined)
            dispatch(getCompanyFulfilled(initialState.data))

            //update companiesStats after delete Workspace
            dispatch(incrementOwned(-1))
            const { success } = useToast()
            success('company:successMsg.deleteCompany')
        } catch (error: any) {
            dispatch(activeCompanyRejected(error.message))
        }
    }

export const deleteUserFromCompanyAsync =
    (idCompany: string, userRef: UserRef): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getCompanyPending())
            await del(`companies/${idCompany}/users`, userRef)
            dispatch(removeUserFulfilled(userRef.id))
            const { success } = useToast()
            success('company:successMsg.deleteUserFromWorkspace')
        } catch (error: any) {
            dispatch(activeCompanyRejected(error.message))
        }
    }

export default companySlice.reducer
