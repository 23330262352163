import BorderRadius from './ExpandableSections/BorderRadius'
import Filters from './ExpandableSections/Filters'
import ImageProperties from './ExpandableSections/ImageProperties'
import MarginAndPadding from './ExpandableSections/MarginAndPadding'
import SizeAndPosition from './ExpandableSections/SizeAndPosition'
import Transforms from './ExpandableSections/Transforms'

interface Props {
    object: SequenceI
    handleSetStyleValue: (property: string, value: any) => void
    handleSetObjectValue: (property: string, value: any) => void
    handleSetAttributeValue: (property: string, value: any) => void
}

const SequenceTools = ({
    object,
    handleSetStyleValue,
    handleSetObjectValue,
    handleSetAttributeValue,
}: Props) => {
    return (
        <div>
            <SizeAndPosition object={object} handleSetValue={handleSetStyleValue} />

            <ImageProperties
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />

            <MarginAndPadding object={object} handleSetValue={handleSetStyleValue} />

            <BorderRadius object={object} handleSetValue={handleSetStyleValue} />

            <Filters object={object} handleSetObjectValue={handleSetObjectValue} />

            <Transforms
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
            />
        </div>
    )
}

export default SequenceTools
