import Grid from '@mui/material/Grid'
import { t } from 'i18next'
import ExpandableSection from '../../../common/ExpandableSection'
import NumberInput from '../../../common/PropertyTools/NumberInput'
import SelectInput from '../../../common/PropertyTools/SelectInput'
import TextInput from '../../../common/PropertyTools/TextInput'
import SingleAlignment from './SingleAlignment'
interface Props {
    object: AnySceneObjectT
    handleSetValue: (property: any, value: any) => void
}

const SizeAndPosition = ({ object, handleSetValue }: Props) => {
    const width: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'width'
    )
    const height: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'height'
    )

    const top: SimpleStyleT | undefined = object.styles.find((style) => style.property === 'top')
    const left: SimpleStyleT | undefined = object.styles.find((style) => style.property === 'left')
    const position: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'position'
    )
    const zIndex: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'zIndex'
    )

    const overflow: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'overflow'
    )

    const alignItems: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'alignItems'
    )

    const justifyContent: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'justifyContent'
    )

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleSetValue(e.target.name, e.target.value)
    }
    const handleAlign = (property: string, value: number) => {
        handleSetValue(property, value)
    }

    if (
        !width &&
        !height &&
        !top &&
        !left &&
        !zIndex &&
        !overflow &&
        !alignItems &&
        !justifyContent
    )
        return <></>

    return (
        <ExpandableSection
            expanded={true}
            title={t('editor:size&positionSettings.title')}
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item md={12}>
                        <SingleAlignment object={object} handleAlign={handleAlign} />
                    </Grid>
                    <Grid item>
                        {width && (
                            <NumberInput styleProperty={width} handleOnChange={handleOnChange} />
                        )}
                        {height && (
                            <NumberInput styleProperty={height} handleOnChange={handleOnChange} />
                        )}
                    </Grid>
                    <Grid item>
                        {top && <NumberInput styleProperty={top} handleOnChange={handleOnChange} />}
                        {left && (
                            <NumberInput styleProperty={left} handleOnChange={handleOnChange} />
                        )}
                    </Grid>
                    <Grid item>
                        {position && (
                            <SelectInput styleProperty={position} handleOnChange={handleOnChange} />
                        )}
                        {zIndex && (
                            <TextInput styleProperty={zIndex} handleOnChange={handleOnChange} />
                        )}
                        {overflow && (
                            <SelectInput styleProperty={overflow} handleOnChange={handleOnChange} />
                        )}
                    </Grid>
                </Grid>
            }
        />
    )
}

export default SizeAndPosition
