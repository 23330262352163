import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'

type Props = {
    left: string
    width: string
}
const TimeStampEmpty = forwardRef<HTMLDivElement, Props>(({ left, width }, ref) => {
    const theme = useTheme()
    return (
        <div
            ref={ref}
            style={{
                background: theme.palette.grey[600],
                position: 'absolute',
                left: left,
                width: width,
                textAlign: 'center',
            }}
        >
            <Typography variant="caption" color={theme.palette.text.primary}>
                ~
            </Typography>
        </div>
    )
})

export default TimeStampEmpty
