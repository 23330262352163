import Tooltip from '@mui/material/Tooltip'

interface Props {
    tooltipTitle?: string
    buttonComponent: JSX.Element
    disabled?: boolean
}

const ButtonWithTooltip = ({ tooltipTitle, buttonComponent, disabled }: Props) =>
    // renders Tooltip only when button isn't disabled
    disabled ? (
        buttonComponent
    ) : (
        //wrap component with div if component is with disabled props
        <Tooltip title={tooltipTitle}>{<span>{buttonComponent}</span>}</Tooltip>
    )

export default ButtonWithTooltip
