import { FormHelperText, Select as MuiSelect } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// interface OptionT {
//     value: number | string
//     label?: string
// }

interface Props {
    control: Control<any, any>
    name: string
    required?: boolean
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    fullWidth?: boolean
    label?: string
    options?: any[]
    disabled?: boolean
    labelField?: string
    valueField?: string
}

const Select = ({
    control,
    name,
    required,
    error,
    fullWidth,
    label,
    options,
    disabled,
    labelField,
    valueField,
}: Props) => {
    const { t } = useTranslation()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    fullWidth={fullWidth}
                    required={required}
                    error={Boolean(error)}
                    disabled={disabled}
                >
                    <InputLabel id={name}>{label || t(name)}</InputLabel>
                    <MuiSelect {...field} labelId={name}>
                        {options?.map((x) => (
                            <MenuItem
                                key={x[valueField || 'value']}
                                value={x[valueField || 'value']}
                                disabled={x.disabled}
                            >
                                {x[labelField || 'label'] || x[valueField || 'value']}
                            </MenuItem>
                        ))}
                    </MuiSelect>
                    {error && (
                        <FormHelperText>
                            {error.message ? t(error.message.toString()) : undefined}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )
}

export default Select
