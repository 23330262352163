import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import useTimeStepsDensity from '../../../../hooks/useTimeStepsDensity'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { setTimelinePreferencesAsync } from '../../../../store/slices/timelinePreferences.slice'
import ZoomSlider from '../../../common/ZoomSlider'
import TimelineCodeTriggers from './TimelineTools/TimelineCodeTriggers'
import TimelineControlsRows from './TimelineTools/TimelineControlRows'

interface Props {
    visibility: 'visible' | 'hidden'
    disabled: boolean
}

const timelineZoomStep: number = 10
const timelineMaxZoom: number = 2000
const timelineMinZoom: number = 50

const TimelineControlTools = ({ visibility, disabled }: Props) => {
    const dispatch = useAppDispatch()
    const { status } = useAppSelector((state) => state.file)

    const { refreshMasterTimeline, masterTimeline } = useTimelineActions()
    const handleRefreshMasterTimeline = () => refreshMasterTimeline()
    const { setDensityOfTimeline } = useTimeStepsDensity()

    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const framerate = timelinePreferences.framerate
    const timelineZoom = timelinePreferences.timelineZoom

    const setTimelineTweeensPerSec = (timelineZoom: number) => {
        setDensityOfTimeline(timelineZoom)
    }

    const setTimelineZoom = (zoomValue: number) => {
        setTimelineTweeensPerSec(Math.min(zoomValue, timelineMaxZoom))
        dispatch(setTimelinePreferencesAsync('timelineZoom', Math.max(zoomValue, timelineMinZoom)))
    }
    useEffect(() => {
        setDensityOfTimeline(timelineZoom)
    }, [setDensityOfTimeline, timelineZoom])

    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-between" p={1}>
            <Grid item></Grid> <Grid item></Grid>
            <Grid item flexGrow={1}>
                <ZoomSlider
                    scalePerc={Math.round((timelineZoom / 100) * 100)}
                    zoom={timelineZoom}
                    zoomStep={timelineZoomStep}
                    minZoom={timelineMinZoom}
                    maxZoom={timelineMaxZoom}
                    disabled={disabled}
                    handleSliderChange={setTimelineZoom}
                />
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="caption">
                            {!status
                                ? t('editor:settings.loading')
                                : `${
                                      framerate === 50
                                          ? t('editor:settings.50fps')
                                          : framerate === 25
                                          ? t('editor:settings.25fps')
                                          : t('editor:settings.sec')
                                  }`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="refresh timeline">
                            <span>
                                <IconButton
                                    disabled={disabled}
                                    onClick={handleRefreshMasterTimeline}
                                >
                                    <RefreshRoundedIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <TimelineControlsRows disabled={disabled || !masterTimeline} />
                    </Grid>
                    <Grid item>
                        <TimelineCodeTriggers disabled={disabled} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TimelineControlTools
