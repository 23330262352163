import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useAnimationActions } from '../../../../hooks/useAnimationActions'
import AnimationState from './AnimationState'

interface Props {
    animation: AnimationI
    type: "fromTo" | "from" | "to" | "set"
}

const AnimationStates = ({ animation, type }: Props) => {

    const { updateAnimationTweenState } = useAnimationActions()

    const handleSetAnimationState = (e: any, state: "from" | "to") =>
        updateAnimationTweenState(animation, state, e.target.name, e.target.value)

    if (animation?.tween.name === "none") return <></>

    else
        return (
            <>
                {
                    (type === "fromTo" || type === "from" || type === "set") &&
                    <Grid container direction="row" alignItems="center">
                        <Typography sx={{ m: 1 }}>from</Typography>
                        {
                            animation.tween.from.map((fromObject: SimpleStyleT, index: number) => (
                                <AnimationState
                                    key={index}
                                    state="from"
                                    animationState={fromObject}
                                    handleSetValue={handleSetAnimationState} />
                            )
                            )
                        }
                    </Grid>

                }
                {
                    (type === "fromTo" || type === "to") &&
                    <Grid container direction="row" alignItems="center">
                        <Typography sx={{ m: 1 }}>to</Typography>
                        {
                            animation.tween.to.map((toObject: SimpleStyleT, index: number) => (
                                <AnimationState
                                    key={index}
                                    state="to"
                                    animationState={toObject}
                                    handleSetValue={handleSetAnimationState} />)
                            )
                        }
                    </Grid>
                }
            </>
        )
}

export default AnimationStates
