import { Typography } from '@mui/material'
import { t } from 'i18next'
import pathToLogo from '../../assets/tweenly-logo.png'
import Link from '../common/Link'

const HeroLogo = () => {
    return (
        <>
            <Link href="/login">
                <img src={pathToLogo} alt="Tweenly logo" width="70%" />
            </Link>
            <Typography variant="caption" align="right" pt={1}>
                {t('hero:logo.subtitlePrefix')}{' '}
                <Link href="https://streamcircle.com" color="inherit" target="_blank">
                    {t('hero:logo.subtitleCompanyNameLink')}
                </Link>
            </Typography>
        </>
    )
}

export default HeroLogo
