import { RefObject, useCallback, useEffect, useMemo } from 'react'
import { setPreferencesAsync } from '../store/slices/editor.slice'
import { useCanvas } from './useCanvas'
import { useAppDispatch, useAppSelector } from './useRedux'

const useFitCanvas = (canvasRef: RefObject<HTMLDivElement>) => {
    const dispatch = useAppDispatch()
    const { canvasResolution } = useCanvas()
    const canvasZoom = useAppSelector((state) => state.editor.value.settings.canvasZoom)
    const editor = useAppSelector((state) => state.editor.value)
    const horizontalSplitterSize = editor.settings.horizontalSplitterSize
    const subVerticalSplitterSize = editor.settings.subVerticalSplitterSize
    const subHorizontalSplitterSize = editor.settings.subHorizontalSplitterSize

    const defaultAspectRation =
        Number(canvasRef.current?.clientWidth) / Number(canvasRef.current?.clientHeight)

    //if aspectRation of graphic >= defaultAspectRation then scaleCeof is canvasRef.current?.clientWidth / canvas.width
    //if aspectRation of graphic < defaultAspectRations then scaleCeof is canvasRef.current?.clientHeight / canvas.height
    const scaleCoef = useMemo(() => {
        const aspectRatio = canvasResolution.width / canvasResolution.height
        return aspectRatio >= defaultAspectRation
            ? Number(canvasRef.current?.clientWidth) / canvasResolution.width
            : Number(canvasRef.current?.clientHeight) / canvasResolution.height
    }, [canvasRef, canvasResolution.height, canvasResolution.width, defaultAspectRation])

    const xScrollAxis =
        (Number(canvasResolution.width) - Number(canvasRef.current?.clientWidth)) / 2

    const yScrollAxis =
        (Number(canvasResolution.height) - Number(canvasRef.current?.clientHeight)) / 2

    //set scrollbar axis to center
    const centerScrollBar = useCallback(() => {
        canvasRef.current?.scrollTo(xScrollAxis, yScrollAxis)
    }, [canvasRef, xScrollAxis, yScrollAxis])

    const handleFitScreen = () => {
        if (!isNaN(scaleCoef)) {
            const zoomAfterFitScreen = Math.max(scaleCoef * 100, 10) * 0.95
            if (zoomAfterFitScreen !== canvasZoom)
                dispatch(setPreferencesAsync('canvasZoom', zoomAfterFitScreen))
        }
        centerScrollBar()
    }

    //fit after every resize Splitters, or first load of Editor
    useEffect(() => {
        if (!isNaN(scaleCoef)) {
            const zoomAfterFitScreen = Math.max(scaleCoef * 100, 10) * 0.95
            if (zoomAfterFitScreen !== canvasZoom)
                dispatch(setPreferencesAsync('canvasZoom', zoomAfterFitScreen))
        }
        centerScrollBar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        centerScrollBar,
        subHorizontalSplitterSize,
        subVerticalSplitterSize,
        horizontalSplitterSize,
    ])

    return {
        handleFitScreen,
    }
}

export default useFitCanvas
