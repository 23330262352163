import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GridValueGetterParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { useMemo } from 'react'
import DataGrid, { QueryOptionsT } from '../../components/common/DataGrid'
import { dateToString } from '../../helpers/date.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { CompanyT } from '../../store/slices/company.slice'
import { selectActionsEnum } from '../../store/slices/enums.slice'
import { getTransactionsAsync } from '../../store/slices/transactions.slice'
import TransactionInfo from './TransactionInfo'

interface Props {
    company: CompanyT
    setOpenTransaction: (value: boolean) => void
}
const TransactionDetail = ({ setOpenTransaction, company }: Props) => {
    const theme = useTheme()
    const transactions = useAppSelector((state) => state.transactions)

    const transactionStats = useAppSelector((state) => state.transactionStats)

    const dispatch = useAppDispatch()

    function getTransactions(queryOptions: QueryOptionsT) {
        queryOptions.filter = {
            $and: [queryOptions.filter],
        }
        company._id && dispatch(getTransactionsAsync(company._id, queryOptions))
    }
    const ActionsEnum = useAppSelector((state) => selectActionsEnum(state))

    const transactionsColumns = useMemo(
        () => [
            {
                field: 'action',
                headerName: t('transaction:type'),
                width: 150,
                type: 'singleSelect',
                valueOptions: ActionsEnum,
            },
            {
                field: 'coins',
                headerName: t('transaction:amount'),
                width: 150,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return `${row.coins} ${t('coins')}`
                },
            },
            {
                field: 'date',
                headerName: t('transaction:date'),
                width: 150,
                type: 'date',
                valueGetter: ({ row }: any) => {
                    return row.date && dateToString(row.date)
                },
            },
            {
                field: 'user',
                headerName: t('transaction:person'),
                width: 150,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return !row.user?.firstName && !row.user?.lastName
                        ? t('transaction:anonymous')
                        : getFullNameString(row.user?.firstName, row.user?.lastName)
                },
            },
            {
                field: 'data',
                width: 250,
                headerName: t('transaction:subject'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return `${!row.data?.name ? t('transaction:noWorkspaceName') : row.data.name}`
                },
            },
        ],
        [ActionsEnum]
    )

    return (
        <Grid container spacing={3} py={3}>
            <Grid item md={12} xs={12}>
                <Grid container alignItems={'center'}>
                    <Grid item md={5} xs={12}>
                        <Button
                            onClick={() => setOpenTransaction(false)}
                            variant="text"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                        >
                            <Typography variant="body1">{company.name}</Typography>
                        </Button>
                        <Typography variant="h2">{t('transaction:transactions')}</Typography>
                    </Grid>
                    <Grid item md={7} xs={12} justifyContent={'flex-end'}>
                        <TransactionInfo transactionStats={transactionStats} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Box>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 1,
                        }}
                    >
                        <DataGrid
                            data={transactions.data}
                            total={transactions.total}
                            onChange={(queryOptions) => getTransactions(queryOptions)}
                            columns={transactionsColumns}
                            noResultsEl={t('transactions:no-results')}
                            loading={!transactions.status}
                            hideCreateButtonInToolbar
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default TransactionDetail
