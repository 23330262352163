import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StateT = {
    value: string | null
    selected: string[]
}

const initialState: StateT = {
    value: null,
    selected: [],
}

type PayloadT = {
    id: string
}

export const activeAnimationSlice = createSlice({
    name: 'activeAnimation',
    initialState,
    reducers: {
        setActiveAnimationAction: (state, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.id
        },
        removeActiveAnimationAction: (state) => {
            state.value = null
        },
        addSelectedAnimationsAction: (state, action: PayloadAction<string[]>) => {
            const set = new Set([...state.selected, ...action.payload])
            state.selected = Array.from(set)
        },
        removeSelectedAnimationsAction: (state, action: PayloadAction<string[]>) => {
            state.selected = state.selected.filter((id) => !action.payload.includes(id))
        },
        clearSelectedAnimationsAction: (state) => {
            state.selected = []
        },
    },
})

export const {
    setActiveAnimationAction,
    removeActiveAnimationAction,
    addSelectedAnimationsAction,
    removeSelectedAnimationsAction,
    clearSelectedAnimationsAction,
} = activeAnimationSlice.actions

export default activeAnimationSlice.reducer
