import UploadIcon from '@mui/icons-material/Upload'
import { Button, Tooltip } from '@mui/material'
import { ChangeEvent, MutableRefObject, useRef } from 'react'
import { useEditorSettings } from '../../../hooks/useEditorSettings'
import { useAppDispatch } from '../../../hooks/useRedux'
import { createFontAsync } from '../../../store/slices/assets.slice'
import { useTranslation } from 'react-i18next'

interface Props {
    onChange: (value: string) => void
}

export const LoadFontButton = ({ onChange }: Props) => {
    const ref: MutableRefObject<HTMLInputElement | null> = useRef(null)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const { companyId } = useEditorSettings()

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]
            dispatch(createFontAsync(file, companyId, (asset) => onChange(asset.name)))
        }
    }

    return (

        <>
            <Tooltip title={t('editor:addNewFont')}>
            <Button
                variant="text"
                    color="success"
                size="large"
                onClick={() => ref.current?.click()}
                startIcon={<UploadIcon />}
                    sx={{ mt: 1 }}
            />
            </Tooltip>
            <input
                type="file"
                ref={ref}
                style={{ display: 'none' }}
                accept=".ttf,.wof" // .otf (?)
                onChange={handleChange}
            />
        </>

    )
}
