import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { EaseStyles, defaultEase } from '../../../../data/styles/ease.styles'
import ExpandableSection from '../../../common/ExpandableSection'

interface Props {
    animation: AnimationI
    selectedAnimations: AnimationI[]
    handleSetValue: (property: any, value: any) => void
    handleSetValues: (animations: AnimationI[], property: any, value: any) => void
}

const Easing = ({ animation, selectedAnimations, handleSetValue, handleSetValues }: Props) => {

    const handleSetEaseStyle = (value: any): void => {
        const ease = EaseStyles.find((e) => e.easeStyle === value)
        if (ease) handleSetValue('ease', ease)
    }

    const handleSetEaseType = (value: string): void => {
        if (animation.ease) {
            handleSetValue('ease.easeType', value)
        } else {
            const ease = {
                ...defaultEase,
                easeType: value,
            }
            handleSetValue('ease', ease)
        }
    }

    const handleSetEaseStrength = (value: string): void => {
        if (animation.ease) {
            handleSetValue('ease.easeStrength', value)
        } else {
            const ease = {
                ...defaultEase,
                easeStrength: value,
            }
            handleSetValue('ease', ease)
        }
    }

    return (
        <ExpandableSection
            expanded={true}
            title={"Easing"}
            component={
                <>
                    <FormControl>
                        <InputLabel>ease style</InputLabel>
                        <Select
                            native
                            name="ease.easeStyle"
                            value={
                                animation.ease
                                    ? animation.ease.easeStyle
                                    : defaultEase.easeStyle
                            }
                            onChange={(e) => handleSetEaseStyle(e.target.value)}
                        >
                            {EaseStyles.map((ease: EaseT, index: number) => {
                                return (
                                    <option key={ease.easeStyle} value={ease.easeStyle}>
                                        {ease.easeStyle}
                                    </option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>ease type</InputLabel>
                        <Select
                            native
                            name="ease.easeType"
                            value={
                                animation.ease ? animation.ease.easeType : defaultEase.easeType
                            }
                            onChange={(e) => handleSetEaseType(e.target.value)}
                        >
                            <option value="in">in</option>
                            <option value="out">out</option>
                            <option value="inOut">inOut</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>ease strength</InputLabel>
                        <Select
                            native
                            name="ease.easeStrength"
                            value={
                                animation.ease
                                    ? animation.ease.easeStrength
                                    : defaultEase.easeStrength
                            }
                            onChange={(e) => handleSetEaseStrength(e.target.value)}
                        >
                            <option value="extra-soft">extra-soft</option>
                            <option value="soft">soft</option>
                            <option value="normal">normal</option>
                            <option value="strong">strong</option>
                            <option value="extra-strong">extra-strong</option>
                        </Select>
                    </FormControl>
                </>
            }
        />
    )
}

export default Easing
