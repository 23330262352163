import { IconButton, Tooltip } from '@mui/material'
interface Props {
    tooltipTitle: string
    children: React.ReactElement<any, any>
    onClick: () => void
    disabled: boolean
}
const CanvasCursorIcon = ({ tooltipTitle, children, onClick, disabled }: Props) => {
    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <IconButton
                    disabled={disabled}
                    onClick={onClick}
                    sx={{ '&.Mui-disabled': { color: (theme) => theme.palette.primary.light } }}
                >
                    {children}
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default CanvasCursorIcon
