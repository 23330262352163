import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { getFile } from '../../helpers/ajax.helpers'
import { fileToBase64 } from '../../helpers/file.helpers'
import { AppThunkT } from '../store'
interface ThumbnailMapFileStateT {
    data: Record<string, ThumbnailMap>
    status?: string
}

const initialState: ThumbnailMapFileStateT = {
    data: {},
    status: Status.OK,
}

interface ThumbnailMap {
    id: string
    src?: string
}

export const thumbnailMapSlice = createSlice({
    name: 'thumbnailMap',
    initialState,
    reducers: {
        getThumbnailMapPending: (state: ThumbnailMapFileStateT) => {
            state.status = undefined
        },
        getThumbnailMapFulfilled: (
            state: ThumbnailMapFileStateT,
            action: PayloadAction<ThumbnailMap>
        ) => {
            state.status = Status.OK
            state.data = { ...state.data, [action.payload.id]: action.payload }
        },
        getThumbnailMapRejected: (state: ThumbnailMapFileStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getThumbnailMapPending, getThumbnailMapFulfilled, getThumbnailMapRejected } =
    thumbnailMapSlice.actions

export const getThumbnailFileAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getThumbnailMapPending())
            const data = await getFile(id)
            const src = await fileToBase64(data)
            dispatch(getThumbnailMapFulfilled({ id, src }))
        } catch (error: any) {
            dispatch(getThumbnailMapRejected(error.message))
        }
    }

export default thumbnailMapSlice.reducer
