import { DependencyList, RefObject, useCallback, useEffect, useState } from 'react'

const useInViewport = (
    ref: RefObject<HTMLDivElement>,
    dependencyList: DependencyList,
    viewPortClassName: string
) => {
    const [isInViewport, setIsInViewport] = useState(false)

    /**
     * function for checking if element is visible or partially visible (depend on @param partiallyVisible) in element with viewPortClassName
     * @param partiallyVisible - if partiallyVisible = true, then return isVisible = true when element is partially visible
     * @set isInViewport = true if element is visible || partially visible
     */
    const checkIfInViewport = useCallback(
        (partiallyVisible = false) => {
            const item = ref.current?.getBoundingClientRect()

            const innerHeight = window.innerHeight || document.documentElement.clientHeight
            const innerWidth = window.innerWidth || document.documentElement.clientWidth
            if (item) {
                const isVisible = partiallyVisible
                    ? ((item.top > 0 && item.top < innerHeight) ||
                          (item.bottom > 0 && item.bottom < innerHeight)) &&
                      ((item.left > 0 && item.left < innerWidth) ||
                          (item.right > 0 && item.right < innerWidth))
                    : item.top >= 0 &&
                      item.left >= 0 &&
                      item.bottom <= innerHeight &&
                      item.right <= innerWidth

                setIsInViewport(isVisible)
            }
        },
        [ref]
    )

    useEffect(() => {
        checkIfInViewport(true) // Initial check

        const handleScroll = () => {
            checkIfInViewport(true)
        }
        const collection = document.getElementsByClassName(viewPortClassName)

        collection[0].addEventListener('scroll', handleScroll)

        return () => {
            collection[0]?.removeEventListener('scroll', handleScroll)
        }
    }, [checkIfInViewport, viewPortClassName, dependencyList])

    return { isInViewport }
}
export default useInViewport
