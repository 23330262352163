import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface LoadingT {
    value: boolean
    valuePercent: number
}

const initialState: LoadingT = {
    value: false,
    valuePercent: 0,
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state: LoadingT, action: PayloadAction<boolean>) => {
            if (action.payload === false) state.valuePercent = 0
            state.value = action.payload
        },
        setPercentLoading: (state: LoadingT, action: PayloadAction<number>) => {
            state.valuePercent = action.payload
        },
    },
})

export const { setLoading, setPercentLoading } = loadingSlice.actions

export default loadingSlice.reducer
