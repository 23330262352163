export const backgroundColor: GroupStyleT = {
	property: "backgroundColor",
	value: { r: 0, g: 169, b: 206, a: 1 },
	type: "fill",
	input: {
		type: "color",
		props: "",
		label: "color",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? {
					property: "background-color",
					value: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
			  }
			: {
					property: "backgroundColor",
					value: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
			  },
}

export const linearGradient: GroupStyleT = {
	property: "linearGradient",
	value: "90deg, rgba(60,100,149,1) 0%, rgba(45,135,195,1) 100%",
	type: "fill",
	input: {
		type: "string",
		props: "",
		label: "linear gradient",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "background-image", value: `linear-gradient(${value})` }
			: { property: "backgroundImage", value: `linear-gradient(${value})` },
}

export const backgroundImage: GroupStyleT = {
	property: "backgroundImage",
	value: "none",
	type: "fill",
	input: {
		type: "image",
		props: "",
		label: "image",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "background-image", value }
			: { property: "backgroundImage", value },
}

export const fillProperties = [backgroundColor, linearGradient, backgroundImage]
export const fillNames = ["backgroundColor", "linearGradient", "backgroundImage"]
