import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'
import { selectUndoable } from '../../../../../helpers/selector.helpers'
import { useAppSelector } from '../../../../../hooks/useRedux'
import { selectAnimationById } from '../../../../../store/slices/animations.slice'
import { TIMELINE_ROW, TIMELINE_TWEEN } from '../../../../../style/sizing'

type Props = {
    animationId: string
    TimelineTweenWidth: number
}

const TimelineTweenEmpty = forwardRef<HTMLDivElement, Props>(
    ({ animationId, TimelineTweenWidth }, ref) => {
        const animation: AnimationI = useAppSelector((state) =>
            selectAnimationById(selectUndoable(state).animations, animationId)
        )
        const theme = useTheme()
        return (
            <div
                ref={ref}
                style={{
                    position: 'absolute',
                    width: TimelineTweenWidth * Number(animation?.duration) - 2 + 'px',
                    height: TIMELINE_TWEEN.height - 6 * TIMELINE_ROW.paddingBottom + 'px',
                    left: TimelineTweenWidth * Number(animation?.delay) + 'px',
                    margin: TIMELINE_ROW.paddingBottom * 3 + 'px 2px',
                    backgroundColor: theme.palette.grey[50],
                }}
            ></div>
        )
    }
)

export default TimelineTweenEmpty
