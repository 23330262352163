import { AppStateT } from '../store/store'

export const selectUndoable = (state: AppStateT) => state.undoable.present
export const selectCanUndo = (state: AppStateT) => state.undoable.past.length > 0
export const selectCanRedo = (state: AppStateT) => state.undoable.future.length > 0

export const selectRefreshLabels = (state: AppStateT) => {
    const timeline = state.undoable.present.timeline.value
    return timeline.labels ? timeline.labels.filter((lbl) => lbl.type === 'refresh') : []
}
