import { Backdrop, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    const value = Math.round(props.value)
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={value} size={60} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6" component="div" color="inherit">{`${value}%`}</Typography>
            </Box>
        </Box>
    )
}
interface Props {
    open: boolean
    progress: number
}
export default function LoadingBackdropWithLabel({ open, progress }: Props) {
    const theme = useTheme()
    return (
        <Backdrop
            sx={{
                color: theme.palette.primary.main,
                zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={open}
        >
            <CircularProgressWithLabel value={progress} />
        </Backdrop>
    )
}
