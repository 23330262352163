import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ErrorState {
    status?: string
}

export const errorSlice = createSlice({
    name: 'error',
    initialState: {},
    reducers: {
        showError: (state: ErrorState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { showError } = errorSlice.actions

export default errorSlice.reducer
