import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { t } from 'i18next'
import { useState } from 'react'
import { License } from '../../constants/license.enum'
import { dateToString } from '../../helpers/date.helpers'
import { checkSuperAdminPermissions } from '../../helpers/permissions.helpers'
import { useAppSelector } from '../../hooks/useRedux'
import UpgradeDialog from './UpgradeDialog'

export default function UserLicense() {
    const theme = useTheme()
    const licenseObj = useAppSelector((state) => state.user.value.license)
    const userPermissions = useAppSelector((state) => state.user.value.permissions)
    const isSuperAdmin = checkSuperAdminPermissions(userPermissions)
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState<boolean>(false)
    const expired = licenseObj?.expired && new Date(licenseObj?.expired).getTime() < Date.now()

    const license = licenseObj?.license ?? License.Free
    const paidLicense = licenseObj?.paidLicense ?? License.Free
    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.paper, p: 4 }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item py={2} md={3}>
                        <Typography variant="body1" mb={1} sx={{ fontWeight: 'bold' }}>
                            {t('user:userLicenseDataGrid.header.license')}
                        </Typography>
                        {/* <Grid item sx={{ borderBottom: 1, mb: 1 }}></Grid> */}
                        <Typography variant="body1">
                            {isSuperAdmin
                                ? t('license:superAdmin')
                                : t(
                                      `license:${
                                          !licenseObj || !licenseObj?.paidLicense
                                              ? License.Free
                                              : licenseObj?.paidLicense
                                      }`
                                  )}
                        </Typography>
                    </Grid>
                    <Grid item py={2} md={3}>
                        <Typography variant="body1" mb={1} sx={{ fontWeight: 'bold' }}>
                            {t('user:userLicenseDataGrid.header.period')}
                        </Typography>
                        {/* <Grid item sx={{ borderBottom: 1, mb: 1 }}></Grid> */}
                        <Typography variant="body1">
                            {isSuperAdmin
                                ? t('license:period.limitless')
                                : !licenseObj?.period
                                ? '-'
                                : licenseObj?.period === 12
                                ? t('license:period.yearly')
                                : t('license:period.monthly')}
                        </Typography>
                    </Grid>
                    <Grid item py={2} md={3}>
                        <Typography variant="body1" mb={1} sx={{ fontWeight: 'bold' }}>
                            {t('user:userLicenseDataGrid.header.status')}
                        </Typography>
                        {/* <Grid item sx={{ borderBottom: 1, mb: 1 }}></Grid> */}
                        <Typography variant="body1">
                            {isSuperAdmin
                                ? t('user:userLicenseDataGrid.active')
                                : expired
                                ? t('user:userLicenseDataGrid.expiredLicense')
                                : license === paidLicense
                                ? t('user:userLicenseDataGrid.active')
                                : `${t(`user:userLicenseDataGrid.pendingForVerify`)} ${t(
                                      `license:${license}`
                                  )}`}
                            {expired && (
                                <Tooltip
                                    placement={'right'}
                                    title={
                                        isSuperAdmin
                                            ? t('user:userLicenseDataGrid.superAdminTooltip')
                                            : t('user:userLicenseDataGrid.expiredTooltip')
                                    }
                                >
                                    <InfoIcon color={'disabled'} />
                                </Tooltip>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item py={2} md={3}>
                        <Typography variant="body1" mb={1} sx={{ fontWeight: 'bold' }}>
                            {t('user:userLicenseDataGrid.header.expired')}
                        </Typography>
                        {/* <Grid item sx={{ borderBottom: 1, mb: 1 }}></Grid> */}
                        <Typography variant="body1">
                            {isSuperAdmin
                                ? dateToString(new Date('3131-11-11'))
                                : licenseObj?.expired
                                ? dateToString(licenseObj.expired)
                                : '-'}
                        </Typography>
                    </Grid>
                </Grid>
                {!isSuperAdmin && (
                    <Grid container>
                        <>
                            <Tooltip placement={'right'} title={t('user:button:upgradeTooltip')}>
                                <span>
                                    <Button
                                        disabled={false}
                                        onClick={() => setOpenUpgradeDialog(true)}
                                    >
                                        {t('user:button.upgrade')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    </Grid>
                )}
            </Box>
            {openUpgradeDialog && (
                <UpgradeDialog
                    open={openUpgradeDialog}
                    onClose={() => setOpenUpgradeDialog(false)}
                />
            )}
        </>
    )
}
