import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Container, Grid, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataGrid, { QueryOptionsT } from '../components/common/DataGrid'
import CompanyForm from '../components/company/CompanyForm'
import TransactionDetail from '../components/company/TransactionDetail'

import { useTheme } from '@mui/material/styles'
import { Status } from '../constants/status'
import { useCompanyStats } from '../hooks/useCompanyStats'
import useId from '../hooks/useId'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { useWorkspacePermission } from '../hooks/useWorkspacePermission'
import { getCompaniesCurrentAsync } from '../store/slices/companies.slice'
import { getCompaniesStatsAsync } from '../store/slices/companiesStats.slice'
import { UserRef, getCompanyAsync, getCompanyResetFulfilled } from '../store/slices/company.slice'
import {
    getTransactionStatsAsync,
    getTransactionStatsResetFulfilled,
} from '../store/slices/transactionStats.slice'

const NumberOfGraphicsCell = ({ id }: { id: string }) => {
    const { graphics } = useCompanyStats({ id: id })

    return <span>{graphics ?? '-'}</span>
}

const SpaceUsedCell = ({ id }: { id: string }) => {
    const { spaceUsed } = useCompanyStats({ id: id })

    return <span>{`${Math.round(spaceUsed / 1000000)} MB`}</span>
}

export default function UserCompanies() {
    const theme = useTheme()
    const { t } = useTranslation()
    const [isGrid, setOpenGrid] = useState<boolean>(true)
    const [openTransaction, setOpenTransaction] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const companies = useAppSelector((state) => state.companies)
    const companiesStats = useAppSelector((state) => state.companiesStats)
    const user = useAppSelector((state) => state.user.value)
    const company = useAppSelector((state) => state.company)
    const { canCreateWorkspace } = useWorkspacePermission()

    const callback = useCallback(
        (id: string) => {
            setOpenGrid(false)
            dispatch(getCompanyAsync(id))
            dispatch(getTransactionStatsAsync(id))
        },
        [dispatch]
    )
    const { id, setId } = useId(callback)

    useEffect(() => {
        if (!id) {
            setOpenGrid(true)
            setOpenTransaction(false)
            //it is necessary for prevent loading in workspace detail
            //and for clear state when use browser back action
            dispatch(getCompanyResetFulfilled())
            dispatch(getTransactionStatsResetFulfilled())
        }
    }, [dispatch, id])

    useEffect(() => {
        if (company.status === Status.NO_COMPANY_PERMISSION) {
            setId(null)
            setOpenGrid(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company.status])

    useEffect(() => {
        dispatch(getCompaniesStatsAsync())
    }, [dispatch])

    const onSelect = (id: string | null) => {
        if (id === null) {
            setOpenGrid(false)
        } else if (companies.data.find((x) => x._id === id && x.isPrivate !== true)) {
            setId(id)
            setOpenGrid(false)
        }
    }

    const handleSetOpenGrid = () => {
        setId(null)
        setOpenGrid(true)
    }
    const handleChange = (queryOptions: QueryOptionsT) => {
        dispatch(
            getCompaniesCurrentAsync({
                ...queryOptions,
                filter: {
                    $and: [queryOptions.filter],
                },
            })
        ) //TO DO add setQuery and send defaultQuery to dataGrid
    }

    const handleDeletedWorkspace = () => {
        setId(null)
        setOpenGrid(true)
    }

    const companyColumns = useMemo(
        () => [
            {
                field: 'name',
                headerName: t('company:dataGridHeader.name'),
                minWidth: 200,
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => {
                    return (
                        <>
                            {row.isPrivate ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {`${row.name}`}
                                    <Tooltip
                                        placement={'right'}
                                        title={t('company:infoTooltip.privateWorkspaceInfo')}
                                    >
                                        <InfoIcon color={'disabled'} />
                                    </Tooltip>
                                </Box>
                            ) : (
                                row.name
                            )}
                        </>
                    )
                },
            },
            {
                field: 'yourRole',
                sortable: false,
                headerName: t('company:dataGridHeader.yourRole'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    const userRole = row.usersRef?.find((u: UserRef) => u.id === user._id)?.role
                    return t(`company:statusRole.${userRole}`)
                },
            },
            {
                field: 'members',
                sortable: false,
                headerName: t('company:dataGridHeader.members'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    // return `${row?.usersRef.filter((u: UserRef) => u.isActive === true).length}`
                    const membersNumber = row?.usersRef.filter((u: UserRef) => !u.isDisabled).length
                    return `${membersNumber === 0 ? '-' : membersNumber}`
                },
            },
            {
                field: 'numberOfGraphics',
                sortable: false,
                headerName: t('company:dataGridHeader.numberOfGraphics'),

                renderCell: ({ id }: GridRenderCellParams) => {
                    return <NumberOfGraphicsCell id={id.toString()} />
                },
            },
            {
                field: 'spaceUsed',
                sortable: false,
                headerName: t('company:dataGridHeader.spaceUsed'),
                renderCell: ({ id }: GridRenderCellParams) => {
                    return <SpaceUsedCell id={id.toString()} />
                },
            },
            {
                field: 'coins',
                sortable: false,
                headerName: t('company:dataGridHeader.wallet'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return `${row.isPrivate !== true ? `${row.coins} ${t('coins')}` : `-`}`
                },
            },
        ],
        [t, user._id]
    )
    const dataWithClassName = companies.data.map((company) => ({
        ...company,
        className: company.isPrivate ? '' : 'pointer',
    }))

    return (
        <Container component="main" maxWidth="lg">
            {isGrid && (
                <>
                    <Grid container py={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} py={3} alignItems={'center'}>
                                <Grid item md={10} sm={9} xs={9}>
                                    <Typography variant="h2">
                                        {t('company:companyTitle')}
                                    </Typography>
                                </Grid>
                                <Grid item md={2} sm={3} xs={3}>
                                    <Grid container justifyContent="flex-end" pr={2}>
                                        <Button
                                            id={
                                                companiesStats.data?.owned < 1
                                                    ? ''
                                                    : 'workspace-step-1'
                                            }
                                            onClick={() => onSelect(null)}
                                            disabled={!canCreateWorkspace}
                                        >
                                            {t('company:newWorkspace')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 1,
                                }}
                            >
                                <DataGrid
                                    data={dataWithClassName}
                                    total={companies.total}
                                    onChange={(queryOptions) => handleChange(queryOptions)}
                                    columns={companyColumns}
                                    onSelect={onSelect}
                                    disabledCreateNewOnSelect={!canCreateWorkspace}
                                    noResultsEl={t('company:noResults')}
                                    loading={!companies.status}
                                    hideCreateButtonInToolbar
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {companiesStats.status &&
                        companiesStats.data?.owned < 1 &&
                        canCreateWorkspace && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                mt={2}
                            >
                                <Typography variant="h4">
                                    {t('company:showNoOwnInfo.titleNoOwnWorkspace')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    mt={2}
                                    mb={4}
                                    width={450}
                                    textAlign={'center'}
                                >
                                    {t('company:showNoOwnInfo.descriptionNoOwnWorkspace')}
                                </Typography>

                                <Button id={'workspace-step-1'} onClick={() => onSelect(null)}>
                                    {t('company:newWorkspace')}
                                </Button>
                            </Box>
                        )}
                </>
            )}
            {!isGrid && !openTransaction && (
                <Grid container py={3}>
                    <Grid item xs={8} md={10}>
                        <Button
                            onClick={handleSetOpenGrid}
                            variant="text"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                        >
                            <Typography variant="body1">{t('company:gridTitle')}</Typography>
                        </Button>
                        <Typography variant="h2">
                            {!company.status || !company.data.name
                                ? t('company:companyTitle')
                                : company.data.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Grid container direction="row" justifyContent="center">
                            <Box
                                py={1}
                                px={2}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 1,
                                }}
                            >
                                <Typography variant="body1">
                                    <b>{t('transaction:accountBalance')}</b>
                                </Typography>
                                <Typography variant="body2">
                                    {`${company.data.coins} ${t('transaction:coins')}`}
                                </Typography>
                            </Box>
                            <Button
                                variant="text"
                                sx={{ ml: 0, mt: 1 }}
                                onClick={() => setOpenTransaction(true)}
                                disabled={!company.data._id}
                            >
                                {t('transaction:seeAllTransactions')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item md={12} py={2} sx={{ borderBottom: 1 }}></Grid>
                    <Grid item md={12} xs={12}>
                        <Box>
                            <CompanyForm
                                onDelete={handleDeletedWorkspace}
                                setOpenTransaction={(value) => setOpenTransaction(value)}
                                loading={!company.status}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
            {!isGrid && openTransaction && (
                <TransactionDetail
                    setOpenTransaction={(value) => setOpenTransaction(value)}
                    company={company.data}
                />
            )}
        </Container>
    )
}
