export const color: SimpleStyleT = {
	property: "color",
	value: { r: 100, g: 100, b: 100, a: 1 }, // "#ccc",
	input: {
		type: "string",
		props: "",
	},
	render: (value) => ({
		property: "color",
		value: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
	}),
}

export const backgroundSize: SimpleStyleT = {
    property: "backgroundSize",
    value: "contain",
    input: {
        type: "select",
        props: ["auto", "cover", "contain"],
        label: "background size",
    },
    render: (value, unit = undefined, type) =>
        type === "css"
            ? { property: "background-size", value }
            : { property: "backgroundSize", value },
}

export const backgroundRepeat: SimpleStyleT = {
    property: "backgroundRepeat",
    value: "no-repeat",
    input: {
        type: "select",
        props: ["repeat", "repeat-x", "repeat-y", "no-repeat"],
        label: "background repeat",
    },
    render: (value, unit = undefined, type) =>
        type === "css"
            ? { property: "background-repeat", value }
            : { property: "backgroundRepeat", value },
}

export const backgroundPosition: SimpleStyleT = {
	property: "backgroundPosition",
	value: "center center",
	input: {
		type: "select",
		props: [
			"left top",
			"left center",
			"left bottom",
			"right top",
			"right center",
			"right bottom",
			"center top",
			"center center",
			"center bottom",
		],
		label: "background position",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "background-position", value }
			: { property: "backgroundPosition", value },
}
