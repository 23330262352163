import { useAppSelector } from './useRedux'

export const useEditorSettings = () => {
    const settings = useAppSelector((state) => state.editor.value.settings)
    const graphic = useAppSelector((state) => state.graphic.data)

    const companyId = graphic.companyId || settings.companyId!

    return { ...settings, companyId }
}
