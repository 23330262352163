import { GraphicT } from '../store/slices/graphic.slice'
import { GraphicAsset } from '../store/slices/graphics.slice'
import { SimilarGraphicT } from '../store/slices/similarGraphics.slice'
import { EMPTY_IMAGE_SRC } from './object.helpers'

//---------------------------------------------------------------------------
//Save As action, name must be unique in company
//change graphic name to next version example (name + (1))
//---------------------------------------------------------------------------
export const duplicateGraphicData = (data: GraphicT, finalNumber: number) => {
    return {
        ...data,
        _id: '',
        isFinal: false,
        isPublished: false,
        name: data.name.match(/(\()(\d+)(\))$/)
            ? data.name.replace(
                  /(\()(\d+)(\))$/,
                  ($0: any, $1: number) => $1 + (finalNumber + 1) + ')'
              )
            : finalNumber === 0
            ? data.name + ' (1)'
            : data.name + ` (${finalNumber + 1})`,
    }
}

//---------------------------------------------------------------------------
//Duplicate, name must be unique in company
//change graphic name to next version example (name + (1))
//---------------------------------------------------------------------------
export const duplicateGraphicName = (data: GraphicT, finalNumber: number) => {
    let name = data.name.match(/(\()(\d+)(\))$/)
        ? data.name.replace(/(\()(\d+)(\))$/, ($0: any, $1: number) => $1 + (finalNumber + 1) + ')')
        : finalNumber === 0
        ? data.name + ' (1)'
        : data.name + ` (${finalNumber + 1})`
    return name
}

//------------------------------------------------------
//find biggest number of version in same named graphics
//------------------------------------------------------
export const findNextNumberVersion = (similarData: SimilarGraphicT[]) => {
    let finalNumber = 0
    similarData.forEach((x) => {
        const string = x.name.match(/(\()(\d+)(\))$/)
        const number = parseInt(string ? string[0].replace(/[^0-9]/g, '') : '')

        return finalNumber < number ? (finalNumber = number) : finalNumber
    })
    return finalNumber
}

export const getGraphicImageUrl = (graphic: GraphicAsset) => {
    switch (graphic.type) {
        case 'font':
        case 'json':
        case 'xml':
        case 'text':
            return EMPTY_IMAGE_SRC
        case 'image':
        case 'sequence':
        default:
            return graphic.url ?? EMPTY_IMAGE_SRC
    }
}
export const getAssetTypeMsg = (assetType: string) => {
    if (['json', 'xml', 'text'].includes(assetType)) {
        return 'DataSource'
    } else {
        return assetType[0].toUpperCase() + assetType.slice(1)
    }
}

export type ImageSize = {
    width: number
    height: number
}

export function getImageSize(url: string): Promise<ImageSize> {
    return new Promise((resolve, reject) => {
        const img = new Image()

        img.onload = () => {
            resolve({ width: img.width, height: img.height })
        }

        img.onerror = (err) => {
            reject(new Error(`Failed to load image at ${url}`))
        }

        img.src = url
    })
}
