import { Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import TopNavbar from '../components/TopNavbar'
import { useSession } from '../hooks/useSession'
import { routes } from '../routes'

const NotFound = () => {
    const { t } = useTranslation()
    const session = useSession()

    if (!session.data) {
        return <Navigate to="/login" />
    } else {
        return (
            <>
                <TopNavbar routes={routes} />
                <Container component="main" maxWidth="lg">
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="stretch"
                        sx={{ height: '90vh' }}
                    >
                        <Typography variant="h2">{t('notFoundHeader')}</Typography>
                        <Typography variant="h5" mt={2} mb={4}>
                            {t('notFoundInfo')}
                        </Typography>
                        <Button href="/app/editor">{t('goToEditorLink')}</Button>
                    </Grid>
                </Container>
            </>
        )
    }
}
export default NotFound
