import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

function useId(callback?: (id: string) => void) {
    const [searchParams, setSearchParams] = useSearchParams()

    const id = searchParams.get('id')

    useEffect(() => {
        if (id) {
            callback?.(id)
        }
    }, [id, callback])

    const setId = (id: string | null) => {
        setSearchParams(id ? { id } : {})
    }

    return { id, setId }
}

export default useId
