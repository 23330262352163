import AbstractContainer from './AbstractContainer'

interface Props {
    object: ItemI
    isHiding: boolean
    canvasScaleCoef: number
    borderCoef: number
    handleSelectObject: (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        ctrlCmdKey: boolean
    ) => void
}
const ItemContainer = ({
    object,
    isHiding,
    canvasScaleCoef,
    borderCoef,
    handleSelectObject,
}: Props) => {
    return (
        <AbstractContainer
            object={object}
            isHiding={isHiding}
            canvasScaleCoef={canvasScaleCoef}
            borderCoef={borderCoef}
            handleSelectObject={handleSelectObject}
            className={'item-container'}
            visibility={object.isHidden || isHiding ? 'hidden' : 'visible'}
        />
    )
}

export default ItemContainer
