import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useElementSize } from 'usehooks-ts'
import { useCanvas } from '../../hooks/useCanvas'
import useHandTool from '../../hooks/useHandTool'
import { useAppSelector } from '../../hooks/useRedux'
import { CanvasCursor } from '../../store/slices/canvasCursor.slice'
import Canvas from './Preview/Canvas'
import PreviewTools from './Preview/PreviewTools'

const Preview = () => {
    const canvasCursorReducer = useAppSelector((state) => state.canvasCursor.value)
    const canvasCursor =
        canvasCursorReducer === CanvasCursor.GrabCursorBySpace
            ? CanvasCursor.GrabCursor
            : canvasCursorReducer
    const enableGrab = canvasCursor !== CanvasCursor.DefaultCursor
    const { canvasResolution: canvas } = useCanvas()
    const canvasZoom = useAppSelector((state) => state.editor.value.settings.canvasZoom)
    const zoomScale: number = canvasZoom / 100

    // zjišťování velikosti plátna, pokud se scaluje např. podle velikosti okna prohlížeče
    // to je nyní nevyužito, ale možná bude potřeba při implementaci lepšího zoomu canvasu
    const [ref, { width, height }] = useElementSize()

    const { ref: canvasRef, handleFocusOnClick } = useHandTool(enableGrab)
    const [cursor, setCursor] = useState<CanvasCursor>(canvasCursor)

    useEffect(() => {
        setCursor(canvasCursor)
    }, [canvasCursor])

    const calculateWidth =
        canvasRef.current?.offsetWidth && canvasRef.current?.offsetWidth > canvas.width
            ? canvasRef.current?.offsetWidth
            : zoomScale === 2
            ? canvas.width * 2.5
            : zoomScale >= 1
            ? canvas.width * 2
            : canvas.width

    const calculateHeight =
        zoomScale === 2 ? canvas.height * 2.5 : zoomScale >= 1 ? canvas.height * 2 : canvas.height

    return (
        //display: grid ensures correctly displayed previewTool and Preview
        <Box sx={{ display: 'grid', height: '100%', minWidth: 600 }}>
            <PreviewTools
                size={{
                    width: width,
                    height: height,
                }}
                canvasRef={canvasRef}
            />
            <ScrollContainer
                innerRef={canvasRef}
                onClick={handleFocusOnClick}
                vertical={enableGrab}
                horizontal={enableGrab}
                hideScrollbars={false}
                onStartScroll={() => enableGrab && setCursor(CanvasCursor.GrabbingCursor)}
                onEndScroll={() => enableGrab && setCursor(canvasCursor)}
                style={{
                    width: '100%',
                    cursor: cursor,
                    outline: 'none',
                }}
            >
                {/* this div wrapper is needed because transform: scale in canvas-div-wrapper, 
                for center div when zooming and when canvasWidth is less than canvasRef.width */}
                <div
                    id="center-resize-wrapper"
                    className="center-resize-wrapper"
                    style={{
                        width: calculateWidth,
                        height: calculateHeight,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        className="canvas-div-wrapper"
                        ref={ref}
                        style={{
                            transform: `scale(${zoomScale})`,
                            width: canvas.width,
                            height: canvas.height,
                            transformOrigin: 'center',
                            position: 'relative',
                            zIndex: 0,
                        }}
                    >
                        <Canvas
                            size={{
                                width: width,
                                height: height,
                            }}
                            zoomScale={zoomScale}
                        />
                    </div>
                </div>
            </ScrollContainer>
        </Box>
    )
}

export default Preview
