import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded'
import { Button, IconButton, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

interface Props {
    handleSetValue: (e: any) => void
}

const BackgroundImageRemove = ({ handleSetValue }: Props) => {
    const { t } = useTranslation()
    const handleDelete = () =>
        handleSetValue({ target: { name: 'backgroundImage', value: 'none' } })

    return (
        <Grid container direction="row" alignItems="center">
            <Tooltip title={t('editor:imageUploaded')}>
                <span>
                    <Button variant="text" startIcon={<LibraryAddCheckRoundedIcon />} disabled>
                        {t('editor:imageUploaded')}
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={t('editor:removeImage')}>
                <IconButton onClick={handleDelete} color="error">
                    <ClearRoundedIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    )
}

export default BackgroundImageRemove
