import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { set } from 'lodash'

export type AnimationsStateT = {
    value: Record<string, AnimationI | undefined>
}

const initialState: AnimationsStateT = {
    value: {},
}

type PayloadT = {
    id: string
    animation: AnimationI
    animationIds?: string[]
}

type PayloadUpdT = {
    id: string
    property: string
    value?: any
}

type PayloadDelT = {
    id: string
}

type PayloadDeleteAnimationIds = {
    animationIds: string[]
}

const animationNode = (state: AnimationI, payload: PayloadUpdT): AnimationI => {
    return set(state, payload.property, payload.value)
}

const deleteOne = (state: AnimationsStateT, id: string) => {
    if (state) delete state.value[id]

    return state
}

const deleteMany = (state: AnimationsStateT, ids: string[]) => {
    if (state) ids.forEach((id) => delete state.value[id])

    return state
}

export const animationsSlice = createSlice({
    name: 'animations',
    initialState,
    reducers: {
        addAnimationAction: (state, action: PayloadAction<PayloadT>) => {
            const id: string = action.payload.animation.id
            state.value = {
                ...state.value,
                [id]: action.payload.animation,
            }
        },
        updateAnimationAction: (state, action: PayloadAction<PayloadUpdT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: animationNode(state.value[id] ?? ({} as AnimationI), action.payload),
            }
        },
        delAnimationAction: (state, action: PayloadAction<PayloadDelT>) => {
            const id = action.payload.id
            state = deleteOne(state, id) ?? {}
        },
        delAnimationsAction: (state, action: PayloadAction<PayloadDeleteAnimationIds>) => {
            const ids: string[] = action.payload.animationIds ?? []
            state = deleteMany(state, ids) ?? {}
        },
        clearAnimationsAction: (state) => {
            state.value = {}
        },
    },
})

export const selectAllAnimations = (animations: AnimationsStateT): AnimationI[] =>
    Object.values(animations.value).map((animation) => animation!)

export const selectAnimationById = (animations: AnimationsStateT, id: string): AnimationI =>
    animations.value[id]!

export const selectAnimationsByIds = (
    allanimations: AnimationsStateT,
    ids: string[]
): AnimationI[] => {
    let animations: AnimationI[] = []
    Object.values(ids).forEach((id: string) => {
        if (id) {
            animations.push(allanimations.value[id]!)
        }
    })
    return animations
}

export const {
    addAnimationAction,
    updateAnimationAction,
    delAnimationAction,
    delAnimationsAction,
    clearAnimationsAction,
} = animationsSlice.actions

export default animationsSlice.reducer
