import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import backgroundImage from '../../assets/snow-grid.svg'

interface Props {
    title: string
    description: string
}
const HeroBannerChristmas = ({ title, description }: Props) => {
    const theme = useTheme()
    return (
        <Box
            sx={{
                p: 4,
                backgroundColor: theme.palette.primary.main,
                backgroundImage: `url(${backgroundImage})`,
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" py={2}>
                {title}
            </Typography>
            <Typography variant="body1">{description}</Typography>
        </Box>
    )
}

export default HeroBannerChristmas
