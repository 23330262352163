import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'

type Props = {
    index: number
    TimelineTimeStepWidth: number
    timelineStepX: number
}

const TimelineTimeStepEmpty = forwardRef<HTMLDivElement, Props>(
    ({ index, TimelineTimeStepWidth, timelineStepX }, ref) => {
        const theme = useTheme()
        return (
            <div
                ref={ref}
                style={{
                    position: 'absolute',
                    left: index * timelineStepX + 2 + 'px',
                    width: TimelineTimeStepWidth + 'px',
                    backgroundColor: theme.palette.background.paper,
                    height: '100%',
                }}
            ></div>
        )
    }
)

export default TimelineTimeStepEmpty
