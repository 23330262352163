import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/useRedux'

interface Props {
    header: string
    info: string
    link: string
}
function EmptyPage({ header, info, link }: Props) {
    const graphics = useAppSelector((state) => state.graphics.data)
    const wizardId = graphics.length < 1 ? 'gallery-step-3' : ''
    const { t } = useTranslation()
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" minHeight={'50vh'}>
                <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h2">{t(header)}</Typography>
                    <Typography variant="h5" mt={2} mb={4}>
                        {t(info)}
                    </Typography>
                    <Button id={wizardId} href="/app/editor">
                        {t(link)}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
export default EmptyPage
