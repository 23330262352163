import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import Grid from '@mui/material/Grid'
import { useObjectActions } from '../../../hooks/useObjectActions'
import AddButton from '../../common/AddButton'
import LoadImageButton from './LoadImageButton'
import LoadSequenceButton from './LoadSequenceButton'

const ObjectsTreeActions = () => {
    const { addItem, addText } = useObjectActions()

    const handleCreateItem = () => addItem()
    const handleCreateText = () => addText()

    return (
        <Grid sx={{ mb: 0.5 }} id="editor-step-1">
            <AddButton
                text="rectangle"
                handleAddObject={handleCreateItem}
                icon={<RectangleOutlinedIcon fontSize="small" />}
            />
            <AddButton
                text="text"
                handleAddObject={handleCreateText}
                icon={<TextFieldsOutlinedIcon fontSize="small" />}
            />
            <LoadImageButton />
            <LoadSequenceButton />
        </Grid>
    )
}

export default ObjectsTreeActions
