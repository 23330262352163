import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import {
    Control,
    FieldError,
    FieldErrorsImpl,
    Merge,
    useController as useFormController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface OptionT {
    [key: string]: any
}

interface Props {
    control: Control<any, any>
    name: string
    labelField: string
    onSearch: (value: string) => void
    options: OptionT[]
    required?: boolean
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    fullWidth?: boolean
    label?: string
    disabled?: boolean
    getEndAdornment?: (value: any) => React.ReactNode
}

export default function TextAutocomplete({
    control,
    name,
    onSearch,
    options,
    required,
    fullWidth,
    label,
    labelField,
    error,
    disabled,
    getEndAdornment,
}: Props) {
    const { t } = useTranslation()
    const { field } = useFormController({
        name,
        control,
    })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(field.value?.[labelField])

    const onBlur = (event: any) => {
        setLoading(true)
        onSearch(event.target.value)
    }

    useEffect(() => {
        if (loading) {
            field.onChange(options.length > 0 ? options[0] : { [labelField]: value })
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    return (
        <TextField
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth={fullWidth}
            label={label || t(name)}
            type={'text'}
            onBlur={onBlur}
            helperText={error && error.message ? t(error.message.toString()) : undefined}
            error={Boolean(error)}
            disabled={disabled}
            InputProps={{
                endAdornment: getEndAdornment?.(field.value),
            }}
        />
    )
}
