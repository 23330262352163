import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'

interface Props {
    styleProperty: SimpleStyleT | GroupStyleI | AttributeT
    handleOnChange: (e: any) => void
}

const SelectInput = ({ styleProperty, handleOnChange }: Props) => {

    const label: string = styleProperty.input.label ?? styleProperty.property
    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
                native
                name={styleProperty.property}
                value={styleProperty.value}
                onChange={handleOnChange}>
                {
                    ((styleProperty.input as SelectInputT).props).map((inputOption: string, index: number) => (
                        <option
                            key={index}
                            value={inputOption}
                        >
                            {inputOption}
                        </option>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectInput
