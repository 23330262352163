import { Navigate } from 'react-router-dom'
import LoginLayout from '../components/layouts/LoginLayout'
import NewPasswordForm from '../components/newPassword/NewPasswordForm'
import { useAppSelector } from '../hooks/useRedux'

export default function NewPassword() {
    const session = useAppSelector((state) => state.session)
    if (session.data) {
        return <Navigate to="/" />
    }

    return (
        <LoginLayout>
            <NewPasswordForm />
        </LoginLayout>
    )
}
