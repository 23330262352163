import { memo, useRef } from 'react'
import useInViewport from '../../../../hooks/useInViewport'
import { useAppSelector } from '../../../../hooks/useRedux'
import useTimeStepsDensity from '../../../../hooks/useTimeStepsDensity'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import TimeStamp from './TimeStamp'
import TimeStampEmpty from './TimeStampEmpty'

interface Props {
    width: number
    index: number
}

const TimeStampWrapper = memo(({ width, index }: Props) => {
    const targetRef = useRef<HTMLDivElement>(null)
    const timelineZoom = useAppSelector((state) => state.timelinePreferences.value.timelineZoom)
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)
    const { isInViewport } = useInViewport(
        targetRef,
        [timelineZoom, framerate],
        'custom-timeline-navbar'
    )

    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const tweensPerSec: number = timelinePreferences.timelineTweensPerSec

    const duration: number = useTimelineActions().masterTimelineDuration
    const { timeStepsDensity } = useTimeStepsDensity()
    const timelineRowWidth: number = width
    const tweenWidth: number = duration > 0 ? timelineRowWidth / duration : timelineRowWidth // width per sec
    const timeStampWidth: number = tweenWidth / tweensPerSec - 2
    const timeStepX: number = tweenWidth / tweensPerSec

    const getTimeStampWidth = () => {
        return [...Array(timeStepsDensity)].length - index === 0 ? tweenWidth : timeStampWidth
    }

    return isInViewport ? (
        <TimeStamp ref={targetRef} index={index} width={width} />
    ) : (
        <TimeStampEmpty
            ref={targetRef}
            left={index * timeStepX + 2 + 'px'}
            width={getTimeStampWidth() + 'px'}
        />
    )
})

export default TimeStampWrapper
