import Check from '@mui/icons-material/Check'
import { InputLabel, MenuItem } from '@mui/material'
import { t } from 'i18next'
import { SortT } from '../common/DataGrid'

export interface MySortT {
    name: string
    getResultSort: () => SortT
}

interface Props {
    sortedBy: string
    handleSortedBy: (value: string) => void
    arrayOfSortItems: MySortT[]
}

export default function SortMenuItem({ sortedBy, handleSortedBy, arrayOfSortItems }: Props) {
    return (
        <>
            <InputLabel sx={{ ml: 2 }}>{t('graphics:pageHeader.labelOrderBy')}</InputLabel>
            {arrayOfSortItems.map((x) => {
                return (
                    <MenuItem key={x.name} onClick={() => handleSortedBy(x.name)}>
                        <>
                            {sortedBy === x.name ? (
                                <Check sx={{ mr: 0.5 }} />
                            ) : (
                                <Check sx={{ mr: 0.5, opacity: 0 }} />
                            )}
                            {t(`graphics:${x.name}`)}
                        </>
                    </MenuItem>
                )
            })}
        </>
    )
}
