import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import React from 'react'
// import SnackbarAlert from '../../common/SnackbarAlert'
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'
import useEditor from '../../../hooks/useEditor'
import { useAppDispatch } from '../../../hooks/useRedux'
import { getFileFulfilled } from '../../../store/slices/file.slice'

interface Props {
    label: string
    onClick: () => void
    disabled: boolean
}

const UploadFile = ({ label, onClick, disabled }: Props) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null)
    const { handleFileRead, handleZipRead } = useEditor(null)

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event: any) => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click()
        }
    }

    const dispatch = useAppDispatch()
    const handleFilePrepareAndRead = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files
        if (!files) return

        if (files[0].type.includes('zip')) {
            handleZipRead(files[0])
        } else {
            handleFileRead(files[0])
        }

        dispatch(getFileFulfilled({ id: '', blob: files[0] }))
        onClick()
    }

    return (
        <>
            <MenuItem key="upload" onClick={handleClick} disabled={disabled}>
                <ListItemIcon>
                    <FileUploadRoundedIcon />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
                <input
                    id="input"
                    type="file"
                    accept={'.html,.zip'}
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={handleFilePrepareAndRead}
                />
            </MenuItem>
        </>
    )
}

export default UploadFile
