export const ORDER_GRAPHIC = [
    {
        name: 'pageHeader.sortedByName',
        getResultSort: () => ({ name: 1 }),
    },
    {
        name: 'pageHeader.sortedByCreated',
        getResultSort: () => ({ created: -1 }),
    },
    {
        name: 'pageHeader.sortedByUpdated',
        getResultSort: () => ({ updated: -1 }),
    },
]

export const ORDER_STORE = [
    {
        name: 'pageHeader.sortedByName',
        getResultSort: () => ({ name: 1 }),
    },
    {
        name: 'pageHeader.sortedByNewest',
        getResultSort: () => ({ updated: -1 }),
    },
]
