import { combineReducers } from '@reduxjs/toolkit'
import undoable from 'redux-undo'
import animationsReducer from './slices/animations.slice'
import canvasReducer from './slices/canvas.slice'
import objectsReducer from './slices/objects.slice'
import timelineReducer from './slices/timeline.slice'

const undoableReducer = undoable(
    combineReducers({
        objects: objectsReducer,
        animations: animationsReducer,
        canvas: canvasReducer,
        timeline: timelineReducer,
    })
)

export default undoableReducer
