import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QueryOptionsT } from '../../components/common/DataGrid'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

export enum actionsEnum {
    purchase = 'purchase',
    earn = 'earn',
    renewal = 'renewal',
}
type TransactionT = {
    _id: string
    coins: number
    action: actionsEnum
    companyId: string
    data: {
        id: string
        name: string
    }
    userId: string
    user: {
        _id: string
        firstName: string
        lastName: string
        email: string
        lastLogged: string
    }
    date: string
}

type TransactionsState = {
    data: TransactionT[]
    total: number
    status?: string
}

const initialState: TransactionsState = {
    data: [],
    total: 0,
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        getTransactionsPending: (state: TransactionsState) => {
            state.status = undefined
        },
        getTransactionsFulfilled: (
            state: TransactionsState,
            action: PayloadAction<ListT<TransactionT>>
        ) => {
            state.status = 'OK'
            state.data = action.payload.data
            state.total = action.payload.total
        },
        getTransactionsRejected: (state: TransactionsState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getTransactionsFulfilled, getTransactionsPending, getTransactionsRejected } =
    transactionsSlice.actions

export const getTransactionsAsync =
    (companyId: string | null, queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            if (!companyId) {
                dispatch(getTransactionsFulfilled(initialState))
            }
            dispatch(getTransactionsPending())
            const Transactions = await get<ListT<TransactionT>>(
                `companies/${companyId}/transactions`,
                queryOptions
            )
            dispatch(getTransactionsFulfilled(Transactions))
        } catch (error: any) {
            dispatch(getTransactionsRejected(error.message))
        }
    }

export default transactionsSlice.reducer
