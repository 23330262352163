import CreatedAccountForm from '../components/forms/CreatedAccountForm'
import LoginLayout from '../components/layouts/LoginLayout'

export default function CreatedAccount() {
    return (
        <LoginLayout>
            <CreatedAccountForm />
        </LoginLayout>
    )
}
