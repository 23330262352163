import dayjs from 'dayjs'

function strToNumber(str: string): number {
    return Number(str)
}

export function getFilter(operator: string | undefined, value: any = null) {
    switch (operator) {
        case '=':
            if (!value) {
                return { $ne: value }
            } else {
                return { $eq: strToNumber(value) }
            }
        case 'isEmpty':
        case 'equals':
            return { $eq: value }
        case '!=':
            return { $ne: strToNumber(value) }
        case 'isNotEmpty':
            return { $ne: value }
        case '>':
            return { $gt: strToNumber(value) }
        case '>=':
            return { $gte: strToNumber(value) }
        case '<':
            return { $lt: strToNumber(value) }
        case '<=':
            return { $lte: strToNumber(value) }
        case 'isAnyOf':
            return { $in: value || [] }
        case 'contains':
            return { $regex: value ?? '', $options: 'i' }
        case 'startsWith':
            return { $regex: '^' + value, $options: 'i' }
        case 'endsWith':
            return { $regex: value + '$', $options: 'i' }
        case 'is':
            if (!value) {
                return { $ne: value }
            } else if (value === 'true') {
                return { $eq: true }
            } else if (value === 'false') {
                return { $eq: false }
            } else {
                const d = dayjs(value)
                return { $eq: d.isValid() ? d.toDate() : value }
            }
        case 'not': {
            const d = dayjs(value)
            return { $ne: d.isValid() ? d.toDate() : value }
        }
        case 'after':
            return { $gt: new Date(value) }
        case 'onOrAfter':
            return { $gte: new Date(value) }
        case 'before':
            return { $lt: new Date(value) }
        case 'onOrBefore':
            return { $lte: new Date(value) }
        case 'default':
            return { $eq: value }
        default:
            return {}
    }
}
