import ImageIcon from '@mui/icons-material/Image'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BackgroundImageUpload = ({ handleSetValue }) => {
    const { t } = useTranslation()

    // reference to the hidden file input element
    const hiddenFileInput = React.useRef(null)

    // click the hidden file input element when the Button component is clicked
    const handleClick = (event) => hiddenFileInput.current.click()

    // call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const readFile = (e) => {
        const file = e.target.files[0]
        var reader = new FileReader()
        reader.addEventListener('load', function () {
            var imageUrl = 'url(' + reader.result + ')'
            // handleSetValue('backgroundImage', imageUrl)
            handleSetValue({
                target: {
                    name: 'backgroundImage',
                    value: imageUrl,
                },
            })
        })

        if (file) reader.readAsDataURL(file)
    }

    return (
        <Tooltip title={t('editor:chooseFromFiles')}>
            <Button
                variant="text"
                color="success"
                onClick={handleClick}
                startIcon={<ImageIcon fontSize="small" />}
            >
                {t('editor:uploadImage')}
                <input
                    id="input"
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={readFile}
                />
            </Button>
        </Tooltip>
    )
}

export default BackgroundImageUpload
