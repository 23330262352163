import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton, Slide, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TransitionProps } from '@mui/material/transitions'
import { t } from 'i18next'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />
})

function createData(
    value: string,
    free: string,
    individual: string,
    business: string,
    enterprise: string
) {
    return { value, free, individual, business, enterprise }
}
interface LicenseTableDataT {
    value: string
    free: string
    individual: string
    business: string
    enterprise: string
}

let array: LicenseTableDataT[] = t('license:licenseTable', { returnObjects: true })

const rows = array.map((x) => {
    return createData(x.value, x.free, x.individual, x.business, x.enterprise)
})
interface Props {
    open: boolean
    onClose?: () => void
}
export default function LicensesDialog({ open, onClose }: Props) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth={'lg'}
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{ zIndex: 1900 }}
        >
            <Box sx={{ p: 4 }}>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h4" textAlign={'center'} mb={2}>
                    {t('license:titleShowLicenses')}
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, border: 'unset' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        {t('license:tableHeader.free')}
                                    </Typography>
                                </TableCell>
                                <TableCell>{t('license:tableHeader.individual')}</TableCell>
                                <TableCell>{t('license:tableHeader.business')}</TableCell>
                                <TableCell>{t('license:tableHeader.enterprise')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.value}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.value}
                                    </TableCell>
                                    <TableCell>{row.free}</TableCell>
                                    <TableCell>{row.individual}</TableCell>
                                    <TableCell>{row.business}</TableCell>
                                    <TableCell>{row.enterprise}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Dialog>
    )
}
