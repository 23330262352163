import { selectUndoable } from '../../helpers/selector.helpers'
import { selectAnimationById } from '../../store/slices/animations.slice'
import { selectObjectById } from '../../store/slices/objects.slice'
import { AppStateT } from '../../store/store'
import { useAnimationActions } from '../useAnimationActions'
import { useObjectActions } from '../useObjectActions'
import { useAppSelector } from '../useRedux'

const useMultiselectAnimation = (animationId: string) => {
    const {
        setActiveAnimations,
        removeActiveAnimationId,
        removeSelectedAnimations,
        clearSelectedAnimations,
    } = useAnimationActions()
    const { clearAllObjectFromSelection, addObjectToSelection, clearObjectFromSelection } =
        useObjectActions()

    const selectedAnimationIds: string[] = useAppSelector((state) => state.activeAnimation.selected)
    const activeAnimationId: string | null = useAppSelector((state) => state.activeAnimation.value)
    const animation: AnimationI = useAppSelector((state) =>
        selectAnimationById(selectUndoable(state).animations, animationId)
    )
    const object: AnySceneObjectT = useAppSelector((state: AppStateT) =>
        selectObjectById(selectUndoable(state).objects, animation.objectId)
    )

    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const handleMultiselectAnimations = (ctrlShiftKey: boolean) => {
        if (!ctrlShiftKey) {
            clearSelectedAnimations()
            clearAllObjectFromSelection()
            setActiveAnimations([animationId], animation.objectId)
        } else if (!selectedAnimationIds.find((id) => id === animationId)) {
            if (selectedObjectIds.find((id) => id !== animation.objectId)) {
                addObjectToSelection(object)
            }
            setActiveAnimations([animationId], animation.objectId)
        } else if (activeAnimationId === animationId) {
            if (object.animationIds.find((x) => x === animationId)) {
                clearObjectFromSelection(object)
            }
            removeActiveAnimationId()
        } else {
            removeSelectedAnimations([animationId])
        }
    }
    return { handleMultiselectAnimations }
}

export default useMultiselectAnimation
