import { createAnimation } from '../helpers/creators.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
    removeActiveAnimationAction,
    removeSelectedAnimationsAction,
    setActiveAnimationAction,
} from '../store/slices/activeAnimation.slice'
import {
    clearObjectFromSelectedObjectsAction,
    removeActiveObjectAction,
    setActiveObjectAction,
} from '../store/slices/activeObject.slice'
import {
    addAnimationAction,
    delAnimationsAction,
    updateAnimationAction,
} from '../store/slices/animations.slice'
import { refreshAllStyles, updateMasterTimeline } from '../store/slices/masterTimeline.slice'
import {
    addAnimationIdAction,
    checkIndexes,
    delObjectAction,
    deleteAnimationsFromObjectAction,
} from '../store/slices/objects.slice'
import useObject from './useObject'
import { useAppDispatch, useAppSelector } from './useRedux'

export const useAnimationActions = () => {
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)

    const activeAnimationId: string | null = useAppSelector((state) => state.activeAnimation.value)

    const animations = useAppSelector((state) => selectUndoable(state).animations)

    const dispatch = useAppDispatch()

    /* ACTIVE ANIMATION HANDLERS */

    const setActiveAnimations = (ids: string[], objectId: string) => {
        dispatch(setActiveAnimationAction({ id: ids[0] }))
        dispatch(addSelectedAnimationsAction(ids))
        dispatch(setActiveObjectAction({ id: objectId }))
    }
    const removeActiveAnimationId = () => {
        if (activeAnimationId) {
            dispatch(removeSelectedAnimationsAction([activeAnimationId]))
        }
        dispatch(removeActiveAnimationAction())
    }

    const addSelectedAnimations = (ids: string[]) => {
        dispatch(addSelectedAnimationsAction(ids))
    }
    const clearSelectedAnimations = () => dispatch(clearSelectedAnimationsAction())
    const removeSelectedAnimations = (ids: string[]) => {
        dispatch(removeSelectedAnimationsAction(ids))
    }

    /* HELPER HANDLERS */

    const handleActiveObjectWhenCreating = (id: string, objectId: string) => {
        clearSelectedAnimations()
        setActiveAnimations([id], objectId)
    }

    const handleAnimationIdsWhenCreating = (objectId: string, animationId: string) => {
        // odstran vsechny prislusne animace
        dispatch(addAnimationIdAction({ id: objectId, animationId: animationId }))
    }

    const addAnimation = (animation: AnimationI) => {
        // @ts-ignore
        dispatch(addAnimationAction({ animation: animation }))
        handleAnimationIdsWhenCreating(animation.objectId, animation.id)
        handleActiveObjectWhenCreating(animation.id, animation.objectId)

        dispatch(updateMasterTimeline(timeToSeek))
    }

    const addAnimations = (animations: AnimationI[]) => {
        animations.forEach((animation: AnimationI) => {
            // @ts-ignore
            dispatch(addAnimationAction({ animation: animation }))
            handleAnimationIdsWhenCreating(animation.objectId, animation.id)
        })

        setTimeout(() => dispatch(updateMasterTimeline(timeToSeek)), 500) // short delay to ensure that the objects are created in Canvas
    }

    const addNewAnimation = (objectId: string, startOffset: number) =>
        addAnimation(createAnimation(objectId, startOffset, undefined))

    const uploadAnimation = (animation: AnimationI) => {
        // @ts-ignore
        dispatch(addAnimationAction({ animation: animation }))
        // handleActiveObjectWhenCreating(animation.id, animation.objectId)
    }

    const duplicateAnimation = (objectId: string, sampleAnimation: AnimationI) => {
        addAnimation(
            createAnimation(objectId, sampleAnimation.delay, {
                ...sampleAnimation,
                title: sampleAnimation.title + ' copy',
            })
        )
    }

    const duplicateAnimations = (animations: AnimationI[]) => {
        if (animations) {
            Object.values(animations).forEach((animation: AnimationI) => {
                duplicateAnimation(animation.objectId, animation)
            })
        }
    }

    const updateAnimation = (id: string, property: any, value: any) => {
        dispatch(updateAnimationAction({ id: id, property: property, value: value }))

        dispatch(refreshAllStyles())
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const moveAnimation = (id: string, delay: number) => {
        if (delay >= 0) {
            dispatch(updateAnimationAction({ id: id, property: 'delay', value: delay }))
        }
    }

    const scaleAnimation = (id: string, duration: number) => {
        if (duration > 0) {
            dispatch(updateAnimationAction({ id: id, property: 'duration', value: duration }))
        }
    }

    const updateAnimationTweenState = (
        animation: AnimationI,
        state: 'from' | 'to',
        property: string,
        value: any
    ) => {
        const updatedStateObject: SimpleStyleT[] = animation.tween[state].map((stateObject) => {
            if (stateObject.property === property) return { ...stateObject, value: value }
            else return stateObject
        })

        const updatedTween: TweenT = {
            ...animation.tween,
            [state]: updatedStateObject,
        }
        updateAnimation(animation.id, 'tween', updatedTween)
    }

    const { findObjectById } = useObject()
    const activeObjectId = useAppSelector((state) => state.activeObject.value)

    //TO DO after union animations.slice and objects.slice refactor this function
    const handleDeleteAnimationFromObjectAndAnimation = (
        objectId: string,
        animationIds: string[]
    ) => {
        dispatch(
            deleteAnimationsFromObjectAction({
                objectId: objectId,
                animationIds: animationIds,
            })
        )
        dispatch(delAnimationsAction({ animationIds: animationIds }))
    }

    /**
     * delete selected animation function
     * @param animations
     */
    const deleteAnimations = (animations: AnimationI[]) => {
        //clear state.activeAnimation
        clearSelectedAnimations()
        dispatch(removeActiveAnimationAction())

        //group selected animation by objectId
        const groupedAnimations: Map<string, AnimationI[]> = new Map()

        animations.forEach((animation) => {
            const existingGroup = groupedAnimations.get(animation.objectId)
            if (existingGroup) {
                const newGroup = [...existingGroup, animation]
                groupedAnimations.set(animation.objectId, newGroup)
            } else {
                groupedAnimations.set(animation.objectId, [animation])
            }
        })
        //delete on every group of animation
        groupedAnimations.forEach((animations, objectId) => {
            const object = findObjectById(objectId)
            const animationIds: string[] = animations.map((animation) => animation.id)
            if (object && object.type === 'sequence') {
                if (object?.animationIds.length === animationIds.length) {
                    handleDeleteAnimationFromObjectAndAnimation(object.id, animationIds)

                    //clear object from state.activeObject
                    if (object.id === activeObjectId) {
                        dispatch(removeActiveObjectAction())
                    } else {
                        dispatch(clearObjectFromSelectedObjectsAction({ id: object.id }))
                    }
                    //delete object and check if exist in parentObjectId and delete in parent
                    dispatch(delObjectAction({ id: object.id }))
                    dispatch(checkIndexes(object.parentId))
                } else {
                    if (object) handleDeleteAnimationFromObjectAndAnimation(object.id, animationIds)
                }
            } else {
                if (object) handleDeleteAnimationFromObjectAndAnimation(object.id, animationIds)
            }
        })
        //refresh styles and set timeToSeek
        dispatch(refreshAllStyles())
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const compareAnimations = (
        animation1: AnimationI | undefined,
        animation2: AnimationI | undefined
    ) => {
        if (animation1 && animation2) {
            if (animation1.tween.name < animation2.tween.name) {
                return -1
            }
            if (animation1.tween.name > animation2.tween.name) {
                return 1
            }
            return animation1.delay - animation2.delay
        }
        return 0
    }

    const pushAnimations = (
        objectId: string,
        tweenname: string,
        fromDelay: number,
        pushOffset: number
    ) => {
        if (animations) {
            Object.values(animations.value)
                .slice()
                .sort(compareAnimations)
                .forEach((anim) => {
                    if (
                        anim &&
                        anim!.objectId === objectId &&
                        anim!.delay >= fromDelay &&
                        anim!.tween.name === tweenname
                    ) {
                        moveAnimation(anim!.id, +anim!.delay + +pushOffset)
                    }
                })
        }

        dispatch(refreshAllStyles())
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const pushAnimation = (animation: AnimationI, pushOffset: number) => {
        if (+animation.delay + +pushOffset >= 0) {
            pushAnimations(animation.objectId, animation.tween.name, +animation.delay, +pushOffset)
        }
    }

    const pushMultipleAnimations = (animations: AnimationI[], pushOffset: number) => {
        if (animations) {
            Object.values(animations)
                .slice()
                .sort(compareAnimations)
                .forEach((animation: AnimationI) => {
                    if (animation) {
                        pushAnimation(animation, pushOffset)
                    }
                })
        }
    }

    const pullAnimation = (animation: AnimationI, pushOffset: number) => {
        if (+animation.delay - +pushOffset >= 0) {
            pushAnimations(animation.objectId, animation.tween.name, +animation.delay, -pushOffset)
        }
    }

    const pullMultipleAnimations = (animations: AnimationI[], pushOffset: number) => {
        if (animations) {
            Object.values(animations)
                .slice()
                .sort(compareAnimations)
                .forEach((animation: AnimationI) => {
                    if (animation) {
                        pullAnimation(animation, pushOffset)
                    }
                })
        }
    }

    const expandAnimation = (animation: AnimationI, expandOffset: number) => {
        if (+animation.duration + +expandOffset > 0) {
            scaleAnimation(animation.id, +animation.duration + +expandOffset)
            pushAnimations(
                animation.objectId,
                animation.tween.name,
                +animation.delay + +animation.duration,
                +expandOffset
            )
        }
    }

    const expandMultipleAnimations = (animations: AnimationI[], pushOffset: number) => {
        if (animations) {
            Object.values(animations)
                .slice()
                .sort(compareAnimations)
                .forEach((animation: AnimationI) => {
                    if (animation) {
                        expandAnimation(animation, pushOffset)
                    }
                })
        }
    }

    const shrinkAnimation = (animation: AnimationI, shrinkOffset: number) => {
        if (+animation.duration - +shrinkOffset > 0) {
            scaleAnimation(animation.id, animation.duration - shrinkOffset)
            pushAnimations(
                animation.objectId,
                animation.tween.name,
                +animation.delay + +animation.duration,
                -shrinkOffset
            )
        }
    }

    const shrinkMultipleAnimations = (animations: AnimationI[], pushOffset: number) => {
        if (animations) {
            Object.values(animations)
                .slice()
                .sort(compareAnimations)
                .forEach((animation: AnimationI) => {
                    if (animation) {
                        shrinkAnimation(animation, pushOffset)
                    }
                })
        }
    }

    return {
        addNewAnimation,
        addAnimation,
        addAnimations,
        uploadAnimation,
        duplicateAnimation,
        duplicateAnimations,
        updateAnimation,
        updateAnimationTweenState,
        deleteAnimations,
        setActiveAnimations,
        removeActiveAnimationId,
        pushAnimation,
        pullAnimation,
        expandAnimation,
        shrinkAnimation,
        addSelectedAnimations,
        removeSelectedAnimations,
        clearSelectedAnimations,
        pushMultipleAnimations,
        pullMultipleAnimations,
        expandMultipleAnimations,
        shrinkMultipleAnimations,
    }
}
