import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { Control, FieldErrorsImpl } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '../../common/form/TextField'

interface Props {
    control: Control<any, any>
    errors: Partial<FieldErrorsImpl<any>>
    error: boolean
    loading: boolean
}
const SecondStep = ({ control, errors, error, loading }: Props) => {
    const { t } = useTranslation()
    return (
        <Grid item md={12}>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        mt: 1,
                        p: 4,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box
                    sx={{
                        mt: 1,
                        p: 4,
                        border: (theme) => `1px solid ${theme.palette.error.main}`,
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="h5" sx={{ pb: 1 }} color={'error'}>
                        {t('dataSource:errorUrlScreen.title')}
                    </Typography>
                    <Typography variant="body1" color={'error'}>
                        {t('dataSource:errorUrlScreen.description')}
                    </Typography>
                </Box>
            ) : (
                <TextField
                    control={control}
                    fullWidth
                    name="JSONdata"
                    multiline={true}
                    disabled
                    error={errors.data}
                    rows={15}
                    label={t('dataSource:dataJson')}
                />
            )}
        </Grid>
    )
}

export default SecondStep
