import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { IconButton } from '@mui/material'
import { useState } from 'react'

interface TreeNodeProps {
    data: {}
    disabled: boolean
    handleAdd: (key: string, currentPath: string) => void
}

export default function TreeNode({ data, disabled, handleAdd }: TreeNodeProps) {
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})

    const toggleExpansion = (key: string) => {
        setExpanded((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }))
    }

    const renderNode = (
        node: {} | null,
        parentKey: string | null = null,
        path: string = '',
        valueWasArray: boolean = false
    ) => {
        return (
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {node &&
                    Object.entries(node).map(([key, value]) => {
                        const isArray = Array.isArray(value)
                        const isNodeArray = Array.isArray(node)
                        const currentPath = parentKey
                            ? `${path}.${valueWasArray ? `[${key}]` : key}`
                            : isNodeArray
                            ? `[${key}]`
                            : key

                        return (
                            <li key={key}>
                                <div style={{ marginLeft: 10 }}>
                                    {typeof value === 'object' ? (
                                        <span
                                            onClick={() =>
                                                toggleExpansion(
                                                    parentKey ? `${parentKey}-${key}` : key
                                                )
                                            }
                                        >
                                            {expanded[parentKey ? `${parentKey}-${key}` : key] ? (
                                                <IconButton>
                                                    <ArrowDropUpIcon fontSize="medium" />
                                                </IconButton>
                                            ) : (
                                                <IconButton>
                                                    <ArrowDropDownIcon fontSize="medium" />
                                                </IconButton>
                                            )}
                                            <strong>{`"${key}" :`}</strong>{' '}
                                        </span>
                                    ) : (
                                        <>
                                            <IconButton
                                                disabled={disabled}
                                                onClick={() => handleAdd(key, currentPath)} // Passing the currentPath to handleAdd
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                            <label>
                                                <strong>{`"${key}" :`}</strong> {String(value)}
                                            </label>
                                        </>
                                    )}
                                    {expanded[parentKey ? `${parentKey}-${key}` : key] &&
                                        typeof value === 'object' &&
                                        renderNode(
                                            value,
                                            parentKey ? `${parentKey}-${key}` : key,
                                            currentPath,
                                            isArray
                                        )}
                                </div>
                            </li>
                        )
                    })}
            </ul>
        )
    }

    return renderNode(data)
}
