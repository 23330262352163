import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import DescriptionIcon from '@mui/icons-material/Description'
import { useTranslation } from "react-i18next"

const DocumentationItem = () => {

    const { t } = useTranslation()

    return (
        <MenuItem
            sx={{ p: 1.75 }}
            target="_blank"
            component="a"
            href="https://docs.tween.ly/" >
            <ListItemIcon sx={{ ml: 1.75, mr: 1 }}>
                <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu:documentation')} />
        </MenuItem >
    )
}
export default DocumentationItem