import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
interface Props {
    id: string
    shortAvatar: string
    updated?: string
    fullName: string
    onClick: () => void
    isActive?: boolean
}
const VersionHistoryItem = ({ id, shortAvatar, updated, fullName, onClick, isActive }: Props) => {
    return (
        <ListItemButton
            key={id}
            onClick={() => onClick()}
            disabled={isActive}
            sx={{
                '&.Mui-disabled': {
                    opacity: 1,
                },
            }}
        >
            <ListItemAvatar>
                <Avatar
                    sx={{
                        backgroundColor: (theme) =>
                            isActive ? theme.palette.primary.main : theme.palette.grey[500],
                        color: (theme) => theme.palette.text.primary,
                    }}
                >
                    {shortAvatar}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={updated} secondary={`${fullName}`} />
        </ListItemButton>
    )
}

export default VersionHistoryItem
