import { filters } from "./filter.styles"
import { transforms } from "./transform.styles"
import { fillProperties } from "./fill.styles"

export type GroupStyleTypeT = "filters" | "transforms" | "fill"

export const groupStyles: GroupStyleT[] = [...filters, ...transforms, ...fillProperties]

export const renderGroupStyleValue = (style: SimpleStyleT, type: "css" | "inline") => {
    return style.render(style.value, style.unit, type).value
}

export const renderGroupStyles = (styles: GroupStyleI[], type: "css" | "inline") => {
	if (type === "inline") {
		let result = {}
		// filteru muze byt vice, proto je potreba zapsat jeho hodnoty za sebou za keyword
		styles.forEach((filter) => {
			const styleRender = filter.render(filter.value, filter.unit, "inline")
			var value = ""
			// pokud filter keyword uz ve stylech existuje, zretez value
			if (result.hasOwnProperty(styleRender.property)) {
				Object.entries(result).forEach((style) => {
					if (style[0] === styleRender.property) value += style[1]
				})
				value += " " + styleRender.value
			} else {
				value = styleRender.value
			}

			return Object.assign(result, {
				[styleRender.property]: value,
			})
		})
		return result
	} else {
		let property: string = "" // filter | transform
		let values = ""
		styles.forEach((filter) => {
			const styleRender = filter.render(filter.value, filter.unit, "css")
			values += styleRender.value + " "
			property = styleRender.property
		})
		return `${property}: ${values};`
	}
}
