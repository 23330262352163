import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import linePatternLeft from '../../assets/line-pattern-left.svg'
import linePatternRight from '../../assets/line-pattern-right.svg'

interface Props {
    title: string
    description: string
}
const HeroBannerGeneral = ({ title, description }: Props) => {
    const theme = useTheme()
    return (
        <Box
            sx={{
                p: 4,
                backgroundColor: theme.palette.primary.main,
                backgroundImage: `url(${linePatternLeft}), url(${linePatternRight})`,
                backgroundPosition: 'left center, right center',
                backgroundSize: '45%, 45%',
                backgroundRepeat: 'no-repeat',
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" pb={2}>
                {title}
            </Typography>
            <Typography variant="body1">{description}</Typography>
        </Box>
    )
}

export default HeroBannerGeneral
