import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

interface Props {
    text1: string
    text2: string
}

const EmptyPanel = ({ text1, text2 }: Props) => {
    const theme = useTheme()
    return (
        <Grid container direction="column" justifyContent="center" sx={{ height: '100%' }}>
            <Grid item>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom={true}
                    color={theme.palette.text.disabled}
                >
                    {text1}
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color={theme.palette.text.disabled}
                >
                    {text2}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default EmptyPanel
