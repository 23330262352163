import Grid from '@mui/material/Grid'
import { useEffect, useRef } from 'react'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { useAppSelector } from '../../../../hooks/useRedux'
import InitState from '../../AnimationsNavbar/TimelineTools/InitState'
interface Props {
    visibility: 'visible' | 'hidden'
    disabled: boolean
}
const TimelineDevTools = ({ visibility, disabled }: Props) => {
    const objects = useAppSelector((state) => selectUndoable(state).objects)

    const devToolsControlsWrapper = useRef<HTMLDivElement | null>(null)

    const removeDevToolsChildren = () => {
        const devToolsRef = devToolsControlsWrapper.current
        if (
            (devToolsRef && devToolsRef.children.length > 1) ||
            (devToolsRef &&
                devToolsRef.children.length > 0 &&
                (objects.value === null || Object.keys(objects.value).length === 0))
        ) {
            devToolsRef.removeChild(devToolsRef.children[0])
            removeDevToolsChildren()
        }
    }

    useEffect(() => {
        removeDevToolsChildren()
    })

    return (
        <Grid
            id="editor-step-5"
            container
            direction="column"
            sx={{
                flexBasis: visibility === 'hidden' ? 0 : 80,
                padding: 2,
                visibility: visibility,
            }}
        >
            <Grid item sx={{ height: '15px' }}></Grid>
            <Grid item id="controls-wrapper" ref={devToolsControlsWrapper}></Grid>
            <Grid item sx={{ marginBottom: 12 + 'px', marginTop: -35 + 'px', zIndex: 50 }}>
                <Grid container direction="row">
                    <InitState disabled={disabled} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TimelineDevTools
