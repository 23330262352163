import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { t } from 'i18next'
import { FilterT } from '../common/DataGrid'

export interface MyFilterT {
    name: string
    getResultFilter: (userId?: string) => FilterT
}
interface Props {
    value: string
    label: string
    index: string
    onChangeFilter: (event: SelectChangeEvent) => void
    arrayOfFilterItem: MyFilterT[]
}
function GraphicFilter({ value, label, index, onChangeFilter, arrayOfFilterItem }: Props) {
    return (
        <FormControl fullWidth sx={{ m: 0 }}>
            <InputLabel id={index}>{label}</InputLabel>
            <Select onChange={onChangeFilter} labelId={index} id={index} value={value}>
                {arrayOfFilterItem.map((x) => {
                    return (
                        <MenuItem
                            key={x.name}
                            value={t(`graphics:${x.name}`) === 'All' ? '' : x.name}
                        >
                            {t(`graphics:${x.name}`)}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
export default GraphicFilter
