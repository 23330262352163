import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { logoutAsync } from '../store/slices/session.slice'

const LogOut = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const session = useAppSelector((state) => state.session)

    useEffect(() => {
        const logout = async () => {
            await dispatch(logoutAsync())
            navigate('/login')
        }
        logout()
    }, [dispatch, navigate])
    return <LoadingBackdrop open={!session.status} />
}

export default LogOut
