import ImageIcon from '@mui/icons-material/Image'
import { Tooltip } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AjaxError } from '../../../helpers/ajax.helpers'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch } from '../../../hooks/useRedux'
import { showError } from '../../../store/slices/error.slice'
import { setLoading } from '../../../store/slices/loading.slice'
import ButtonContextMenu from '../../common/ContextMenu/ButtonContextMenu'
import AssetsDialog from './AssetsDialog'
import ImageSrcDialog from './ImageSrcDialog'

interface Props {
    object?: AnySceneObjectT
}

const LoadImageButton = ({ object }: Props) => {
    const [openImageSrcDialog, setOpenImageSrcDialog] = useState<boolean>(false)
    const [openAssetsDialog, setOpenAssetsDialog] = useState<boolean>(false)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const { readImageFile, replaceImageSource } = useObjectActions()

    const handleReadImage = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            dispatch(setLoading(true))
            if (object) {
                await replaceImageSource(e.target.files?.[0]!, object)
            } else {
                await readImageFile(e.target.files?.[0]!, null)
            }
        } catch (error: unknown) {
            if (error instanceof AjaxError && error.statusCode === 400) {
                dispatch(showError('fileSize'))
            } else {
                console.error(error)
                throw error
            }
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Tooltip title={object ? '' : t('editor:image.tooltip')}>
            <span>
                <ButtonContextMenu
                    title={object ? t('editor:image.replaceImageButton') : t('editor:image.title')}
                    icon={<ImageIcon fontSize="small" />}
                    data={[
                        {
                            title: t('editor:newButton'),
                            onClick: () => {},
                            onChange: handleReadImage,
                            type: 'file',
                        },
                        {
                            title: t('editor:imageSrcDialog.buttonOpen'),
                            onClick: () => setOpenImageSrcDialog(true),
                        },
                        {
                            title: t('editor:assetsDialog.buttonOpen'),
                            onClick: () => setOpenAssetsDialog(true),
                        },
                    ]}
                />
                <ImageSrcDialog
                    open={openImageSrcDialog}
                    onClose={() => setOpenImageSrcDialog(false)}
                    parentId={null}
                    object={object}
                />
                <AssetsDialog
                    open={openAssetsDialog}
                    onClose={() => setOpenAssetsDialog(false)}
                    parentId={null}
                    object={object}
                    type="image"
                />
            </span>
        </Tooltip>
    )
}

export default LoadImageButton
