import CheckIcon from '@mui/icons-material/Check'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Box, Button, Dialog, Grid, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import {
    CompanyT,
    CompanyUserT,
    inviteUserInCompanyAsync,
    inviteUserSchema,
} from '../../store/slices/company.slice'
import { checkUsersAsync } from '../../store/slices/users.slice'
import { Transition } from '../common/Transition'
import RadioGroupButton from '../common/form/RadioGroupButton'
import TextAutocomplete from '../common/form/TextAutocomplete'
import TextField from '../common/form/TextField'
interface Props {
    company: CompanyT
    open: boolean
    onClose: () => void
    submitButtonTxt: string
    dialogTitle: string
    companyUserData?: CompanyUserT
    disableWhenEdit?: boolean
}

const DEFAULT_VALUE = {
    user: null,
    role: 2,
    id: '',
    isDisabled: true,
    firstName: '',
    lastName: '',
}

const MemberDialog = ({
    company,
    open,
    onClose,
    submitButtonTxt,
    dialogTitle,
    companyUserData,
    disableWhenEdit,
}: Props) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const users = useAppSelector((state) => state.users)
    const [disableAfterUseWatch, setDisableAfterUseWatch] = useState<boolean>(false)
    const onSubmitCompanyUser = (data: CompanyUserT) => {
        if (data.user && !data.user?.lastLogged) {
            data.user = { ...data.user, firstName: data?.firstName, lastName: data.lastName }
        }
        dispatch(inviteUserInCompanyAsync(data, company._id))
        onClose()
    }

    const onSearch = (email: string) => dispatch(checkUsersAsync(email))

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting, isDirty },
    } = useReduxForm({
        schema: inviteUserSchema,
        defaultValues: companyUserData ? companyUserData : DEFAULT_VALUE,
    })

    const user = useWatch({
        control,
        name: 'user',
    })

    useEffect(() => {
        if (user) {
            if ((user?.email && user?.lastLogged && !user.firstName) || !user.lastName) {
                //To do - ask Martina what to do if i have account without firstName or LastName, actually disabling this two input
                setDisableAfterUseWatch(true)
            } else if (user.firstName || user.lastName) {
                setValue('firstName', user.firstName)
                setValue('lastName', user.lastName)
                setDisableAfterUseWatch(true)
            } else if (user?.email && !user?.lastLogged && (!user.firstName || !user.lastName)) {
                setValue('firstName', '')
                setValue('lastName', '')
                setDisableAfterUseWatch(false)
            }
        }
    }, [setValue, user])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmitCompanyUser)}
                noValidate
                sx={{
                    padding: 4,
                    backgroundColor: theme.palette.background.paper,
                    width: 600,
                }}
            >
                <Typography variant="h5" my={3}>
                    {dialogTitle}
                </Typography>
                {/* TO DO how to check if it correct format of email  */}
                <TextAutocomplete
                    control={control}
                    name="user"
                    label={t('company:dialogs.user')}
                    onSearch={onSearch}
                    options={users.data}
                    labelField="email"
                    error={errors.user}
                    fullWidth
                    required
                    disabled={disableWhenEdit}
                    getEndAdornment={(value) =>
                        value && value._id && value.lastLogged ? (
                            <Tooltip title={t('company:userFound')}>
                                <CheckIcon />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('company:userNotFound')}>
                                <HourglassEmptyIcon />
                            </Tooltip>
                        )
                    }
                />
                <Grid container spacing={1}>
                    {/* TO DO - vyplnit v pripade ze existuje user s danym emailom, ak neexistuje tak potrebne user:{firstName, lastName}*/}
                    <Grid item md={6}>
                        <TextField
                            control={control}
                            fullWidth
                            name="firstName"
                            disabled={disableWhenEdit || disableAfterUseWatch}
                            label={t('company:dialogs.firstName')}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            control={control}
                            fullWidth
                            name="lastName"
                            disabled={disableWhenEdit || disableAfterUseWatch}
                            label={t('company:dialogs.lastName')}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ my: 3 }}>
                    <RadioGroupButton
                        control={control}
                        name="role"
                        title={t('company:dialogs.role')}
                        required={true}
                        options={[
                            {
                                value: 2,
                                title: t('company:dialogs.admin'),
                                description: t('company:dialogs.adminTxt'),
                            },
                            {
                                value: 1,
                                title: t('company:dialogs.collaborate'),
                                description: t('company:dialogs.collaborateTxt'),
                            },
                        ]}
                        error={errors.user}
                        fullWidth
                    />
                </Box>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Button
                        variant="text"
                        color="inherit"
                        sx={{ px: 2 }}
                        disabled={false}
                        onClick={onClose}
                    >
                        <Typography variant="body1">{t('disagree')}</Typography>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ px: 2 }}
                        disabled={!isDirty || isSubmitting}
                    >
                        <Typography variant="body1">{submitButtonTxt}</Typography>
                    </Button>
                </Grid>
            </Box>
        </Dialog>
    )
}

export default MemberDialog
