import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataGrid, { QueryOptionsT } from '../components/common/DataGrid'
import CompanyForm from '../components/company/CompanyForm'
import useId from '../hooks/useId'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getCompaniesAsync } from '../store/slices/companies.slice'
import { getCompanyAsync } from '../store/slices/company.slice'
import { selectPeriodsEnum } from '../store/slices/enums.slice'
import { getTransactionStatsAsync } from '../store/slices/transactionStats.slice'

export default function Companies() {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const companies = useAppSelector((state) => state.companies)
    const company = useAppSelector((state) => state.company)
    const periodsEnum = useAppSelector((state) => selectPeriodsEnum(state))

    const callback = useCallback(
        (id: string) => {
            dispatch(getCompanyAsync(id))
            dispatch(getTransactionStatsAsync(id))
        },
        [dispatch]
    )
    const { id, setId } = useId(callback)
    const navigate = useNavigate()

    const onSelect = (id: string | null) => {
        if (id) {
            setId(id)
        } else {
            navigate('/app/workspaces')
        }
    }

    const onChange = (queryOptions: QueryOptionsT) => {
        const q = {
            ...queryOptions,
            filter: {
                $and: [queryOptions.filter, { isPrivate: { $ne: true } }],
            },
        }
        dispatch(getCompaniesAsync(q))
    }

    const { t } = useTranslation()
    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'name',
                headerName: t('company:name'),
                width: 200,
            },
            {
                field: 'licenses',
                type: 'string',
            },
            {
                field: 'paidLicenses',
                type: 'string',
            },
            {
                field: 'ended',
                type: 'date',
            },
            {
                field: 'period',
                type: 'singleSelect',
                valueOptions: periodsEnum,
            },
            {
                field: 'isVerified',
                type: 'boolean',
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return (
                        row.ended?.getTime() > Date.now() &&
                        row.isVerified &&
                        row.licenses === row.paidLicenses
                    )
                },
                filterable: false,
                sortable: false,
            },
        ],
        [t, periodsEnum]
    )

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                margin: 4,
            }}
        >
            {Boolean(id) ? (
                <CompanyForm onDelete={() => setId(null)} loading={!company.status} />
            ) : (
                <DataGrid
                    data={companies.data}
                    total={companies.total}
                    onChange={onChange}
                    columns={columns}
                    onSelect={onSelect}
                    loading={!companies.status}
                />
            )}
        </Box>
    )
}
