import Box from '@mui/material/Box'
import { filters } from '../../../../data/styles/groups/filter.styles'
import { useObjectGroupActions } from '../../../../hooks/useObjectGroupActions'
import AddButton from '../../../common/AddButton'
import ExpandableSection from '../../../common/ExpandableSection'
import GroupFields from '../../../common/PropertyTools/GroupFields/GroupFields'

interface Props {
    object: AnySceneObjectT
    handleSetObjectValue: (property: any, value: any) => void
}

const Filters = ({ object, handleSetObjectValue }: Props) => {

    const { addObjectGroup, updateObjectGroups, deleteObjectGroup } = useObjectGroupActions()

    const handleAddGroup = (e: any) => {
        if (!e) e = window.event
        e.cancelBubble = true
        if (e.stopPropagation) e.stopPropagation()
        const updatedGroups = addObjectGroup("filters", object["filters"])
        handleSetObjectValue("filters", updatedGroups)
    }

    const handleSetGroup = (index: number, property: any, value: any) => {
        const updatedGroups = updateObjectGroups(object["filters"], index, property, value)
        handleSetObjectValue("filters", updatedGroups)
    }

    const handleDeleteGroup = (index: number) => {
        const updatedGroups = deleteObjectGroup(object["filters"], index)
        handleSetObjectValue("filters", updatedGroups)
    }

    const objectFilters: GroupStyleI[] | undefined = object.filters

    if (!objectFilters && !filters) return <></>

    return (
        <ExpandableSection
            expanded={false}
            title={"Filters"}
            secondaryActionComponent={
                <AddButton
                    text="filter"
                    handleAddObject={handleAddGroup}
                />
            }
            component={
                <Box>
                    {
                        objectFilters && filters &&
                        <GroupFields
                            object={object}
                            groupStyles={objectFilters}
                            properties={filters}
                            handleSetGroupValue={handleSetGroup}
                            handleDeleteGroup={handleDeleteGroup}
                        />
                    }
                </Box>
            }
        />
    )
};

export default Filters
