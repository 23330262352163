import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { isMobile } from 'react-device-detect'

const DARK_MODE_GREY = {
    50: '#F0F0F0',
    100: '#DCDCDC',
    200: '#C2C2C2',
    300: '#8f8f8f',
    400: '#6B6B6B',
    500: '#4C4B4C',
    600: '#313033',
    700: '#272629',
    800: '#1F1E1F',
    900: '#0E0D0E',
}

const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: {
            primary: '#FFFFFF',
            secondary: DARK_MODE_GREY[300],
            disabled: DARK_MODE_GREY[400],
        },
        primary: {
            main: '#0090B1',
            dark: '#006379',
            light: '#01B3DB',
            contrastText: '#FFFFFF',
        },
        secondary: blueGrey,
        success: {
            main: '#369B3B',
            light: '#4CAF50',
            dark: '#1B5E20',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#619CBA',
            light: '#6FC5F0',
            dark: '#37718E',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#CA2F23',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#F8AF41',
            light: '#FFE172',
            dark: '#C18003',
            contrastText: '#FFFFFF',
        },
        grey: DARK_MODE_GREY,
        background: {
            default: DARK_MODE_GREY[800],
            paper: DARK_MODE_GREY[700],
        },
        divider: DARK_MODE_GREY[600],
    },
    typography: {
        fontSize: 12,
        caption: {
            color: DARK_MODE_GREY[300],
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    width: 8,
                    height: 8,
                },
                '*::-webkit-scrollbar-corner': {
                    backgroundColor: DARK_MODE_GREY[600],
                    color: DARK_MODE_GREY[600],
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: DARK_MODE_GREY[600],
                    borderRadius: 4,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: DARK_MODE_GREY[900],
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                size: isMobile ? 'large' : 'small',
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'default',
            },
            styleOverrides: {
                root: {
                    padding: 6,
                },
            },
        },
        MuiToggleButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    margin: 3,
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    margin: 3,
                },
                fullWidth: {
                    width: '100%',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    // to allow onClick events on TextField labels
                    pointerEvents: 'auto',
                    width: '130%',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
                type: 'number',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: DARK_MODE_GREY[600],
                    borderRadius: 2,
                },
                underline: {
                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                    '&:hover:before': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: DARK_MODE_GREY[700],
                    backgroundImage: 'none',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: DARK_MODE_GREY[700],
                    backgroundImage: 'none',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    },
})

export default DarkTheme
