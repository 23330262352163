import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useMultiselectObject from '../../../hooks/editor/useMultiselectObject'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppSelector } from '../../../hooks/useRedux'
import RightClickContextMenu, { MenuItemT } from '../../common/ContextMenu/RightClickContextMenu'
import ImageContainer from './CanvasContainerType/ImageContainer'
import ItemContainer from './CanvasContainerType/ItemContainer'
import SequenceContainer from './CanvasContainerType/SequenceContainer'
import TextContainer from './CanvasContainerType/TextContainer'

interface Props {
    object: AnySceneObjectT
    canvasScaleCoef: number
    isHiding: boolean
    borderCoef: number
}

export type ObjectType = {
    position: 'relative' | 'absolute'
    left: number
    top: number
    width: number
    height: number
}

const CanvasItem = ({ object, canvasScaleCoef, isHiding, borderCoef }: Props) => {
    const { t } = useTranslation()
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const { handleMultiselectObject } = useMultiselectObject(object)
    const { duplicateObject, deleteObject } = useObjectActions()

    const menuItem: MenuItemT[] = [
        {
            icon: <ContentCopyIcon />,
            title: t('editor:duplicate'),
            onClick: (event) => handleDuplicateObject(event, object),
            divider: true,
        },
        {
            icon: <DeleteRoundedIcon />,
            title: t('editor:delete'),
            onClick: (event) => handleDeleteObject(event, object),
        },
    ]
    const getElementaryItem = () => {
        switch (object.type) {
            case 'item':
                return (
                    <ItemContainer
                        object={object}
                        isHiding={isHiding}
                        canvasScaleCoef={canvasScaleCoef}
                        borderCoef={borderCoef}
                        handleSelectObject={handleSelectObject}
                    />
                )
            case 'image':
                return (
                    <ImageContainer
                        object={object}
                        isHiding={isHiding}
                        canvasScaleCoef={canvasScaleCoef}
                        borderCoef={borderCoef}
                        handleSelectObject={handleSelectObject}
                    />
                )
            case 'text':
                return (
                    <TextContainer
                        object={object}
                        isHiding={isHiding}
                        canvasScaleCoef={canvasScaleCoef}
                        borderCoef={borderCoef}
                        handleSelectObject={handleSelectObject}
                    />
                )
            case 'sequence':
                return (
                    <SequenceContainer
                        object={object}
                        isHiding={isHiding}
                        canvasScaleCoef={canvasScaleCoef}
                        borderCoef={borderCoef}
                        handleSelectObject={handleSelectObject}
                    />
                )
            default:
                return <></>
        }
    }

    if (!object) {
        return <React.Fragment key={Math.random()}></React.Fragment>
    }

    const handleSelectObject = (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        ctrlCmdKey: boolean
    ) => {
        event.preventDefault()
        event.cancelable = true
        if (event.stopPropagation) event.stopPropagation()
        handleMultiselectObject(ctrlCmdKey)
    }

    const handleDuplicateObject = (event: React.MouseEvent, object: AnySceneObjectT) => {
        event.preventDefault()
        event.cancelable = true
        if (event.stopPropagation) event.stopPropagation()

        duplicateObject(object, object.parentId)
    }
    const handleDeleteObject = (event: React.MouseEvent, object: AnySceneObjectT) => {
        event.preventDefault()
        event.cancelable = true
        if (event.stopPropagation) event.stopPropagation()

        deleteObject(object)
    }

    return selectedObjectIds.length <= 1 ? (
        <RightClickContextMenu
            key={object.id}
            scaleCoef={canvasScaleCoef}
            menuLabel={getElementaryItem()}
            data={menuItem}
        />
    ) : (
        getElementaryItem()
    )
}

export default CanvasItem
