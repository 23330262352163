import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import CanvasSettingDialog from './CanvasSettingDialog'

const CanvasSettings = () => {
    const [openCanvasDialog, setOpenCanvasDialog] = useState<boolean>(false)
    const handleOpenCanvasDialog = () => {
        setOpenCanvasDialog(true)
    }
    const handleCloseCanvasDialog = () => {
        setOpenCanvasDialog(false)
    }

    return (
        <>
            <Tooltip title={t('editor:settings.title')}>
                <IconButton onClick={handleOpenCanvasDialog} sx={{ ml: 1 }}>
                    <SettingsIcon fontSize="small" sx={{ opacity: 0.5 }} />
                </IconButton>
            </Tooltip>
            <CanvasSettingDialog
                open={openCanvasDialog}
                disabled
                onClose={handleCloseCanvasDialog}
            />
        </>
    )
}

export default CanvasSettings
