import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QueryOptionsT } from '../../components/common/DataGrid'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'
import { CompanyT } from './company.slice'

interface CompaniesState {
    data: CompanyT[]
    total: number
    status?: string
}

const initialState: CompaniesState = {
    data: [],
    total: 0,
}

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        getCompaniesPending: (state: CompaniesState) => {
            state.status = undefined
        },
        getCompaniesFulfilled: (state: CompaniesState, action: PayloadAction<ListT<CompanyT>>) => {
            //add disabled prop when workspace is expired
            const modifiedData = action.payload.data.map((x) =>
                (x.expired && x.expired.getTime() > Date.now()) || x.isPrivate === true
                    ? x
                    : { ...x, disabled: true }
            )
            state.status = Status.OK
            state.data = modifiedData
            state.total = action.payload.total
        },
        getCompaniesRejected: (state: CompaniesState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getCompaniesFulfilled, getCompaniesPending, getCompaniesRejected } =
    companiesSlice.actions

export const getCompaniesAsync =
    (queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getCompaniesPending())
            const companies = await get<ListT<CompanyT>>('companies', queryOptions)
            dispatch(getCompaniesFulfilled(companies))
        } catch (error: any) {
            dispatch(getCompaniesRejected(error.message))
        }
    }

export const getCompaniesCurrentAsync =
    (queryOptions?: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getCompaniesPending())
            const companies = await get<ListT<CompanyT>>('companies/current', queryOptions)
            dispatch(getCompaniesFulfilled(companies))
        } catch (error: any) {
            dispatch(getCompaniesRejected(error.message))
        }
    }

export default companiesSlice.reducer
