import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { useTimelineActions } from '../../../hooks/useTimelineActions'
import { AssetT, softDeleteAssetAsync } from '../../../store/slices/assets.slice'
import { ScrollableContainer } from '../../../style/styles'
import ButtonContextMenu from '../../common/ContextMenu/ButtonContextMenu'
import RightClickContextMenu from '../../common/ContextMenu/RightClickContextMenu'
import AssetsDialog from '../ObjectsTree/AssetsDialog'
import DataSourceDialog from './DataSourceDialog'
import DataSourceNode from './DataSourceNode'

const DATA_SOURCE_TYPE = 'json|xml|text'

const DataSource = () => {
    const [openAssetsDialog, setOpenAssetsDialog] = useState<boolean>(false)

    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const assets = useAppSelector((state) => state.assets.data)
    const graphic = useAppSelector((state) => state.graphic)
    const [dialog, setDialog] = useState<{
        open: boolean
        selectedAsset: AssetT | undefined
    }>({ open: false, selectedAsset: undefined })

    const handleCreateDataSource = () => {
        setDialog({ open: true, selectedAsset: undefined })
    }

    const { delTimelineLabel } = useTimelineActions()

    const handleDeleteDataSource = (asset: AssetT) => {
        dispatch(softDeleteAssetAsync(asset._id))
        delTimelineLabel(asset.slug)
    }

    const handleOpenDataSource = (asset: AssetT) => {
        setDialog({ open: true, selectedAsset: asset })
    }

    const handleCloseDialog = () => {
        setDialog({ open: false, selectedAsset: undefined })
    }

    return (
        <>
            <ScrollableContainer>
                <div>
                    <Grid sx={{ mb: 0.5 }}>
                        <ButtonContextMenu
                            title={'data source'}
                            icon={<ElectricalServicesIcon fontSize="small" />}
                            data={[
                                {
                                    title: t('editor:newButton'),
                                    onClick: handleCreateDataSource,
                                },
                                {
                                    title: t('editor:assetsDialog.buttonOpen'),
                                    onClick: () => setOpenAssetsDialog(true),
                                },
                            ]}
                        />
                        <AssetsDialog
                            open={openAssetsDialog}
                            onClose={() => setOpenAssetsDialog(false)}
                            parentId={null}
                            type={DATA_SOURCE_TYPE}
                        />
                    </Grid>
                </div>
                {!graphic.status || assets.length === 0 ? (
                    <></>
                ) : (
                    assets
                        .filter((asset) => asset.type && DATA_SOURCE_TYPE.includes(asset.type))
                        .map((asset: AssetT, index: number) => {
                            return (
                                <Typography
                                    key={asset._id}
                                    variant="h6"
                                    align="center"
                                    gutterBottom={true}
                                    color={theme.palette.text.disabled}
                                    m={0}
                                >
                                    <RightClickContextMenu
                                        menuLabel={
                                            <DataSourceNode
                                                title={asset.name}
                                                handleSelect={() => handleOpenDataSource(asset)}
                                            />
                                        }
                                        data={[
                                            {
                                                icon: <DeleteRoundedIcon />,
                                                title: t('dataSource:deleteDataSource'),
                                                onClick: () => handleDeleteDataSource(asset),
                                            },
                                        ]}
                                    />
                                </Typography>
                            )
                        })
                )}
            </ScrollableContainer>
            <DataSourceDialog
                selectedAsset={dialog.selectedAsset}
                open={dialog.open}
                onClose={handleCloseDialog}
            />
        </>
    )
}

export default DataSource
