import { Button, ButtonGroup, Tooltip, useTheme } from '@mui/material'

interface Option {
    name: string
    icon?: React.ElementType
    tooltipTitle: string
}

interface Props {
    onChange: (value: string) => void
    options: Option[]
    value: string
}

const AssetsFilter = ({ onChange, value, options }: Props) => {
    const theme = useTheme()

    return (
        <ButtonGroup variant="text" aria-label="assets group" size="large" color="inherit">
            {options.map((option) => {
                const isSelected = option.name === value
                return (
                    <Tooltip title={option.tooltipTitle}>
                        <Button
                            key={option.name}
                            onClick={() => onChange(option.name)}
                            sx={{
                                backgroundColor: isSelected
                                    ? theme.palette.grey[500]
                                    : theme.palette.grey[600],
                                ':hover': {
                                    backgroundColor: theme.palette.grey[500],
                                },
                            }}
                        >
                            {option.icon ? <option.icon size={16} /> : option.name}
                        </Button>
                    </Tooltip>
                )
            })}
        </ButtonGroup>
    )
}

export default AssetsFilter
