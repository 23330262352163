import ReactSplit, { SplitDirection } from '@devbookhq/splitter'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { SettingsKey, setPreferencesAsync } from '../../store/slices/editor.slice'
import DataSource from './DataSource/DataSource'
import ObjectsTree from './ObjectsTree/ObjectsTree'

const LeftSideNavbar = () => {
    const dispatch = useAppDispatch()
    const horizontalLeftSideBarSplitterSize = useAppSelector(
        (state) => state.editor.value.settings.horizontalLeftSideBarSplitterSize
    )
    const handleResizeFinished = (newSizes: number[], preferencesType: SettingsKey) => {
        dispatch(setPreferencesAsync(preferencesType, newSizes))
    }
    return (
        <>
            <ReactSplit
                direction={SplitDirection.Vertical}
                initialSizes={horizontalLeftSideBarSplitterSize}
                onResizeFinished={(_, newSizes) =>
                    handleResizeFinished(newSizes, 'horizontalLeftSideBarSplitterSize')
                }
                gutterClassName="custom-gutter-vertical"
            >
                <ObjectsTree />
                <DataSource />
            </ReactSplit>
        </>
    )
}

export default LeftSideNavbar
