import { ReactElement, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export interface RouteT {
    path: string
    element?: ReactElement
    permission?: number
    children?: RouteT[]
    icon?: ReactElement
    label?: string
    disabled?: boolean
}

const getRoute = (routes: RouteT[], path: string[]): RouteT | undefined => {
    const currentPath = path.shift()
    const route = routes.find((r) => r.path === currentPath)

    if (route && route.children && path.length > 0) {
        return getRoute(route.children, path)
    } else {
        return route
    }
}

export function useRoute(routes: RouteT[]): RouteT | undefined {
    const location = useLocation()
    const route = useMemo(() => {
        const path = location.pathname.split('/').slice(1)
        return getRoute(routes, path)
    }, [location, routes])

    return route
}
