export const LinearEaseStyle: EaseT = {
    easeStyle: "linear",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const Power1EaseStyle: EaseT = {
    easeStyle: "power1",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const Power2EaseStyle: EaseT = {
    easeStyle: "power2",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const Power3EaseStyle: EaseT = {
    easeStyle: "power3",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const Power4EaseStyle: EaseT = {
    easeStyle: "power4",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const BackEaseStyle: EaseT = {
    easeStyle: "back",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [
        { strength: 'extra-soft', value: "(0.5)" },
        { strength: 'soft', value: "(0.75)" },
        { strength: 'normal', value: "(1.0)" },
        { strength: 'strong', value: "(2.0)" },
        { strength: 'extra-strong', value: "(3.0)" }
    ],
}

export const ElasticEaseStyle: EaseT = {
    easeStyle: "elastic",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const BounceEaseStyle: EaseT = {
    easeStyle: "bounce",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const CircEaseStyle: EaseT = {
    easeStyle: "circ",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const ExpoEaseStyle: EaseT = {
    easeStyle: "expo",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const SineEaseStyle: EaseT = {
    easeStyle: "sine",
    easeType: "out",
    easeStrength: "normal",
    easeStrengthValues: [],
}

export const defaultEase: EaseT = LinearEaseStyle

export const EaseStyles: EaseT[] = [
    LinearEaseStyle,
    Power1EaseStyle,
    Power2EaseStyle,
    Power3EaseStyle,
    Power4EaseStyle,
    BackEaseStyle,
    ElasticEaseStyle,
    BounceEaseStyle,
    CircEaseStyle,
    ExpoEaseStyle,
    SineEaseStyle
]
