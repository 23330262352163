import React from 'react'
import ErrorFallback from './ErrorFallback'

interface Props {
    children: React.ReactNode
    render: (error: Error) => React.ReactNode
    onError: (error: Error, errorInfo: ErrorInfo) => void
}

interface ErrorInfo {
    componentStack: string
}

class ErrorBoundary extends React.Component<Props> {
    state: { error: Error | null }

    constructor(props: Props) {
        super(props)
        this.state = { error: null }
    }

    static getDerivedStateFromError(error: Error) {
        return { error }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.onError(error, errorInfo)
    }

    render() {
        if (this.state.error) {
            return this.props.render(this.state.error)
        }

        return this.props.children
    }
}

export const withErrorBoundary = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const onError = async (error: Error, info: ErrorInfo) => {
        console.error(error)
    }

    const ComponentWithExtraInfo = (props: P) => {
        return (
            <ErrorBoundary
                render={(error: Error) => <ErrorFallback error={error} />}
                onError={onError}
            >
                <WrappedComponent {...props} />
            </ErrorBoundary>
        )
    }
    return ComponentWithExtraInfo
}
