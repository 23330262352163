import { Box, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Draggable from 'react-draggable'
import { renderStyles } from '../../../../data/styles/styles'
import { getImageSrc, preloadTween, processTween } from '../../../../helpers/object.helpers'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { useDraggable } from '../../../../hooks/editor/useDraggable'
import useDynamicRefs from '../../../../hooks/useDynamicRefs'
import { useAppSelector } from '../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { CanvasCursor } from '../../../../store/slices/canvasCursor.slice'
import DraggableItem, { DRAGGABLE_ITEMS } from '../DraggableItem'

interface Props {
    object: ImageI | SequenceI
    isHiding: boolean
    canvasScaleCoef: number
    borderCoef: number
    handleSelectObject: (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        CtrlCmdKey: boolean
    ) => void
}

const DELAY = 0.5

const ImageContainer = ({
    object,
    isHiding,
    canvasScaleCoef,
    borderCoef,
    handleSelectObject,
}: Props) => {
    const theme = useTheme()
    const activeObjectId: string | null = useAppSelector((state) => state.activeObject.value)
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const canvasCursor = useAppSelector((state) => state.canvasCursor.value)
    const [lastTime, setLastTime] = useState(0)

    const objectStyles = renderStyles(object, 'inline')
    const imageSrc = useMemo(() => getImageSrc(object, []), [object])

    const [, setRef] = useDynamicRefs<HTMLImageElement>()

    const {
        handleOnStartDrag,
        handleDragging,
        handleDragEnd,
        draggableAxis,
        draggableScale,
        objectPositioning,
        setCursorWhenDragging,
    } = useDraggable(object, canvasScaleCoef, undefined)
    const draggableBoxRef = React.useRef<HTMLDivElement>(null)
    const visibility = object.isHidden || isHiding ? 'hidden' : 'visible'

    const animations = useAppSelector((state) => selectUndoable(state).animations.value)
    const sequences: AnimationI[] = useMemo(() => {
        return (
            Object.values(animations).filter(
                (animation) =>
                    animation &&
                    object.animationIds.includes(animation.id) &&
                    animation.tween.name === 'sequence'
            ) as AnimationI[]
        ).map((sequence) => {
            return {
                ...sequence,
                tween: {
                    ...sequence.tween,
                    from: processTween(sequence.tween.from),
                },
            }
        })
    }, [animations, object.animationIds])

    const masterTimeline = useTimelineActions().masterTimeline

    //masterTimeline?.eventCallback must be in useEffect
    useEffect(() => {
        masterTimeline?.eventCallback('onStart', () => {
            sequences
                .filter((sequence) => sequence.delay <= DELAY)
                .forEach((sequence) => preloadTween(sequence.tween.from))
        })

        masterTimeline?.eventCallback('onUpdate', () => {
            const time = masterTimeline.time()

            sequences
                .filter(
                    (sequence) =>
                        sequence.delay > lastTime + DELAY && sequence.delay <= time + DELAY
                )
                .forEach((animation) => preloadTween(animation.tween.from))

            setLastTime(time)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterTimeline])

    return (
        <Box
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Draggable
                position={{
                    x: 0,
                    y: 0,
                }}
                axis={draggableAxis}
                scale={draggableScale}
                onStop={handleDragEnd}
                onDrag={handleDragging}
                onStart={handleOnStartDrag}
                disabled={
                    activeObjectId !== object.id || canvasCursor === CanvasCursor.GrabCursorBySpace
                }
            >
                <Box>
                    <img
                        src={imageSrc}
                        alt={object.id}
                        className={`image-container ${object.id}`}
                        id={object.id}
                        ref={setRef(object.id)}
                        onClick={(e) =>
                            handleSelectObject(
                                e,
                                object.id,
                                object.animationIds,
                                e.metaKey || e.ctrlKey
                            )
                        }
                        style={{
                            ...objectStyles,
                            visibility: visibility,
                            outline:
                                activeObjectId === object.id ||
                                selectedObjectIds.find((id) => id === object.id)
                                    ? `${borderCoef}px dotted ${theme.palette.secondary.light}`
                                    : 'none',
                            cursor: setCursorWhenDragging,
                        }}
                    />
                </Box>
            </Draggable>
            {activeObjectId === object.id &&
                DRAGGABLE_ITEMS.map((x) => (
                    <DraggableItem
                        key={x.direction}
                        ref={draggableBoxRef}
                        borderCoef={borderCoef}
                        object={object}
                        position={objectPositioning}
                        canvasScaleCoef={canvasScaleCoef}
                        direction={x.direction}
                        axis={x.axis}
                    />
                ))}
        </Box>
    )
}

export default ImageContainer
