import { SelectChangeEvent } from '@mui/material'
import TextField from '@mui/material/TextField'
import { t } from 'i18next'
import { ChangeEvent, useMemo } from 'react'
import { defaultTimeline } from '../../../../data/defaults/timeline.types.defaults'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { getFirstLetterUpper } from '../../../../helpers/string.helpers'
import { useController } from '../../../../hooks/useController'
import useFramerate from '../../../../hooks/useFramerate'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import {
    EndState,
    InitState as InitStateEnum,
    setPreferencesAsync,
} from '../../../../store/slices/editor.slice'
import SelectTimelineState from './SelectTimelineState'

interface Props {
    disabled: boolean
}

const InitState = ({ disabled }: Props) => {
    const dispatch = useAppDispatch()
    const timeline: TimelineI = useAppSelector((state) => selectUndoable(state).timeline.value)
    const settingsTimeline = useAppSelector((state) => state.editor.value.settings.timeline)
    const { updateTimeline } = useTimelineActions()
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)
    const { resultTimeFramerate } = useFramerate()

    const timelineMinDuration = useMemo(() => {
        return resultTimeFramerate(timeline.minduration ?? defaultTimeline.minduration)
    }, [resultTimeFramerate, timeline.minduration])

    const { value, onChange } = useController(timelineMinDuration.toString())

    const handleUpdateTimeline = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //convert fps to sec and save it like string
        let helper = (Math.round((Number(e.target.value) / framerate) * 100) / 100).toString()
        updateTimeline(e.target.name, helper)
    }

    const handleUpdateTimelinePreferences = (e: SelectChangeEvent<InitStateEnum | EndState>) => {
        //TO DO need to refactor -> set nested object
        dispatch(
            setPreferencesAsync('timeline', {
                ...settingsTimeline,
                [e.target.name]: e.target.value,
            })
        )
    }

    return (
        <>
            <SelectTimelineState
                name="initState"
                labelTitle={t(
                    `editor:timeline.select${getFirstLetterUpper('initState')}.labelTitle`
                )}
                value={settingsTimeline.initState}
                onChange={handleUpdateTimelinePreferences}
                disabled={disabled}
                options={Object.values(InitStateEnum)}
                prefix={getFirstLetterUpper('initState')}
            />
            <SelectTimelineState
                name="endState"
                labelTitle={t(
                    `editor:timeline.select${getFirstLetterUpper('endState')}.labelTitle`
                )}
                value={settingsTimeline.endState}
                onChange={handleUpdateTimelinePreferences}
                disabled={disabled}
                options={Object.values(EndState)}
                prefix={getFirstLetterUpper('endState')}
            />
            <TextField
                type="number"
                label="repeats"
                name="repeat"
                value={timeline.repeat}
                onChange={handleUpdateTimeline}
                disabled={disabled}
                InputProps={{
                    inputProps: {
                        min: -1,
                        step: 1,
                    },
                }}
            />
            <TextField
                type="number"
                label="minduration"
                name="minduration"
                value={value}
                onChange={onChange}
                onBlur={handleUpdateTimeline}
                disabled={disabled}
                InputProps={{
                    inputProps: {
                        min: 0,
                        step: 1,
                    },
                }}
            />
        </>
    )
}
export default InitState
