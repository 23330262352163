import Color from './ExpandableSections/Color'
import Filters from './ExpandableSections/Filters'
import MarginAndPadding from './ExpandableSections/MarginAndPadding'
import SizeAndPosition from './ExpandableSections/SizeAndPosition'
import TextAndFont from './ExpandableSections/TextAndFont'
import Transforms from './ExpandableSections/Transforms'

interface Props {
    object: TextI
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const TextTools = ({ object, handleSetStyleValue, handleSetObjectValue, handleSetAttributeValue }: Props) => {
    return (
        <div>
            <SizeAndPosition object={object} handleSetValue={handleSetStyleValue} />

            <TextAndFont
                object={object}
                handleSetValue={handleSetObjectValue}
                handleSetStyleValue={handleSetStyleValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />

            <MarginAndPadding object={object} handleSetValue={handleSetStyleValue} />

            <Color
                title="Color & background"
                object={object}
                handleSetValue={handleSetStyleValue}
            />

            <Filters object={object} handleSetObjectValue={handleSetObjectValue} />

            <Transforms
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
            />
        </div>
    )
}

export default TextTools
