import JSONfn from 'json-fn'
import JSZip from 'jszip'
import { GSAPlib } from '../data/libs'
import { renderStyles } from '../data/styles/styles'
import { FileResponse, get, getFile } from '../helpers/ajax.helpers'
import {
    S3_URL,
    createJSONparams,
    getFileId,
    getFilePath,
    getFontPath,
    getImagePath,
    getSequencePath,
} from '../helpers/file.helpers'
import {
    initMasterTimelineForDownload,
    masterTimelineScripts,
} from '../helpers/masterTimeline.helpers'
import { getImageSrc } from '../helpers/object.helpers'
import { generalScripts } from '../helpers/scripts.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import { replaceSpacesWithDashes } from '../helpers/string.helpers'
import { selectAllAnimations } from '../store/slices/animations.slice'
import { setPercentLoading } from '../store/slices/loading.slice'
import {
    selectObjectById,
    selectObjects,
    selectSortedRootObjects,
} from '../store/slices/objects.slice'
import { AppStateT } from '../store/store'
import { useCanvas } from './useCanvas'
import { useAppDispatch, useAppSelector } from './useRedux'

// const GSAPlibrary: string = '<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/gsap.min.js"></script>'
// const jQueryLibrary: string = `<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>`

const globalCSS: string = `* { box-sizing:border-box; margin:0; padding:0; } `
let inlineCSS: string

const NewLine = '\n'

const useHTMLFileContent = () => {
    const dispatch = useAppDispatch()
    const state: AppStateT = useAppSelector((state) => state)
    const { canvasResolution: canvas } = useCanvas()
    const objects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )
    const allObjects: AnySceneObjectT[] = useAppSelector((state) =>
        selectObjects(selectUndoable(state).objects)
    )
    const timeline: TimelineI = useAppSelector((state) => selectUndoable(state).timeline.value)
    const animations: AnimationI[] = useAppSelector((state) =>
        selectAllAnimations(selectUndoable(state).animations)
    )

    // Transform object to HTML div according to the object's type - item or text
    const ObjectToHTML = (object: AnySceneObjectT, state: AppStateT, assets: FileResponse[]) => {
        let result = ''
        const styles = renderStyles(object, 'css')

        if (object.type === 'item') {
            result +=
                "<div class='object-container " +
                object.id +
                ' ' +
                replaceSpacesWithDashes(object.title) +
                "' id='" +
                object.id +
                "' style=' "
            inlineCSS += '#' + object.id + ' {' + styles + '} '
            result += ";'>" + NewLine
            result += object.childIds
                .map((childId) => {
                    return ObjectToHTML(
                        selectObjectById(selectUndoable(state).objects, childId),
                        state,
                        assets
                    )
                })
                .join('')
            result += '</div>' + NewLine
        } else if (object.type === 'text') {
            result +=
                "<div class='object-text-container " +
                object.id +
                ' ' +
                replaceSpacesWithDashes(object.title) +
                " ' id='" +
                object.id +
                "' style=' "
            inlineCSS += '#' + object.id + ' {' + styles + '} '
            result += ";'>" + NewLine
            result += (object as TextI).text
            result += (object as TextI).childIds
                .map((childId) => {
                    return ObjectToHTML(
                        selectObjectById(selectUndoable(state).objects, childId),
                        state,
                        assets
                    )
                })
                .join('')
            result += '</div>' + NewLine
        }
        if (object.type === 'image' || object.type === 'sequence') {
            result += `
            <img src="${getImageSrc(object, assets)}" class="object-image-container ${
                object.id
            } ${replaceSpacesWithDashes(object.title)}" id="${object.id}" style="${styles}">
                    ${NewLine}
                    ${object.childIds
                        .map((childId) =>
                            ObjectToHTML(
                                selectObjectById(selectUndoable(state).objects, childId),
                                state,
                                assets
                            )
                        )
                        .join('')}
                </img>${NewLine}`
        }
        return result
    }

    // Body of final HTML graphics as a string
    const createHTMLbody = (
        canvas: CanvasI,
        objects: AnySceneObjectT[],
        animations: AnimationI[],
        state: AppStateT,
        assets: FileResponse[]
    ): string => {
        const objectsAsDivs = objects
            .map((object) => {
                return ObjectToHTML(object, state, assets)
            })
            .join('') // join removes commas between mapped objects
        return (
            "<div class='canvas' id='canvas' style=' " +
            'width: ' +
            canvas.width +
            'px; ' +
            'height: ' +
            canvas.height +
            'px; ' +
            'position: relative;' +
            'overflow: hidden;' +
            'visibility: ' +
            (animations.length === 0 ? 'visible;' : 'hidden;') +
            " '>" +
            NewLine +
            objectsAsDivs +
            '</div>' +
            NewLine
        )
    }

    const processObjects = (assets: FileResponse[]) => {
        if (assets.length) {
            return allObjects.map((o) => {
                if (o.type === 'image') {
                    return {
                        ...o,
                        fill: o.fill.map((f) => {
                            if (f.property === 'backgroundImage') {
                                const regex = new RegExp(`${S3_URL}/([^/]+)"`)
                                const match = regex.exec(f.value)
                                if (match) {
                                    const file = assets.find((f) => f._id === getFileId(match[1]))
                                    if (file) {
                                        return {
                                            ...f,
                                            value: `url("${getImagePath(file)}")`,
                                        }
                                    }
                                }
                            }
                            return f
                        }),
                    }
                } else {
                    return o
                }
            })
        }

        return allObjects
    }

    const processAnimations = (assets: FileResponse[]) => {
        if (assets.length) {
            return animations.map((animation) => {
                if (animation.tween.name === 'sequence') {
                    return {
                        ...animation,
                        tween: {
                            ...animation.tween,
                            from: animation.tween.from.map((f) => {
                                if (f.property === 'attr.src') {
                                    const filename = f.value.split('/').pop() as string
                                    const file = assets.find(
                                        (file) => file._id === getFileId(filename)
                                    )
                                    if (file) {
                                        return { ...f, value: getSequencePath(file) }
                                    }
                                }
                                return f
                            }),
                        },
                    }
                }
                return animation
            })
        }

        return animations
    }

    // HTML graphics as a string
    const processFile = (
        basePath: string = window.location.origin,
        assets: FileResponse[] = []
    ) => {
        const processedObjects = processObjects(assets)
        const processedAnimations = processAnimations(assets)

        inlineCSS = globalCSS
        const HTMLbody: string = createHTMLbody(canvas, objects, processedAnimations, state, assets)
        const settingsTimeline = state.editor.value.settings.timeline

        let finalHTML: string = ''
        finalHTML += '<!DOCTYPE html>' + NewLine
        // add HTML head with graphic params
        finalHTML += '<head>' + NewLine
        finalHTML += "<meta version='" + process.env.REACT_APP_VERSION + "'>" + NewLine
        finalHTML += "<meta charset='UTF-8'>" + NewLine
        finalHTML +=
            "<meta property='JSONparams' content='" +
            createJSONparams(processedObjects, 'meta') +
            "'>" +
            NewLine
        finalHTML += "<meta property='canvas' content='" + JSONfn.stringify(canvas) + "'>" + NewLine
        finalHTML +=
            `<meta property='assets' content='${JSONfn.stringify(state.assets.data)}'>` + NewLine
        finalHTML +=
            "<meta property='objects' content='" +
            JSONfn.stringify(processedObjects) +
            "'>" +
            NewLine
        // finalHTML += "<meta property='animations' content='" + JSONfn.stringify(removeTargetsFromTweens(animations)) + "'>"
        finalHTML +=
            processedAnimations.length > 0
                ? "<meta property='animations' content='" +
                  JSONfn.stringify(processedAnimations) +
                  "'>" +
                  NewLine
                : ''
        finalHTML +=
            processedAnimations.length > 0
                ? "<meta property='timeline' content='" +
                  JSONfn.stringify(timeline) +
                  "'>" +
                  NewLine
                : ''

        finalHTML += '<style>' + NewLine
        state.assets.data
            .filter((asset) => asset.type === 'font')
            .forEach((asset) => {
                const url = basePath === '.' ? getFontPath(asset) : asset.url
                finalHTML += `
                    @font-face {
                        font-family: ${asset.name};
                        src: url(${url});
                    }
                `
            })

        finalHTML += inlineCSS + NewLine
        finalHTML += '</style>' + NewLine

        finalHTML += '</head>' + NewLine
        // add HTML body
        finalHTML +=
            processedAnimations.length > 0 ? "<body onload='initTimeline()'>" : '<body>' + NewLine
        // add HTML objects
        finalHTML += HTMLbody + NewLine
        // add GSAP
        finalHTML +=
            processedAnimations.length > 0
                ? '<script>' + NewLine + GSAPlib + NewLine + '</script>' + NewLine
                : '' + NewLine
        finalHTML += `<script src="${basePath}/scripts/tweenly.umd.cjs"></script>` + NewLine
        // add GSAP timeline
        finalHTML +=
            processedAnimations.length > 0
                ? '<script>' +
                  NewLine +
                  initMasterTimelineForDownload(timeline, processedAnimations, settingsTimeline) +
                  NewLine +
                  '</script>' +
                  NewLine
                : ''
        // add custom scripts
        finalHTML +=
            '<script>' +
            NewLine +
            generalScripts(processedObjects, state.assets.data) +
            NewLine +
            '</script>' +
            NewLine
        finalHTML +=
            animations.length > 0
                ? '<script>' + NewLine + masterTimelineScripts() + NewLine + '</script>' + NewLine
                : ''
        finalHTML += '</body>' + NewLine + '</html>' + NewLine
        return finalHTML
    }

    const processZip = async () => {
        const zip = new JSZip()
        let basePath = window.location.origin
        const libResponse = await fetch(`${basePath}/scripts/tweenly.umd.cjs`)
        if (libResponse.ok) {
            zip.file('scripts/tweenly.umd.cjs', await libResponse.blob())
            basePath = '.'
        }

        let files: FileResponse[] = []

        const images = allObjects.filter((obj) => obj.type === 'image' || obj.type === 'sequence')

        if (images.length) {
            files = await get('files', {
                key: `/(images|sequences)/(${images.map((image) => image.id).join('|')})`,
            })

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const path = getFilePath(file)
                if (path) {
                    const data = await getFile(file._id)
                    zip.file(path, data)
                }
                dispatch(setPercentLoading(Number(((i / files.length) * 100) / 2)))
            }
        }

        for (const asset of state.assets.data.filter((asset) => asset.type === 'font')) {
            const path = getFontPath(asset)
            const data = await getFile(asset.slug)
            zip.file(path, data)
        }

        let content = processFile(basePath, files)
        zip.file('index.html', content)

        //updateCallback(metadata) is called for set perceptual Loading when generate ZIP file
        return await zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
            dispatch(setPercentLoading(Number(100 / 2 + metadata.percent / 2)))
        })
    }

    return { processFile, processZip }
}

export default useHTMLFileContent
