import { useCallback, useEffect, useState } from 'react'
import { sumArray } from '../../helpers/array.helpers'
import { getObjectPositioning } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { selectObjects } from '../../store/slices/objects.slice'
import { AppStateT } from '../../store/store'
import useObject from '../useObject'
import { useAppSelector } from '../useRedux'
import { ObjectStylesWithId } from './useWrapSelectedObjects'

export const DEFAULT_OBJECT_STYLES = { top: 0, left: 0, width: 0, height: 0 }
export const DEFAULT_OBJECT_STYLES_WITH_ID = { ...DEFAULT_OBJECT_STYLES, objectId: '' }

const useWrapSelectedObject = () => {
    const activeObjectId: string | null = useAppSelector((state) => state.activeObject.value)
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const [wrapperObjectsStyles, setWrapperObjectsStyles] = useState<ObjectStylesWithId>(
        DEFAULT_OBJECT_STYLES_WITH_ID
    )
    const [activeChildObjectIds, setActiveChildObjectIds] = useState<string[]>([])
    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )
    const { findObjectById } = useObject()

    /**
     * recursive function for find every child ids of current object
     * @param object - current object
     * @param ids - array of child ids of current object
     * @returns all ids inside object
     */
    const getChildObjectIds = (object: AnySceneObjectT, ids: string[] = []) => {
        ids.push(object.id)

        object.childIds.forEach((id) => {
            const childObject = objects.find((o) => o.id === id)
            if (childObject) {
                getChildObjectIds(childObject, ids)
            }
        })

        return ids
    }

    /**
     * Get absolute position for object,
     * recursive find root parent and set absolute position of top, left, edgeRight, edgeBottom
     * @param parentSize { height: number; width: number } - the size of the object for which you need an absolute position
     * @param objectId :string - current object ID,
     * @param top - local param for store values
     * @param left - local param for store values
     * @returns top, left, edgeRight, edgeBottom
     */
    const getAbsoluteObjectPosition = (
        currentObjectSize: { height: number; width: number },
        objectId: string,
        top: number[] = [],
        left: number[] = []
    ) => {
        const object = findObjectById(objectId)

        if (object) {
            const actualStyles = getObjectPositioning(object)
            top.push(Number(actualStyles.top))
            left.push(Number(actualStyles.left))
            if (object.parentId) {
                getAbsoluteObjectPosition(currentObjectSize, object.parentId, top, left)
            }
        }
        return {
            top: sumArray(top),
            left: sumArray(left),
            edgeRight: sumArray(left) + Number(currentObjectSize.width),
            edgeBottom: sumArray(top) + Number(currentObjectSize.height),
        }
    }

    /**
     * group all absolute position of object inside of single selected object
     * @param ids - ids of object for which you need absolute positions
     * @returns arrays of top, left, edgeRight, edgeBottom
     */
    const handleGroupAbsolutePosition = (ids: string[]) => {
        let finalTop: number[] = []
        let finalLeft: number[] = []
        let finalEdgeRight: number[] = []
        let finalEdgeBottom: number[] = []

        ids.forEach((id) => {
            const object = findObjectById(id)
            if (object) {
                const objectStyles = getObjectPositioning(object)
                const currentObjectSize = { height: objectStyles.height, width: objectStyles.width }
                const absolutePosition = getAbsoluteObjectPosition(currentObjectSize, object.id)
                finalTop = [...finalTop, absolutePosition.top]
                finalLeft = [...finalLeft, absolutePosition.left]
                finalEdgeRight = [...finalEdgeRight, absolutePosition.edgeRight]
                finalEdgeBottom = [...finalEdgeBottom, absolutePosition.edgeBottom]
            }
        })
        return {
            top: finalTop,
            left: finalLeft,
            edgeRight: finalEdgeRight,
            edgeBottom: finalEdgeBottom,
        }
    }

    /**
     * wrap all object inside of selected object
     */
    const handleWrapSelectedObject = useCallback(() => {
        let arrayTop: number[] = []
        let arrayLeft: number[] = []
        let arrayEdgeRight: number[] = []
        let arrayEdgeBottom: number[] = []
        let selectedObject: AnySceneObjectT | undefined = undefined
        let objectId: string = ''
        let relativeTop: number = 0
        let relativeLeft: number = 0

        if (activeObjectId) {
            selectedObject = findObjectById(activeObjectId)
        } else if (selectedObjectIds.length === 0) {
            selectedObject = findObjectById(selectedObjectIds[0])
        }
        if (selectedObject) {
            const allObjectIds = getChildObjectIds(selectedObject)

            //set all childIds object in active object
            setActiveChildObjectIds(allObjectIds)

            const valuesAbsoluteObjectsPosition = handleGroupAbsolutePosition(allObjectIds)

            const selectedObjectStyles = getObjectPositioning(selectedObject)
            const selectedObjectSize = {
                height: selectedObjectStyles.height,
                width: selectedObjectStyles.width,
            }
            relativeTop =
                getAbsoluteObjectPosition(selectedObjectSize, selectedObject.id).top -
                selectedObjectStyles.top
            relativeLeft =
                getAbsoluteObjectPosition(selectedObjectSize, selectedObject.id).left -
                selectedObjectStyles.left

            arrayTop = [...arrayTop, ...valuesAbsoluteObjectsPosition.top]
            arrayLeft = [...arrayLeft, ...valuesAbsoluteObjectsPosition.left]
            arrayEdgeRight = [...arrayEdgeRight, ...valuesAbsoluteObjectsPosition.edgeRight]
            arrayEdgeBottom = [...arrayEdgeBottom, ...valuesAbsoluteObjectsPosition.edgeBottom]
            objectId = selectedObject.id
        }

        const wrapperObjectsStyles: ObjectStylesWithId = {
            top: Number(Math.min(...arrayTop.map((x) => x - relativeTop))),
            left: Number(Math.min(...arrayLeft.map((x) => x - relativeLeft))),
            width: Number(Math.max(...arrayEdgeRight)) - Number(Math.min(...arrayLeft)),
            height: Number(Math.max(...arrayEdgeBottom) - Math.min(...arrayTop)),
            objectId: objectId,
        }
        if (selectedObjectIds.length > 1) {
            setWrapperObjectsStyles(DEFAULT_OBJECT_STYLES_WITH_ID)
            setActiveChildObjectIds([])
        } else if (selectedObject) {
            setWrapperObjectsStyles(wrapperObjectsStyles)
        } else {
            setWrapperObjectsStyles(DEFAULT_OBJECT_STYLES_WITH_ID)
            setActiveChildObjectIds([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeObjectId, findObjectById, selectedObjectIds])

    //render wrapper for single selected object every time when condition is fulfilled
    useEffect(() => {
        handleWrapSelectedObject()
    }, [handleWrapSelectedObject])

    return {
        handleWrapSelectedObject,
        wrapperObjectsStyles,
        activeChildObjectIds,
    }
}

export default useWrapSelectedObject
