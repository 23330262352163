import ColorPickerInput from '../ColorPickerInput'
import ImageInput from '../ImageInput'
import NumberInput from '../NumberInput'
import SelectInput from '../SelectInput'
import TextInput from '../TextInput'

interface Props {
    groupStyle: GroupStyleI
    handleOnChange: (field: 'value', value: any) => void
    handleSetStyleValue?: (property: any, value: any) => void
    handleSetFullWidthValue: (value: boolean) => void
    handleSetAttributeValue?: (property: any, value: any) => void
}

const GroupFieldValue = ({
    groupStyle,
    handleOnChange,
    handleSetStyleValue,
    handleSetFullWidthValue,
    handleSetAttributeValue,
}: Props) => {
    const handleFieldOnChange = (e: any) => handleOnChange('value', e.target.value)

    if (groupStyle.input.type === 'number') {
        handleSetFullWidthValue(false)
        return <NumberInput styleProperty={groupStyle} handleOnChange={handleFieldOnChange} />
    } else if (groupStyle.input.type === 'string' && groupStyle.property !== 'linearGradient') {
        handleSetFullWidthValue(false)
        return <TextInput styleProperty={groupStyle} handleOnChange={handleFieldOnChange} />
    } else if (groupStyle.input.type === 'select') {
        handleSetFullWidthValue(false)
        return <SelectInput styleProperty={groupStyle} handleOnChange={handleFieldOnChange} />
    } else if (groupStyle.property === 'linearGradient') {
        handleSetFullWidthValue(true)
        return (
            <TextInput
                fullWidth={true}
                styleProperty={groupStyle}
                handleOnChange={handleFieldOnChange}
            />
        )
    } else if (
        groupStyle.input.type === 'image' &&
        handleSetStyleValue &&
        handleSetAttributeValue
    ) {
        handleSetFullWidthValue(true)
        return (
            <ImageInput
                styleProperty={groupStyle}
                handleOnChange={handleFieldOnChange}
                handleSetStyleValue={handleSetStyleValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />
        )
    } else if (groupStyle.input.type === 'color' && handleSetStyleValue) {
        handleSetFullWidthValue(true)
        return (
            <ColorPickerInput
                styleProperty={groupStyle}
                // handleOnChange={(e: any) => handleSetStyleValue(e.target.name, e.target.value)}
                handleOnChange={handleFieldOnChange}
            />
        )
    } else return <></>
}

export default GroupFieldValue
