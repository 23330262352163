import MuiAlert, { AlertProps } from '@mui/material/Alert'
import React from 'react'
import toaster, { resolveValue, Toaster } from 'react-hot-toast'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const typeToSeverity = (type: string) => {
    switch (type) {
        case 'success':
            return 'success'
        case 'error':
            return 'error'
        default:
            return 'info'
    }
}

const ToasterComponent = () => {
    return (
        <Toaster position="bottom-left">
            {(t) => {
                return (
                    <Alert
                        sx={{
                            opacity: t.visible ? 1 : 0,
                        }}
                        onClose={() => toaster.dismiss(t.id)}
                        severity={typeToSeverity(t.type)}
                    >
                        {resolveValue(t.message, t)}
                    </Alert>
                )
            }}
        </Toaster>
    )
}

export default ToasterComponent
