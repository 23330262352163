import { useAnimationActions } from '../useAnimationActions'
import { useObjectActions } from '../useObjectActions'
import { useAppSelector } from '../useRedux'
import useWrapSelectedObject from './useWrapSelectedObject'
import useWrapSelectedObjects from './useWrapSelectedObjects'

const useMultiselectObject = (object: AnySceneObjectT) => {
    const { setActiveAnimations, removeSelectedAnimations, clearSelectedAnimations } =
        useAnimationActions()

    const {
        setActiveObjectId,
        clearAllObjectFromSelection,
        addObjectToSelection,
        clearObjectFromSelection,
        removeActiveObjectId,
    } = useObjectActions()

    const activeObjectId: string | null = useAppSelector((state) => state.activeObject.value)
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const isActiveObject =
        activeObjectId === object.id || !!selectedObjectIds.find((x) => x === object.id)

    const isSelectedObject = (id: string): boolean => {
        return (
            selectedObjectIds.findIndex((s: string) => {
                return s === id
            }) !== -1
        )
    }

    const { activeChildObjectIds } = useWrapSelectedObject()
    const { activeChildObjectsIds } = useWrapSelectedObjects()

    const handleMultiselectObject = (ctrlCmdKey: boolean) => {
        if (!ctrlCmdKey) {
            // todo
            clearSelectedAnimations()
            clearAllObjectFromSelection()
            setActiveObjectId(object.id, object.animationIds)
            setActiveAnimations(object.animationIds, object.id)
        } else if (!isSelectedObject(object.id) && activeObjectId !== object.id) {
            //prevent click on child when parent was selected
            if (
                !activeChildObjectIds.includes(object.id) &&
                !activeChildObjectsIds.includes(object.id)
            ) {
                addObjectToSelection(object)
                setActiveAnimations(object.animationIds, object.id)
            }
        } else if (isSelectedObject(object.id)) {
            removeActiveObjectId(object)
            clearObjectFromSelection(object)
            removeSelectedAnimations(object.animationIds)
        } else {
            clearSelectedAnimations()
            clearAllObjectFromSelection()
        }
    }

    return { handleMultiselectObject, isActiveObject }
}

export default useMultiselectObject
