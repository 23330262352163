import { useRef } from 'react'
import useInViewport from '../../../../../hooks/useInViewport'
import { useAppSelector } from '../../../../../hooks/useRedux'
import TimelineTween from './TimelineTween'
import TimelineTweenEmpty from './TimelineTweenEmpty'

type Props = {
    objectId: string
    animationId: string
    TimelineTweenWidth: number
    index: number
}

/**
 * TimelineTweenWrapper - component use for optimalit
 */
const TimelineTweenWrapper = ({ objectId, animationId, TimelineTweenWidth, index }: Props) => {
    const targetRef = useRef<HTMLDivElement>(null)
    const timelineZoom = useAppSelector((state) => state.timelinePreferences.value.timelineZoom)
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)

    const { isInViewport } = useInViewport(
        targetRef,
        [timelineZoom, framerate],
        'custom-timeline-navbar'
    )
    return isInViewport ? (
        <TimelineTween
            ref={targetRef}
            objectId={objectId}
            animationId={animationId}
            TimelineTweenWidth={TimelineTweenWidth}
            index={index}
        />
    ) : (
        <TimelineTweenEmpty
            ref={targetRef}
            animationId={animationId}
            TimelineTweenWidth={TimelineTweenWidth}
        />
    )
}

export default TimelineTweenWrapper
