import { useMemo } from 'react'
import {
    ObjectStyles,
    getDistributionGap,
    getObjectPositioning,
    getResultMultiAlignment,
    getSortedObjectArray,
} from '../../../../helpers/object.helpers'
import useObject from '../../../../hooks/useObject'
import { useObjectActions } from '../../../../hooks/useObjectActions'
import { useAppSelector } from '../../../../hooks/useRedux'
import Alignment from './Alignment'

const MultiAlign = () => {
    const { updateObjectStyle } = useObjectActions()
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const { findObjectById } = useObject()
    const handleAlign = (property: string, value: string, styles: ObjectStyles) => {
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)
            if (selectedObject) {
                updateObjectStyle(
                    selectedObject,
                    property,
                    getResultMultiAlignment(selectedObject, value, styles)
                )
            }
        })
    }

    const handleDistribution = (
        property: string,
        distributionGap: number,
        sortedObjects: AnySceneObjectT[],
        arrayTop: number[],
        arrayLeft: number[]
    ) => {
        let modifiedTop: number
        let modifiedLeft: number
        sortedObjects.forEach((object, index) => {
            const prevObjectStyles: ObjectStyles =
                index === 0
                    ? { top: 0, left: 0, width: 0, height: 0 }
                    : getObjectPositioning(sortedObjects[index - 1])
            const actualObjectStyles: ObjectStyles = getObjectPositioning(object)

            if (property === 'top') {
                if (
                    actualObjectStyles.top !== Number(Math.min(...arrayTop)) &&
                    actualObjectStyles.top !== Number(Math.max(...arrayTop))
                ) {
                    modifiedTop = !modifiedTop
                        ? Number(prevObjectStyles.top + prevObjectStyles.height + distributionGap)
                        : Number(modifiedTop + prevObjectStyles.height + distributionGap)

                    updateObjectStyle(object, property, modifiedTop)
                }
            }
            if (property === 'left') {
                if (
                    actualObjectStyles.left !== Number(Math.min(...arrayLeft)) &&
                    actualObjectStyles.left !== Number(Math.max(...arrayLeft))
                ) {
                    modifiedLeft = !modifiedLeft
                        ? Number(prevObjectStyles.left + prevObjectStyles.width + distributionGap)
                        : Number(modifiedLeft + prevObjectStyles.width + distributionGap)
                    updateObjectStyle(object, property, modifiedLeft)
                }
            }
        })
    }
    /**
     * when selected objects do not have same parentId then disable multiAlignment action
     */
    const disabledMultiAlignment = useMemo(() => {
        let parentId: string | null | undefined = 'empty'
        let disabled: boolean = false
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)
            if (parentId === 'empty') {
                parentId = selectedObject?.parentId
            }
            if (parentId !== selectedObject?.parentId) {
                disabled = true
                parentId = 'empty'
            }
        })
        return disabled
    }, [findObjectById, selectedObjectIds])

    const findEdgeValues = (property: string, value: string) => {
        let arrayTop: number[] = []
        let arrayLeft: number[] = []
        let arrayEdgeRight: number[] = []
        let arrayEdgeBottom: number[] = []
        let heights: number[] = []
        let widths: number[] = []
        let selectedObjects: AnySceneObjectT[] = []
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)

            if (selectedObject) {
                const values: ObjectStyles = getObjectPositioning(selectedObject)
                arrayTop = [...arrayTop, values.top]
                arrayLeft = [...arrayLeft, values.left]
                arrayEdgeRight = [...arrayEdgeRight, values.left + values.width]
                arrayEdgeBottom = [...arrayEdgeBottom, values.top + values.height]
                heights = [...heights, values.height]
                widths = [...widths, values.width]
                selectedObjects = [...selectedObjects, selectedObject]
            }
        })

        const wrapperObjectsStyles: ObjectStyles = {
            top: Number(Math.min(...arrayTop)),
            left: Number(Math.min(...arrayLeft)),
            width: Number(Math.max(...arrayEdgeRight) - Math.min(...arrayLeft)),
            height: Number(Math.max(...arrayEdgeBottom) - Math.min(...arrayTop)),
        }

        if (value === 'horizontallyDistribute') {
            const distributionHorizontalGap = getDistributionGap(
                wrapperObjectsStyles.width,
                widths,
                selectedObjectIds.length - 1
            )

            const sortedSelectedObject = getSortedObjectArray(selectedObjects, 'left')

            handleDistribution(
                property,
                distributionHorizontalGap,
                sortedSelectedObject,
                arrayTop,
                arrayLeft
            )
        } else if (value === 'verticallyDistribute') {
            const distributionVerticalGap = getDistributionGap(
                wrapperObjectsStyles.height,
                heights,
                selectedObjectIds.length - 1
            )

            const sortedSelectedObject = getSortedObjectArray(selectedObjects, 'top')

            handleDistribution(
                property,
                distributionVerticalGap,
                sortedSelectedObject,
                arrayTop,
                arrayLeft
            )
        } else {
            handleAlign(property, value, wrapperObjectsStyles)
        }
    }

    return (
        <Alignment
            onClick={findEdgeValues}
            disabled={disabledMultiAlignment}
            multiAlign
            disabledDistribute={selectedObjectIds.length < 3}
        />
    )
}

export default MultiAlign
