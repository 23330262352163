import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum CanvasCursor {
    DefaultCursor = 'default',
    GrabCursor = 'grab',
    GrabCursorBySpace = 'grabBySpace',
    GrabbingCursor = 'grabbing', // type only for local setCursor in Preview.tsx
}

type StateT = {
    value: CanvasCursor
}

const initialState: StateT = {
    value: CanvasCursor.DefaultCursor,
}

export const canvasCursorSlice = createSlice({
    name: 'canvasCursor',
    initialState,
    reducers: {
        setCanvasCursor: (state, action: PayloadAction<CanvasCursor>) => {
            state.value = action.payload
        },
    },
})

export const { setCanvasCursor } = canvasCursorSlice.actions
export default canvasCursorSlice.reducer
