import CollectionsIcon from '@mui/icons-material/Collections'
import { Tooltip } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch } from '../../../hooks/useRedux'
import { setLoading } from '../../../store/slices/loading.slice'
import ButtonContextMenu from '../../common/ContextMenu/ButtonContextMenu'
import AssetsDialog from './AssetsDialog'

const LoadSequenceButton = () => {
    const [openAssetsDialog, setOpenAssetsDialog] = useState<boolean>(false)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const { readImageSequence } = useObjectActions()

    const handleReadImageSequence = async (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoading(true))
        await readImageSequence(e.target.files!, null)
        dispatch(setLoading(false))
    }

    return (
        <Tooltip title={t('editor:sequence.tooltip')}>
            <span>
                <ButtonContextMenu
                    title={t('editor:sequence.title')}
                    icon={<CollectionsIcon fontSize="small" />}
                    data={[
                        {
                            title: t('editor:newButton'),
                            onClick: () => {},
                            onChange: handleReadImageSequence,
                            type: 'file',
                            multiple: true,
                        },
                        {
                            title: t('editor:assetsDialog.buttonOpen'),
                            onClick: () => setOpenAssetsDialog(true),
                        },
                    ]}
                />
                <AssetsDialog
                    open={openAssetsDialog}
                    onClose={() => setOpenAssetsDialog(false)}
                    parentId={null}
                    type={'sequence'}
                />
            </span>
        </Tooltip>
    )
}

export default LoadSequenceButton
