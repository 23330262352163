import ReactSplit, { SplitDirection } from '@devbookhq/splitter'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { setOpenSaveForm, setOpenVersionHistoryDetail } from '../../store/slices/editor.slice'
import { ScrollableContainer } from '../../style/styles'
import GraphicSaveForm from '../Graphic/GraphicSaveForm'
import EmptyPanel from '../common/EmptyPanel'
import AnimationTools from './AnimationsNavbar/AnimationTools'
import ObjectTools from './RightSideNavbar/ObjectTools'
import VersionHistoryForm from './VersionHistoryForm'

const RightSideNavbar = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        return () => {
            dispatch(setOpenSaveForm(false))
            dispatch(setOpenVersionHistoryDetail(false))
        }
    }, [dispatch])

    const { visibleSaveForm, versionHistoryDetail } = useAppSelector((state) => state.editor.value)

    const activeObjectId: string | null = useAppSelector((state) => state.activeObject.value)
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)
    const activeAnimationId: string | null = useAppSelector((state) => state.activeAnimation.value)
    const selectedAnimationIds: string[] = useAppSelector((state) => state.activeAnimation.selected)
    if (visibleSaveForm) {
        return (
            <ScrollableContainer>
                <GraphicSaveForm />
            </ScrollableContainer>
        )
    } else if (versionHistoryDetail) {
        return (
            <ScrollableContainer>
                <VersionHistoryForm />
            </ScrollableContainer>
        )
    } else {
        if (!activeObjectId && !activeAnimationId && selectedObjectIds.length === 0)
            return <EmptyPanel text1="Object settings" text2="Select an object" />
        else if (
            (activeObjectId || selectedObjectIds.length >= 1) &&
            selectedAnimationIds.length < 1
        )
            return (
                <ObjectTools
                    objectId={activeObjectId || selectedObjectIds[selectedObjectIds.length - 1]}
                />
            )
        else if (
            (activeObjectId && activeAnimationId) ||
            ((selectedObjectIds.length >= 1 || activeObjectId) &&
                (selectedAnimationIds.length >= 1 || activeAnimationId))
        )
            return (
                <ReactSplit
                    direction={SplitDirection.Vertical}
                    initialSizes={[60, 40]}
                    gutterClassName="custom-gutter-vertical"
                >
                    <ObjectTools
                        objectId={activeObjectId || selectedObjectIds[selectedObjectIds.length - 1]}
                    />

                    {(activeAnimationId || selectedAnimationIds.length >= 1) && (
                        <AnimationTools
                            animationId={
                                activeAnimationId ||
                                selectedAnimationIds[selectedAnimationIds.length - 1]
                            }
                        />
                    )}
                </ReactSplit>
            )
        else return <></>
    }
}

export default RightSideNavbar
