import { Container, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import EmptyPage from '../components/GraphicCommon/EmptyPage'
import GraphicStoreTile from '../components/GraphicStore/StoreTile'
import { FilterT, SortT } from '../components/common/DataGrid'

import { useDebounce } from 'usehooks-ts'
import BottomPage from '../components/GraphicCommon/BottomPage'
import EmptyPageLoading from '../components/GraphicCommon/EmptyPageLoading'
import StorePageHeader from '../components/GraphicCommon/StorePageHeader'
import GraphicStoreDetail from '../components/GraphicStore/GraphicStoreDetail'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { FILTER_CREATED_BY } from '../constants/filterLists'
import { ORDER_STORE } from '../constants/sortLists'
import useId from '../hooks/useId'
import useInfinityScroll from '../hooks/useInfinityScroll'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getCompaniesCurrentAsync } from '../store/slices/companies.slice'
import { getGraphicAndFileAsync } from '../store/slices/graphic.slice'
import { getGraphicsClearState, getGraphicsPublishedAsync } from '../store/slices/graphics.slice'

function GraphicStore() {
    const [searchTxt, setSearchTxt] = useState<string>('')
    const { storeFilters } = useAppSelector((state) => state.editor.value)
    const sortedBy = storeFilters.sortedBy ?? ''
    const filterCreatedBy = storeFilters.createdBy ?? ''
    const dispatch = useAppDispatch()
    const graphicsStore = useAppSelector((state) => state.graphics)
    const user = useAppSelector((state) => state.user)

    const [openDetailPreview, setOpenDetailPreview] = useState(false)
    const debouncedSearchTxt = useDebounce<string>(searchTxt)
    const fileContent = useAppSelector((state) => state.file.data)
    const fileStatus = useAppSelector((state) => state.file.status)
    const { scrollState, setFirstPage, setNextPage } = useInfinityScroll(
        graphicsStore.data.length,
        graphicsStore.total
    )

    const callback = useCallback(
        (id: string) => {
            setOpenDetailPreview(true)
            dispatch(getGraphicAndFileAsync(id))
        },
        [dispatch]
    )
    const { id, setId } = useId(callback)

    const handleOpenDetailPreview = (graphicId: string) => {
        setId(graphicId)
    }

    const handleClearSetIdAndClose = () => {
        setId(null)
    }

    //close DetailPreview when use backButton in browser or close dialog
    useEffect(() => {
        if (!id) {
            setOpenDetailPreview(false)
        }
    }, [id])

    const handleLoadMoreContent = () => {
        setNextPage()
    }
    const filter = useMemo(() => {
        const searchFilter = debouncedSearchTxt
            ? {
                  $or: [
                      { tags: { $eq: debouncedSearchTxt } },
                      { description: { $regex: debouncedSearchTxt, $options: 'i' } },
                      { name: { $regex: debouncedSearchTxt, $options: 'i' } },
                  ],
              }
            : {}

        const filterOptions: FilterT = {
            $and: [
                searchFilter,
                FILTER_CREATED_BY.find((x) => x.name === filterCreatedBy)?.getResultFilter(
                    user.value._id
                ) || {},
            ],
        }
        return filterOptions
    }, [debouncedSearchTxt, filterCreatedBy, user.value._id])

    const sort = useMemo(() => {
        const sortOptions: SortT = ORDER_STORE.find(
            (x) => x.name === sortedBy
        )?.getResultSort() || {
            updated: -1,
        }
        return sortOptions
    }, [sortedBy])

    useEffect(() => {
        setFirstPage()
        dispatch(getGraphicsClearState())
    }, [dispatch, filter, setFirstPage, sort])

    useEffect(() => {
        dispatch(
            getGraphicsPublishedAsync({
                filter,
                sort,
                skip: scrollState.skip,
                limit: scrollState.limit,
            })
        )
        // dependencies only on scrollState, if change filter or sort must setFirstPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, scrollState])

    useEffect(() => {
        dispatch(getCompaniesCurrentAsync())
    }, [dispatch])

    return (
        <Container component="main" maxWidth="xl">
            <Grid container py={3}>
                <StorePageHeader
                    searchTxt={searchTxt}
                    setSearchTxt={setSearchTxt}
                    sortedBy={sortedBy}
                    filterCreatedBy={filterCreatedBy}
                    title={'graphics:graphicStoreTitle'}
                />
            </Grid>
            <Grid className="store-content" container spacing={3} p={2}>
                {graphicsStore.status && graphicsStore.data.length === 0 && (
                    <EmptyPage
                        header={'emptyStoreGalleryHeader'}
                        info={'emptyStoreGalleryInfo'}
                        link={'goToEditorLink'}
                    />
                )}
                {graphicsStore.data.map((g) => {
                    return (
                        <GraphicStoreTile
                            key={g._id}
                            graph={g}
                            handleOpenDetailPreview={(id) => handleOpenDetailPreview(id)}
                            setFirstPage={() => setFirstPage()}
                        />
                    )
                })}
                {openDetailPreview && (
                    <>
                        <GraphicStoreDetail
                            open={openDetailPreview}
                            onClose={handleClearSetIdAndClose}
                            fileContent={fileContent?.blob}
                        />
                        <LoadingBackdrop open={!fileStatus} />
                    </>
                )}
                {graphicsStore.data.length < graphicsStore.total && (
                    <BottomPage
                        loading={!graphicsStore.status}
                        loaded={graphicsStore.data.length}
                        total={graphicsStore.total}
                        handleLoadMoreContent={handleLoadMoreContent}
                    />
                )}
                {!graphicsStore.status && graphicsStore.data.length === 0 && <EmptyPageLoading />}
            </Grid>
        </Container>
    )
}
export default GraphicStore
