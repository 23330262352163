import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { t } from 'i18next'
import { TransactionStatsState } from '../../store/slices/transactionStats.slice'

interface Props {
    transactionStats: TransactionStatsState
}
const TransactionInfo = ({ transactionStats }: Props) => {
    const theme = useTheme()
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item md={3}>
                <Box p={1} sx={{ backgroundColor: theme.palette.grey[600], borderRadius: 1 }}>
                    <Typography variant="body1">
                        <b>{t('transaction:accountBalance')}</b>
                    </Typography>
                    <Typography variant="body2">
                        {`${transactionStats.accountBalance} ${t('transaction:coins')}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={3}>
                <Box p={1} sx={{ backgroundColor: theme.palette.grey[600], borderRadius: 1 }}>
                    <Typography variant="body1">
                        <b>{t('transaction:totalEarning')}</b>
                    </Typography>
                    <Typography variant="body2">
                        {`${transactionStats.earn} ${t('transaction:coins')}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={3}>
                <Box p={1} sx={{ backgroundColor: theme.palette.grey[600], borderRadius: 1 }}>
                    <Typography variant="body1">
                        <b>{t('transaction:totalPurchase')}</b>
                    </Typography>
                    <Typography variant="body2">
                        {`${transactionStats.purchase} ${t('transaction:coins')}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={3}>
                <Box p={1} sx={{ backgroundColor: theme.palette.grey[600], borderRadius: 1 }}>
                    <Typography variant="body1">
                        <b>{t('transaction:totalRenewal')}</b>
                    </Typography>
                    <Typography variant="body2">
                        {`${transactionStats.renewal} ${t('transaction:coins')}`}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default TransactionInfo
