import { isImageEditable, isTextEditable, textSizing } from './objects.attributes.defaults'
import {
    defaultImageFills,
    defaultImageStyle,
    defaultImageTransforms,
    defaultItemFills,
    defaultItemStyle,
    defaultItemTransforms,
    defaultTextStyle,
    defaultTextTransforms,
} from './objects.styles.defaults'

export const defaultCanvas: CanvasI = {
    id: '',
    type: 'canvas',
    width: 1920,
    height: 1080,
}

export const defaultItem: ItemI = {
    id: '',
    index: 0,
    title: 'Rectangle',
    isHidden: false,
    type: 'item',
    parentId: '',
    childIds: [],
    animationIds: [],
    attributes: [isImageEditable],
    styles: defaultItemStyle,
    fill: defaultItemFills,
    filters: [],
    transforms: defaultItemTransforms,
}

export const defaultText: TextI = {
    id: '',
    index: 0,
    title: 'Text',
    isHidden: false,
    type: 'text',
    text: 'Lorem ipsum',
    parentId: '',
    childIds: [],
    animationIds: [],
    attributes: [isTextEditable, textSizing],
    styles: defaultTextStyle,
    fill: [],
    filters: [],
    transforms: defaultTextTransforms,
}

export const defaultImage: ImageI = {
    id: '',
    index: 0,
    title: 'Image',
    isHidden: false,
    type: 'image',
    parentId: '',
    childIds: [],
    animationIds: [],
    attributes: [isImageEditable],
    styles: defaultImageStyle,
    fill: defaultImageFills,
    filters: [],
    transforms: defaultImageTransforms,
}

export const defaultSequence: SequenceI = {
    id: '',
    index: 0,
    title: 'Image sequence',
    isHidden: false,
    type: 'sequence',
    parentId: '',
    childIds: [],
    animationIds: [],
    attributes: [isImageEditable],
    styles: defaultImageStyle,
    fill: defaultImageFills,
    filters: [],
    transforms: defaultImageTransforms,
}
