import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { ButtonPropsSizeOverrides, ButtonPropsVariantOverrides, Tooltip } from '@mui/material'
import { OverridableStringUnion as OverRidableStringUnion } from '@mui/types'

interface Props {
    size?: OverRidableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>
    type?: 'button' | 'reset' | 'submit'
    variant?: OverRidableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
    disabled?: boolean
    loading?: boolean
    loadingPosition?: 'end' | 'start' | 'center'
    children?: React.ReactNode
    tooltipTitle?: string
    fullWidth?: boolean
    color?: string
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    onClick?: () => void
}
const LoadingButton = ({
    size,
    type,
    variant,
    disabled,
    loading,
    loadingPosition,
    children,
    tooltipTitle,
    fullWidth,
    color,
    startIcon,
    endIcon,
    onClick,
}: Props) => {
    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <MuiLoadingButton
                    size={size}
                    type={type}
                    variant={variant}
                    disabled={disabled}
                    loading={loading}
                    loadingPosition={loadingPosition}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    sx={{
                        color: color,
                        ':hover': {
                            backgroundColor: { color },
                            // borderRadius: 20, //TO DO problem set to circle hover because parent element set borderRadius with smaller number
                        },
                        '& .MuiLoadingButton-loadingIndicatorEnd': {
                            marginLeft: loadingPosition === 'end' ? 1 : 0,
                            position: loadingPosition === 'end' ? 'unset' : 'absolute',
                            padding: 'unset',
                        },
                        '& .MuiButton-endIcon': {
                            marginLeft: 'unset',
                            display: loading ? 'none' : 'unset',
                            lineHeight: 0,
                        },
                    }}
                    fullWidth={fullWidth}
                    onClick={onClick}
                >
                    {children}
                </MuiLoadingButton>
            </span>
        </Tooltip>
    )
}

export default LoadingButton
