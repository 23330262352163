import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'

interface Props {
    stylePropertyBeforeDivider: string[]
    styleProperty: string[]
    label: string
    value: string
    handleOnChange: (e: SelectChangeEvent<any>) => void
}
const SelectDividerInput = ({
    styleProperty,
    stylePropertyBeforeDivider,
    handleOnChange,
    value,
    label,
}: Props) => {
    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select native value={value} onChange={handleOnChange}>
                {stylePropertyBeforeDivider.map((inputOption: string, index: number) => (
                    <option key={index} value={inputOption}>
                        {inputOption}
                    </option>
                ))}

                {/* to do style not work for this divider */}
                <Divider
                    sx={{
                        borderBottomWidth: 10,
                        borderColor: (theme) => theme.palette.secondary.main,
                    }}
                />

                {styleProperty.map((inputOption: string, index: number) => (
                    <option key={index} value={inputOption}>
                        {inputOption}
                    </option>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectDividerInput
