import Grid from '@mui/material/Grid'
import { transforms } from '../../../../data/styles/groups/transform.styles'
import { useObjectGroupActions } from '../../../../hooks/useObjectGroupActions'
import AddButton from '../../../common/AddButton'
import ExpandableSection from '../../../common/ExpandableSection'
import GroupFields from '../../../common/PropertyTools/GroupFields/GroupFields'
import SelectInput from '../../../common/PropertyTools/SelectInput'
interface Props {
    object: AnySceneObjectT
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
}

const Transforms = ({ object, handleSetStyleValue, handleSetObjectValue }: Props) => {

    const { addObjectGroup, updateObjectGroups, deleteObjectGroup } = useObjectGroupActions()

    const handleAddGroup = (e: any) => {
        if (!e) e = window.event
        e.cancelBubble = true
        if (e.stopPropagation) e.stopPropagation()
        const updatedGroups = addObjectGroup("transforms", object["transforms"])
        handleSetObjectValue("transforms", updatedGroups)
    }

    const handleSetGroup = (index: number, property: any, value: any) => {
        const updatedGroups = updateObjectGroups(object["transforms"], index, property, value)
        handleSetObjectValue("transforms", updatedGroups)
    }

    const handleDeleteGroup = (index: number) => {
        const updatedGroups = deleteObjectGroup(object["transforms"], index)
        handleSetObjectValue("transforms", updatedGroups)
    }

    const transformOrigin: SimpleStyleT | undefined = object.styles.find(style => style.property === "transformOrigin")
    const objectTransforms: GroupStyleI[] | undefined = object.transforms

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => handleSetStyleValue(e.target.name, e.target.value)

    if (!transformOrigin && !objectTransforms && !transforms) return <></>

    return (
        <ExpandableSection
            expanded={false}
            title={"Transforms"}
            secondaryActionComponent={
                <AddButton
                    text="transform"
                    handleAddObject={handleAddGroup}
                />
            }
            component={
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        {
                            transformOrigin &&
                            <SelectInput
                                styleProperty={transformOrigin}
                                handleOnChange={handleOnChange}
                            />
                        }
                    </Grid>
                    <Grid item>
                        {
                            objectTransforms && transforms &&
                            <GroupFields
                                object={object}
                                groupStyles={objectTransforms}
                                properties={transforms}
                                handleSetGroupValue={handleSetGroup}
                                handleDeleteGroup={handleDeleteGroup}
                            />
                        }
                    </Grid>
                </Grid>
            }
        />
    )
}

export default Transforms
