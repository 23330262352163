import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import VisibilityRoundedIcon from '@mui/icons-material/Visibility'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOff'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { useState } from 'react'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppSelector } from '../../../hooks/useRedux'
import { selectObjectById } from '../../../store/slices/objects.slice'
import { AppStateT } from '../../../store/store'
import ObjectAnimationSelection from './ObjectAnimationSelection'
import ObjectsTreeNodeMenu from './ObjectsTreeNodeMenu'

interface Props {
    object: AnySceneObjectT
    numberOfObjects: number
    isHiding: boolean
}

const ObjectsTreeNode = ({ object, numberOfObjects, isHiding }: Props) => {
    const state: AppStateT = useAppSelector((state) => state)
    const activeObjectId: string | null = useAppSelector((state) => state.activeObject.value)

    //to do union activeObjectId and findObjectInSelectedObjects
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const findObjectInSelectedObjects = selectedObjectIds.map((id) =>
        id === object.id ? id : null
    )[0]
    const theme = useTheme()

    const [isExpanded, setIsExpanded] = useState<boolean>(true)

    const handleCollapse = () => {
        setIsExpanded(false)
    }

    const handleExpand = () => {
        setIsExpanded(true)
    }

    const isVisible = !object.isHidden
    const { updateObject } = useObjectActions()

    const handleVisibility = () => {
        updateObject(object.id, 'isHidden', !object.isHidden)
    }

    const renderChild = (childId: string) => {
        let childObject = selectObjectById(selectUndoable(state).objects, childId)
        if (childObject.parentId === object.id && !isExpanded)
            return (
                <Box key={childObject.id} sx={{ display: 'none' }}>
                    <ObjectsTreeNode
                        object={childObject}
                        key={childId}
                        numberOfObjects={object.childIds.length}
                        isHiding={isHiding || !isVisible}
                    />
                </Box>
            )
        else {
            return (
                <Box key={childObject.id}>
                    <ObjectsTreeNode
                        object={childObject}
                        key={childId}
                        numberOfObjects={object.childIds.length}
                        isHiding={isHiding || !isVisible}
                    />
                </Box>
            )
        }
    }
    if (!object) return <></>

    return (
        <Box sx={{ paddingLeft: 2, width: '16rem' }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginBottom: 1,
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid item id="editor-step-3">
                    {isExpanded ? (
                        <Tooltip title={!object.childIds.length ? '' : t('editor:collapse')}>
                            <span>
                                <IconButton
                                    onClick={handleCollapse}
                                    disabled={!object.childIds.length ? true : false}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('editor:expand')}>
                            <IconButton onClick={handleExpand}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <ObjectsTreeNodeMenu
                    object={object}
                    activeObjectId={activeObjectId || findObjectInSelectedObjects}
                    numberOfObjects={numberOfObjects}
                />
                <Grid item>
                    <Tooltip title={isVisible ? t('editor:hide') : t('editor:show')}>
                        <span>
                            <IconButton onClick={handleVisibility} disabled={isHiding}>
                                {isVisible && !isHiding ? (
                                    <VisibilityRoundedIcon fontSize="small" />
                                ) : (
                                    <VisibilityOffRoundedIcon fontSize="small" />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <ObjectAnimationSelection object={object} invisible={!isVisible || isHiding} />
                </Grid>
            </Grid>
            {object.childIds.map(renderChild)}
        </Box>
    )
}

export default ObjectsTreeNode
