import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton } from '@mui/material'
import { Transition } from '../common/Transition'

interface Props {
    open: boolean
    thumbnailSrc: string | undefined
    onClose: (refresh: boolean) => void
}

function ThumbnailDetail({ open, onClose, thumbnailSrc }: Props) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
        >
            <Box>
                <IconButton
                    aria-label="close"
                    onClick={() => onClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            {thumbnailSrc && <Box component="img" src={thumbnailSrc} maxHeight={540} m={0} />}
        </Dialog>
    )
}
export default ThumbnailDetail
