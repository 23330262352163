import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import config from './config'
import enCommon from './locales/en/common.json'
import enCompany from './locales/en/company.json'
import enDataSource from './locales/en/dataSource.json'
import enEditor from './locales/en/editor.json'
import enGraphics from './locales/en/graphics.json'
import enHero from './locales/en/hero.json'
import enLicense from './locales/en/license.json'
import enMenu from './locales/en/menu.json'
import enStatus from './locales/en/status.json'
import enTransaction from './locales/en/transaction.json'
import enUser from './locales/en/user.json'
import enWizard from './locales/en/wizard.json'

const en = {
    common: enCommon,
    company: enCompany,
    dataSource: enDataSource,
    editor: enEditor,
    graphics: enGraphics,
    hero: enHero,
    license: enLicense,
    menu: enMenu,
    status: enStatus,
    transaction: enTransaction,
    user: enUser,
    wizard: enWizard,
}

export const defaultNS = 'common'

i18n.use(initReactI18next).init({
    resources: {
        en,
    },
    lng: config.lng,
    ns: Object.keys(en),
    defaultNS,
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
})

export default i18n
