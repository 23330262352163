import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { del } from '../../helpers/ajax.helpers'
import { S3_PREFIX } from '../../helpers/file.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { AppThunkT } from '../store'

type ObjectsStateT = {
    value: Record<string, AnySceneObjectT>
    tmpIds: string[]
}

const initialState: ObjectsStateT = {
    value: {},
    tmpIds: [],
}

type PayloadObjectT = {
    object: AnySceneObjectT
}

type PayloadChildT = {
    id: string
    childId?: string
}

type PayloadStyledT = {
    id: string
    styleId?: string
}

type PayloadObjT = {
    id: string
    property: string
    value?: any
}

type PayloadObjDelT = {
    id: string
}

type PayloadParentT = {
    id: string
    parentId?: string
}

type PayloadIndexT = {
    id: string
    index?: number
}

type PayloadAnimationT = {
    id: string
    animationId?: string
}

const objectNodeUpdate = (stateObject: any, action: PayloadObjT): AnySceneObjectT => {
    return {
        ...stateObject,
        [action.property]: action.value,
    }
}

const objectNodeUpdIndex = (stateObject: any, action: PayloadIndexT): AnySceneObjectT => {
    return {
        ...stateObject,
        index: action.index,
    }
}

const objectNodeUpdParentId = (stateObject: any, action: PayloadParentT): AnySceneObjectT => {
    return {
        ...stateObject,
        parentId: action.parentId,
    }
}

const objectNodeAddChildId = (stateObject: any, action: PayloadChildT): AnySceneObjectT => {
    return {
        ...stateObject,
        childIds: [...stateObject.childIds, action.childId],
    }
}

const objectNodeDelChildId = (stateObject: any, action: PayloadChildT): AnySceneObjectT => {
    return {
        ...stateObject,
        childIds: stateObject.childIds.filter((id: string) => id !== action.childId),
    }
}

const objectNodeAddAnimationId = (stateObject: any, action: PayloadAnimationT): AnySceneObjectT => {
    if (stateObject.animationIds.includes(action.animationId)) return stateObject
    else
        return {
            ...stateObject,
            animationIds: [...stateObject.animationIds, action.animationId],
        }
}

const objectNodeDelAnimationId = (stateObject: any, action: PayloadAnimationT): AnySceneObjectT => {
    return {
        ...stateObject,
        animationIds: stateObject.animationIds.filter((id: string) => id !== action.animationId),
    }
}

const objectNodeAddGroupStyleId = (stateObject: any, action: PayloadStyledT): AnySceneObjectT => {
    return {
        ...stateObject,
        styleIds: [...stateObject.groupStyleIds, action.styleId],
    }
}

const objectNodeDelGroupStyleId = (stateObject: any, action: PayloadStyledT): AnySceneObjectT => {
    return {
        ...stateObject,
        styleIds: stateObject.styleIds.filter((id: string) => id !== action.styleId),
    }
}

export const getAllDescendantIds = (state: ObjectsStateT, nodeId: string): any => {
    return state.value[nodeId].childIds.reduce((acc: any, childId: string) => {
        return [...acc, childId, ...getAllDescendantIds(state, childId)]
    }, [])
}

const deleteMany = (state: ObjectsStateT, ids: string[]) => {
    //state = { ...state }
    ids.forEach((id) => {
        if (state) delete state.value[id]
    })
    return state
}

export const objectsSlice = createSlice({
    name: 'objects',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        pushTmpIds: (state, action: PayloadAction<string[]>) => {
            state.tmpIds = [...state.tmpIds, ...action.payload]
        },
        resetTmpIds: (state) => {
            state.tmpIds = []
        },
        addObjAction: (state, action: PayloadAction<PayloadObjectT>) => {
            const id: string = action.payload.object.id
            state.value = {
                ...state.value,
                [id]: action.payload.object,
            }
        },
        updObjectAction: (state, action: PayloadAction<PayloadObjT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdate(state.value[id], action.payload),
            }
        },
        updIndexAction: (state, action: PayloadAction<PayloadIndexT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdIndex(state.value[id], action.payload),
            }
        },
        updParentIdAction: (state, action: PayloadAction<PayloadParentT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdParentId(state.value[id], action.payload),
            }
        },
        addChildIdAction: (state, action: PayloadAction<PayloadChildT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeAddChildId(state.value[id], action.payload),
            }
        },
        delChildIdAction: (state, action: PayloadAction<PayloadChildT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelChildId(state.value[id], action.payload),
            }
        },
        addAnimationIdAction: (state, action: PayloadAction<PayloadAnimationT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeAddAnimationId(state.value[id], action.payload),
            }
        },
        delAnimationIdAction: (state, action: PayloadAction<PayloadAnimationT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelAnimationId(state.value[id], action.payload),
            }
        },
        deleteAnimationsFromObjectAction: (
            state,
            action: PayloadAction<{ objectId: string; animationIds: string[] }>
        ) => {
            const id = action.payload.objectId

            state.value = {
                ...state.value,
                [id]: {
                    ...state.value[id],
                    animationIds: state.value[id].animationIds.filter(
                        (id: string) => !action.payload.animationIds.includes(id)
                    ),
                },
            }
        },
        addGroupStyleIdAction: (state, action: PayloadAction<PayloadStyledT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeAddGroupStyleId(state.value[id], action.payload),
            }
        },
        delGroupStyleIdAction: (state, action: PayloadAction<PayloadStyledT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelGroupStyleId(state.value[id], action.payload),
            }
        },
        delObjectAction: (state, action: PayloadAction<PayloadObjDelT>) => {
            const id = action.payload.id
            const descendantIds = getAllDescendantIds(state, id)

            state = deleteMany(state, [id, ...descendantIds])
        },
        clearObjectsAction: (state) => {
            state.value = {}
        },
    },
})

export const addObjectAction =
    (object: AnySceneObjectT): AppThunkT =>
    (dispatch, getState) => {
        return dispatch(addObjAction({ object: object }))
    }

export const checkIndexes =
    (parentId: string | null): AppThunkT =>
    (dispatch, getState) => {
        const { objects } = selectUndoable(getState())

        // pokud nejsou zadne objekty ke kontrole
        if (Object.keys(objects.value).length === 0) return

        let siblings: AnySceneObjectT[]

        if (parentId) {
            const parentChildIds: string[] = selectObjectById(objects, parentId).childIds
            // pokud nejsou zadne indexy ke kontrole
            if (parentChildIds.length === 0) return
            // najdi vsechny sourozence
            siblings = parentChildIds.map(
                (id) => selectObjects(objects).filter((object) => object.id === id)[0]
            )
        } else {
            siblings = selectObjects(objects).filter((object) => object.parentId === null)
        }

        // serad je podle indexu
        const orderedSiblings: AnySceneObjectT[] = sortObjects(siblings)

        // pokud se jejich index nerovna poradi, updatni jeho index
        orderedSiblings.forEach((sibling, index) => {
            if (sibling.index !== index) updIndexAction({ id: sibling.id, index: index })
        })
    }

export const sortObjects = (objects: AnySceneObjectT[]): AnySceneObjectT[] =>
    objects.sort((first, second) => first.index - second.index)

export const filterAndSortSiblings = (
    objects: AnySceneObjectT[],
    parentId: string | null
): AnySceneObjectT[] => {
    const sortedObjects: AnySceneObjectT[] = sortObjects(objects)
    return sortedObjects.filter((sortedObject) => sortedObject.parentId === parentId)
}

export const filterObjectsByType = (
    objects: AnySceneObjectT[],
    type: string
): AnySceneObjectT[] => {
    return objects.filter((object) => object.type === type)
}

export const getObjectByTitle = (
    objects: AnySceneObjectT[],
    title: string
): AnySceneObjectT | undefined => {
    return objects.find((object) => object.title === title)
}

export const selectObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    Object.values(objects.value).map((object) => object)

export const selectRootObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    selectObjects(objects).filter((object) => object.parentId === null)

export const selectSortedObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    sortObjects(Object.values(objects.value).map((object) => object))

export const selectSortedRootObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    sortObjects(Object.values(objects.value).map((object) => object)).filter(
        (object) => object.parentId === null
    )

export const selectObjectById = (objects: ObjectsStateT, id: string | null): AnySceneObjectT => {
    return id ? objects.value[id] : objects.value['']
}

export const selectObjectsByIds = (objects: ObjectsStateT, ids: string[]): AnySceneObjectT[] =>
    ids.map((id) => selectObjectById(objects, id))

export const clearTmpObjectsAsync =
    (graphicId: string): AppThunkT =>
    async (dispatch, getState) => {
        try {
            const tmpIds = selectUndoable(getState()).objects.tmpIds
            if (tmpIds.length > 0) {
                await del('files', undefined, {
                    key: `${S3_PREFIX}/${graphicId}/(images|sequences)/(${tmpIds.join('|')})`,
                })
            }
        } catch (error: any) {
            console.error(error)
        } finally {
            dispatch(resetTmpIds())
        }
    }

export const {
    addObjAction,
    updObjectAction,
    updIndexAction,
    updParentIdAction,
    addChildIdAction,
    delChildIdAction,
    addAnimationIdAction,
    delAnimationIdAction,
    deleteAnimationsFromObjectAction,
    addGroupStyleIdAction,
    delGroupStyleIdAction,
    delObjectAction,
    clearObjectsAction,
    pushTmpIds,
    resetTmpIds,
} = objectsSlice.actions

export default objectsSlice.reducer
