import { CircularProgress, Grid } from '@mui/material'

const EmptyPageLoading = () => {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                alignContent="stretch"
                sx={{ height: 300 }}
            >
                <CircularProgress color="info" />
            </Grid>
        </>
    )
}

export default EmptyPageLoading
