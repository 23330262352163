import {
    ConsoleInstrumentation,
    ErrorsInstrumentation,
    EventAttributes,
    SessionInstrumentation,
    createSession,
    faro,
    initializeFaro,
} from '@grafana/faro-web-sdk'
import config from '../config'
import { SessionT } from '../store/slices/session.slice'
import { SessionKey } from './ajax.helpers'
import { getItem } from './storage.helpers'

export const pushFaroEvent = (name: string, attributes?: EventAttributes) => {
    faro.api?.pushEvent(name, attributes)
}

const createFaroSession = (session: SessionT) => {
    return createSession({ user: session.userId })
}

export const setFaroSession = (session: SessionT | null) => {
    if (session) {
        faro.api?.setSession(createFaroSession(session))
    } else {
        faro.api?.resetSession()
    }
}

export const initFaro = () => {
    if (!config.faro) {
        return
    }

    const faro = initializeFaro({
        url: config.faro,
        app: {
            version: process.env.REACT_APP_VERSION,
        },
        instrumentations: [
            new ErrorsInstrumentation(),
            new ConsoleInstrumentation(),
            new SessionInstrumentation(),
        ],
    })

    const session = getItem<SessionT>(SessionKey)
    if (session) {
        faro.api.setSession(createFaroSession(session))
    }
}
