import { fillProperties } from "../data/styles/groups/fill.styles"
import { filters } from "../data/styles/groups/filter.styles"
import { groupStyles, GroupStyleTypeT } from "../data/styles/groups/groups.styles"
import { transforms } from "../data/styles/groups/transform.styles"

export const useObjectGroupActions = () => {

    const defaultGroupByType = (type: GroupStyleTypeT): GroupStyleT => {
        if (type === "filters") return filters[0]
        else if (type === "transforms") return transforms[0]
        else return fillProperties[0]
    }
    
    const createGroupByType = (type: GroupStyleTypeT, index: number): GroupStyleI => ({
        ...defaultGroupByType(type),
        index,
    })

	const newIndexInGroups = (groups: GroupStyleI[]) : number => {
		return (groups.length === 0) ? 0 : (Math.max(...groups.map(group => group.index)) + 1)
	}

	const addObjectGroup = (groupType: GroupStyleTypeT, groups: GroupStyleI[]): GroupStyleI[] => ([
        ...groups,
        createGroupByType(groupType, newIndexInGroups(groups))
    ])

	const defaultStyleByName = (property: string, index: number): GroupStyleI => {
		const defaultStyle = groupStyles[0]
		let defaultGroup: GroupStyleI = {
			...defaultStyle,
			index,
		}
		groupStyles.forEach((style: GroupStyleT) => {
			if (property === style.property) {
				defaultGroup = {
					...style,
					index,
				}
			}
		})
		return defaultGroup
	}


	const updateObjectGroup = (group: GroupStyleI, property: string, value: any): GroupStyleI => ({
        ...group,
        [property]: value,
    })

	const updateObjectGroups = (groups: GroupStyleI[], groupIndex: number, property: string, value: any): GroupStyleI[] => {
		return groups.map((group) => {
            if (group.index === groupIndex) {
				if (property === "property") {
					return defaultStyleByName(value, newIndexInGroups(groups))
				} else return updateObjectGroup(group, property, value)
			} else return group
		})
	}

	const deleteObjectGroup = (groups: GroupStyleI[], groupIndex: number): GroupStyleI[] => (
        groups.filter((group) => group.index !== groupIndex)
    )

	return {
		addObjectGroup,
		updateObjectGroups,
		deleteObjectGroup,
	}
}
