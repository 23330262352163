export const checkImage = async (url: string) => {
    try {
        const res = await fetch(url)
        const fileBuffer = await res.blob()
        if (fileBuffer.type.startsWith('image/')) return true
        else return false
    } catch (e) {
        console.error(e)
        return false
    }
}
