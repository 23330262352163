import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useAppDispatch } from '../../../hooks/useRedux'
import { clear } from '../../../store/slices/editor.slice'

interface Props {
    label: string
    onClick: () => void
}

function ClearScene({ label, onClick }: Props) {
    const dispatch = useAppDispatch()

    const handleClear = () => {
        onClick()
        dispatch(clear())
    }

    return (
        <MenuItem key="clear" onClick={handleClear}>
            <ListItemIcon>
                <BackspaceRoundedIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

export default ClearScene
