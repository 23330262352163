import NumberInput from '../../../common/PropertyTools/NumberInput'
import SelectInput from '../../../common/PropertyTools/SelectInput'
import TextInput from '../../../common/PropertyTools/TextInput'

interface Props {
    state: "from" | "to"
    animationState: SimpleStyleT
    handleSetValue: (e: any, state: "from" | "to") => void
}

const AnimationState = ({ state, animationState, handleSetValue }: Props) => {

    const handleAnimationStateChange = (e: any) =>
        handleSetValue(e, state)

    if (animationState.input.type === 'number')
        return (
            <NumberInput
                styleProperty={animationState}
                handleOnChange={handleAnimationStateChange}
            />
        )

    else if (animationState.input.type === 'string')
        return (
            <TextInput
                styleProperty={animationState}
                handleOnChange={handleAnimationStateChange}
            />
        )

    else if (animationState.input.type === "select")
        return (
            <SelectInput
                styleProperty={animationState}
                handleOnChange={handleAnimationStateChange}
            />
        )

    else return <></>
}

export default AnimationState