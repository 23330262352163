export const TimelineInitCodeTrigger: CodeTriggerT = {
    scope: 'timeline',
    type: 'init',
    code: ''
}

export const TimelineUpdateCodeTrigger: CodeTriggerT = {
    scope: 'timeline',
    type: 'time',
    code: '',
}

export const LabelCodeTrigger: CodeTriggerT = {
    scope: 'label',
    type: 'init',
    code: '',
}

export const AnimationInitCodeTrigger: CodeTriggerT = {
    scope: 'animation',
    type: 'init',
    code: '',
}

export const AnimationUpdateCodeTrigger: CodeTriggerT = {
    scope: 'animation',
    type: 'time',
    code: '',
}
