import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Dialog, Grid, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { License } from '../../constants/license.enum'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { useWorkspacePermission } from '../../hooks/useWorkspacePermission'
import { EnumLicense, upgradeSchema, upgradeUserLicenseAsync } from '../../store/slices/user.slice'
import { Transition } from '../common/Transition'
import RadioGroupButton from '../common/form/RadioGroupButton'

interface Props {
    open: boolean
    onClose: () => void
}
const DEFAULT_VALUES = {
    period: 1,
    license: 'free',
}

const MONTHLY_OPTION = {
    value: 1,
    title: t('license:period.monthly'),
    description: '',
}

const YEARLY_OPTION = {
    value: 12,
    title: t('license:period.yearly'),
    description: t('license:period.yearlyTxt'),
}

const PERIOD_OPTIONS = [MONTHLY_OPTION]

const TEMPORARILY_HIDDEN = true

const UpgradeDialog = ({ open, onClose }: Props) => {
    const theme = useTheme()
    const user = useAppSelector((state) => state.user.value)
    const dispatch = useAppDispatch()
    const { userHaveExpiredLicense } = useWorkspacePermission()

    const onSubmitUpgradeLicense = (data: { period: number; license: EnumLicense }) => {
        dispatch(upgradeUserLicenseAsync(user, data))
        onClose()
    }
    const defaultValues = useMemo(() => {
        return {
            ...DEFAULT_VALUES,
            period: user.license?.period,
            license: user.license?.paidLicense,
        }
    }, [user])

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: upgradeSchema,
        defaultValues: defaultValues,
    })

    const period = useWatch({
        control,
        name: 'period',
    })

    const getLicenseValue = (license: EnumLicense | undefined) => {
        switch (license) {
            case License.Enterprise:
                return 60
            case License.Business:
                return 20
            case License.Individual:
                return 15
            case License.Free:
            default:
                return 0
        }
    }

    //set disabled for monthly || yearly when user do not have expired license
    const disablePeriod =
        userHaveExpiredLicense || user.license?.paidLicense === 'free' || !user.license?.paidLicense

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmitUpgradeLicense)}
                noValidate
                sx={{
                    padding: 4,
                    backgroundColor: theme.palette.background.paper,
                    width: 400,
                }}
            >
                <Typography variant="h5" mb={3}>
                    {t('license:upgradeTitle')}
                </Typography>
                <Box sx={{ my: 3 }}>
                    <RadioGroupButton
                        control={control}
                        row
                        name="period"
                        title={'Period'}
                        info={
                            !TEMPORARILY_HIDDEN &&
                            !disablePeriod && (
                                <span style={{ marginLeft: 5 }}>
                                    <Tooltip
                                        placement={'right'}
                                        title={t('license:tooltip.disabledChangePeriod')}
                                    >
                                        <InfoIcon color={'disabled'} />
                                    </Tooltip>
                                </span>
                            )
                        }
                        required={true}
                        disabled={TEMPORARILY_HIDDEN && !disablePeriod}
                        options={
                            Number(period) === 12
                                ? [...PERIOD_OPTIONS, YEARLY_OPTION]
                                : PERIOD_OPTIONS
                        }
                        error={errors.license}
                        fullWidth
                    />
                    <RadioGroupButton
                        control={control}
                        name="license"
                        title={'License'}
                        required={true}
                        options={[
                            {
                                value: 'free',
                                title: t('license:free'),
                                description: t('license:freeTxt'),
                                disabled:
                                    getLicenseValue(user.license?.paidLicense) >
                                    getLicenseValue(License.Free),
                            },
                            {
                                value: 'individual',
                                title: t('license:individual'),
                                description:
                                    Number(period) === 1
                                        ? t('license:individualMonthlyTxt')
                                        : t('license:individualYearlyTxt'),
                                disabled:
                                    getLicenseValue(user.license?.paidLicense) >
                                    getLicenseValue(License.Individual),
                            },
                            {
                                value: 'business',
                                title: t('license:business'),
                                description:
                                    Number(period) === 1
                                        ? t('license:businessMonthlyTxt')
                                        : t('license:businessYearlyTxt'),
                                disabled:
                                    getLicenseValue(user.license?.paidLicense) >
                                    getLicenseValue(License.Business),
                            },
                            {
                                value: 'enterprise',
                                title: t('license:enterprise'),
                                description:
                                    Number(period) === 1
                                        ? t('license:enterpriseMonthlyTxt')
                                        : t('license:enterpriseYearlyTxt'),
                            },
                        ]}
                        error={errors.license}
                        fullWidth
                    />
                </Box>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Button
                        variant="text"
                        color="inherit"
                        sx={{ px: 2 }}
                        disabled={false}
                        onClick={onClose}
                    >
                        <Typography variant="body1">{t('disagree')}</Typography>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ px: 2 }}
                        disabled={isSubmitting}
                    >
                        <Typography variant="body1">{t('user:button.upgrade')}</Typography>
                    </Button>
                </Grid>
            </Box>
        </Dialog>
    )
}

export default UpgradeDialog
