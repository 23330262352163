import { Grid } from '@mui/material'
import { Control, FieldErrorsImpl, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from '../../common/form/Select'
import Switch from '../../common/form/Switch'
import TextField from '../../common/form/TextField'

interface Props {
    control: Control<any, any>
    errors: Partial<FieldErrorsImpl<any>>
    disableFormInput: boolean
}

const FirstStep = ({ control, errors, disableFormInput }: Props) => {
    const refresh = useController({ name: 'refresh', control })

    const { t } = useTranslation()
    return (
        <Grid container spacing={1}>
            <Grid item md={9}>
                <TextField
                    control={control}
                    fullWidth
                    name="name"
                    error={errors.name}
                    label={t('dataSource:name')}
                />
            </Grid>
            <Grid item md={3}>
                {/* TO DO - uniqueness control */}
                <TextField
                    control={control}
                    fullWidth
                    name="slug"
                    error={errors.slug}
                    label={t('dataSource:slug')}
                    disabled={disableFormInput}
                />
            </Grid>
            <Grid item md={9}>
                <TextField
                    control={control}
                    fullWidth
                    name="url"
                    error={errors.url}
                    label={t('dataSource:url')}
                    disabled={disableFormInput}
                />
            </Grid>
            <Grid item md={3}>
                <Select
                    control={control}
                    required
                    name="type"
                    label={t('dataSource:type')}
                    fullWidth
                    labelField="type"
                    valueField="type"
                    error={errors.type}
                    options={[{ type: 'json' }, { type: 'xml' }, { type: 'text' }]}
                    disabled={disableFormInput}
                />
            </Grid>
            <Grid item md={12}>
                <Switch
                    control={control}
                    name="refresh"
                    error={errors.isFinal}
                    label={t('dataSource:refreshSwitch')}
                />
            </Grid>
            <Grid item md={12}>
                <TextField
                    control={control}
                    label={t('dataSource:refreshAtField')}
                    name="refreshAt"
                    type="number"
                    error={errors.refreshAt}
                    disabled={!refresh.field.value}
                />
            </Grid>
        </Grid>
    )
}

export default FirstStep
