import { Grid } from '@mui/material'
import { t } from 'i18next'
import ExpandableSection from '../../common/ExpandableSection'
import MultiAlign from './ExpandableSections/MultiAlign'
import MultiSize from './ExpandableSections/MultiSize'

const MultiselectItemsTools = () => {
    return (
        <ExpandableSection
            expanded={true}
            title={t('editor:size&positionSettings.title')}
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item>
                        <MultiAlign />
                    </Grid>
                    <Grid item>
                        <MultiSize />
                    </Grid>
                </Grid>
            }
        />
    )
}

export default MultiselectItemsTools
