import ForgottenPasswordForm from '../components/forgottenPassword/ForgottenPasswordForm'
import LoginLayout from '../components/layouts/LoginLayout'

export default function ForgottenPassword() {
    return (
        <LoginLayout>
            <ForgottenPasswordForm />
        </LoginLayout>
    )
}
