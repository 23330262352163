import { Box, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { memo } from 'react'
import useTimeStepsDensity from '../../../../hooks/useTimeStepsDensity'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { TIMELINE_ROW } from '../../../../style/sizing'
import TimeStampWrapper from './TimeStampWrapper'
import TimelinePlayhead from './TimelinePlayhead'

interface Props {
    width: number
    visibility: 'visible' | 'hidden'
}

const TimeStamps = memo(({ width, visibility }: Props) => {
    const theme = useTheme()

    const duration: number = useTimelineActions().masterTimelineDuration
    const effectiveDuration: number = useTimelineActions().effectiveTimelineDuration
    const { timeStepsDensity } = useTimeStepsDensity()

    const arrayOfFrames: number[] = [...Array(timeStepsDensity)]

    const timelineRowWidth: number = width
    const tweenWidth: number = duration > 0 ? timelineRowWidth / duration : timelineRowWidth // width per sec

    return (
        <>
            <Box
                sx={{
                    zIndex: 60,
                    position: 'absolute',
                    width: TIMELINE_ROW.headingWidth + 'px',
                    height: TIMELINE_ROW.height + 'px',
                    background: theme.palette.grey[800],
                    visibility: visibility,
                    textAlign: 'center',
                }}
            ></Box>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                    top: '0px',
                    position: 'sticky',
                    zIndex: 50,
                    flexBasis: 20,
                    paddingLeft: TIMELINE_ROW.headingWidth + 'px',
                    visibility: visibility,
                    height: TIMELINE_ROW.height + 'px',
                }}
            >
                <TimelinePlayhead width={effectiveDuration * tweenWidth} visibility={visibility} />
                <Grid
                    container
                    direction="row"
                    sx={{
                        position: 'absolute',
                        width: timelineRowWidth + 'px', //100%
                        height: TIMELINE_ROW.height + 'px',
                        overflow: 'visible',
                        background: theme.palette.grey[800],
                        paddingBottom: 3,
                    }}
                >
                    {arrayOfFrames.map((_, index) => (
                        <TimeStampWrapper key={index} index={index} width={width} />
                    ))}
                </Grid>
            </Grid>
        </>
    )
})

export default TimeStamps
