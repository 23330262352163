import { Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useRoute } from '../../hooks/router/useRoute'
import { useAppSelector } from '../../hooks/useRedux'
import { useSession } from '../../hooks/useSession'
import { routes } from '../../routes'
import { AppContainer } from '../../style/styles'
import TopNavbar from '../TopNavbar'
import { Transition } from '../common/Transition'
import ChangePassword from '../settings/ChangePassword'
import WizardStepper from './WizardStepper'

const MainLayout = () => {
    const session = useSession()
    const route = useRoute(routes)
    const location = useLocation()
    const user = useAppSelector((state) => state.user)
    const [changePasswordOpen, setChangePasswordOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (user.value?.isPasswordTemp) {
            navigate('/created-account')
        }
    }, [location.state?.from, navigate, user])

    if (!session.data) {
        return <Navigate to="/login" replace state={{ from: location }} />
    } else if (!route || !session.hasPermission(route.permission)) {
        return <Navigate to="/404" />
    } else {
        return (
            <AppContainer>
                <TopNavbar routes={routes} />
                <Outlet />
                <Dialog open={changePasswordOpen} TransitionComponent={Transition} keepMounted>
                    <ChangePassword onClose={() => setChangePasswordOpen(false)} />
                </Dialog>
                <WizardStepper />
            </AppContainer>
        )
    }
}

export default MainLayout
