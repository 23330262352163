import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useAppSelector } from './useRedux'

const useFramerate = () => {
    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const framerate = useMemo(() => {
        return timelinePreferences.framerate
    }, [timelinePreferences.framerate])

    const resultTimeFramerate = useCallback(
        (time: number) => {
            switch (framerate) {
                case 50:
                    return Math.round(time * 50 * 100) / 100
                case 25:
                    return Math.round(time * 25 * 100) / 100
                default:
                    return time
            }
        },
        [framerate]
    )

    const setFramerateValue = (value: number) => {
        switch (framerate) {
            case 50:
                return value * 0.02
            case 25:
                return value * 0.04
            default:
                return value
        }
    }

    const getInputAdornment = () => {
        switch (framerate) {
            case 50:
                return t('editor:inputAdornmentUnit.fps')
            case 25:
                return t('editor:inputAdornmentUnit.fps')
            default:
                return t('editor:inputAdornmentUnit.sec')
        }
    }

    return {
        framerate,
        setFramerateValue,
        resultTimeFramerate,
        getInputAdornment,
    }
}

export default useFramerate
