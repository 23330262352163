import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { KeyboardEvent } from 'react'
import { useController } from '../../../hooks/useController'
import useKeyDown from '../../../hooks/useKeyDown'

interface Props {
    fullWidth?: boolean
    styleProperty: SimpleStyleT | GroupStyleI
    handleOnChange: (e: any) => void
}

const TextInput = ({ fullWidth, styleProperty, handleOnChange }: Props) => {
    const { value, onChange } = useController(styleProperty.value)
    const { handleOnEnterKeyDown } = useKeyDown()

    const tooltipTitle: string = (styleProperty.input as StringInputT).tooltipTitle ?? ''
    const label: string = styleProperty.input.label ?? styleProperty.property
    if (tooltipTitle.length > 0)
        return (
            <Tooltip title={tooltipTitle}>
                <TextField
                    fullWidth={fullWidth}
                    type={styleProperty.input.type}
                    label={label}
                    name={styleProperty.property}
                    value={value}
                    onChange={onChange}
                    onBlur={handleOnChange}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                        handleOnEnterKeyDown(e, handleOnChange)
                    }
                />
            </Tooltip>
        )
    else
        return (
            <TextField
                fullWidth={fullWidth}
                type={styleProperty.input.type}
                label={label}
                name={styleProperty.property}
                value={value}
                onChange={onChange}
                onBlur={handleOnChange}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleOnEnterKeyDown(e, handleOnChange)
                }
            />
        )
}

export default TextInput
