import { ReactElement } from 'react'
import { ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material'

type ButtonProps = {
    value: string, tooltip: string, icon: ReactElement
}

interface Props {
    buttons: ButtonProps[],
    property: string,
    value: string,
    handleOnChange: (property: any, value: any) => void
}

const ButtonGroupInput = ({ buttons, property, value, handleOnChange }: Props) => {

    const handleButtonGroupChange = (e: React.MouseEvent<HTMLElement>, newValue: string) =>
        handleOnChange(property, newValue)

    return (
        <ToggleButtonGroup value={value}
            exclusive
            onChange={handleButtonGroupChange}
            sx={{ margin: 0.5 }}
        >
            {
                buttons.map((b: ButtonProps, index: number) => {
                    return (
                        <ToggleButton key={index} value={b.value}>
                            <Tooltip title={b.tooltip}>
                                {b.icon}
                            </Tooltip>
                        </ToggleButton>
                    )
                })
            }
        </ToggleButtonGroup>
    )
}

export default ButtonGroupInput