import AlignHorizontalCenterRoundedIcon from '@mui/icons-material/AlignHorizontalCenterRounded'
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded'
import AlignHorizontalRightRoundedIcon from '@mui/icons-material/AlignHorizontalRightRounded'
import AlignVerticalBottomRoundedIcon from '@mui/icons-material/AlignVerticalBottomRounded'
import AlignVerticalCenterRoundedIcon from '@mui/icons-material/AlignVerticalCenterRounded'
import AlignVerticalTopRoundedIcon from '@mui/icons-material/AlignVerticalTopRounded'
import DensityMediumRoundedIcon from '@mui/icons-material/DensityMediumRounded'
import { Button, ButtonGroup } from '@mui/material'
import { t } from 'i18next'
import ButtonWithTooltip from '../../../common/ButtonWithTooltip'
interface Props {
    onClick: (property: string, value: string) => void
    disabled?: boolean
    multiAlign?: boolean
    disabledDistribute?: boolean
}
const Alignment = ({ onClick, disabled, multiAlign, disabledDistribute }: Props) => {
    return (
        <>
            <ButtonGroup color="inherit" variant="text">
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.horizontalLeft')}
                    buttonComponent={
                        <Button
                            onClick={() => onClick('left', 'horizontalLeft')}
                            disabled={disabled}
                        >
                            <AlignHorizontalLeftRoundedIcon />
                        </Button>
                    }
                />
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.horizontalCenter')}
                    buttonComponent={
                        <Button
                            disabled={disabled}
                            onClick={() => onClick('left', 'horizontalCenter')}
                        >
                            <AlignHorizontalCenterRoundedIcon />
                        </Button>
                    }
                />
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.horizontalRight')}
                    buttonComponent={
                        <Button
                            disabled={disabled}
                            onClick={() => onClick('left', 'horizontalRight')}
                        >
                            <AlignHorizontalRightRoundedIcon />
                        </Button>
                    }
                />
            </ButtonGroup>
            <ButtonGroup color="inherit" variant="text">
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.verticalTop')}
                    buttonComponent={
                        <Button disabled={disabled} onClick={() => onClick('top', 'verticalTop')}>
                            <AlignVerticalTopRoundedIcon />
                        </Button>
                    }
                />
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.verticalCenter')}
                    buttonComponent={
                        <Button
                            disabled={disabled}
                            onClick={() => onClick('top', 'verticalCenter')}
                        >
                            <AlignVerticalCenterRoundedIcon />
                        </Button>
                    }
                />
                <ButtonWithTooltip
                    tooltipTitle={t('editor:size&positionSettings.alignTooltip.verticalBottom')}
                    buttonComponent={
                        <Button
                            disabled={disabled}
                            onClick={() => onClick('top', 'verticalBottom')}
                        >
                            <AlignVerticalBottomRoundedIcon />
                        </Button>
                    }
                />
            </ButtonGroup>
            {multiAlign && (
                <ButtonGroup color="inherit" variant="text">
                    <ButtonWithTooltip
                        tooltipTitle={t(
                            'editor:size&positionSettings.alignTooltip.verticallyDistribute'
                        )}
                        buttonComponent={
                            <Button
                                disabled={disabled || disabledDistribute}
                                onClick={() => onClick('top', 'verticallyDistribute')}
                            >
                                <DensityMediumRoundedIcon />
                            </Button>
                        }
                    />
                    <ButtonWithTooltip
                        tooltipTitle={t(
                            'editor:size&positionSettings.alignTooltip.horizontallyDistribute'
                        )}
                        buttonComponent={
                            <Button
                                disabled={disabled || disabledDistribute}
                                onClick={() => onClick('left', 'horizontallyDistribute')}
                            >
                                <DensityMediumRoundedIcon sx={{ rotate: '90deg' }} />
                            </Button>
                        }
                    />
                </ButtonGroup>
            )}
        </>
    )
}

export default Alignment
