import { Box, CircularProgress, Grid, Slider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    scalePerc?: number
    zoom: number
    zoomStep: number
    minZoom: number
    maxZoom: number
    isPending?: boolean
    disabled?: boolean
    handleSliderChange: (e: any) => void
}

const ZoomSlider = ({
    scalePerc,
    zoom,
    zoomStep,
    minZoom,
    maxZoom,
    isPending,
    disabled,
    handleSliderChange,
}: Props) => {
    const [zoomState, setZoomState] = useState<number>(zoom)
    const { t } = useTranslation()

    // change local value when mouse is down or moving
    const handleChange = (event: Event, value: number | number[]) => {
        setZoomState(value as number)
    }

    //set zoomState after change it from fit screen action
    useEffect(() => {
        setZoomState(zoom)
    }, [zoom])

    // call api when mouse is up
    const handleChangeCommited = (
        event: React.SyntheticEvent | Event,
        value: number | Array<number>
    ) => {
        handleSliderChange(value as number)
    }

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item mr={2}>
                <Typography variant="caption">
                    {t('editor:scale')}: {scalePerc} %
                </Typography>
            </Grid>
            <Grid item width={160} mt={1}>
                <Slider
                    disabled={disabled}
                    value={zoomState}
                    size="small"
                    step={zoomStep}
                    min={minZoom}
                    max={maxZoom}
                    color="secondary"
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommited}
                    aria-labelledby="input-slider"
                />
            </Grid>
            <Grid item>
                <Box
                    sx={{
                        width: 45,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isPending && (
                        <CircularProgress size="small" color="secondary" sx={{ width: 20 }} />
                    )}
                </Box>
            </Grid>
        </Grid>
    )
}
export default ZoomSlider
