import { toast } from 'react-hot-toast'
import i18n from '../i18n'

interface Args {
    [key: string]: string | number
}

const formatUnicorn = (str: string, args: Args): string => {
    Object.entries(args).forEach(([key, value]) => {
        str = str.replace(new RegExp(`\\{${key}\\}`, 'gi'), value.toString())
    })

    return str
}

export const useToast = () => {
    const error = (msg: string, args: Args = {}) => {
        toast.error(formatUnicorn(i18n.t(msg), args))
    }

    const success = (msg: string, args: Args = {}) => {
        toast.success(formatUnicorn(i18n.t(msg), args))
    }

    const info = (msg: string, args: Args = {}) => {
        toast(formatUnicorn(i18n.t(msg), args))
    }

    return { error, success, info }
}
