import CloseIcon from '@mui/icons-material/Close'
import Alert, { AlertColor } from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
interface Props {
    alertMessage: string
    severity: AlertColor
}
export default function TransitionAlerts({ alertMessage, severity }: Props) {
    const [open, setOpen] = useState(true)

    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alertMessage}
                </Alert>
            </Collapse>
        </Box>
    )
}
