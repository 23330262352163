import { Button, Dialog } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import CodeEditor from '@uiw/react-textarea-code-editor'
import * as React from 'react'
import { Transition } from '../../common/Transition'

interface Props {
    open: boolean,
    title: string,
    code: CodeTriggerT | null,
    onSaveHandler?: (newCode: CodeTriggerT) => void
    onCloseHandler?: () => void
}


function CodeTriggerEditor({ open, title, code, onSaveHandler, onCloseHandler }: Props) {
    const theme = useTheme()

    const [editorCode, setEditorCode] = React.useState(code?.code)
    const handleClose = () => {
        if (onCloseHandler) onCloseHandler()
    }
    const codeToSave = (newCode: string) => {
        return newCode.replaceAll("'", "&apos").replaceAll("\"", "\\\"")
    }
    const handleSave = () => {
        if (code && (editorCode !== null) && (editorCode !== undefined)) {
            if (onSaveHandler) onSaveHandler({ ...code, code: codeToSave(editorCode) })
        }
        if (onCloseHandler) onCloseHandler()
    }
    const codeToLoad = (): string | undefined => {
        return code?.code.replaceAll("&apos", "'").replaceAll("\\\"", "\"")
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'xl'}
            sx={{
                '.MuiPaper-root': {
                    paddingTop: 1,
                    paddingLeft: 1,
                    paddingRight: 2,
                    paddingBottom: 2
                },
            }}
        >
            <DialogTitle>Edit the code trigger</DialogTitle>
            <DialogContent>
                <DialogContentText pb={2}>{title}</DialogContentText>
                <CodeEditor
                    data-color-mode="dark"
                    value={codeToLoad()}
                    language="js"
                    placeholder="Please enter JS code."
                    onChange={(evn) => setEditorCode(evn.target.value)}
                    padding={0}
                    minHeight={500}
                    style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        backgroundColor: theme.palette.background.paper,
                        fontFamily:
                            'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="text" color="inherit">Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CodeTriggerEditor
