import { Button, Grid, Paper, Popover, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectUndoable } from '../../helpers/selector.helpers'
import { useAnimationActions } from '../../hooks/useAnimationActions'
import useHTMLFileContent from '../../hooks/useHTMLFileContent'
import { useObjectActions } from '../../hooks/useObjectActions'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useWizard } from '../../hooks/useWizard'
import { getCompaniesCurrentAsync } from '../../store/slices/companies.slice'
import { setOpenMenuWizard, setOpenSaveForm } from '../../store/slices/editor.slice'
import { submitTutorialGraphicAsync } from '../../store/slices/graphic.slice'
import { selectSortedRootObjects } from '../../store/slices/objects.slice'

const WizardStepper = () => {
    const { t } = useTranslation()
    const { addItem } = useObjectActions()
    const { addNewAnimation } = useAnimationActions()
    const { processFile } = useHTMLFileContent()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const companies = useAppSelector((state) => state.companies.data)
    const privateCompanyId = companies?.find((x) => x.isPrivate)?._id
    const graphic = useAppSelector((state) => state.graphic)
    const objects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )

    const canvasResolution = useAppSelector((state) => state.editor.value.settings.canvasResolution)
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)

    const handleAddAnimation = () => addNewAnimation(objects[0].id, 0)

    const callback = async (nextStep: string) => {
        if (nextStep === 'editor-step-2') {
            addItem()
        }
        if (nextStep === 'editor-step-3') {
            handleAddAnimation()
        }
        if (nextStep === 'editor-step-6') {
            const file = processFile()
            dispatch(setOpenSaveForm(true))
            const successMessage = 'graphics:successMessage.graphicTutorialDetailSave'
            if (privateCompanyId) {
                let newData = {
                    ...graphic.data,
                    name: 'Tutorial graphic file',
                    description: 'This is your first graphic file from tutorial',
                    companyId: privateCompanyId,
                    canvasSize: [canvasResolution[0], canvasResolution[1]],
                    framerate: framerate,
                }
                await dispatch(submitTutorialGraphicAsync(newData, file, successMessage))
            }
        }
        if (nextStep === 'editor-step-7') {
            dispatch(setOpenMenuWizard(true))
        }
        if (nextStep === 'editor-step-8') {
            dispatch(setOpenMenuWizard(false))
            dispatch(setOpenSaveForm(false))
        }
    }

    const { currentRef, next, finish, dismiss, step, lastStep } = useWizard(callback)

    const handleNavigateToProfile = () => {
        navigate('/account/profile')
        finish()
    }

    //get current companies after refresh -> needed for load data before save in editor-step-6
    useEffect(() => {
        dispatch(getCompaniesCurrentAsync())
    }, [dispatch])

    //necessary because transition is not smooth when close popover
    if (!Boolean(step)) {
        return null
    }

    return (
        <Popover
            open={Boolean(currentRef)}
            anchorEl={currentRef}
            anchorOrigin={step?.anchorOrigin}
            transformOrigin={step?.transformOrigin}
            sx={{ zIndex: 1500 }}
        >
            <Paper
                sx={{
                    p: 2,
                    border: 2,
                    borderColor: (theme) => theme.palette.secondary.main,
                    maxWidth: 500,
                }}
            >
                <Typography variant="h6" color={'primary'}>
                    {step?.title}
                </Typography>
                <Typography>{step?.description}</Typography>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item md={3}>
                        <Button variant="text" color={'secondary'} onClick={dismiss} sx={{ mr: 1 }}>
                            {t('wizardButtons.dismiss')}
                        </Button>
                    </Grid>
                    <Grid item md={9}>
                        <Grid container justifyContent={'flex-end'}>
                            {step?.key === lastStep.key && (
                                <Button
                                    variant="text"
                                    color={'secondary'}
                                    onClick={handleNavigateToProfile}
                                    sx={{ mr: 1 }}
                                >
                                    {t('wizardButtons.openProfile')}
                                </Button>
                            )}
                            <Button onClick={step?.key === lastStep.key ? finish : next}>
                                {step?.key === lastStep.key
                                    ? t('wizardButtons.finish')
                                    : t('wizardButtons.next')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    )
}

export default WizardStepper
