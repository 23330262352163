import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import gsap from 'gsap'
import GSDevTools from 'gsap/GSDevTools'
import { set } from 'lodash'
import { renderGroupStyleValue } from '../../data/styles/groups/groups.styles'
import { imageSrc } from '../../data/styles/simple/display.styles'
import { renderSimpleStyleValue } from '../../data/styles/simple/simple.styles'
import useDynamicRefs from '../../hooks/useDynamicRefs'
import { AppThunkT } from '../store'

import { roundNumber } from '../../helpers/number.helpers'
import { EMPTY_IMAGE_SRC, preloadTween, processTween } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'

gsap.registerPlugin(GSDevTools)

// type StateT = {
//     value: GSAPTimeline | null
// }

type StateT = {
    value: any | null
    time: number
}

const initialState: StateT = {
    value: null,
    time: 0,
}

type PayloadT = {
    gsapTimeline: GSAPTimeline | null
}

export const masterTimelineSlice = createSlice({
    name: 'masterTimeline',
    initialState,
    reducers: {
        updMasterTimelineAction: (state: StateT, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.gsapTimeline
        },
        setTimeToSeek: (state: StateT, action: PayloadAction<number>) => {
            state.time = action.payload
        },
    },
})

const serializeAnimationStateForPreview = (animationStates: SimpleStyleT[]): GSAPTweenVars => {
    const result: GSAPTweenVars = {}
    animationStates.forEach((animationState) =>
        set(result, animationState.property, animationState.value)
    )
    return result
}

const serializeEaseForPreview = (ease: EaseT): string => {
    let result: string = ''
    if (ease) {
        result = ease.easeStyle + '.' + ease.easeType
        const strength = ease.easeStrengthValues.find((s) => s.strength === ease.easeStrength)
        if (strength) result += strength.value
    }
    return result
}

export const updateMasterTimeline =
    (timeToSeek: number): AppThunkT =>
    (dispatch, getState) => {
        const { objects, animations, timeline } = selectUndoable(getState())

        const [getRef] = useDynamicRefs()

        if (objects.value === null || Object.keys(objects.value).length === 0) {
            dispatch(updMasterTimelineAction({ gsapTimeline: null }))
            return null
        }

        const initTweenForPreview = (animation: AnimationI): GSAPTween => {
            let gsapTween: GSAPTween = gsap.to({}, {})
            // create animation
            if (animation.tween.type === 'fromTo')
                gsapTween = gsap.fromTo(
                    getRef(animation.objectId)?.current as GSAPTweenTarget,
                    // @ts-ignore
                    serializeAnimationStateForPreview(animation.tween.from),
                    {
                        ...serializeAnimationStateForPreview(animation.tween.to),
                        duration: animation.duration,
                        ease: serializeEaseForPreview(animation.ease),
                    },
                    animation.delay
                )
            else if (animation.tween.type === 'from')
                gsapTween = gsap.from(
                    getRef(animation.objectId)?.current as GSAPTweenTarget,
                    // @ts-ignore
                    {
                        ...serializeAnimationStateForPreview(animation.tween.from),
                        duration: animation.duration,
                        ease: serializeEaseForPreview(animation.ease),
                    },
                    animation.delay
                )
            else if (animation.tween.type === 'to')
                gsapTween = gsap.to(
                    getRef(animation.objectId)?.current as GSAPTweenTarget,
                    // @ts-ignore
                    {
                        ...serializeAnimationStateForPreview(animation.tween.to),
                        duration: animation.duration,
                        ease: serializeEaseForPreview(animation.ease),
                    },
                    animation.delay
                )
            else if (animation.tween.type === 'set') {
                let from = animation.tween.from
                if (animation.tween.name === 'sequence') {
                    from = processTween(animation.tween.from)
                    // Preload all images.
                    preloadTween(from)
                }
                gsapTween = gsap.set(
                    getRef(animation.objectId)?.current as GSAPTweenTarget,
                    serializeAnimationStateForPreview(from)
                )
            }

            // if animation is repeating, set repeats
            if (animation.repeat > 0) gsapTween.repeat(animation.repeat)
            return gsapTween
        }

        const newMasterTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: Number(timeline.value.repeat),
            id: 'master',
        })

        const zeroAnimation = Object.values(animations.value).find(
            (a) => a && a.delay === 0 && a.tween.name === 'sequence'
        )

        //set empty image src for active object on position 0 on timeline when zeroAnimation not exist
        if (!zeroAnimation) {
            const activeObjectId = getState().activeObject.value
            if (activeObjectId) {
                const activeObject = objects.value[activeObjectId]
                if (activeObject.type === 'sequence') {
                    const emptyTween = gsap.set(
                        getRef(activeObjectId)?.current as GSAPTweenTarget,
                        serializeAnimationStateForPreview([imageSrc(EMPTY_IMAGE_SRC)])
                    )
                    newMasterTimeline.add(emptyTween, 0)
                }
            }
        }

        // @ts-ignore
        Object.values(animations.value).forEach((animation: AnimationI) => {
            const gsapTween: GSAPTween = initTweenForPreview(animation)
            newMasterTimeline.add(gsapTween, animation.delay)

            if (animation.tween.name === 'sequence') {
                const nextDelay = roundNumber(animation.delay + animation.duration, 2)
                const nextAnimation = Object.values(animations.value).find(
                    (a) =>
                        a &&
                        a.objectId === animation.objectId &&
                        roundNumber(a.delay, 2) === nextDelay &&
                        a.tween.name === 'sequence'
                )

                if (!nextAnimation) {
                    const emptyTween = gsap.set(
                        getRef(animation.objectId)?.current as GSAPTweenTarget,
                        serializeAnimationStateForPreview([imageSrc(EMPTY_IMAGE_SRC)])
                    )
                    newMasterTimeline.add(emptyTween, nextDelay)
                }
            }
        })

        if (timeline.value.labels) {
            Object.values(timeline.value.labels).forEach((label: TimelineLabelI) => {
                newMasterTimeline.addLabel(label.title, label.time)
            })
        }

        GSDevTools.create({
            id: 'master',
            hideGlobalTimeline: true,
            animation: 'master',
            container: '#controls-wrapper',
            persist: false,
            keyboard: false, // [NOTE] why not allow keyboard shortcut ?
        })

        if (timeline.value.freezeTime !== null) {
            newMasterTimeline.pause(timeline.value.freezeTime, false)
        } else {
            newMasterTimeline.pause(timeToSeek, false)
        }

        dispatch(updMasterTimelineAction({ gsapTimeline: newMasterTimeline }))
    }

export const refreshAllStyles = (): AppThunkT => (dispatch, getState) => {
    const objects = selectUndoable(getState()).objects
    const [getRef] = useDynamicRefs<HTMLElement>()

    const refreshStyles = (object: AnySceneObjectT) => {
        Object.values(object.styles).forEach((style) => {
            const ref = getRef(object.id)
            if (ref && ref.current) {
                const styleProperty = style.property as keyof string

                if (style.value !== ref.current.style[styleProperty as any]) {
                    ref.current.style[styleProperty as any] = renderSimpleStyleValue(style, 'css')
                }
            }
        })
        Object.values(object.transforms).forEach((style) => {
            const ref = getRef(object.id)
            if (ref && ref.current) {
                const transformType = style.type as keyof GroupStyleTypeT
                if (style.value !== ref.current.style[transformType as any]) {
                    ref.current.style[transformType as any] = renderGroupStyleValue(style, 'css')
                }
            }
        })
    }

    Object.values(objects.value).forEach((object: AnySceneObjectT) => refreshStyles(object))
}

export const { updMasterTimelineAction, setTimeToSeek } = masterTimelineSlice.actions
export default masterTimelineSlice.reducer
