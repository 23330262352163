import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import ImageIcon from '@mui/icons-material/Image'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Alert, Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { toJpeg } from 'html-to-image'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Status } from '../../constants/status'
import { duplicateGraphicData, findNextNumberVersion } from '../../helpers/graphic.helpers'
import { useCanvas } from '../../hooks/useCanvas'
import useDynamicRefs from '../../hooks/useDynamicRefs'
import useId from '../../hooks/useId'
import { useObjectActions } from '../../hooks/useObjectActions'
import { BaseState, useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { getCompaniesCurrentAsync } from '../../store/slices/companies.slice'
import { setOpenSaveForm } from '../../store/slices/editor.slice'
import { GraphicT, graphicSchema, submitGraphicAsync } from '../../store/slices/graphic.slice'
import { AssetRef } from '../../store/slices/graphics.slice'
import {
    deleteThumbnailAsync,
    insertThumbnailAndSaveAsync,
    insertThumbnailBeforeSaveAsync,
} from '../../store/slices/thumbnail.slice'
import { getThumbnailFileAsync } from '../../store/slices/thumbnailMap.slice'
import DeleteButton from '../common/DeleteButton'
import LoadingButton from '../common/LoadingButton'
import Select from '../common/form/Select'
import Switch from '../common/form/Switch'
import Tags from '../common/form/Tags'
import TextField from '../common/form/TextField'
import ThumbnailDetail from './ThumbnailDetail'

function GraphicSaveForm() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [getRef] = useDynamicRefs()
    const { prevFile } = useAppSelector((state) => state.editor.value)
    const [openThumbnail, setOpenThumbnail] = useState<boolean>(false)
    const graphic = useAppSelector((state) => state.graphic as BaseState<GraphicT>)
    const thumbnailMap = useAppSelector((state) => state.thumbnailMap)
    const similarGraphics = useAppSelector((state) => state.similarGraphics)
    const signSaveAs = useAppSelector((state) => state.editor.value.signSaveAs)
    const thumbnailData = useAppSelector((state) => state.thumbnail.data)
    const thumbnailStatus = useAppSelector((state) => state.thumbnail.status)
    const thumbnailMapStatus = useAppSelector((state) => state.thumbnailMap.status)
    const [graphicData, setGraphicData] = useState<GraphicT>(graphic.data)
    const currentCompanies = useAppSelector((state) => state.companies)
    const assets = useAppSelector((state) => state.assets.data)
    const { canvasResolution: canvas } = useCanvas()
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)
    const settings = useAppSelector((state) => state.editor.value.settings)
    const { deselectObjects } = useObjectActions()

    useEffect(() => {
        const dataBeforeSaveAs = () => {
            let finalNumber = findNextNumberVersion(similarGraphics.data)
            if (!signSaveAs) {
                if (!graphic.data.companyId && settings.companyId) {
                    return { ...graphic.data, companyId: settings.companyId }
                }
                return graphic.data
            } else {
                return duplicateGraphicData(graphic.data, finalNumber)
            }
        }
        return setGraphicData(dataBeforeSaveAs())
    }, [graphic.data, signSaveAs, similarGraphics.data, settings])

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: graphicSchema,
        defaultValues: graphicData,
    })

    const companyId = useWatch({
        control,
        name: 'companyId',
    })

    const { canCreateNew } = useVerifyPermission(
        graphic.data,
        currentCompanies?.data.find((x) => x._id === companyId) || graphic.data.company
    )

    useEffect(() => {
        dispatch(getCompaniesCurrentAsync())
    }, [dispatch])

    const handleClose = () => {
        dispatch(setOpenSaveForm(false))
    }
    const handleOnSubmit = (id?: string) => {
        if (id) {
            setId(id)
            handleClose()
        } else {
            handleClose()
        }
    }

    const { id, setId } = useId()
    const onSubmitGraphic = async (data: GraphicT) => {
        let assetsRef: AssetRef[] = assets.map((asset) => ({ id: asset._id }))
        const successMessage = 'graphics:successMessage.graphicDetailSave'
        const newData = {
            ...data,
            thumbnailId: thumbnailData.id,
            url: thumbnailData.url,
            canvasSize: [canvas.width, canvas.height],
            framerate: framerate,
            assetsRef,
        }
        await dispatch(
            submitGraphicAsync(newData, prevFile, successMessage, (id?: string) =>
                handleOnSubmit(id)
            )
        )
    }

    const generateThumbnail = async () => {
        const canvasRef = getRef('canvas')
        const aspectRatio = canvas.width / canvas.height

        try {
            const thumbnailSrc = await toJpeg(canvasRef?.current as HTMLDivElement, {
                style: {
                    transform: 'scale(1)',
                },
                quality: 0.8,
                canvasWidth: aspectRatio >= 1 ? 540 * aspectRatio : 960,
                canvasHeight: aspectRatio >= 1 ? 540 : 960 / aspectRatio,
            })
            const successMessage = t('graphics:successMessage.thumbnailSave')
            if (!graphic.data._id) {
                dispatch(insertThumbnailBeforeSaveAsync(thumbnailSrc, successMessage))
            } else {
                dispatch(insertThumbnailAndSaveAsync(graphic.data, thumbnailSrc, successMessage))
            }
        } catch (error) {
            console.error('Error creating thumbnail:', error)
        }
    }

    const createThumbnail = () => {
        deselectObjects()
        // use setTimeout to ensure deselectObjects
        setTimeout(() => generateThumbnail(), 100)
    }

    const handleOpenThumbnail = async () => {
        dispatch(getThumbnailFileAsync(thumbnailData.id as string))
        setOpenThumbnail(true)
    }

    const handleCloseThumbnail = () => {
        setOpenThumbnail(false)
    }

    const handleDeleteThumbnail = async () => {
        if (graphic.data._id) {
            dispatch(deleteThumbnailAsync(thumbnailData.id, graphic.data))
        } else {
            dispatch(deleteThumbnailAsync(thumbnailData.id))
        }
    }

    return (
        <div>
            <Box
                id="editor-step-6"
                component="form"
                onSubmit={handleSubmit(onSubmitGraphic)}
                noValidate
            >
                <Grid container py={3} px={1}>
                    <Grid item xs={12} mb={2} display={'flex'}>
                        <Box mr={1}>
                            {signSaveAs ? (
                                <ContentCopyRoundedIcon sx={{ width: 30, height: 30 }} />
                            ) : (
                                <SaveRoundedIcon sx={{ width: 30, height: 30 }} />
                            )}
                        </Box>
                        <Typography variant="h4" sx={{ verticalAlign: 'center' }}>
                            {signSaveAs ? t('graphics:duplicate') : t('graphics:save')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            control={control}
                            required
                            fullWidth
                            name="name"
                            error={errors.name}
                            label={t('graphics:name')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            control={control}
                            fullWidth
                            name="description"
                            label={t('graphics:description')}
                            error={errors.description}
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tags
                            control={control}
                            name="tags"
                            label={t('graphics:tags')}
                            error={errors.tags}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            control={control}
                            required
                            name="companyId"
                            label={t('company:title')}
                            fullWidth
                            labelField="name"
                            valueField="_id"
                            error={errors.companyId}
                            options={currentCompanies.data}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {graphic.status === Status.GRAPHIC_EXISTS && (
                            <Alert severity="warning" variant="filled" sx={{ mt: 1 }}>
                                {t('graphics:warningMessage.graphicExists')}
                            </Alert>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Switch
                                control={control}
                                name="isFinal"
                                error={errors.isFinal}
                                label={t('graphics:final')}
                                onIcon={<CheckCircleRoundedIcon />}
                            />

                            {graphic.data.isPublished && (
                                <Tooltip title={t('graphics:isPublished')}>
                                    <PublicRoundedIcon sx={{ width: 20, height: 20 }} />
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" my={2}>
                        <Grid item xs={12}>
                            {!thumbnailData.id ? (
                                <LoadingButton
                                    loading={!thumbnailStatus}
                                    loadingPosition="start"
                                    startIcon={<AddRoundedIcon />}
                                    onClick={createThumbnail}
                                    disabled={!thumbnailStatus}
                                    color={'success'}
                                    tooltipTitle={t('graphics:addThumbnailTooltip')}
                                >
                                    {t('graphics:addThumbnail')}
                                </LoadingButton>
                            ) : (
                                <>
                                    <LoadingButton
                                        tooltipTitle={t('graphics:showThumbnailTooltip')}
                                        variant="text"
                                        onClick={() => handleOpenThumbnail()}
                                        disabled={
                                            !thumbnailMapStatus ||
                                            (!thumbnailStatus && !!thumbnailData.id)
                                        }
                                        loadingPosition="start"
                                        startIcon={<ImageIcon />}
                                        // if loading thumbnail source from thumbnailMap or loading when delete thumbnailId
                                        loading={!thumbnailMapStatus || !thumbnailStatus}
                                    >
                                        {t('graphics:thumbnailButton')}
                                    </LoadingButton>
                                    {/* if loading thumbnail or loading thumbnailId then delete thumbnail is hide*/}
                                    {thumbnailMapStatus && thumbnailStatus && (
                                        <DeleteButton
                                            handleOnChange={handleDeleteThumbnail}
                                            tooltipTitle={t('graphics:deleteThumbnail')}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} flexDirection={{ sm: 'row-reverse', xs: 'row' }}>
                        <Grid item>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting || !canCreateNew}
                                loading={isSubmitting || !graphic.status}
                                fullWidth
                                loadingPosition="end"
                                endIcon={<></>}
                            >
                                {
                                    <Typography variant="body1" m={0} p={0}>
                                        {id
                                            ? signSaveAs
                                                ? t('submitShortDuplicate')
                                                : t('submitShort')
                                            : t('submitCreate')}
                                    </Typography>
                                }
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                color="inherit"
                                disabled={isSubmitting}
                                onClick={handleClose}
                                fullWidth
                            >
                                <Typography variant="body1">{t('disagree')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {openThumbnail && (
                <ThumbnailDetail
                    open={openThumbnail}
                    thumbnailSrc={thumbnailMap.data[thumbnailData.id!]?.src}
                    onClose={handleCloseThumbnail}
                />
            )}
        </div>
    )
}
export default GraphicSaveForm
