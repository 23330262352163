import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material'
import { t } from 'i18next'
interface Props {
    name: string
    labelTitle: string
    value: any
    onChange: (e: SelectChangeEvent<any>) => void
    disabled?: boolean
    options: string[]
    prefix: string
}
const SelectTimelineState = ({
    name,
    labelTitle,
    value,
    onChange,
    disabled,
    options,
    prefix,
}: Props) => {
    return (
        <FormControl>
            <InputLabel id={name}>{labelTitle}</InputLabel>
            <Select
                native
                name={name}
                labelId={name}
                id={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {options.map((x) => (
                    <option value={x} key={x}>
                        {t(`editor:timeline.select${prefix}.${x}`)}
                    </option>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectTimelineState
