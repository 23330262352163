import useAlignment from '../../../../hooks/editor/useAlignment'
import Alignment from './Alignment'

interface Props {
    object: AnySceneObjectT
    handleAlign: (property: string, value: number) => void
}
const SingleAlignment = ({ object, handleAlign }: Props) => {
    const { resultAlignment } = useAlignment()

    const handleOnChange = (property: string, value: string) => {
        const newValue = resultAlignment(object, value)
        handleAlign(property, newValue)
    }

    return <Alignment onClick={handleOnChange} />
}

export default SingleAlignment
