import CircleIcon from '@mui/icons-material/Circle'
import { FormControl, FormHelperText, Switch as MuiSwitch } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
    control: Control<any, any>
    name: string
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    fullWidth?: boolean
    label?: string
    disabledLabel?: boolean
    onIcon?: any
    offIcon?: any
    disabled?: boolean
}

export default function Switch({
    name,
    control,
    fullWidth,
    error,
    label,
    disabledLabel,
    onIcon,
    offIcon,
    disabled,
}: Props) {
    const { t } = useTranslation()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl fullWidth={fullWidth} error={Boolean(error)} sx={{ pl: 1 }}>
                    <FormControlLabel
                        control={
                            <MuiSwitch
                                checked={field.value === true}
                                onChange={(event) => field.onChange(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                checkedIcon={onIcon ? onIcon : <CircleIcon />}
                                icon={offIcon ? offIcon : <CircleIcon />}
                                disabled={disabled}
                            />
                        }
                        label={!disabledLabel && (label || t(name))}
                    />
                    {error && (
                        <FormHelperText>
                            {error.message ? t(error.message.toString()) : undefined}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )
}
