import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'

interface Props {
    groupStyleProperty: string
    properties: GroupStyleT[]
    handleOnChange: (field: 'property', value: any) => void
}

const GroupFieldProperty = ({ groupStyleProperty, properties, handleOnChange }: Props) => {
    const handleFieldOnChange = (e: any) => handleOnChange('property', e.target.value)

    const getLabel = (inputGroup: GroupStyleT): string =>
        inputGroup.input.label ?? inputGroup.property

    return (
        <FormControl>
            <InputLabel>type</InputLabel>
            <Select
                native
                name={groupStyleProperty}
                value={groupStyleProperty}
                onChange={handleFieldOnChange}
            >
                {(properties as GroupStyleT[])
                    .filter(
                        (x) =>
                            groupStyleProperty === 'backgroundImage' ? x : x.input.label !== 'image' //backgroundImage visible only for old graphics with this background settings
                    )
                    .map((inputOption: GroupStyleT, index: number) => (
                        <option key={index} value={inputOption.property}>
                            {getLabel(inputOption)}
                        </option>
                    ))}
            </Select>
        </FormControl>
    )
}

export default GroupFieldProperty
