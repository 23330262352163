export const defaultTimeline: TimelineI = {
    //initState,endState..  moved to editor.settings.timeline
    /// TO DO  - move other values to editor.settings.timeline
    minduration: 30,
    repeat: 0,
    labels: [],
    freezeTime: null,
    onInitCode: null,
    onUpdateCode: null,
}

export const defaultTimelineLabel: TimelineLabelI = {
    title: 'label',
    time: 0,
    type: 'continue',
    jumpto: '',
    onLabelCode: null,
}
