import { MAX_INPUT_NUM } from "../../../constants"

const positiveUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const blur: GroupStyleT = {
	property: "blur",
	value: 0,
	unit: "px",
	type: "filter",
	input: positiveUnitaryInput,
	render: (value, unit) => ({ property: "filter", value: `blur(${value + unit})` }),
}

export const dropShadow: GroupStyleT = {
	property: "dropShadow",
	value: "0px 0px 0px #000000",
	type: "filter",
	input: {
		type: "string",
		props: "",
		label: "drop shadow",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "filter", value: `drop-shadow(${value})` }
			: { property: "filter", value: `drop-shadow(${value})` },
}

// all possible filters for custom fields
export const filters = [blur, dropShadow]
export const filterNames = ["blur", "dropShadow"]
