import { RefreshRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../hooks/useRedux'
import { refreshAllStyles } from '../../../store/slices/masterTimeline.slice'

const RefreshStyle = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const handleRefresh = () => dispatch(refreshAllStyles())

    return (
        <Tooltip title={t('menu:refreshScene')}>
            <IconButton onClick={handleRefresh}>
                <RefreshRounded fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}
export default RefreshStyle
