import { ChangeEvent } from 'react'
import { useAnimationActions } from '../../../../hooks/useAnimationActions'
import useFramerate from '../../../../hooks/useFramerate'
import ExpandableSection from '../../../common/ExpandableSection'
import { TimingInput, TimingOffsetInput } from './TimingInput'

interface Props {
    animation: AnimationI
    selectedAnimations: AnimationI[]
    handleSetValue: (property: any, value: any) => void
    handleSetValues: (animations: AnimationI[], property: any, value: any) => void
}

const Timing = ({ animation, selectedAnimations, handleSetValue, handleSetValues }: Props) => {
    const { setFramerateValue, getInputAdornment } = useFramerate()
    const inputAdornmentUnit = getInputAdornment()
    const { pushAnimation, pushMultipleAnimations, expandAnimation, expandMultipleAnimations } =
        useAnimationActions()

    const handleSetDuration = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value !== '' && e.target.value !== '*') {
            if (selectedAnimations.length < 2) {
                handleSetValue('duration', setFramerateValue(Number(e.target.value)))
            } else {
                handleSetValues(
                    selectedAnimations,
                    'duration',
                    setFramerateValue(Number(e.target.value))
                )
            }
        }
    }

    const handleSetDelay = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value !== '' && e.target.value !== '*') {
            if (selectedAnimations.length < 2) {
                handleSetValue('delay', setFramerateValue(Number(e.target.value)))
            } else {
                handleSetValues(
                    selectedAnimations,
                    'delay',
                    setFramerateValue(Number(e.target.value))
                )
            }
        }
    }

    const handlePushDelay = (e: ChangeEvent<HTMLInputElement>): void => {
        if (selectedAnimations.length < 2) {
            pushAnimation(animation, setFramerateValue(Number(e.target.value)))
        } else {
            pushMultipleAnimations(selectedAnimations, setFramerateValue(Number(e.target.value)))
        }
    }

    const handleExpandDuration = (e: ChangeEvent<HTMLInputElement>): void => {
        if (selectedAnimations.length < 2) {
            expandAnimation(animation, setFramerateValue(Number(e.target.value)))
        } else {
            expandMultipleAnimations(selectedAnimations, setFramerateValue(Number(e.target.value)))
        }
    }

    const { updateAnimation } = useAnimationActions()
    const handleMoveDelay = (e: ChangeEvent<HTMLInputElement>): void => {
        Object.values(selectedAnimations).forEach((animation: AnimationI) => {
            updateAnimation(
                animation.id,
                'delay',
                Number(animation.delay) + setFramerateValue(Number(e.target.value))
            )
        })
    }

    const getSelectedAnimationsDuration = (): string => {
        let duration: number = 0
        Object.values(selectedAnimations).forEach((animation: AnimationI) => {
            duration =
                duration === 0
                    ? animation.duration
                    : animation.duration === duration
                    ? duration
                    : -1
        })
        return duration < 0 ? '*' : duration.toString()
    }

    const getSelectedAnimationsDelay = (): string => {
        let delay: number = -2
        Object.values(selectedAnimations).forEach((animation: AnimationI) => {
            delay = delay === -2 ? animation.delay : animation.delay === delay ? delay : -1
        })
        return delay < 0 ? '*' : delay.toString()
    }

    return (
        <ExpandableSection
            expanded={true}
            title={'Timing'}
            component={
                <>
                    <TimingInput
                        time={
                            selectedAnimations.length < 2
                                ? +animation.duration
                                : +getSelectedAnimationsDuration()
                        }
                        name="duration"
                        label="duration"
                        unit={inputAdornmentUnit}
                        handleOnChange={handleSetDuration}
                    />
                    <TimingOffsetInput
                        time={0}
                        name="expand-offset"
                        label="expand-offset"
                        unit={inputAdornmentUnit}
                        handleOnChange={handleExpandDuration}
                    />
                    <TimingInput
                        time={
                            selectedAnimations.length < 2
                                ? +animation.delay
                                : +getSelectedAnimationsDelay()
                        }
                        name="start-offset"
                        label="start-offset"
                        unit={inputAdornmentUnit}
                        handleOnChange={handleSetDelay}
                    />
                    <TimingOffsetInput
                        time={0}
                        name="push-offset"
                        label="push-offset"
                        unit={inputAdornmentUnit}
                        handleOnChange={handlePushDelay}
                    />
                    <TimingOffsetInput
                        time={0}
                        name="move-offset"
                        label="move-offset"
                        unit={inputAdornmentUnit}
                        handleOnChange={handleMoveDelay}
                    />
                </>
            }
        />
    )
}

export default Timing
