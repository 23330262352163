import { HSLColor, RGBColor } from 'react-color'

export const hexToRGBColor = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (result) {
        let RGBColor: RGBColor = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1,
        }
        return RGBColor
    } else {
        return {
            r: 0,
            g: 0,
            b: 0,
            a: 1,
        }
    }
}

export const hexToHSLColor = (hex: string) => {
    let RGBColor = hexToRGBColor(hex)

    let r = RGBColor.r
    let g = RGBColor.g
    let b = RGBColor.b
    r /= 255
    g /= 255
    b /= 255

    let max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    let h,
        s,
        l = (max + min) / 2
    if (max === min) {
        h = s = 0 // achromatic
    } else {
        let d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        h ? (h /= 6) : (h = 0)
    }
    let HSLColor: HSLColor = {
        a: 1,
        h: h,
        l: l,
        s: s,
    }

    return HSLColor
}
