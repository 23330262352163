import { useTheme } from '@mui/material/styles'
import {
    GridColDef,
    GridFilterModel,
    GridSelectionModel,
    GridSortItem,
    GridSortModel,
    GridValueGetterParams,
    DataGrid as MuiDataGrid,
} from '@mui/x-data-grid'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFilter } from '../../helpers/filters.helpers'
import getGridNoResultsOverlay from './grid/GridNoResultsOverlay'
import { getGridToolbar } from './grid/GridToolbar'
interface Props {
    columns: GridColDef[]
    total: number
    data: any[]
    onChange: (queryOptions: QueryOptionsT) => void
    onSelect?: (id: string | null) => void
    disabledCreateNewOnSelect?: boolean
    noResultsEl?: ReactNode
    loading: boolean
    disableToolbar?: boolean
    hideFooterPagination?: boolean
    hideCreateButtonInToolbar?: boolean
}

export interface SortT {
    [field: string]: null | undefined | number
}

export interface FilterT {
    [field: string]: { [operator: string]: any }
}

export interface QueryOptionsT {
    filter: FilterT
    sort: SortT
    skip: number
    limit: number
}

export default function DataGrid({
    total,
    data,
    onChange,
    columns,
    onSelect,
    disabledCreateNewOnSelect,
    noResultsEl,
    loading,
    disableToolbar,
    hideFooterPagination,
    hideCreateButtonInToolbar,
}: Props) {
    const theme = useTheme()
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [queryOptions, setQueryOptions] = useState<QueryOptionsT>({
        skip: 0,
        limit: 5,
        sort: { _id: 1 },
        filter: {},
    })

    const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
        if (onSelect) {
            onSelect(selectionModel[0] as string)
        }
    }

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        let sort: SortT = { _id: 1 }
        if (sortModel.length) {
            sort = sortModel.reduce(
                (obj: SortT, item: GridSortItem) => ({
                    ...obj,
                    [item.field]: item.sort === 'desc' ? -1 : 1,
                }),
                {}
            )
        }

        setQueryOptions((prevRowCountState) => ({
            ...prevRowCountState,
            sort,
        }))
    }, [])

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        const f = filterModel.items[0]
        const filter: FilterT = f
            ? {
                  [f.columnField]: getFilter(f.operatorValue, f.value),
              }
            : {}

        setQueryOptions((prevRowCountState) => ({
            ...prevRowCountState,
            filter,
        }))
    }, [])

    const handlePageChange = (newPage: number) => {
        setPage(newPage)
        setQueryOptions((prevQueryOptions) => ({
            ...prevQueryOptions,
            skip: newPage * prevQueryOptions.limit,
        }))
    }

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize)
        setQueryOptions((prevQueryOptions) => ({ ...prevQueryOptions, limit: newPageSize }))
    }

    useEffect(() => {
        onChange(queryOptions)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryOptions])

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = useState(total || 0)
    useEffect(() => {
        setRowCountState((prevRowCountState) => (total !== undefined ? total : prevRowCountState))
    }, [total, setRowCountState])

    const { t } = useTranslation()
    const columnsWithHeaders = useMemo(() => {
        return columns.map((c) => {
            const headerName = c.headerName || t(c.field)

            let valueGetter = c.valueGetter
            if (c.type === 'singleSelect' && c.valueOptions) {
                valueGetter = ({ value }: GridValueGetterParams) => {
                    const valueOptions = c.valueOptions as any[]
                    const v = valueOptions.find((x) => x.value === value)
                    return v ? v.label : value
                }
            }
            return { ...c, headerName, valueGetter }
        })
    }, [columns, t])

    return (
        <div style={{ width: '100%' }}>
            <MuiDataGrid
                autoHeight
                getRowId={(row) => row._id}
                rows={data}
                columns={columnsWithHeaders}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                loading={loading}
                rowCount={rowCountState}
                rowsPerPageOptions={[5, 25]}
                onSelectionModelChange={onSelectionModelChange}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                hideFooterPagination={hideFooterPagination}
                getRowClassName={(param) => param.row.className}
                sx={{
                    padding: 4,
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    '& .MuiDataGrid-overlay': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    '& .MuiPaper-root-MuiDataGrid-paper': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        color: theme.palette.text.primary,
                    },

                    '& .pointer:hover': {
                        cursor: 'pointer',
                    },
                }}
                components={{
                    Toolbar: !disableToolbar
                        ? getGridToolbar({
                              onSelect,
                              disabledCreateNewOnSelect,
                              hideCreateButtonInToolbar,
                          })
                        : undefined,
                    NoResultsOverlay: getGridNoResultsOverlay({ noResultsEl }),
                }}
            />
        </div>
    )
}
