export const renderSimpleStyleValue = (style: SimpleStyleT, type: "css" | "inline") => {
    return style.render(style.value, style.unit, type).value
}

export const renderSimpleStyles = (styles: SimpleStyleT[], type: "css" | "inline") => {
	if (type === "inline") {
		let result = {}
		styles.forEach((style) => {
			const styleRender = style.render(style.value, style.unit, "inline")
			return Object.assign(result, {
				[styleRender.property]: styleRender.value,
			})
		})
		return result
	} else {
		let result: string = styles
			.map((style) => {
				const styleRender = style.render(style.value, style.unit, "css")
				return `${styleRender.property}:${styleRender.value}`
			})
			.join("; ")
		return result
	}
}
