import { selectUndoable } from '../../../helpers/selector.helpers'
import { useAppSelector } from '../../../hooks/useRedux'
import { filterAndSortSiblings, selectSortedRootObjects } from '../../../store/slices/objects.slice'
import { ScrollableContainer } from '../../../style/styles'
import EmptyPanel from '../../common/EmptyPanel'
import ObjectsTreeActions from './ObjectsTreeActions'
import ObjectTreeNode from './ObjectsTreeNode'

const ObjectsTree = () => {
    const objects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )

    if (objects.length === 0)
        return (
            <ScrollableContainer>
                <div>
                    <ObjectsTreeActions />
                </div>
                <EmptyPanel text1="No objects" text2="Create a new rectangle or text" />
            </ScrollableContainer>
        )
    else
        return (
            <ScrollableContainer>
                <div>
                    <ObjectsTreeActions />
                    {objects.map((object: AnySceneObjectT, index: number) => (
                        <ObjectTreeNode
                            key={index}
                            object={object}
                            numberOfObjects={filterAndSortSiblings(objects, object.parentId).length}
                            isHiding={false}
                        />
                    ))}
                </div>
            </ScrollableContainer>
        )
}

export default ObjectsTree
