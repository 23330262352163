import { Button, Container, Grid, Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataGrid from '../components/common/DataGrid'
import { License } from '../constants/license.enum'
import { dateToString } from '../helpers/date.helpers'
import { checkSuperAdminPermissions } from '../helpers/permissions.helpers'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getUsersAsync, verifyUsersAsync } from '../store/slices/users.slice'

export default function AdminUsers() {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const users = useAppSelector((state) => state.users)

    const usersAdminColumns = useMemo(() => {
        const handleVerify = (id: string) => {
            dispatch(verifyUsersAsync(id))
        }
        return [
            {
                field: 'email',
                headerName: t('user:userLicenseDataGrid.header.email'),
                minWidth: 200,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return row.email
                },
            },
            {
                field: 'license',
                sortable: false,
                headerName: t('user:userLicenseDataGrid.header.license'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return checkSuperAdminPermissions(row.permissions)
                        ? t('license:superAdmin')
                        : !row.license || !row.license?.paidLicense
                        ? t(`license:${License.Free}`)
                        : t(`license:${row.license?.paidLicense}`)
                },
            },
            {
                field: 'period',
                sortable: false,
                headerName: t('user:userLicenseDataGrid.header.period'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return checkSuperAdminPermissions(row.permissions)
                        ? t('license:period.limitless')
                        : `${
                              !row.license?.period
                                  ? '-'
                                  : row.license?.period === 12
                                  ? t('license:period.yearly')
                                  : t('license:period.monthly')
                          }`
                },
            },
            {
                field: 'status',
                sortable: false,
                headerName: t('user:userLicenseDataGrid.header.status'),
                minWidth: 250,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    const paidLicense = row.license?.paidLicense ?? License.Free
                    const license = row.license?.license ?? License.Free

                    let status =
                        row.license?.expired &&
                        new Date(row.license?.expired).getTime() < Date.now()
                            ? t('user:userLicenseDataGrid.expiredLicense')
                            : license === paidLicense
                            ? t('user:userLicenseDataGrid.active')
                            : `${t(`user:userLicenseDataGrid.pendingForVerify`)} ${t(
                                  `license:${license}`
                              )}`

                    return checkSuperAdminPermissions(row.permissions)
                        ? t('user:userLicenseDataGrid.active')
                        : `${status}`
                },
            },
            {
                field: 'expired',
                sortable: false,
                headerName: t('user:userLicenseDataGrid.header.expired'),
                valueGetter: ({ row }: GridValueGetterParams) => {
                    let expiredDate = row.license?.expired
                        ? dateToString(row.license?.expired)
                        : '-'

                    return expiredDate
                },
            },
            {
                field: 'verifyButton',
                sortable: false,
                disableColumnMenu: true,
                headerName: ' ',
                renderCell: ({ row }: GridRenderCellParams) => {
                    const paidLicense = row.license?.paidLicense ?? License.Free
                    const license = row.license?.license ?? License.Free
                    const superAdmin = checkSuperAdminPermissions(row.permissions)

                    return (
                        <>
                            <Tooltip placement={'right'} title={t('user:button:upgradeTooltip')}>
                                <span>
                                    <Button
                                        disabled={
                                            paidLicense === license &&
                                            row.license?.expired &&
                                            new Date(row.license?.expired).getTime() > Date.now()
                                        }
                                        color="success"
                                        onClick={() => handleVerify(row._id)}
                                    >
                                        {superAdmin
                                            ? t('user:button.verifyAdmin')
                                            : t('user:button.verify')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    )
                },
            },
        ]
    }, [dispatch, t])

    const handleNavigateToProfile = () => {
        navigate('/settings/profile')
    }

    return (
        <Container component="main" maxWidth="lg">
            <Grid container p={0} my={4} width={'100%'} justifyContent="space-between">
                <Grid item md={12} xs={12} mb={1}>
                    <Typography variant="h2">{t('user:usersAdmin')}</Typography>

                    <Button variant="text" onClick={handleNavigateToProfile}>
                        {t('user:button.backToProfile')}
                    </Button>
                </Grid>
                <Grid item md={12} py={2} sx={{ borderBottom: 1 }}></Grid>
                <Grid item md={12} mt={2}>
                    <DataGrid
                        data={users.data}
                        total={users.total}
                        onChange={(queryOptions) => dispatch(getUsersAsync(queryOptions))}
                        columns={usersAdminColumns}
                        loading={!users.status}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
