import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { createdAccountProfileAsync } from '../../store/slices/user.slice'
import LoadingButton from '../common/LoadingButton'
import LegalConsent from '../common/form/LegalConsent'
import TextField from '../common/form/TextField'
import Turnstile from '../common/form/Turnstile'
import { DEFAULT_SING_UP_VALUES, SignUpT, signUpSchema } from './SignupForm'

//component used for cases when the user was invited via email
const CreatedAccountForm = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.value)
    const formData = useMemo(() => {
        if (user?.email) {
            return { ...DEFAULT_SING_UP_VALUES, email: user.email }
        } else {
            return DEFAULT_SING_UP_VALUES
        }
    }, [user])

    const navigate = useNavigate()

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        mode: 'onBlur',
        schema: signUpSchema,
        defaultValues: formData,
    })

    const handleNavigate = () => {
        navigate('/')
    }
    useEffect(() => {
        if (!formData.email) {
            navigate('/login')
        }
    }, [formData, navigate])

    const onSubmit = async (data: SignUpT) => {
        await dispatch(createdAccountProfileAsync(data, handleNavigate))
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 1,
                }}
                p={4}
            >
                <Typography component="h1" variant="h3" mt={1}>
                    {t('user:createdAccount.title')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate mt={3}>
                    <TextField
                        control={control}
                        fullWidth
                        name="firstName"
                        error={errors.firstName}
                    />
                    <TextField
                        control={control}
                        fullWidth
                        name="lastName"
                        error={errors.lastName}
                    />
                    <TextField
                        disabled
                        control={control}
                        required
                        fullWidth
                        name="email"
                        type="email"
                        autoComplete="email"
                        error={errors.email}
                    />
                    <TextField
                        control={control}
                        name="password"
                        label={t('newPassword')}
                        required
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        error={errors.password}
                    />
                    <TextField
                        control={control}
                        name="passwordConfirmation"
                        label={t('newPasswordConfirmation')}
                        required
                        fullWidth
                        type="password"
                        error={errors.passwordConfirmation}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        my={2}
                    >
                        <LegalConsent actionName={t('user:createdAccount.consentAction')} />
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            loadingPosition="end"
                            endIcon={<></>}
                        >
                            {t('user:createdAccount.submit')}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
            <Turnstile control={control} name="token" action="signup" />
        </>
    )
}
export default CreatedAccountForm
