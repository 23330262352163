import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { t } from 'i18next'

interface Props {
    loading: boolean
    loaded: number
    total: number
    handleLoadMoreContent: () => void
}
function BottomPage({ loading, loaded, total, handleLoadMoreContent }: Props) {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="stretch"
            sx={{ mt: 2 }}
        >
            {loading ? (
                <CircularProgress color="primary" />
            ) : (
                <>
                    <Typography variant="body1" noWrap>
                        {` ${loaded} `}
                        {t('from')}
                        {` ${total} `}
                        {t('graphicsViewed')}
                        <LinearProgress
                            variant="determinate"
                            value={(loaded / total) * 100}
                            sx={{ my: 1 }}
                        />
                    </Typography>
                    <Button variant="text" onClick={() => handleLoadMoreContent()}>
                        {t('loadMore')}
                    </Button>
                </>
            )}
        </Grid>
    )
}

export default BottomPage
