import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as yup from 'yup'
import { SignUpT } from '../../components/forms/SignupForm'
import { ChangePasswordT } from '../../components/settings/ChangePassword'
import { Status } from '../../constants/status'
import { del, get, put } from '../../helpers/ajax.helpers'
import { getItem, setItem } from '../../helpers/storage.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { logoutAsync } from './session.slice'

export type EnumLicense = 'free' | 'individual' | 'business' | 'enterprise'

export type LicenseT = {
    license?: EnumLicense
    period?: number
    expired?: Date
    paidLicense?: EnumLicense
}
export type UserT = {
    _id: string
    email: string
    password?: string
    permissions: number
    firstName?: string
    lastName?: string
    isPasswordTemp?: boolean
    lastLogged?: Date
    isDeleted?: boolean
    license?: LicenseT
}

export type UserState = {
    value: UserT
    status?: string
}

const initialState: UserState = {
    value: getItem<any>('user'),
}

export const upgradeSchema = yup.object({
    period: yup.number(),
    license: yup.string(),
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUserPending: (state: UserState) => {
            state.status = undefined
        },
        getUserFulfilled: (state: UserState, action: PayloadAction<UserT | undefined>) => {
            state.status = Status.OK
            if (action.payload) {
                state.value = action.payload
            }
        },
        getUserRejected: (state: UserState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        updatePasswordPending: (state: UserState) => {
            state.status = undefined
        },
        updatePasswordFulfilled: (state: UserState) => {
            const user = { ...state.value, isPasswordTemp: false }
            setItem('user', user)
            state.status = Status.OK
            state.value = user
        },
        updatePasswordRejected: (state: UserState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    getUserFulfilled,
    getUserPending,
    getUserRejected,
    updatePasswordPending,
    updatePasswordFulfilled,
    updatePasswordRejected,
} = userSlice.actions
export default userSlice.reducer

export const getUserAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(getUserPending())
        const user = await get<UserT>('users/profile', undefined)
        setItem('user', user)
        dispatch(getUserFulfilled(user))
    } catch (error: any) {
        dispatch(getUserRejected(error.message))
    }
}

export const updateProfileAsync =
    (data: UserT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUserPending())
            await put('users/profile', data)
            const { success } = useToast()
            success('profileChangeSuccessful')
            dispatch(getUserAsync())
        } catch (error: any) {
            dispatch(
                getUserRejected(error.statusCode === 400 ? Status.EMAIL_EXISTS : error.message)
            )
        }
    }
export const createdAccountProfileAsync =
    (data: SignUpT, onNavigate: () => void): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUserPending())
            const user = await put<UserT>('users/profile', data)
            await put('users/password', { password: data.password })
            const newUser = { ...user, isPasswordTemp: false }
            const { success } = useToast()
            await dispatch(getUserFulfilled(newUser))
            setItem('user', newUser)
            await success('profileChangeSuccessful')
            onNavigate()
        } catch (error: any) {
            dispatch(getUserRejected(error.message))
        }
    }

export const updatePasswordAsync =
    (data: ChangePasswordT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(updatePasswordPending())
            await put('users/password', data)
            const { success } = useToast()
            success('passwordChangeSuccessful')
            dispatch(updatePasswordFulfilled())
        } catch (error: any) {
            dispatch(updatePasswordRejected(error.message))
        }
    }

export const deleteProfileAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(getUserPending())
        await del('users/profile', undefined)
        dispatch(logoutAsync())
        dispatch(getUserFulfilled())
        const { success } = useToast()
        success('profileDeleteSuccessful')
    } catch (error: any) {
        dispatch(getUserRejected(error.message))
    }
}

export const upgradeUserLicenseAsync =
    (user: UserT, data: { period: number; license: EnumLicense }): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUserPending())
            await put('users/license', data)
            let newUserData: UserT = {
                ...user,
                license: { ...user.license, period: data.period, license: data.license },
            }
            setItem('user', newUserData)
            dispatch(getUserFulfilled(newUserData))
            const { success, info } = useToast()
            success('user:successMsg.upgrade')
            info('user:infoMsg.waitForConfirmEmail')
        } catch (error: any) {
            dispatch(getUserRejected(error.message))
        }
    }
