import TextField from '@mui/material/TextField'
import { KeyboardEvent } from 'react'
import { useController } from '../../../hooks/useController'
import useKeyDown from '../../../hooks/useKeyDown'

interface Props {
    styleProperty: SimpleStyleT | GroupStyleI
    handleOnChange: (e: any) => void
}

const NumberInput = ({ styleProperty, handleOnChange }: Props) => {
    const { value, onChange } = useController(styleProperty.value)
    const { handleOnEnterKeyDown } = useKeyDown()

    const label: string =
        (styleProperty.input.label ?? styleProperty.property) +
        (styleProperty.unit ? ` (${styleProperty.unit})` : '')

    return (
        <TextField
            type={styleProperty.input.type}
            label={label}
            name={styleProperty.property}
            value={value}
            onChange={onChange}
            onBlur={handleOnChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                handleOnEnterKeyDown(e, handleOnChange)
            }
            InputProps={(styleProperty.input as NumberInputT).props}
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}

export default NumberInput
