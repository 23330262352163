import { MutableRefObject } from 'react'

interface Props {
    hiddenFileInputRef: MutableRefObject<HTMLInputElement | null>
    onChange: React.ChangeEventHandler<HTMLInputElement>
    multiple?: boolean
}

const LoadImageAction = ({ hiddenFileInputRef, onChange, multiple }: Props) => {
    return (
        <input
            id="input"
            type="file"
            ref={hiddenFileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={onChange}
            multiple={multiple}
        />
    )
}

export default LoadImageAction
