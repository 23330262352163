import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, Skeleton, Tooltip, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { License } from '../../constants/license.enum'
import { Role } from '../../constants/role.enum'
import { getFullNameString } from '../../helpers/string.helpers'
import useId from '../../hooks/useId'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { useWorkspacePermission } from '../../hooks/useWorkspacePermission'
import {
    CompanyT,
    CompanyUserT,
    UserRef,
    companySchema,
    deleteCompanyAsync,
    deleteUserFromCompanyAsync,
    submitCompanyAsync,
} from '../../store/slices/company.slice'
import { UsersT } from '../../store/slices/users.slice'
import AlertDialog from '../common/AlertDialog'
import SimpleDataGrid from '../common/SimpleDataGrid'
import TextField from '../common/form/TextField'
import MemberDialog from './MemberDialog'

interface Props {
    loading: boolean
    onDelete: () => void
    setOpenTransaction?: (value: boolean) => void
}

export default function CompanyForm({ onDelete, setOpenTransaction, loading }: Props) {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const company = useAppSelector((state) => state.company)
    const user = useAppSelector((state) => state.user)
    const [openDialog, setOpenDialog] = useState(false)

    const [companyData, setCompanyData] = useState<CompanyT>(company.data)
    const [companyUserData, setCompanyUserData] = useState<CompanyUserT>()
    const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)
    const [openRemoveUserAlert, setOpenRemoveUserAlert] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
    const [userId, setUserId] = useState<string>('')
    const [userFullNameRemove, setUserFullNameRemove] = useState<string>('')

    const {
        canUpdateWorkspace,
        canInviteUser,
        canRemoveUser,
        canDeleteWorkspace,
        canModifiedWorkspace,
    } = useWorkspacePermission(company.data)

    useEffect(() => {
        const setData = () => {
            if (company.data.user) {
                return company.data
            } else {
                return { ...company.data, user: user.value }
            }
        }
        return setCompanyData(setData())
    }, [company.data, user.value])

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useReduxForm({
        schema: companySchema,
        defaultValues: companyData,
    })

    const { setId } = useId()
    const onSubmitCompany = (data: CompanyT) => {
        dispatch(submitCompanyAsync(data, (id: string) => setId(id)))
    }

    const handleDelete = async () => {
        setOpenDialog(false)
        await dispatch(deleteCompanyAsync(company.data._id!))
        onDelete()
    }

    const handleRemoveUser = async () => {
        const data = company.data.usersRef?.find((u: UserRef) => u.id === userId)
        if (data) dispatch(deleteUserFromCompanyAsync(company.data._id, data))
        setOpenRemoveUserAlert(false)
    }

    const handleOpenRemoveUser = useCallback(
        (userId: string, event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            let userInWorkspace = company.data.users?.find((x: UsersT) => x._id === userId)
            setUserFullNameRemove(
                getFullNameString(userInWorkspace?.firstName, userInWorkspace?.lastName)
            )
            setUserId(userId)
            setOpenRemoveUserAlert(true)
        },
        [company.data.users]
    )

    const handleOpenInviteDialog = () => {
        setOpenInviteDialog(true)
    }

    const handleOpenEdit = useCallback(
        (id: string | null) => {
            if (
                company.data.usersRef.find((x) => x.id === id)?.role === Role.Owner ||
                !canUpdateWorkspace
            ) {
                return
            } else {
                const userRef = company.data.usersRef.find((x) => x.id === id)
                const user = company.data.users.find((x) => x._id === id)
                if (userRef && user) {
                    setCompanyUserData({
                        id: userRef?.id,
                        role: userRef?.role,
                        isDisabled: userRef?.isDisabled,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        user: user,
                    })
                }
                setOpenEditDialog(true)
            }
        },
        [canUpdateWorkspace, company.data.users, company.data.usersRef]
    )

    const userColumns = useMemo(
        () => [
            {
                field: 'name',
                headerName: t('company:userDataGrid.header.name'),
                width: 150,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return !row.firstName && !row.lastName
                        ? t('transaction:anonymous')
                        : getFullNameString(row.firstName, row.lastName)
                },
            },
            {
                field: 'email',
                headerName: t('company:userDataGrid.header.email'),
                width: 200,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    return `${row.email}`
                },
            },
            {
                field: 'role',
                headerName: t('company:userDataGrid.header.role'),
                width: 120,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    const userRole = company.data.usersRef?.find(
                        (u: UserRef) => u.id === row._id
                    )?.role
                    return t(`company:statusRole.${userRole}`)
                },
            },
            {
                field: 'status',
                headerName: t('company:userDataGrid.header.status'),
                width: 100,
                valueGetter: ({ row }: GridValueGetterParams) => {
                    const isActiveUser = company.data.usersRef?.find(
                        (u: UserRef) => u.id === row._id
                    )?.isDisabled
                    return `${
                        !!isActiveUser && !row.lastLogged
                            ? t('company:userDataGrid.cell.pendingInvite')
                            : t('company:userDataGrid.cell.active')
                    }`
                },
            },
            {
                field: 'removeButton',
                sortable: false,
                headerName: ' ',
                width: 200,
                renderCell: ({ row }: GridRenderCellParams) => {
                    let isOwner =
                        company.data.usersRef?.find((u: UserRef) => u.id === row._id)?.role === 1024
                    return (
                        <>
                            <Tooltip placement={'right'} title={t('company:button:removeTooltip')}>
                                <span>
                                    <Button
                                        sx={{ px: 2, py: 1 }}
                                        variant="text"
                                        disabled={isOwner || !canRemoveUser}
                                        onClick={(e) => handleOpenRemoveUser(row._id, e)}
                                    >
                                        {t('company:button.remove')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    )
                },
            },
        ],
        [canRemoveUser, company.data.usersRef, handleOpenRemoveUser, t]
    )
    const dataWithClassName = company.data.users.map((user) => ({
        ...user,
        className:
            company.data.usersRef?.find((u: UserRef) => u.id === user._id)?.role === Role.Owner ||
            !canRemoveUser
                ? ''
                : 'pointer',
    }))

    const memberSectionText = () => {
        switch (company.data.license) {
            case License.Enterprise:
                return t('company:detail.textSectionMemberEnterprise')
            case License.Business:
                return t('company:detail.textSectionMemberBusiness')
            case License.Individual:
                return t('company:detail.textSectionMemberIndividual')
            default:
                return ''
        }
    }

    return (
        <>
            <Grid container p={0} my={4} width={'100%'} justifyContent="space-between">
                <Grid item md={3} xs={12} mb={1}>
                    <Typography variant="h5">{t('company:detail.titleSectionInfo')}</Typography>
                    <Typography variant="body1">{t('company:detail.textSectionInfo')}</Typography>
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={12}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmitCompany)}
                        noValidate
                        sx={{
                            padding: 4,
                            borderRadius: 1,
                        }}
                    >
                        {loading ? (
                            <Skeleton variant="rounded" sx={{ height: 40 }}></Skeleton>
                        ) : (
                            <TextField
                                control={control}
                                required
                                name="name"
                                fullWidth
                                autoFocus={false}
                                error={errors.name}
                                disabled={!canModifiedWorkspace || isSubmitting}
                            />
                        )}
                        <Button
                            sx={{ mt: 2 }}
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting || !canModifiedWorkspace || !isDirty}
                        >
                            {t('submit')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container p={0} my={4} width={'100%'} justifyContent="space-between">
                <Grid item md={3} xs={12} mb={1}>
                    <Typography variant="h5">{t('company:detail.titleSectionMember')}</Typography>
                    <Typography variant="body1">
                        {t('company:detail.textSectionMember')}
                        {memberSectionText()}
                    </Typography>
                    <Button
                        sx={{ mt: 2 }}
                        onClick={handleOpenInviteDialog}
                        variant="contained"
                        disabled={isSubmitting || loading || !canInviteUser}
                    >
                        {t('company:button.invite')}
                    </Button>
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={12}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <SimpleDataGrid
                        columns={userColumns}
                        total={company.data.users.length}
                        data={dataWithClassName}
                        onChange={() => {}}
                        onSelect={handleOpenEdit}
                        loading={loading}
                        hideFooterPagination={true}
                    />
                </Grid>
            </Grid>
            <Grid container p={0} my={4} width={'100%'} justifyContent="space-between">
                <Grid item md={3} xs={12} mb={1}>
                    <Typography variant="h5">{t('company:detail.titleSectionDelete')}</Typography>
                    <Typography variant="body1">{t('company:detail.textSectionDelete')}</Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Button
                        color="error"
                        variant="text"
                        onClick={() => setOpenDialog(true)}
                        startIcon={<DeleteIcon />}
                        disabled={loading || !canDeleteWorkspace}
                    >
                        <Typography variant="body2">{t('company:button.delete')}</Typography>
                    </Button>
                </Grid>
            </Grid>
            {openInviteDialog && (
                <MemberDialog
                    dialogTitle={t('company:dialogs.memberInviteTitle')}
                    company={company.data}
                    open={openInviteDialog}
                    onClose={() => setOpenInviteDialog(false)}
                    submitButtonTxt={t('company:button:invite')}
                />
            )}
            {openEditDialog && (
                <MemberDialog
                    dialogTitle={t('company:dialogs.memberDetailTitle')}
                    company={company.data}
                    companyUserData={companyUserData}
                    open={openEditDialog}
                    disableWhenEdit={true}
                    onClose={() => setOpenEditDialog(false)}
                    submitButtonTxt={t('company:button:save')}
                />
            )}
            <AlertDialog
                open={openRemoveUserAlert}
                title={t('company:dialogs.removeTitle')}
                content={`'${userFullNameRemove}' ${t('company:dialogs.removeQuestion1')} '${
                    company.data.name
                }'
                ${t('company:dialogs.removeQuestion2')}`}
                onDisagree={() => setOpenRemoveUserAlert(false)}
                onAgree={handleRemoveUser}
                txtDisagree={t('disagree')}
                txtAgree={t('company:dialogs.removeButton')}
                colorAgree="error"
            />
            <AlertDialog
                open={openDialog}
                title={`${t('company:deleteTitle')} '${company.data.name}' ?`}
                content={t('company:deleteQuestion')}
                onDisagree={() => setOpenDialog(false)}
                onAgree={handleDelete}
                txtDisagree={t('disagree')}
                txtAgree={t('delete')}
                colorAgree="error"
            />
        </>
    )
}
