import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import * as yup from 'yup'
import Link from '../../components/common/Link'
import TextField from '../../components/common/form/TextField'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { signupAsync } from '../../store/slices/session.slice'
import LoadingButton from '../common/LoadingButton'
import LegalConsent from '../common/form/LegalConsent'
import Turnstile from '../common/form/Turnstile'

export const DEFAULT_SING_UP_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    token: '',
}
export const signUpSchema = yup
    .object({
        firstName: yup.string().max(255),
        lastName: yup.string().max(255),
        email: yup.string().email('emailValidation').max(255).required('emailRequired'),
        password: yup.string().required('passwordRequired').min(6, 'passwordMin'),
        token: yup.string().required(),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), null], 'passwordMatch')
            .required('passwordRequired'),
    })
    .required()

export type SignUpT = yup.InferType<typeof signUpSchema>

const SignupForm = () => {
    const theme = useTheme()
    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        mode: 'onBlur',
        schema: signUpSchema,
        defaultValues: DEFAULT_SING_UP_VALUES,
    })

    const dispatch = useAppDispatch()
    const onSubmit = async (data: SignUpT) => {
        data.passwordConfirmation = ''
        await dispatch(signupAsync(data))
    }

    const session = useAppSelector((state) => state.session)
    if (session.data) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 1,
                }}
                p={4}
            >
                <Typography component="h1" variant="h3" mt={1}>
                    {t('user:signupTitle')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate mt={3}>
                    <TextField
                        control={control}
                        fullWidth
                        name="firstName"
                        error={errors.firstName}
                    />
                    <TextField
                        control={control}
                        fullWidth
                        name="lastName"
                        error={errors.lastName}
                    />
                    <TextField
                        control={control}
                        required
                        fullWidth
                        name="email"
                        type="email"
                        autoComplete="email"
                        error={errors.email}
                    />
                    <TextField
                        control={control}
                        name="password"
                        required
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        error={errors.password}
                    />
                    <TextField
                        control={control}
                        name="passwordConfirmation"
                        required
                        fullWidth
                        type="password"
                        error={errors.passwordConfirmation}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        my={2}
                    >
                        <LegalConsent actionName={t('user:signupConsent')} />
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            loadingPosition="end"
                            endIcon={<></>}
                        >
                            {t('user:signupSubmit')}
                        </LoadingButton>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            mb={3}
                            mt={2}
                        >
                            <Grid item>
                                <Typography variant="body2">{t('user:signupToLogin')}</Typography>
                            </Grid>
                            <Grid item>
                                <Link href="/login" variant="body2" mb={3} mt={1}>
                                    {t('user:signupToLoginButton')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Turnstile control={control} name="token" action="signup" />
        </>
    )
}
export default SignupForm
