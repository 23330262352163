import LoginForm from '../components/forms/LoginForm'
import LoginLayout from '../components/layouts/LoginLayout'

export default function Login() {
    return (
        <LoginLayout>
            <LoginForm />
        </LoginLayout>
    )
}
