export const isImageEditable: AttributeT = {
    property: "isImageEditable",
	value: true,
    input: {
        type: "switch",
        label: "editable image",
        props: ""
    }
}

export const isTextEditable: AttributeT = {
    property: "isTextEditable",
	value: true,
    input: {
        type: "switch",
        label: "editable text",
        props: ""
    }
}

export const textSizing: AttributeT = {
    property: 'textSizing',
    value: 'fixed',
    input: {
        type: 'select',
        props: ['fixed', 'fitting' ],
        label: 'text sizing',
    },
}
