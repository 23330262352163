import CloseIcon from '@mui/icons-material/Close'
import { Chip, FormControl, Grid, Input, InputLabel } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
    control: Control<any, any>
    name: string
    label?: string
    fullWidth?: boolean
    disabled?: boolean
    required?: boolean
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
}
function Tags({ control, name, label, fullWidth, disabled, required, error }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [tag, setTag] = useState('')
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [showTF, setShowTF] = useState(false)

                const handleKeyDownTag = (event: any) => {
                    if (['Enter', 'Tab', ' ', ',', '.'].includes(event.key)) {
                        const newSelectedItem = [...(field.value || [])]
                        const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

                        if (duplicatedValues !== -1) {
                            setTag('')
                            event.preventDefault()
                            return
                        }

                        if (!event.target.value.replace(/\s/g, '').length) {
                            event.preventDefault()
                            return
                        }

                        newSelectedItem.push(event.target.value.trim())
                        field.onChange(newSelectedItem)
                        setTag('')
                        event.preventDefault()
                    }
                    if (field.value.length && !tag.length && event.key === 'Backspace') {
                        field.onChange(field.value.slice(0, field.value.length - 1))
                    }
                }

                const handleDeleteTag = (item: string) => () => {
                    const newSelectedItem = [...(field.value || [])]
                    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
                    field.onChange(newSelectedItem)
                }

                return (
                    <FormControl
                        fullWidth={fullWidth}
                        required={required}
                        error={Boolean(error)}
                        disabled={disabled}
                        onClick={() => setShowTF(true)}
                        onBlur={() => setShowTF(false)}
                    >
                        <Grid
                            container
                            direction="column"
                            sx={{
                                p: 1,
                                backgroundColor: theme.palette.grey[600],
                                borderRadius: 0.5,
                            }}
                        >
                            <Grid item xs={12} minHeight={40}>
                                <InputLabel size="small" id={name}>
                                    {label || t(name)}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                {field.value?.map((item: string, index: number) => (
                                    <Chip
                                        key={index.toString()}
                                        label={item}
                                        onDelete={handleDeleteTag(item)}
                                        deleteIcon={<CloseIcon />}
                                        size="small"
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                {showTF && (
                                    <Input
                                        type="text"
                                        value={tag}
                                        onKeyDown={handleKeyDownTag}
                                        onChange={(event) => setTag(event.target.value)}
                                        fullWidth
                                        name={name}
                                        id={name}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                )
            }}
        />
    )
}
export default Tags
