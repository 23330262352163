import { useMemo } from 'react'
import { selectUndoable } from '../helpers/selector.helpers'
import { addCanvasAction } from '../store/slices/canvas.slice'
import { useAppDispatch, useAppSelector } from './useRedux'

export const useCanvas = () => {
    const dispatch = useAppDispatch()
    const editor = useAppSelector((state) => state.editor.value)
    const addCanvas = (canvas: CanvasI) => dispatch(addCanvasAction({ canvas }))
    const canvas: CanvasI = useAppSelector((state) => selectUndoable(state).canvas.value!)
    const canvasResolution = useMemo(() => {
        return {
            ...canvas,
            width: editor.settings.canvasResolution[0],
            height: editor.settings.canvasResolution[1],
        }
    }, [canvas, editor.settings.canvasResolution])

    return {
        addCanvas,
        canvasResolution,
    }
}
