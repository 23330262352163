import { Box, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />
})

interface Props {
    open: boolean
    title: string
    content?: string
    onDisagree: () => void
    onAgree: () => void
    txtDisagree: string
    txtAgree: string
    colorAgree?: 'primary' | 'secondary' | 'error' | 'warning' | 'inherit' | 'info' | 'success'
}

const AlertDialog = ({
    open,
    title,
    content,
    onDisagree,
    onAgree,
    txtDisagree,
    txtAgree,
    colorAgree,
}: Props) => {
    const theme = useTheme()
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onDisagree}
            aria-describedby="alert-dialog-slide-description"
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    minWidth: { lg: 500, sm: 'auto' },
                    pt: 1,
                    pl: 1,
                    pb: 2,
                    pr: 2,
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDisagree} variant="text" color="inherit">
                        {txtDisagree}
                    </Button>
                    <Button onClick={onAgree} color={colorAgree === null ? 'primary' : colorAgree}>
                        {txtAgree}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default AlertDialog
