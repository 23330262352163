export const swapChildIds = (childIds: string[], child1Id: string, child2Id: string): string[] => {
	const child1Index: number = childIds.indexOf(child1Id)
	const child2Index: number = childIds.indexOf(child2Id)

	// prohod dva childId v poli childIds
	var temp = childIds[child1Index]
	childIds[child1Index] = childIds[child2Index]
	childIds[child2Index] = temp

	return childIds
}
