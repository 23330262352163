import TextField from '@mui/material/TextField'
import { KeyboardEvent } from 'react'
import { useController } from '../../../hooks/useController'
import useKeyDown from '../../../hooks/useKeyDown'

interface Props {
    fullWidth?: boolean
    onChange: (e: any) => void
    label: JSX.Element | 'text'
    value: string
    name: string
}

const TextArea = ({
    fullWidth,
    onChange: handleOnChange,
    value: defaultValue,
    label,
    name,
}: Props) => {
    const { value, onChange } = useController(defaultValue)
    const { handleOnEnterKeyDown } = useKeyDown()

    return (
        <TextField
            fullWidth={fullWidth}
            multiline
            rows={2}
            type="text"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={handleOnChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                handleOnEnterKeyDown(e, handleOnChange)
            }
        />
    )
}

export default TextArea
