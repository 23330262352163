import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import { Tooltip, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { forwardRef, memo, useMemo } from 'react'
import { LabelCodeTrigger } from '../../../../data/defaults/codetriggers.types.defaults'
import { createTimelineLabel } from '../../../../helpers/creators.helpers'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import useFramerate from '../../../../hooks/useFramerate'
import { useAppSelector } from '../../../../hooks/useRedux'
import useTimeStepsDensity from '../../../../hooks/useTimeStepsDensity'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { filterTimelineLabelsInTime } from '../../../../store/slices/timeline.slice'
import { TIMELINE_TWEEN } from '../../../../style/sizing'
import RightClickContextMenu, { MenuItemT } from '../../../common/ContextMenu/RightClickContextMenu'
import CodeTriggerEditor from '../CodeTriggerEditor'

type Props = {
    width: number
    index: number
}

const TimeStamp = memo(
    forwardRef<HTMLDivElement, Props>(({ width, index }, ref) => {
        const theme = useTheme()
        const timeline: TimelineI = useAppSelector((state) => selectUndoable(state).timeline.value)
        const { resultTimeFramerate } = useFramerate()
        const {
            addTimelineLabel,
            delTimelineLabel,
            updTimelineLabelCode,
            seekMasterTimeline,
            updateTimeline,
            labelsInTimeStamp,
        } = useTimelineActions()

        const handleSetFreezeTime = (time: number) => {
            updateTimeline('freezeTime', time)
        }
        const handleClearFreezeTime = () => {
            updateTimeline('freezeTime', null)
        }

        const [editorIsOpen, openEditor] = React.useState<boolean>(false)
        const [editorCode, setEditorCode] = React.useState<CodeTriggerT | null>(null)
        const [editorLabel, setEditorLabel] = React.useState<string | null>(null)

        const handleCloseEditor = () => {
            openEditor(false)
        }
        const handleSaveLabelCode = (newCode: CodeTriggerT) => {
            if (newCode.scope !== 'label') return
            if (!editorLabel) return

            if (newCode.type === 'init') {
                updTimelineLabelCode(editorLabel, newCode)
            }
        }
        const handleEditLabelCode = (label: string) => {
            const labelCode = timeline.labels.filter((lbl) => {
                return lbl.title === label
            })[0].onLabelCode

            setEditorCode(labelCode ? labelCode : LabelCodeTrigger)
            setEditorLabel(label)
            openEditor(true)
        }

        const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
        const tweensPerSec: number = timelinePreferences.timelineTweensPerSec

        const duration: number = useTimelineActions().masterTimelineDuration
        const effectiveDuration: number = useTimelineActions().effectiveTimelineDuration
        const { timeStepsDensity } = useTimeStepsDensity()
        const timelineRowWidth: number = width
        const tweenWidth: number = duration > 0 ? timelineRowWidth / duration : timelineRowWidth // width per sec
        const timeStampWidth: number = tweenWidth / tweensPerSec - 2
        const timeStepX: number = tweenWidth / tweensPerSec

        const timeStampFromTime = index / tweensPerSec
        const timeStampToTime = (index + 1) / tweensPerSec
        const timeStampDuration = 1.0 / tweensPerSec
        const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)

        const labelsInTimeStampByIndex = (): TimelineLabelI[] => {
            return labelsInTimeStamp(index === 0 ? -1 : timeStampFromTime, timeStampToTime)
        }

        const handleAddLabel = (time: number, type: TimelineLabelTypeT, jumpto?: string) => {
            addTimelineLabel(
                createTimelineLabel(
                    time,
                    type,
                    resultTimeFramerate(time).toString() + ' ' + type,
                    jumpto
                )
            )
        }

        const handleDeleteLabel = (title: string) => {
            delTimelineLabel(title)
        }

        const handleSeekTimeStamp = () => {
            if (timeStampToTime <= effectiveDuration) seekMasterTimeline(timeStampToTime)
        }

        const timelineLabelColor = (label: TimelineLabelI): string => {
            return timeline.freezeTime === label.time
                ? theme.palette.error.light
                : label.type === 'pause'
                ? theme.palette.warning.light
                : label.type === 'jump'
                ? theme.palette.info.main
                : label.type === 'refresh'
                ? theme.palette.success.light
                : theme.palette.action.active
        }

        const getLabelMenuItems = (label: TimelineLabelI): MenuItemT[] => {
            return label.type === 'refresh'
                ? []
                : [
                      {
                          icon: <DataObjectRoundedIcon />,
                          title: 'Edit ' + resultTimeFramerate(label.time) + ' ' + label.type,
                          onClick: () => handleEditLabelCode(label.title),
                          divider: false,
                      },
                      {
                          icon: <DeleteRoundedIcon />,
                          title: 'Delete ' + resultTimeFramerate(label.time) + ' ' + label.type,
                          onClick: () => handleDeleteLabel(label.title),
                          divider: true,
                      },
                  ]
        }

        const getJumpToLabelsSubmenu = (titlePrefix: string, hidden: boolean): MenuItemT[] => {
            return timeline.labels
                ? timeline.labels
                      .slice()
                      .sort((a: TimelineLabelI, b: TimelineLabelI) => {
                          return a.time - b.time
                      })
                      .map((lbl) => {
                          return {
                              icon: <RepeatRoundedIcon />,
                              title:
                                  titlePrefix +
                                  ': Add jump to ' +
                                  resultTimeFramerate(lbl.time).toString() +
                                  ' label',
                              onClick: () => handleAddLabel(timeStampToTime, 'jump', lbl.title),
                              hidden,
                          }
                      })
                : []
        }

        const getLabelsMenuItemsByIndex = (): MenuItemT[] => {
            const emptyLabelsAtZero =
                index === 0 && filterTimelineLabelsInTime(timeline, 0)?.length === 0
            const emptyLabelsAtTimeStamp =
                filterTimelineLabelsInTime(timeline, timeStampToTime)?.length === 0

            let menuItems: MenuItemT[] = [
                {
                    icon: <StopCircleRoundedIcon />,
                    title: 'Clear freeze at ' + resultTimeFramerate(timeStampToTime),
                    onClick: () => handleClearFreezeTime(),
                    divider: true,
                    hidden: timeStampToTime !== timeline.freezeTime,
                },
                {
                    icon: <StopRoundedIcon />,
                    title: 'Freeze at ' + resultTimeFramerate(timeStampToTime),
                    onClick: () => handleSetFreezeTime(timeStampToTime),
                    divider: true,
                    hidden: timeStampToTime === timeline.freezeTime,
                },
                ...labelsInTimeStampByIndex().flatMap((label) => getLabelMenuItems(label)),
                {
                    icon: <AddRoundedIcon />,
                    title: '0: Add continue label',
                    onClick: () => handleAddLabel(0, 'continue'),
                    divider: false,
                    hidden: !emptyLabelsAtZero,
                },
                {
                    icon: <PauseRoundedIcon />,
                    title: '0: Add pause label',
                    onClick: () => handleAddLabel(0, 'pause'),
                    divider: true,
                    hidden: !emptyLabelsAtZero,
                },
                ...getJumpToLabelsSubmenu('0', !emptyLabelsAtZero),
                {
                    icon: <AddRoundedIcon />,
                    title: resultTimeFramerate(timeStampToTime) + ': Add continue label',
                    onClick: () => handleAddLabel(timeStampToTime, 'continue'),
                    divider: false,
                    hidden: !emptyLabelsAtTimeStamp,
                },
                {
                    icon: <PauseRoundedIcon />,
                    title: resultTimeFramerate(timeStampToTime) + ': Add pause label',
                    onClick: () => handleAddLabel(timeStampToTime, 'pause'),
                    divider: true,
                    hidden: !emptyLabelsAtTimeStamp,
                },
                ...getJumpToLabelsSubmenu(
                    resultTimeFramerate(timeStampToTime).toString(),
                    !emptyLabelsAtTimeStamp
                ),
            ]

            return menuItems.filter((item) => !item.hidden)
        }

        const getTimeStampWidth = () => {
            return [...Array(timeStepsDensity)].length - index === 0 ? tweenWidth : timeStampWidth
        }

        const getTimeStampBackground = (width: number): string => {
            let background: string = ''
            const timelineLabelWidth = 6
            const baseColor =
                timeStampFromTime < effectiveDuration
                    ? theme.palette.grey[400]
                    : theme.palette.grey[600]

            const labels: TimelineLabelI[] = labelsInTimeStampByIndex()

            if (
                timeline.freezeTime &&
                timeline.freezeTime >= timeStampFromTime &&
                timeline.freezeTime <= timeStampToTime
            ) {
                labels.push({
                    time: timeline.freezeTime,
                    type: 'continue',
                    title: '',
                    onLabelCode: null,
                })
                labels.sort((a: TimelineLabelI, b: TimelineLabelI) => {
                    return a.time - b.time
                })
            }

            if (labels.length > 0) {
                background = `linear-gradient(to right, ${baseColor} 0px,`

                labels.forEach((lbl) => {
                    background += `${baseColor} ${
                        (width * (lbl.time - timeStampFromTime)) / timeStampDuration -
                        timelineLabelWidth / 2
                    }px,${timelineLabelColor(lbl)} ${
                        (width * (lbl.time - timeStampFromTime)) / timeStampDuration -
                        timelineLabelWidth / 2
                    }px,${timelineLabelColor(lbl)} ${
                        (width * (lbl.time - timeStampFromTime)) / timeStampDuration +
                        timelineLabelWidth / 2
                    }px,${baseColor} ${
                        (width * (lbl.time - timeStampFromTime)) / timeStampDuration +
                        timelineLabelWidth / 2
                    }px,`
                })

                background += `${baseColor} ${width}px)`
            } else {
                background = baseColor
            }

            return background
        }
        const displayTweenValue = useMemo(() => {
            if (timeStampWidth >= 20) {
                if (framerate === 50) {
                    return Math.round(timeStampToTime / 0.02)
                } else if (framerate === 25) {
                    return Math.round(timeStampToTime / 0.04)
                } else if (framerate === 1) {
                    return timeStampToTime
                }
            } else {
                return '.'
            }
        }, [framerate, timeStampToTime, timeStampWidth])

        return (
            <Grid
                ref={ref}
                container
                key={index}
                alignItems="center"
                onClick={(e) => {
                    handleSeekTimeStamp()
                }}
                sx={{
                    position: 'absolute',
                    left: index * timeStepX + 2 + 'px',
                    background: getTimeStampBackground(getTimeStampWidth()),
                    borderRadius: TIMELINE_TWEEN.borderRadius,
                    width: getTimeStampWidth() + 'px',
                    cursor: timeStampToTime <= effectiveDuration ? 'pointer' : '',
                }}
            >
                {timeStampFromTime >= effectiveDuration ? (
                    <Grid
                        item
                        sx={{
                            position: 'relative',
                            textAlign: 'center',
                            height: '100%',
                            width:
                                [...Array(timeStepsDensity)].length - index === 0
                                    ? tweenWidth + 'px'
                                    : timeStampWidth + 'px',
                        }}
                    >
                        <Typography variant="caption" color={theme.palette.text.primary}>
                            {displayTweenValue}
                        </Typography>
                    </Grid>
                ) : (
                    <RightClickContextMenu
                        menuLabel={
                            <Tooltip
                                title={
                                    (labelsInTimeStampByIndex().length > 1 ? 'labels ' : '') +
                                    labelsInTimeStampByIndex().map((lbl) => {
                                        return lbl.type === 'refresh'
                                            ? lbl.type + ' ' + lbl.title
                                            : lbl.title +
                                                  (lbl.jumpto !== undefined && lbl.jumpto !== ''
                                                      ? ' to ' + lbl.jumpto
                                                      : '') +
                                                  ','
                                    })
                                }
                            >
                                <Grid
                                    item
                                    sx={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        height: '100%',
                                        width:
                                            [...Array(timeStepsDensity)].length - index === 0
                                                ? tweenWidth + 'px'
                                                : timeStampWidth + 'px',
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        color={theme.palette.text.primary}
                                    >
                                        {displayTweenValue}
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        }
                        data={getLabelsMenuItemsByIndex()}
                    />
                )}
                <CodeTriggerEditor
                    open={editorIsOpen}
                    title="Code to be uploaded into the HTML/JS engine"
                    code={editorCode}
                    onSaveHandler={handleSaveLabelCode}
                    onCloseHandler={handleCloseEditor}
                />
            </Grid>
        )
    })
)

export default TimeStamp
