import { ChangeEvent } from 'react'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

export type SettingsSwitchT = {
    label: string,
    checked: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

interface Props {
    switches: SettingsSwitchT[]
}

const PopupSettingsSwitches = ({ switches }: Props) => {
    return (
        <Grid container direction="column" alignItems="flex-start" justifyContent="space-between" p={2}>
            {
                switches.map((s: SettingsSwitchT, index: number) => {
                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Switch
                                    size="small"
                                    checked={s.checked}
                                    onChange={s.onChange}
                                    color="primary"
                                />
                            }
                            labelPlacement="end"
                            label={s.label}
                        />
                    )
                })
            }
        </Grid>
    )
}

export default PopupSettingsSwitches
