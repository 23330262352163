import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import TextField from '../../components/common/form/TextField'
import config from '../../config'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { loginAsync } from '../../store/slices/session.slice'
import LoadingButton from '../common/LoadingButton'
const loginSchema = yup
    .object({
        email: yup.string().email('emailValidation').max(255).required('emailRequired'),
        password: yup.string().max(255).required('passwordRequired'),
    })
    .required()

type LoginT = yup.InferType<typeof loginSchema>

const LoginForm = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const location = useLocation()

    const [searchParams] = useSearchParams()
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            email: searchParams.get('email') || '',
            password: '',
        },
    })

    const dispatch = useAppDispatch()
    const onSubmit = async (data: LoginT) => {
        await dispatch(loginAsync(data.email, data.password))
    }

    const session = useAppSelector((state) => state.session)
    if (session.data) {
        if (location.state?.from) {
            // navigate(location.state.from)
            return <Navigate to={location.state.from} />
        } else {
            return <Navigate to="/app/graphics" state={{ from: '/login' }} />
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
                padding: 4,
                borderRadius: 1,
            }}
        >
            <Typography component="h1" variant="h3" mt={2}>
                {t('user:loginTitle')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 4 }}>
                <TextField
                    control={control}
                    required
                    fullWidth
                    name="email"
                    type="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email}
                />
                <TextField
                    control={control}
                    name="password"
                    required
                    fullWidth
                    type="password"
                    autoComplete="current-password"
                    error={errors.password}
                />
                <Link href="/forgotten-password" variant="body2" ml={1} mt={4}>
                    {/* 
                    TO DO - it is able to loading when href to another side ?
                    or how i can indicate i am at state on navigate to another URL
                    */}
                    {t('user:forgotPassword')}
                </Link>
                <Box
                    sx={{
                        marginTop: 2,
                        marginbottom: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="end"
                        endIcon={<></>}
                    >
                        {t('user:loginSubmit')}
                    </LoadingButton>
                    {!config.signUpDisabled && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            mb={3}
                            mt={1}
                        >
                            <Grid item>
                                <Typography variant="body2">{t('user:loginToSignup')}</Typography>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {/* 
                                TO DO - it is able to loading when href to another side ?
                                or how i can indicate i am at state on navigate to another URL
                                */}
                                    {t('user:loginToSignupButton')}
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
export default LoginForm
