import EmptyObject from "../../EmptyObject"
import GroupFieldRow from "./GroupFieldRow"
interface Props {
    object: AnySceneObjectT
    groupStyles: GroupStyleI[]
    properties: GroupStyleT[]
    handleSetGroupValue: (index: number, property: "property" | "value", value: any) => void
    handleDeleteGroup: (index: number) => void
    handleSetStyleValue?: (property: any, value: any) => void
    handleSetAttributeValue?: (property: any, value: any) => void
}

const GroupFields = ({ object, groupStyles, properties, handleSetGroupValue, handleDeleteGroup, handleSetStyleValue, handleSetAttributeValue }: Props) => {

    if (groupStyles.length === 0 && properties.length > 0)
        return (
            <EmptyObject text={`No ${properties[0].type}s`} />
        )
    else
        return (
            <>
                {
                    groupStyles.map((style: GroupStyleI, index: number) => (
                        <div key={index}>
                            <GroupFieldRow
                                object={object}
                                key={index}
                                groupStyle={style}
                                properties={properties}
                                handleSetValue={handleSetGroupValue}
                                handleDeleteGroup={handleDeleteGroup}
                                handleSetStyleValue={handleSetStyleValue}
                                handleSetAttributeValue={handleSetAttributeValue}
                            />
                        </div>
                    ))
                }
            </>
        )
}

export default GroupFields
