import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useAppDispatch } from '../../hooks/useRedux'
import { resetConfirmAsync } from '../../store/slices/session.slice'
import LoadingButton from '../common/LoadingButton'
import TextField from '../common/form/TextField'

const newPasswordSchema = yup
    .object({
        otp: yup.string().required('otpRequired'),
        password: yup.string().required('passwordRequired').min(6, 'passwordMin'),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), null], 'passwordMatch')
            .required('passwordRequired'),
    })
    .required()

export type NewPasswordT = yup.InferType<typeof newPasswordSchema>

const NewPasswordForm = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(newPasswordSchema),
        defaultValues: {
            password: '',
            passwordConfirmation: '',
            otp: '',
        },
    })

    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const onSubmit = async (data: NewPasswordT) => {
        const email = searchParams.get('email') || ''
        dispatch(resetConfirmAsync(email, data.otp, data.password))
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
                padding: 4,
                borderRadius: 1,
            }}
        >
            <Typography component="h1" variant="h3" mt={2}>
                {t('user:newPasswordTitle')}
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 4, width: '100%' }}
            >
                <TextField
                    control={control}
                    name="otp"
                    label={t('otp')}
                    required
                    fullWidth
                    error={errors.otp}
                />
                <TextField
                    control={control}
                    name="password"
                    label={t('newPassword')}
                    required
                    fullWidth
                    type="password"
                    autoComplete="current-password"
                    error={errors.password}
                />
                <TextField
                    control={control}
                    name="passwordConfirmation"
                    label={t('newPasswordConfirmation')}
                    required
                    fullWidth
                    type="password"
                    error={errors.passwordConfirmation}
                />
                <Box sx={{ marginTop: 3 }}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="end"
                        endIcon={<></>}
                    >
                        {t('user:newPasswordSubmit')}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}
export default NewPasswordForm
