import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton } from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { swapArrayElements } from '../../../helpers/array.helpers'
import TextField from '../../common/form/TextField'

interface Props {
    key: string
    index: number
    control: Control<any, any>
}

const AttributeRow = ({ key, control, index }: Props) => {
    const { t } = useTranslation()
    const attributes = useController({ name: 'attributes', control })
    const disableMoveUp = index === 0
    const disableMoveDown = index + 1 === attributes.field.value?.length

    const handleMoveAttribute = (index: number, newIndex: number) => {
        const newAttributes = swapArrayElements(attributes.field.value, index, newIndex)
        attributes.field.onChange(newAttributes)
    }

    const handleRemoveAttribute = (index: number) => {
        attributes.field.onChange(
            attributes.field.value.filter((_: any, AIndex: number) => AIndex !== index)
        )
    }
    const error =
        Array.isArray(attributes.formState.errors.attributes) &&
        attributes.formState.errors.attributes[index] &&
        attributes.formState.errors.attributes[index]?.name

    return (
        <Grid
            key={key}
            container
            justifyContent={'space-between'}
            spacing={1}
            alignItems={'center'}
        >
            <Grid item md={2}>
                <Grid container>
                    <Grid item md={6}>
                        <IconButton
                            disabled={disableMoveUp}
                            aria-label="menu"
                            onClick={() => handleMoveAttribute(index, index - 1)}
                            size="large"
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={6}>
                        <IconButton
                            disabled={disableMoveDown}
                            onClick={() => handleMoveAttribute(index, index + 1)}
                            size="small"
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={9}>
                <Grid container alignContent={'start'} justifyContent={'space-between'} spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            control={control}
                            fullWidth
                            name={`attributes[${index}].path`}
                            disabled
                            label={t('dataSource:thirdStep.attributeRow.labelPath')}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <TextField
                            control={control}
                            fullWidth
                            name={`attributes[${index}].name`}
                            error={error ? error : ''} // Access errors for the name field
                            label={t('dataSource:thirdStep.attributeRow.labelCustomName')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1}>
                <IconButton
                    aria-label="menu"
                    onClick={() => handleRemoveAttribute(index)}
                    size="large"
                >
                    <CloseIcon color="error" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default AttributeRow
