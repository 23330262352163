import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
    error: Error
}

function ErrorFallback({ error }: Props) {
    const { t } = useTranslation()

    return (
        <Container component="main" maxWidth="lg">
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                alignContent="stretch"
                sx={{ height: '90vh' }}
            >
                <Typography variant="h2">{t('errorHeader')}</Typography>
                <Typography variant="h5" mt={2} mb={4}>
                    {t('errorInfo')}
                </Typography>
            </Grid>
        </Container>
    )
}

export default ErrorFallback
