import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import pathToPlayImage from '../../assets/play.png'
import { defaultCanvas } from '../../data/defaults/objects.types.defaults'
import { GSAPlib } from '../../data/libs'
import { initMasterTimelineForThumbnailPreview } from '../../helpers/masterTimeline.helpers'
import { useCanvasTimer } from '../../hooks/editor/useCanvasTimer'
import { useCanvas } from '../../hooks/useCanvas'

interface Props {
    htmlSrc: string
    objects: AnySceneObjectT[]
    animations: AnimationI[]
    jsonParams: JSONParamI[]
    width: number
    height: number
}

const PreviewScreen = ({ htmlSrc, objects, animations, jsonParams, width, height }: Props) => {
    const theme = useTheme()
    const [isPaused, setIsPaused] = useState<boolean>(true)
    const previewBoxRef = useRef<HTMLDivElement>()
    const playImageRef = useRef<HTMLDivElement>()
    const { canvasResolution: canvas } = useCanvas()

    const aspectRatio = canvas.width / canvas.height
    const defaultAspectRation = defaultCanvas.width / defaultCanvas.height
    const previewHeight = (width * defaultCanvas.height) / defaultCanvas.width

    //if aspectRation of graphic >= defaultAspectRation then scaleCeof is width / canvas.width
    //if aspectRation of graphic < defaultAspectRations then scaleCeof is height / canvasHeight
    const scaleCoef =
        aspectRatio >= defaultAspectRation ? width / canvas.width : previewHeight / canvas.height

    const playAnimation = () => {
        if (animations.length > 0) {
            // eslint-disable-next-line no-new-func
            const executeAnimation = new Function(initMasterTimelineForThumbnailPreview(animations))
            executeAnimation()
        }
    }

    useEffect(() => {
        // eslint-disable-next-line no-new-func
        const executeGsapLib = new Function(GSAPlib)
        executeGsapLib()
    }, [])

    useEffect(() => {
        if (
            previewBoxRef.current &&
            previewBoxRef.current.children &&
            previewBoxRef.current.children.length > 0
        ) {
            const htmlNodes: HTMLCollection = previewBoxRef.current.children
            const htmlCanvas: HTMLDivElement = htmlNodes[1] as HTMLDivElement

            htmlCanvas.style.transformOrigin = 'top left'
            htmlCanvas.style.transform = 'scale(' + scaleCoef + ')'
            htmlCanvas.style.visibility = 'visible'
        }
        // eslint-disable-next-line
    }, [previewBoxRef.current !== undefined && previewBoxRef.current.clientWidth, scaleCoef])

    const handleOnClick = () => {
        if (isPaused) {
            playAnimation()
            setIsPaused(false)
            if (playImageRef.current) {
                playImageRef.current.style.opacity = '0'
                playImageRef.current.style.transitionProperty = 'opacity'
                playImageRef.current.style.transitionDuration = '0.5s'
            }
        }
    }

    useCanvasTimer(objects)
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
            }}
        >
            {animations.length > 0 && (
                <Box
                    onClick={handleOnClick}
                    ref={playImageRef}
                    sx={{
                        backgroundImage: `url(${pathToPlayImage})`,
                        backgroundSize: 'contain',
                        width: '100px',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        opacity: 0.75,
                        scale: { xl: '0.85', md: '0.75', sm: '0.5', xs: '0.3' },
                        zIndex: 10000,
                        cursor: isPaused ? 'pointer' : 'default',
                    }}
                />
            )}
            <Box
                onClick={handleOnClick}
                ref={previewBoxRef}
                sx={{
                    height:
                        aspectRatio >= defaultAspectRation
                            ? (canvas.height * width) / canvas.width
                            : previewHeight,
                    width:
                        aspectRatio >= defaultAspectRation
                            ? width
                            : (canvas.width * previewHeight) / canvas.height,
                    border: `0.5px solid ${theme.palette.divider}`,
                    zIndex: 900,
                    cursor: isPaused ? 'pointer' : 'default',
                }}
                dangerouslySetInnerHTML={{
                    __html: htmlSrc,
                }}
            />
        </Box>
    )
}
export default PreviewScreen
