import SortIcon from '@mui/icons-material/Sort'
import { ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import SortMenuItem, { MySortT } from './SortMenuItem'

interface Props {
    keyValue: string
    sortedBy: string
    handleSortedBy: (value: string) => void
    arrayOfSortItems: MySortT[]
}
function GraphicSort({ keyValue, sortedBy, arrayOfSortItems, handleSortedBy }: Props) {
    const [openSort, setOpenSort] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

        setOpenSort(false)
    }
    const handleToggle = () => {
        setOpenSort((prevOpen) => !prevOpen)
    }
    const prevOpen = useRef(openSort)
    useEffect(() => {
        prevOpen.current = openSort
    }, [openSort])

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenSort(false)
        } else if (event.key === 'Escape') {
            setOpenSort(false)
        }
    }
    return (
        <>
            <IconButton
                aria-label="menu"
                size="large"
                ref={anchorRef}
                id="composition-button"
                aria-controls={openSort ? 'composition-menu' : undefined}
                aria-expanded={openSort ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <SortIcon sx={{ width: 25, height: 25 }} />
            </IconButton>
            <Popper
                open={openSort}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 50 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'right top' : 'right bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    key={keyValue}
                                    autoFocusItem={openSort}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <SortMenuItem
                                        handleSortedBy={handleSortedBy}
                                        sortedBy={sortedBy}
                                        arrayOfSortItems={arrayOfSortItems}
                                    />
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
export default GraphicSort
