import { useCallback } from 'react'
import { selectUndoable } from '../helpers/selector.helpers'
import { useAppSelector } from './useRedux'

const useObject = () => {
    const objects = useAppSelector((state) => selectUndoable(state).objects)
    /**
     * find object by id from objects
     */
    const findObjectById = useCallback(
        (id: string) => {
            let object: AnySceneObjectT | undefined = Object.values(objects.value).find(
                (object) => object?.id === id
            )
            return object
        },
        [objects.value]
    )
    return { findObjectById }
}

export default useObject
