import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { publishOrUnPublishGraphicAsync } from '../../store/slices/graphic.slice'
import { getGraphicAfterPublishUnPublish } from '../../store/slices/graphics.slice'
import { Transition } from '../common/Transition'
interface Props {
    open: boolean
    onClose: (refresh: boolean) => void
    setFirstPage: () => void
}
function UnPublishDialog({ open, onClose, setFirstPage }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic)

    const onClickUnPublishGraphic = async () => {
        const data = { isPublished: false, price: 0 }
        if (graphic.data._id) {
            dispatch(publishOrUnPublishGraphicAsync(graphic.data._id, data))
            dispatch(
                getGraphicAfterPublishUnPublish({
                    id: graphic.data._id,
                    isPublished: data.isPublished,
                })
            )
        }
        onClose(true)
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => onClose(true)}
            aria-describedby="alert-dialog-slide-description"
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    minWidth: { lg: 500, sm: 'auto' },
                    pt: 1,
                    pl: 1,
                    pb: 2,
                    pr: 2,
                }}
            >
                <DialogTitle>
                    {`${t('graphics:removeGraphicFromStoreTitle')} '${graphic.data.name}'?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('graphics:removeGraphicFromStoreTextInDialog')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(true)} variant="text" color="secondary">
                        {t('disagree')}
                    </Button>
                    <Button onClick={onClickUnPublishGraphic} color={'warning'}>
                        {t('graphics:unPublish')}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
export default UnPublishDialog
