import React from 'react'
import ImageContainer from './ImageContainer'

interface Props {
    object: SequenceI
    isHiding: boolean
    canvasScaleCoef: number
    borderCoef: number
    handleSelectObject: (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        shiftKey: boolean
    ) => void
}

const SequenceContainer = ({
    object,
    isHiding,
    canvasScaleCoef,
    borderCoef,
    handleSelectObject,
}: Props) => {
    return (
        <ImageContainer
            object={object}
            isHiding={isHiding}
            canvasScaleCoef={canvasScaleCoef}
            borderCoef={borderCoef}
            handleSelectObject={handleSelectObject}
        />
    )
}

export default SequenceContainer
