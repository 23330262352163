import { Divider, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import { MouseEventHandler, useState } from 'react'
import { useAppSelector } from '../../../hooks/useRedux'

interface Item {
    onClick: MouseEventHandler<HTMLLIElement>
    icon: any
    title: string
    disabled?: boolean
    divider?: boolean
    submenu?: Item[]
}

export type MenuItemT = {
    onClick: MouseEventHandler<HTMLLIElement>
    icon: any
    title: string
    disabled?: boolean
    divider?: boolean
    submenu?: MenuItemT[]
    hidden?: boolean
}

interface Props {
    data: Item[]
    scaleCoef?: number
    menuLabel?: JSX.Element
}

const getValueWithoutUnit = (value: string): number => Number(value.replace(/[^\d.-]/g, ''))

const RightClickContextMenu = ({ data, scaleCoef, menuLabel }: Props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [mouseClickPosition, setMouseClickPosition] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    })
    const canvasZoom = useAppSelector((state) => state.editor.value.settings.canvasZoom)
    const canvasScale = canvasZoom / 100

    const handleClick = (event: any) => {
        event.preventDefault()
        if (!event) event = window.event
        event.cancelBubble = true
        if (event.stopPropagation) event.stopPropagation()

        const left =
            scaleCoef !== undefined ? getValueWithoutUnit(event.target.style.left) * scaleCoef : 0 // for absolutely positioned, scaled elements in the Canvas
        const top =
            scaleCoef !== undefined ? getValueWithoutUnit(event.target.style.top) * scaleCoef : 0 // for absolutely positioned, scaled elements in the Canvas

        setMouseClickPosition({
            x: event.clientX - event.target.getBoundingClientRect().left + left,
            y: event.clientY - event.target.getBoundingClientRect().top + top,
        })

        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)

    return (
        <>
            <Grid onContextMenu={handleClick}>{menuLabel}</Grid>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: mouseClickPosition.y * canvasScale,
                    horizontal: mouseClickPosition.x * canvasScale,
                }}
                onClick={handleClose}
            >
                {data.map((x: Item, index: number) =>
                    x.submenu !== undefined && x.submenu.length > 0 ? (
                        <RightClickContextMenu
                            menuLabel={
                                <Tooltip title={x.title}>
                                    <div key={index}>
                                        <MenuItem key={index} disabled={x.disabled}>
                                            <ListItemIcon>{x.icon}</ListItemIcon>
                                            <ListItemText>{x.title}</ListItemText>
                                        </MenuItem>
                                        {x.divider && data[index + 1] && <Divider />}
                                    </div>
                                </Tooltip>
                            }
                            data={x.submenu}
                        />
                    ) : (
                        <div key={index}>
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    x.onClick(event)
                                    handleClose()
                                }}
                                disabled={x.disabled}
                            >
                                <ListItemIcon>{x.icon}</ListItemIcon>
                                <ListItemText>{x.title}</ListItemText>
                            </MenuItem>
                            {x.divider && data[index + 1] && <Divider />}
                        </div>
                    )
                )}
            </Menu>
        </>
    )
}

export default RightClickContextMenu
