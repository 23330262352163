import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatchT, AppStateT } from '../store/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatchT>()
export const useAppSelector: TypedUseSelectorHook<AppStateT> = useSelector

export type BaseState<T> = {
    data: T
    status?: string
}
