const opacityInput: StyleInputT = {
    type: "number",
    props: {
        inputProps: {
            min: 0,
            max: 1,
            step: 0.1,
        },
    },
}

export const display: SimpleStyleT = {
    property: "display",
    value: "inline-block",
    input: {
        type: "select",
        props: ["block", "flex", "inline-block", "inline-flex"],
    },
    render: (value) => ({
        property: "display",
        value,
    }),
}

export const overflow: SimpleStyleT = {
    property: "overflow",
    value: "visible",
    input: {
        type: "select",
        props: ["visible", "hidden"],
    },
    render: (value) => ({
        property: "overflow",
        value,
    }),
}

export const opacity: SimpleStyleT = {
    property: "opacity",
    value: 1,
    input: opacityInput,
    render: (value) => ({
        property: "opacity",
        value,
    }),
}

export const imageSrc = (value: string): SimpleStyleT => ({
    property: "attr.src",
    value,
    input: {
        type: "image",
        props: "",
    },
    render: (value) => ({
        property: "attr.src",
        value,
    }),
})
