import { KeyboardEvent } from 'react'

const useKeyDown = () => {
    const handleOnEnterKeyDown = (
        e: KeyboardEvent<HTMLInputElement>,
        onChange: (e: any) => void
    ) => {
        if (e.key === 'Enter') {
            onChange(e)
        }
    }

    return { handleOnEnterKeyDown }
}

export default useKeyDown
