import { Skeleton } from '@mui/material'

const SkeletonGraphicTile = () => {
    return (
        <Skeleton
            variant="rectangular"
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: 170,
            }}
        ></Skeleton>
    )
}

export default SkeletonGraphicTile
