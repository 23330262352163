import {
	fadeIn,
	fadeOut,
	rotateXLeft,
	rotateXRight,
	rotateYLeft,
	rotateYRight,
	rotateZLeft,
	rotateZRight,
	sizeIn,
	sizeOut,
	slideFromBottom,
	slideFromLeft,
	slideFromRight,
	slideFromTop,
	slideToBottom,
	slideToLeft,
	slideToRight,
	slideToTop,
	zoomIn,
	zoomOut
} from "../styles/tweens.styles"

export const AnimationTweens: TweenT[] = [
	fadeIn,
	fadeOut,
	zoomIn,
	zoomOut,
	rotateXRight,
	rotateXLeft,
	rotateYRight,
	rotateYLeft,
	rotateZRight,
	rotateZLeft,
	slideFromLeft,
	slideToLeft,
	slideFromRight,
	slideToRight,
	slideFromTop,
	slideToTop,
	slideFromBottom,
	slideToBottom,
	sizeIn,
	sizeOut,
]

// export const dynamicAnimationTweens: TweenT[] = [slideFromLeft]
