import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import { Grid, IconButton } from '@mui/material'
import { t } from 'i18next'
import React, { useCallback, useMemo } from 'react'
import useObject from '../../../../hooks/useObject'
import { useObjectActions } from '../../../../hooks/useObjectActions'
import { useAppSelector } from '../../../../hooks/useRedux'
import ButtonWithTooltip from '../../../common/ButtonWithTooltip'
import NumberInput from '../../../common/PropertyTools/NumberInput'

const MultiSize = () => {
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const { findObjectById } = useObject()
    const { updateObjectStyle } = useObjectActions()

    const getStylesValue = useCallback(
        (property: 'width' | 'height'): SimpleStyleT | undefined => {
            let oldValue: number | undefined = undefined
            let modifiedStyles = undefined
            selectedObjectIds.forEach((id: string) => {
                const stylesValues = findObjectById(id)?.styles.find(
                    (style) => style.property === property
                )
                if (oldValue === undefined) {
                    oldValue = stylesValues?.value
                    modifiedStyles = stylesValues
                } else {
                    if (oldValue !== stylesValues?.value) {
                        if (stylesValues) modifiedStyles = { ...stylesValues, value: undefined }
                    } else {
                        modifiedStyles = stylesValues
                    }
                }
            })
            return modifiedStyles
        },
        [findObjectById, selectedObjectIds]
    )

    const width: SimpleStyleT | undefined = useMemo(() => getStylesValue('width'), [getStylesValue])

    const height: SimpleStyleT | undefined = useMemo(
        () => getStylesValue('height'),
        [getStylesValue]
    )

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        selectedObjectIds.forEach((id) => {
            let object = findObjectById(id)
            if (object && e.target.value) updateObjectStyle(object, property, e.target.value)
        })
    }

    /**
     * handler for set average value of width or height for selected objects
     * @param property name of property to update values of objects
     */
    const handleSetAverageValue = (property: 'width' | 'height') => {
        let valuesArray: number[] = []
        selectedObjectIds.map((x) =>
            valuesArray.push(
                Number(
                    findObjectById(x)?.styles.find((style) => style.property === property)?.value
                )
            )
        )
        const averageWidth: number = valuesArray.reduce((a, b) => a + b) / valuesArray.length
        selectedObjectIds.forEach((id) => {
            let object = findObjectById(id)
            if (object) updateObjectStyle(object, property, Number(averageWidth.toFixed(2)))
        })
    }

    return (
        <Grid container alignItems="center">
            {width && (
                <NumberInput
                    styleProperty={width}
                    handleOnChange={(e) => handleOnChange(e, 'width')}
                />
            )}

            <ButtonWithTooltip
                tooltipTitle={t('editor:size&positionSettings.multiSize.AverageWidthTooltip')}
                buttonComponent={
                    <IconButton onClick={() => handleSetAverageValue('width')}>
                        <AutoFixHighRoundedIcon />
                    </IconButton>
                }
            />

            {height && (
                <NumberInput
                    styleProperty={height}
                    handleOnChange={(e) => handleOnChange(e, 'height')}
                />
            )}
            <ButtonWithTooltip
                tooltipTitle={t('editor:size&positionSettings.multiSize.AverageHeightTooltip')}
                buttonComponent={
                    <IconButton onClick={() => handleSetAverageValue('height')}>
                        <AutoFixHighRoundedIcon />
                    </IconButton>
                }
            />
        </Grid>
    )
}

export default MultiSize
