import MenuIcon from '@mui/icons-material/Menu'
import { Divider, List, IconButton, Box } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteT } from '../hooks/router/useRoute'
import { useAppSelector } from '../hooks/useRedux'
import LoadingBackdrop from './common/LoadingBackdrop'
import MenuItem from './common/MenuItem'
import ProfileMenuItem from './MainMenu/ProfileMenuItem'
import DocumentationItem from './MainMenu/DocumentationItem'
import CopyrightItem from './MainMenu/CopyrightItem'

interface Props {
    routes: RouteT[]
}

export default function MainMenu({ routes }: Props) {
    const theme = useTheme()
    const [state, setState] = React.useState({ openMenu: false })
    const session = useAppSelector((state) => state.session)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setState({ openMenu: open })
    }

    const navigate = useNavigate()
    const location = useLocation()
    const handleClick = async (route: string) => {
        if (location.pathname === route) {
            return
        }
        navigate(route)
    }

    return (
        <Box>
            <React.Fragment key={'mainMenu'}>
                <IconButton aria-label="menu" onClick={toggleDrawer(!state.openMenu)} size="large">
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor={'left'}
                    open={state.openMenu}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        sx={{
                            width: 300,
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            backgroundColor: theme.palette.grey[900],
                        }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <Box>
                            <List>
                                {routes
                                    .find((r) => r.path === 'app')
                                    ?.children?.map((r: RouteT) => (
                                        <MenuItem
                                            key={r.path}
                                            route={r}
                                            onClick={() => handleClick(`/app/${r.path}`)}
                                        />
                                    ))}
                            </List>
                        </Box>
                        <Box sx={{ alignContent: 'flex-end' }}>
                            <List>
                                {routes
                                    .find((r) => r.path === 'settings')
                                    ?.children?.map((r: RouteT) => (
                                        <MenuItem
                                            key={r.path}
                                            route={r}
                                            onClick={() => handleClick(`/settings/${r.path}`)}
                                        />
                                    ))}
                                <DocumentationItem />
                                <Divider />
                                <ProfileMenuItem />
                                <Divider />
                                <CopyrightItem />
                            </List>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </React.Fragment>
            <LoadingBackdrop open={!session.status} />
        </Box>
    )
}
