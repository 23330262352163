//fullName from firstName and LastName
export const getFullNameString = (firstName?: string, lastName?: string) => {
    let spaceCondition = firstName && lastName ? ' ' : ''
    return `${firstName || ''}${spaceCondition}${lastName || ''}`
}
//Shortcut start letter from firstName and lastName
export const getShortcutFromFullNameString = (firstName?: string, lastName?: string) => {
    const result =
        !firstName && !lastName
            ? ''
            : `${firstName?.charAt(0)?.toUpperCase()}${lastName?.charAt(0)?.toUpperCase()}`
    return result
}

export const getFirstLetterUpper = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

// trim() removes whitespace from both ends of a string
// replace() replaces one or more spaces with one dash
// toLowerCase() converts string do lower case
export const replaceSpacesWithDashes = (str: string): string =>
    str.trim().replace(/\s+/g, '-').toLowerCase()

const hex = (value: number) => Math.floor(value).toString(16)

export const generateObjectId = () => {
    return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
}
