import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './App.css'
import Toaster from './components/common/Toaster'
import { routes } from './routes'
import store from './store/store'
import DarkTheme from './style/mui.theme'

const router = createBrowserRouter(routes)

const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={DarkTheme}>
                <CssBaseline />
                <RouterProvider router={router} />
                <Toaster />
            </ThemeProvider>
        </Provider>
    )
}

export default App
