import ShareIcon from '@mui/icons-material/Share'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Alert, Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Role } from '../../constants/role.enum'
import { dateToString } from '../../helpers/date.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useClipboard } from '../../hooks/useClipboard'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { getCompaniesCurrentAsync } from '../../store/slices/companies.slice'
import { CompanyT } from '../../store/slices/company.slice'
import { purchaseGraphicAsync } from '../../store/slices/graphic.slice'
import { getGraphicStatusAsync } from '../../store/slices/graphicStatus.slice'
import PreviewScreenWrapper from '../GraphicCommon/PreviewScreenWrapper'
import { Transition } from '../common/Transition'
import Select from '../common/form/Select'

interface Props {
    open: boolean
    fileContent: Blob | undefined
    onClose: () => void
}

function GraphicStoreDetail({ open, fileContent, onClose }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic)
    const user = useAppSelector((state) => state.user.value)
    const currentCompanies = useAppSelector((state) => state.companies.data)

    const filteredCurrentCompanies = currentCompanies
        .filter((company) =>
            company.usersRef.find(
                (userRef) => userRef.id === user._id && userRef.role > Role.Collaborator
            )
        )
        .map((company) => ({
            ...company,
            disabled:
                company.isPrivate && graphic.data.price && graphic.data.price > 0 ? true : false,
        }))

    const graphicStatus = useAppSelector((state) => state.graphicStatus)
    const [selectedCompany, setSelectedCompany] = useState<CompanyT>()

    const { canPurchase, isMyGraphic, notEnoughCoins } = useVerifyPermission(
        graphic.data,
        selectedCompany
    )
    const { share } = useClipboard()

    const companySchema = yup
        .object({
            companyId: yup.string().required('company:companyRequired'),
        })
        .required()

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: companySchema,
        defaultValues: graphic.data, // to do osetrit undefined musi byt object
    })

    const companyId = useWatch({
        control,
        name: 'companyId',
    })

    useEffect(() => {
        dispatch(getCompaniesCurrentAsync())
    }, [dispatch])

    useEffect(() => {
        if (companyId) {
            let comp = filteredCurrentCompanies?.find((x) => x._id === companyId)
            if (comp) {
                setSelectedCompany(comp)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId])

    useEffect(() => {
        if (
            graphic.data._id &&
            selectedCompany?._id &&
            !selectedCompany?.isPrivate &&
            !notEnoughCoins
        ) {
            dispatch(getGraphicStatusAsync(graphic.data._id, selectedCompany?._id))
        }
    }, [
        dispatch,
        graphic.data._id,
        notEnoughCoins,
        selectedCompany?._id,
        selectedCompany?.isPrivate,
    ])

    const onPurchaseGraphic = async (data: { companyId: string }) => {
        graphic.data._id && dispatch(purchaseGraphicAsync(graphic.data._id, data.companyId))
        onClose()
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'xl'}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onPurchaseGraphic)}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid container p={4} spacing={4} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <PreviewScreenWrapper fileContent={fileContent} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid
                            container
                            py={2}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item>
                                <Grid container pb={2} alignItems={'center'}>
                                    <Grid item xs={10}>
                                        <Typography
                                            variant="h3"
                                            gutterBottom
                                            sx={{ wordBreak: 'break-word' }}
                                        >
                                            {graphic.data.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent="flex-end" p={0}>
                                            <Grid item>
                                                <Tooltip title={t('graphics:copyUrl')}>
                                                    <IconButton
                                                        onClick={() =>
                                                            share(
                                                                'graphics:urlSetToClipboardSuccess'
                                                            )
                                                        }
                                                    >
                                                        <ShareIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>
                                        {graphic.data.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography variant="h4" gutterBottom>
                                        <ShoppingCartIcon sx={{ mr: 1 }} />
                                        {t('graphics:purchaseSubmit')}
                                    </Typography>
                                    <Select
                                        control={control}
                                        required
                                        name="companyId"
                                        label={t('company:title')}
                                        fullWidth
                                        labelField="name"
                                        valueField="_id"
                                        error={errors.companyId}
                                        options={filteredCurrentCompanies}
                                        disabled={isMyGraphic}
                                    />
                                    <Typography variant="body1" mt={2} gutterBottom>
                                        {t('graphics:accountBalance')}
                                        {selectedCompany?._id === undefined
                                            ? 0
                                            : filteredCurrentCompanies?.find(
                                                  (x) => x._id === selectedCompany?._id
                                              )?.coins}
                                        {t('graphics:coinsWithIcon')}
                                    </Typography>
                                    {graphicStatus.data?.isPurchased && (
                                        <Alert severity="warning" variant="filled" sx={{ mt: 1 }}>
                                            {`'${graphic.data.name}' ${t(
                                                'graphics:warningMessage.alreadyPurchased'
                                            )}`}
                                        </Alert>
                                    )}
                                    {notEnoughCoins && (
                                        <Alert severity="error" variant="filled" sx={{ mt: 1 }}>
                                            {t('graphics:warningMessage.notEnoughCoins')}
                                        </Alert>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t('graphics:price')}
                                            {graphic.data.price === 0 ||
                                            graphic.data.price === undefined
                                                ? t('graphics:free')
                                                : graphic.data.price + t('graphics:coinsWithIcon')}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            disabled={isSubmitting}
                                            onClick={() => onClose()}
                                        >
                                            {t('disagree')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting || !canPurchase}
                                            onClick={handleSubmit(onPurchaseGraphic)}
                                        >
                                            {t('graphics:purchaseSubmit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography variant="caption">
                                        {t('graphics:created')}
                                        {graphic.data.created &&
                                            dateToString(graphic.data.created)}{' '}
                                        {t('graphics:by')}
                                        {
                                            <i>
                                                {!graphic.data.user?.firstName &&
                                                !graphic.data.user?.lastName
                                                    ? t('graphics:anonymous')
                                                    : ''}
                                            </i>
                                        }
                                        {getFullNameString(
                                            graphic.data.user?.firstName,
                                            graphic.data.user?.lastName
                                        )}
                                    </Typography>
                                    <Typography variant="caption">
                                        {t('graphics:lastUpdated')}
                                        {graphic.data.updated && dateToString(graphic.data.updated)}
                                        {t('graphics:by')}
                                        {
                                            <i>
                                                {!graphic.data.user?.firstName &&
                                                !graphic.data.user?.lastName
                                                    ? t('graphics:anonymous')
                                                    : ''}
                                            </i>
                                        }
                                        {getFullNameString(
                                            graphic.data.user?.firstName,
                                            graphic.data.user?.lastName
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}
export default GraphicStoreDetail
