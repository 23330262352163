import { useEffect, useMemo, useState } from 'react'
import { DraggableData, DraggableEvent } from 'react-draggable'
import { ObjectStyles, getObjectPositioning } from '../../helpers/object.helpers'
import useObject from '../useObject'
import { useObjectActions } from '../useObjectActions'
import { useAppSelector } from '../useRedux'
import useWrapSelectedObject from './useWrapSelectedObject'
import useWrapSelectedObjects, { ObjectStylesWithId } from './useWrapSelectedObjects'

const DEFAULT_OBJECT_POSITION = { position: 'absolute', top: 0, left: 0, width: 0, height: 0 }
export const useDraggable = (
    object: AnySceneObjectT | undefined,
    canvasScaleCoef: number,
    selectedObjects: AnySceneObjectT[] | undefined
) => {
    //general const
    const [dragging, setDragging] = useState<boolean>(false)
    const [draggableAxis, setDraggableAxis] = useState<'x' | 'y' | undefined>(undefined)
    const { updateObjectStyles } = useObjectActions()
    const editor = useAppSelector((state) => state.editor.value)
    const canvasSnapToGrid = editor.settings.canvasSnapToGridOn
    const canvasSnapGrid = canvasSnapToGrid ? 20 : 1

    //single object const
    const { wrapperObjectsStyles } = useWrapSelectedObject()

    //multiObjects const
    const { findObjectById } = useObject()
    const { wrapperObject, wrapperExist } = useWrapSelectedObjects()
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)

    const draggableScale = (canvasScaleCoef * editor.settings.canvasZoom) / 100

    /**
     * recursive function for check parentIds up to rootId
     * @param object current object
     * @returns true if parentId is in selectedObjectIds, else false
     */
    const handleCheckParentIdInSelectedIds = (object: AnySceneObjectT): boolean => {
        if (object.parentId) {
            if (selectedObjectIds.find((id) => id === object.parentId)) {
                return true
            } else {
                const parentObject = findObjectById(object.parentId)
                if (parentObject) {
                    return handleCheckParentIdInSelectedIds(parentObject)
                } else {
                    return false
                }
            }
        } else {
            if (selectedObjectIds.find((id) => id === object.id)) {
                return false
            } else {
                return false
            }
        }
    }

    const handleDragEnd = (e: DraggableEvent, data: DraggableData) => {
        if (wrapperExist && selectedObjects) {
            selectedObjects
                .filter((object) => (handleCheckParentIdInSelectedIds(object) ? false : true))
                .find((object: AnySceneObjectT) => {
                    return updateObjectStyles(object, {
                        left:
                            draggableAxis === 'y'
                                ? Number(getObjectPositioning(object).left)
                                : Math.round(
                                      Number(getObjectPositioning(object).left) +
                                          Number(data.x) / canvasSnapGrid
                                  ) * canvasSnapGrid,
                        top:
                            draggableAxis === 'x'
                                ? Number(getObjectPositioning(object).top)
                                : Math.round(
                                      Number(getObjectPositioning(object).top) +
                                          Number(data.y) / canvasSnapGrid
                                  ) * canvasSnapGrid,
                    })
                })
        } else if (!wrapperExist && object) {
            updateObjectStyles(object, {
                left:
                    draggableAxis === 'y'
                        ? Number(getObjectPositioning(object).left)
                        : Math.round(
                              (Number(getObjectPositioning(object).left) + data.x) / canvasSnapGrid
                          ) * canvasSnapGrid,
                top:
                    draggableAxis === 'x'
                        ? Number(getObjectPositioning(object).top)
                        : Math.round(
                              (Number(getObjectPositioning(object).top) + data.y) / canvasSnapGrid
                          ) * canvasSnapGrid,
            })
        }
        setDragging(false)
        setDraggableAxis(undefined)
    }
    const handleDragging = (e: any, data: DraggableData) => {
        if (e.shiftKey) {
            if (draggableAxis === undefined) {
                if (Math.abs(data.x) > Math.abs(data.y)) {
                    setDraggableAxis('x')
                } else {
                    setDraggableAxis('y')
                }
            }
        } else {
            setDraggableAxis(undefined)
        }
    }

    const setCursorWhenDragging = useMemo(
        () =>
            draggableAxis
                ? draggableAxis === 'x'
                    ? 'ew-resize'
                    : draggableAxis === 'y'
                    ? 'ns-resize'
                    : 'pointer'
                : dragging
                ? 'move'
                : 'pointer',
        [draggableAxis, dragging]
    )

    const handleOnStartDrag = () => {
        if (!dragging) setDragging(true)
    }

    //used for correct wrapping object with child when move and after moved (avoid of flickering)
    const [singleWrapBeforeUpdate, setSingleWrapBeforeUpdate] = useState<
        ObjectStylesWithId | undefined
    >(undefined)

    //used for correct wrapping objects with child when move and after moved (avoid of flickering)
    const [multiWrapBeforeUpdate, setMultiWrapBeforeUpdate] = useState<ObjectStyles | undefined>(
        undefined
    )
    useEffect(() => {
        if (wrapperExist) {
            if (dragging) {
                setMultiWrapBeforeUpdate(wrapperObject)
            } else {
                setMultiWrapBeforeUpdate(undefined)
            }
        } else {
            if (dragging) {
                setSingleWrapBeforeUpdate(wrapperObjectsStyles)
            } else {
                setSingleWrapBeforeUpdate(undefined)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragging, wrapperExist])

    const defaultSingleWrapperState =
        !dragging &&
        wrapperObjectsStyles.top !== singleWrapBeforeUpdate?.top &&
        wrapperObjectsStyles.left !== singleWrapBeforeUpdate?.left

    const defaultMultiWrapperState =
        !dragging &&
        wrapperObject.top !== multiWrapBeforeUpdate?.top &&
        wrapperObject.left !== multiWrapBeforeUpdate?.left

    const objectPositioning = object ? getObjectPositioning(object) : DEFAULT_OBJECT_POSITION

    return {
        handleDragEnd,
        handleDragging,
        handleOnStartDrag,
        setCursorWhenDragging,
        dragging,
        draggableAxis,
        draggableScale,
        objectPositioning,
        defaultSingleWrapperState,
        defaultMultiWrapperState,
    }
}
