import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonWithTooltip from './ButtonWithTooltip'

interface Props {
    text?: string
    tooltipTitle: string
    handleOnChange: (props?: any) => void
}

const DeleteButton = ({ text, tooltipTitle, handleOnChange }: Props) => {
    if (text === undefined)
        return (
            <ButtonWithTooltip
                tooltipTitle={tooltipTitle}
                buttonComponent={
                    <IconButton color="error" onClick={handleOnChange}>
                        <CloseRoundedIcon />
                    </IconButton>
                }
                disabled={false}
            />
        )
    else
        return (
            <ButtonWithTooltip
                tooltipTitle={tooltipTitle}
                buttonComponent={
                    <Button
                        variant="text"
                        color="error"
                        onClick={handleOnChange}
                        startIcon={<CloseRoundedIcon />}
                    >
                        {text}
                    </Button>
                }
                disabled={false}
            />
        )
}
export default DeleteButton
