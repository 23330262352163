import BackHandIcon from '@mui/icons-material/BackHand'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import NearMeIcon from '@mui/icons-material/NearMe'
import { IconButton } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { t } from 'i18next'
import { RefObject } from 'react'
import { useCanvas } from '../../../hooks/useCanvas'
import useFitCanvas from '../../../hooks/useFitCanvas'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { CanvasCursor, setCanvasCursor } from '../../../store/slices/canvasCursor.slice'
import { setPreferencesAsync } from '../../../store/slices/editor.slice'
import ButtonWithTooltip from '../../common/ButtonWithTooltip'
import PopupSettings from '../../common/PopupSettings/PopupSettings'
import PopupSettingsSwitches, {
    SettingsSwitchT,
} from '../../common/PopupSettings/PopupSettingsSwitches'
import ZoomSlider from '../../common/ZoomSlider'
import CanvasCursorIcon from './CanvasCursorIcon'
import CanvasSettings from './CanvasSettings'
import RefreshStyle from './RefreshStyle'

interface Props {
    size: {
        width: number
        height: number
    }
    canvasRef: RefObject<HTMLDivElement>
}

const canvasZoomStep: number = 10
const canvasMaxZoom: number = 200
const canvasMinZoom: number = 10

const PreviewTools = ({ size, canvasRef }: Props) => {
    const dispatch = useAppDispatch()
    const { canvasResolution: canvas } = useCanvas()
    const { status } = useAppSelector((state) => state.file)
    const scale: number = size.width / canvas.width
    const editor = useAppSelector((state) => state.editor)
    const canvasOverflowOn: boolean = editor.value.settings.canvasOverflowOn
    const canvasGridOn: boolean = editor.value.settings.canvasGridOn
    const canvasSnapToGridOn: boolean = editor.value.settings.canvasSnapToGridOn
    const canvasZoom: number = editor.value.settings.canvasZoom

    const canvasCursor = useAppSelector((state) => state.canvasCursor.value)
    const { handleFitScreen } = useFitCanvas(canvasRef)

    const handleShowCanvasOverflow = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setPreferencesAsync('canvasOverflowOn', e.target.checked))
    }

    const handleShowCanvasGrid = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setPreferencesAsync('canvasGridOn', e.target.checked))
    }

    const handleSwitchCanvasSnapToGrid = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setPreferencesAsync('canvasSnapToGridOn', e.target.checked))
    }

    const settingSwitches: SettingsSwitchT[] = [
        {
            label: 'show canvas overflow',
            checked: canvasOverflowOn,
            onChange: handleShowCanvasOverflow,
        },
        {
            label: 'show canvas grid',
            checked: canvasGridOn,
            onChange: handleShowCanvasGrid,
        },
        {
            label: 'snap to canvas grid',
            checked: canvasSnapToGridOn,
            onChange: handleSwitchCanvasSnapToGrid,
        },
    ]

    const setCanvasZoom = (value: number) => {
        dispatch(setPreferencesAsync('canvasZoom', Math.max(value, canvasMinZoom)))
    }

    return (
        <Grid
            id="editor-step-8"
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: (theme) => `5px solid ${theme.palette.grey[700]}` }}
        >
            <Grid item alignItems="center">
                <CanvasCursorIcon
                    tooltipTitle={t('editor:cursor.default')}
                    disabled={canvasCursor !== CanvasCursor.GrabCursor}
                    onClick={() => dispatch(setCanvasCursor(CanvasCursor.DefaultCursor))}
                    children={<NearMeIcon fontSize="small" sx={{ opacity: 0.5 }} />}
                />
                <CanvasCursorIcon
                    tooltipTitle={t('editor:cursor.hand')}
                    disabled={canvasCursor === CanvasCursor.GrabCursor}
                    onClick={() => dispatch(setCanvasCursor(CanvasCursor.GrabCursor))}
                    children={<BackHandIcon fontSize="small" sx={{ opacity: 0.5 }} />}
                />
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item mr={3}>
                        <Typography variant="caption">
                            {`${t('editor:settings.canvasSize')} ${
                                !status
                                    ? t('editor:settings.loading')
                                    : `${canvas.width} X ${canvas.height}`
                            }`}
                            <CanvasSettings />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ZoomSlider
                            scalePerc={Math.round(scale * (canvasZoom / 100) * 100)}
                            zoom={canvasZoom}
                            zoomStep={canvasZoomStep}
                            minZoom={canvasMinZoom}
                            maxZoom={canvasMaxZoom}
                            handleSliderChange={setCanvasZoom}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    <ButtonWithTooltip
                        tooltipTitle={t('menu:fitTooltip')}
                        buttonComponent={
                            <IconButton onClick={handleFitScreen}>
                                <FitScreenIcon fontSize="small" />
                            </IconButton>
                        }
                        disabled={false}
                    />
                    <RefreshStyle />
                    <PopupSettings
                        settings={<PopupSettingsSwitches switches={settingSwitches} />}
                        autoClose={false}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PreviewTools
