import PublicOffRoundedIcon from '@mui/icons-material/PublicOffRounded'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateToString } from '../../helpers/date.helpers'
import { EMPTY_IMAGE_SRC } from '../../helpers/object.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useOnScreen } from '../../hooks/useOnScreen'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { getGraphicAsync } from '../../store/slices/graphic.slice'
import { GraphicAsset } from '../../store/slices/graphics.slice'
import { getThumbnailFileAsync } from '../../store/slices/thumbnailMap.slice'
import SkeletonGraphicTile from '../GraphicCommon/SkeletonGraphicTile'
import UnPublishDialog from '../GraphicCommon/UnPublishDialog'
import ButtonWithTooltip from '../common/ButtonWithTooltip'
import MoreContextMenu from '../common/ContextMenu/MoreContextMenu'

interface Props {
    graph: GraphicAsset
    handleOpenDetailPreview: (id: string) => void
    setFirstPage: () => void
}
function GraphicStoreTile({ graph, handleOpenDetailPreview, setFirstPage }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [openDeleteFromStore, setOpenDeleteFromStore] = useState(false)
    const ref: any = useRef<HTMLDivElement>()
    const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-300px')
    const thumbnailMap = useAppSelector((state) => state.thumbnailMap)
    const thumbnailSrc = thumbnailMap.data[graph.thumbnailId!]?.src
    const { canPublishUnPublish } = useVerifyPermission(graph)

    useEffect(() => {
        if (graph.thumbnailId && thumbnailSrc === undefined)
            dispatch(getThumbnailFileAsync(graph.thumbnailId))
    }, [dispatch, graph.thumbnailId, onScreen, thumbnailSrc])

    const handleOpenDeleteFromStore = async () => {
        await dispatch(getGraphicAsync(graph._id))
        setOpenDeleteFromStore(true)
    }

    const handleCloseDeleteFromStore = () => {
        setOpenDeleteFromStore(false)
    }

    return (
        <>
            <Card
                ref={ref}
                sx={{
                    width: 350,
                    backgroundColor: theme.palette.background.paper,
                    padding: 1,
                    borderRadius: 1,
                    minHeight: 100,
                    margin: 1.25,
                }}
            >
                <CardContent>
                    {!thumbnailSrc && graph?.thumbnailId ? (
                        <SkeletonGraphicTile />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: theme.palette.grey[900],
                                height: 170,
                            }}
                        >
                            <Box
                                component="img"
                                src={thumbnailSrc ?? EMPTY_IMAGE_SRC}
                                sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 0.7,
                                    p: 0,
                                }}
                            ></Box>
                        </Box>
                    )}
                    <Grid container mt={1}>
                        <Grid item md={11} xs={11}>
                            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                                {graph.name}
                            </Typography>
                            <Typography variant="body2">
                                {dateToString(new Date(graph.updated))}
                                {' | '}
                                {!graph.user?.firstName && !graph.user?.lastName ? (
                                    <i>{t('graphics:anonymous')}</i>
                                ) : graph.company?.isPrivate === true ? (
                                    getFullNameString(graph.user?.firstName, graph.user?.lastName)
                                ) : (
                                    `${graph.company?.name}`
                                )}
                            </Typography>
                        </Grid>
                        <Grid item md={1} xs={1}>
                            <MoreContextMenu
                                data={[
                                    {
                                        icon: <PublicOffRoundedIcon />,
                                        title: t('graphics:unPublish'),
                                        disabled: !canPublishUnPublish,
                                        onClick: handleOpenDeleteFromStore,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={1} alignItems="center">
                        <Grid item md={6} xs={6}>
                            {graph.price === 0 || graph.price === undefined
                                ? 'free'
                                : graph.price + ' coins 💰'}
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box sx={{ direction: 'rtl' }}>
                                <ButtonWithTooltip
                                    tooltipTitle={t('graphics:previewButtonTooltip')}
                                    buttonComponent={
                                        <Button
                                            aria-label="menu"
                                            size="large"
                                            variant="text"
                                            onClick={() => handleOpenDetailPreview(graph._id)}
                                        >
                                            {t('graphics:previewButton')}
                                        </Button>
                                    }
                                    disabled={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <UnPublishDialog
                open={openDeleteFromStore}
                onClose={() => handleCloseDeleteFromStore()}
                setFirstPage={() => setFirstPage()}
            />
        </>
    )
}

export default GraphicStoreTile
