export const flexDirection: SimpleStyleT = {
	property: "flexDirection",
	value: "column",
	input: {
		type: "select",
		props: ["column", "row"],
		label: "flex direction",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "flex-direction", value }
			: { property: "flexDirection", value },
}

export const alignItems: SimpleStyleT = {
	property: "alignItems",
	value: "stretch",
	input: {
		type: "select",
		props: ["flex-start", "flex-end", "center", "baseline", "stretch"],
		label: "align items",
	},
	render: (value, unit = undefined, type) =>
		type === "css" ? { property: "align-items", value } : { property: "alignItems", value },
}

export const justifyContent: SimpleStyleT = {
	property: "justifyContent",
	value: "center",
	input: {
		type: "select",
		props: [
			"center",
			"flex-start",
			"flex-end",
			"space-around",
			"space-evenly",
			"space-between",
		],
		label: "justify content",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "justify-content", value }
			: { property: "justifyContent", value },
}
