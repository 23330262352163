import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    RadioGroup as MuiRadioGroup,
    Radio,
    Typography,
} from '@mui/material'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface OptionsDataArray {
    value: number | string
    title: string

    description?: string
    disabled?: boolean
}
interface Props {
    row?: boolean
    control: Control<any, any>
    name: string
    info?: React.ReactNode
    title: string
    disabled?: boolean
    options: OptionsDataArray[]
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
    fullWidth?: boolean
    required?: boolean
}

const RadioGroupButton = ({
    row,
    control,
    name,
    title,
    info,
    error,
    disabled,
    fullWidth,
    options,
    required,
}: Props) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl fullWidth={fullWidth} error={Boolean(error)} required={required}>
                    <Typography alignContent={'center'} variant="h5">
                        <FormLabel>{title}</FormLabel>
                        {info}
                    </Typography>
                    <MuiRadioGroup
                        row={row}
                        name={name}
                        value={field.value}
                        onChange={(event) => field.onChange(event.target.value)}
                    >
                        {options?.map((options: OptionsDataArray) => (
                            <FormControlLabel
                                key={options.value}
                                value={options.value}
                                control={<Radio />}
                                disabled={options.disabled || disabled}
                                sx={{ mb: 1 }}
                                label={
                                    <>
                                        <Typography variant="body1">{options.title}</Typography>
                                        {options.description && (
                                            <Typography variant="body2">
                                                {options.description}
                                            </Typography>
                                        )}
                                    </>
                                }
                            />
                        ))}
                    </MuiRadioGroup>
                    {error && (
                        <FormHelperText>
                            {error.message ? t(error.message.toString()) : undefined}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )
}

export default RadioGroupButton
