import { MAX_INPUT_NUM } from "../../../constants"

const positiveUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const borderTopLeftRadius: SimpleStyleT = {
	property: "borderTopLeftRadius",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "top left",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "border-top-left-radius", value: value + unit }
			: { property: "borderTopLeftRadius", value: value + unit },
}

export const borderTopRightRadius: SimpleStyleT = {
	property: "borderTopRightRadius",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "top right",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "border-top-right-radius", value: value + unit }
			: { property: "borderTopRightRadius", value: value + unit },
}

export const borderBottomLeftRadius: SimpleStyleT = {
	property: "borderBottomLeftRadius",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "bottom left",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "border-bottom-left-radius", value: value + unit }
			: { property: "borderBottomLeftRadius", value: value + unit },
}

export const borderBottomRightRadius: SimpleStyleT = {
	property: "borderBottomRightRadius",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "bottom right",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "border-bottom-right-radius", value: value + unit }
			: { property: "borderBottomRightRadius", value: value + unit },
}
