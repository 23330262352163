import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

export interface SimilarGraphicT {
    _id: string
    name: string
}

interface SimilarGraphicStateT {
    data: SimilarGraphicT[]
    status?: string
}

const initialState: SimilarGraphicStateT = {
    status: Status.OK,
    data: [],
}

export const similarGraphicsSlice = createSlice({
    name: 'similarGraphics',
    initialState,
    reducers: {
        getSimilarGraphicPending: (state: SimilarGraphicStateT) => {
            state.status = undefined
        },
        getSimilarGraphicFulfilled: (
            state: SimilarGraphicStateT,
            action: PayloadAction<SimilarGraphicT[]>
        ) => {
            state.data = action.payload
            state.status = Status.OK
        },
        getSimilarGraphicRejected: (state: SimilarGraphicStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getSimilarGraphicPending, getSimilarGraphicFulfilled, getSimilarGraphicRejected } =
    similarGraphicsSlice.actions

export const getSimilarGraphicAsync =
    (companyId: string, regex: string | undefined): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getSimilarGraphicPending())
            const similarGraphics = await get<SimilarGraphicT[]>(
                `graphics/similar?companyId=${companyId}&name=${regex}`
            )
            dispatch(getSimilarGraphicFulfilled(similarGraphics))
        } catch (error: any) {
            dispatch(getSimilarGraphicRejected(error.message))
        }
    }

export default similarGraphicsSlice.reducer
