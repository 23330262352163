import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import BackgroundImageRemove from './BackgroundImage/BackgroundImageRemove'
import BackgroundImageUpload from './BackgroundImage/BackgroundImageUpload'

interface Props {
    object: AnyImageOnwerTypeT
    styleProperty: GroupStyleI
    handleOnChange: (e: any) => void
    handleSetStyleValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const BackgroundImage = ({
    object,
    styleProperty,
    handleOnChange,
    handleSetStyleValue,
    handleSetAttributeValue,
}: Props) => {
    return (
        <Box>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    {(styleProperty.value === 'none' || styleProperty.value === '') && (
                        <BackgroundImageUpload handleSetValue={handleOnChange} />
                    )}
                    {styleProperty.value !== 'none' && styleProperty.value !== '' && (
                        <BackgroundImageRemove handleSetValue={handleOnChange} />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default BackgroundImage
