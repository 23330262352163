import { selectUndoable } from '../../helpers/selector.helpers'
import { useObjectActions } from '../useObjectActions'
import { useAppSelector } from '../useRedux'

const useMultiselectObjectAction = () => {
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)
    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const { duplicateObject, deleteObject, clearObjectFromSelection } = useObjectActions()

    const findAllSelectedObjects = (id: string) => {
        let selectedObject: AnySceneObjectT | undefined = Object.values(objects.value).find(
            (object) => object?.id === id
        )
        return selectedObject
    }

    const handleDuplicateObjects = () => {
        selectedObjectIds.forEach((id) => {
            let selectedObject: AnySceneObjectT | undefined = Object.values(objects.value).find(
                (object) => object?.id === id
            )
            if (selectedObject) {
                duplicateObject(selectedObject, selectedObject.parentId)
            }
        })
    }

    const handleDeleteObjects = () => {
        selectedObjectIds.forEach((id) => {
            let selectedObject = findAllSelectedObjects(id)
            if (selectedObject) {
                deleteObject(selectedObject)
                clearObjectFromSelection(selectedObject)
            }
        })
    }

    return {
        handleDuplicateObjects,
        handleDeleteObjects,
    }
}

export default useMultiselectObjectAction
