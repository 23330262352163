import { Grid } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { ChromePicker, Color, ColorResult, RGBColor } from 'react-color'
import { useAppDispatch } from '../../../hooks/useRedux'
import { setRecentColorPreferencesAsync } from '../../../store/slices/editor.slice'
import EyeDropper from './EyeDropper'
import RecentColorInput from './RecentColorInput'

interface Props {
    styleProperty: SimpleStyleT
    handleOnChange: (e: { target: { name: string; value: RGBColor } }) => void
}

const ColorPickerInput = ({ styleProperty, handleOnChange }: Props) => {
    const dispatch = useAppDispatch()
    const [color, setColor] = useState<Color>(styleProperty.value)

    const handleRecentColor = (colorResult: ColorResult) => {
        dispatch(setRecentColorPreferencesAsync(colorResult))
    }
    const handleChangeColor = (colorResult: ColorResult) => {
        handleRecentColor(colorResult)
        handleOnChange({
            target: { name: styleProperty.property, value: colorResult.rgb },
        })
    }

    useEffect(() => {
        setColor(styleProperty.value)
    }, [styleProperty])

    const ref = useRef<HTMLDivElement>(null)
    const getMaxWidth = () => {
        if (ref.current?.offsetWidth) {
            if (ref.current?.offsetWidth > 350) {
                return 75
            } else if (ref.current?.offsetWidth < 350) {
                return 200
            }
        } else {
            return 200
        }
    }

    return (
        <Grid container ref={ref}>
            <Grid item>
                <ChromePicker
                    color={color}
                    onChangeComplete={(color) => {
                        handleRecentColor(color)
                        handleOnChange({
                            target: { name: styleProperty.property, value: color.rgb },
                        })
                    }}
                    onChange={(color) => setColor(color.rgb)}
                    disableAlpha={false}
                />
            </Grid>
            <Grid item>
                <Grid container sx={{ m: 0.5 }}>
                    <Grid item sx={{ mr: 0.5 }}>
                        <EyeDropper onPickColor={(color) => handleChangeColor(color)} />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            maxWidth: getMaxWidth(),
                        }}
                    >
                        <RecentColorInput
                            styleProperty={styleProperty}
                            handleOnChange={handleOnChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ColorPickerInput
