import configLocal from './config.local.json'
import configProd from './config.prod.json'
import configDev from './config.dev.json'

const getConfig = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'prod':
            return configProd
        case 'dev':
            return configDev
        case 'local':
        default:
            return configLocal
    }
}

export default getConfig()
