import { useRef } from 'react'
import useInViewport from '../../../../../hooks/useInViewport'
import { useAppSelector } from '../../../../../hooks/useRedux'
import TimelineTimeStep from './TimelineTimeStep'
import TimelineTimeStepEmpty from './TimelineTimeStepEmpty'

type Props = {
    objectId: string
    index: number
    TimelineTimeStepWidth: number
    timelineStepX: number
}

const TimelineTimeStepWrapper = ({
    objectId,
    index,
    TimelineTimeStepWidth,
    timelineStepX,
}: Props) => {
    const targetRef = useRef<HTMLDivElement>(null)
    const timelineZoom = useAppSelector((state) => state.timelinePreferences.value.timelineZoom)
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)

    const { isInViewport } = useInViewport(
        targetRef,
        [timelineZoom, framerate],
        'custom-timeline-navbar'
    )
    return isInViewport ? (
        <TimelineTimeStep
            ref={targetRef}
            objectId={objectId}
            key={index}
            index={index}
            TimelineTimeStepWidth={TimelineTimeStepWidth}
            timelineStepX={timelineStepX}
        />
    ) : (
        <TimelineTimeStepEmpty
            ref={targetRef}
            key={index}
            index={index}
            TimelineTimeStepWidth={TimelineTimeStepWidth}
            timelineStepX={timelineStepX}
        />
    )
}

export default TimelineTimeStepWrapper
