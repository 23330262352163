import { Box, Grid } from '@mui/material'
import { Control, FieldErrorsImpl, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AttributesT } from '../../../store/slices/assets.slice'
import AttributeRow from './AttributeRow'
import TreeNode from './TreeNode'

interface Props {
    rawJSON: {}
    control: Control<any, any>
    errors: Partial<FieldErrorsImpl<any>>
}
const ThirdStep = ({ rawJSON, control }: Props) => {
    const { t } = useTranslation()
    const attributes = useController({ name: 'attributes', control })

    const handleAdd = async (key: string, currentPath: string) => {
        attributes.field.onChange([...attributes.field.value, { name: key, path: currentPath }])
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={6}>
                <fieldset style={{ borderColor: 'gray', borderRadius: 6 }}>
                    <legend style={{ padding: 4 }}>
                        {' '}
                        {t('dataSource:thirdStep.parsedDataTitle')}
                    </legend>
                    <Box
                        sx={{
                            maxHeight: 300,
                            maxWidth: 550,
                            overflow: 'scroll',
                            pr: 2,
                        }}
                    >
                        <TreeNode
                            data={rawJSON}
                            disabled={!attributes.formState.isValid}
                            handleAdd={handleAdd}
                        />
                    </Box>
                </fieldset>
            </Grid>
            <Grid item md={6}>
                <fieldset style={{ borderColor: 'gray', borderRadius: 6 }}>
                    <legend style={{ padding: 4 }}>
                        {' '}
                        {t('dataSource:thirdStep.myDataSectionTitle')}
                    </legend>
                    <Box
                        component="form"
                        noValidate
                        sx={{ maxHeight: 300, overflowY: 'scroll', pr: 2 }}
                    >
                        {attributes.field.value?.map((_: AttributesT, index: number) => {
                            return (
                                <AttributeRow
                                    key={_.path + index}
                                    control={control}
                                    index={index}
                                />
                            )
                        })}
                    </Box>
                </fieldset>
            </Grid>
        </Grid>
    )
}

export default ThirdStep
