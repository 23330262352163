export const Status = {
    OK: 'OK',
    EMAIL_EXISTS: 'Email Exists',
    EMAIL_NOT_EXISTS: 'Email Not Exists',
    SERVICE_UNAVAILABLE: 'Service Unavailable',
    INCORRECT_PASSWORD: 'Incorrect Password',
    UNAUTHORIZED: 'Unauthorized',
    NO_COMPANY_PERMISSION: 'No Company Permission',
    NO_GRAPHIC_PERMISSION: 'No Graphic Permission',
    NO_ASSET_PERMISSION: 'No Asset Permission',
    GRAPHIC_EXISTS: 'GraphicExists',
}
