import { MAX_INPUT_NUM } from "../../../constants"

const unlimitedUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: -MAX_INPUT_NUM,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

const positiveUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const top: SimpleStyleT = {
	property: "top",
	value: 0,
	unit: "px",
	input: unlimitedUnitaryInput,
	render: (value, unit) => ({ property: "top", value: value + unit }),
}

export const left: SimpleStyleT = {
	property: "left",
	value: 0,
	unit: "px",
	input: unlimitedUnitaryInput,
	render: (value, unit) => ({ property: "left", value: value + unit }),
}

export const position: SimpleStyleT = {
	property: "position",
	value: "absolute",
	input: {
		type: "select",
		props: ["relative", "absolute"],
	},
	render: (value) => ({ property: "position", value: value }),
}

// DOLADIT
export const zIndex: SimpleStyleT = {
	property: "zIndex",
	value: "auto", // 1 | "auto"
	input: {
		type: "string",
		props: "",
		label: "z-index",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "z-index", value: value }
			: { property: "zIndex", value: value },
}

export const paddingTop: SimpleStyleT = {
	property: "paddingTop",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "padding top",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "padding-top", value: value + unit }
			: { property: "paddingTop", value: value + unit },
}

export const paddingBottom: SimpleStyleT = {
	property: "paddingBottom",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "padding bottom",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "padding-bottom", value: value + unit }
			: { property: "paddingBottom", value: value + unit },
}

export const paddingLeft: SimpleStyleT = {
	property: "paddingLeft",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "padding left",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "padding-left", value: value + unit }
			: { property: "paddingLeft", value: value + unit },
}

export const paddingRight: SimpleStyleT = {
	property: "paddingRight",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "padding right",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "padding-right", value: value + unit }
			: { property: "paddingRight", value: value + unit },
}

export const marginTop: SimpleStyleT = {
	property: "marginTop",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "margin top",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "margin-top", value: value + unit }
			: { property: "marginTop", value: value + unit },
}

export const marginBottom: SimpleStyleT = {
	property: "marginBottom",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "margin bottom",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "margin-bottom", value: value + unit }
			: { property: "marginBottom", value: value + unit },
}

export const marginLeft: SimpleStyleT = {
	property: "marginLeft",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "margin left",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "margin-left", value: value + unit }
			: { property: "marginLeft", value: value + unit },
}

export const marginRight: SimpleStyleT = {
	property: "marginRight",
	value: 0,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "margin right",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "margin-right", value: value + unit }
			: { property: "marginRight", value: value + unit },
}
