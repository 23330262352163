import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../components/common/AlertDialog'
import PageSection from '../components/common/page/PageSection'
import ChangePassword from '../components/settings/ChangePassword'
import LicensesDialog from '../components/settings/LicensesDialog'
import MyProfile from '../components/settings/MyProfile'
import UserLicense from '../components/settings/UserLicense'
import { Permission } from '../constants/permission.enum'
import { useAppDispatch } from '../hooks/useRedux'
import { useSession } from '../hooks/useSession'
import { deleteProfileAsync } from '../store/slices/user.slice'

export default function Profile() {
    const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false)
    const [openLicenses, setOpenLicenses] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { hasPermission } = useSession()

    const navigate = useNavigate()
    const onDelete = async () => {
        setOpenDeleteAlert(false)
        await dispatch(deleteProfileAsync())
        navigate('/signup')
    }
    const handleNavigateToSuperAdmin = () => {
        navigate('/admin/users')
    }
    return (
        <Container component="main" maxWidth="lg">
            <Grid container my={4} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h2">{t('user:settingsProfile')}</Typography>
                </Grid>
                <Grid item>
                    {hasPermission(Permission.SuperAdmin) && (
                        <Typography variant="h5">
                            <Button variant="text" onClick={handleNavigateToSuperAdmin}>
                                {t('user:button.usersAdmin')}
                            </Button>
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: 1 }}></Grid>

            <PageSection
                heading={t('user:userProfileHeading')}
                description={t('user:userProfileDescription')}
                section={<MyProfile />}
            />

            <PageSection
                heading={t('user:licenseDetailHeading')}
                description={t('user:licenseDetailDescription')}
                descriptionAction={
                    <Button variant="text" onClick={() => setOpenLicenses(true)}>
                        {t('user:button.showLicenses')}
                    </Button>
                }
                section={<UserLicense />}
            />

            <PageSection
                heading={t('user:changePasswordHeading')}
                description={t('user:changePasswordDescription')}
                section={<ChangePassword />}
            />

            <PageSection
                heading={t('user:deleteProfileHeading')}
                description={t('user:deleteProfileDescription')}
                section={
                    <Button
                        variant="text"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => setOpenDeleteAlert(true)}
                    >
                        <Typography variant="body2">{t('user:settingsDeleteProfile')}</Typography>
                    </Button>
                }
            />

            {openLicenses && (
                <LicensesDialog open={openLicenses} onClose={() => setOpenLicenses(false)} />
            )}
            {openDeleteAlert && (
                <AlertDialog
                    open={openDeleteAlert}
                    title={t('user:settingsDeleteProfileTitle')}
                    content={t('user:settingsDeleteProfileContent')}
                    onDisagree={() => setOpenDeleteAlert(false)}
                    onAgree={onDelete}
                    txtDisagree={t('disagree')}
                    txtAgree={t('delete')}
                    colorAgree="error"
                />
            )}
        </Container>
    )
}
