import { imageSrc } from '../data/styles/simple/display.styles'
import { Timeline } from '../store/slices/editor.slice'
import { roundNumber } from './number.helpers'
import { EMPTY_IMAGE_SRC, processTween } from './object.helpers'

// ------------------------------------
// SERIALIZE ANIMATION
// ------------------------------------

const serializeAnimationStateForDownload = (animationStates: SimpleStyleT[]): string => {
    const numOfStates = animationStates.length
    let result: string = ''

    animationStates.forEach((animationState: SimpleStyleT, index: number) => {
        // Object.assign(result, { [animationState.property as string]: animationState.value })
        const keys = animationState.property.split('.')
        keys.forEach((key, index) => {
            if (index > 0) result += '{'
            result += key + ':'
            if (index === keys.length - 1) result += `'${animationState.value}'${'}'.repeat(index)}`
        })

        if (index < numOfStates - 1) result += ','
    })
    return result
}

const serializeAnimationEaseForDownload = (ease: EaseT): string => {
    let result: string = ''
    if (ease) {
        result = ease.easeStyle + '.' + ease.easeType
        const strength = ease.easeStrengthValues.find((s) => s.strength === ease.easeStrength)
        if (strength) result += strength.value
    }
    return result
}

// ------------------------------------
// INIT ANIMATION
// ------------------------------------

const initTweenForDownload = (animation: AnimationI, isPreview: boolean): string => {
    let gsapTween: string = ''

    if (animation?.tween.type === 'fromTo')
        gsapTween = `masterTimeline.fromTo("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(
            animation.tween.from
        )}}, {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'from')
        gsapTween = `masterTimeline.from("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(animation.tween.from)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'to')
        gsapTween = `masterTimeline.to("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'set') {
        let from = animation.tween.from
        if (animation.tween.name === 'sequence' && isPreview) {
            from = processTween(animation.tween.from)
        }

        gsapTween = `masterTimeline.set("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(from)}}, ${animation.delay});
            `
    }
    return gsapTween
}

// ------------------------------------
// INIT MASTER TIMELINE
// ------------------------------------

export const initMasterTimelineForDownload = (
    timeline: TimelineI,
    animations: AnimationI[],
    settingsTimeline: Timeline
    // TO DO use timeline from editor.settings
): string => {
    const initializedMasterTimeline: string = `
        masterTimeline = new gsap.timeline({
            repeat: ${timeline.repeat},
        });
    `
    const masterTimelineOnInit: string = `
        masterTimeline.set("#canvas", { visibility: "${
            settingsTimeline.initState === 'hideAndPause' ? 'hidden' : 'visible'
        }" });
    `

    // timeline.value.endState ???
    const masterTimelineOnEnd: string = `
        masterTimeline.set("#canvas", { visibility: "${
            settingsTimeline.endState === 'endAndClear' ? 'hidden' : 'visible'
        }" });
    `

    // init animations
    const initializedTweens = getInitializedTweens(animations, false)
    const createLabels: string =
        `
        timelineLabels = [` +
        timeline.labels
            ?.map((label) => {
                return (
                    '{' +
                    `title: '${label.title}',` +
                    `time: ${label.time},` +
                    `type: '${label.type}',` +
                    `jumpto: '${label.jumpto}'` +
                    '}'
                )
            })
            .join(',') +
        `];
    `

    const timelineOnUpdateCode: string = timeline.onUpdateCode
        ? `
    const timelineOnUpdate = (time, lasttime, duration) => {
        ${timeline.onUpdateCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
    }    
    `
        : `
    const timelineOnUpdate = (time, lasttime, duration) => {}
    `

    const labelsCode = (): string => {
        var result: string = ''

        timeline.labels.forEach((label) => {
            result += label.onLabelCode
                ? `
        const label_${label.title.replace(/\s+/g, '_')}_onLabel = () => {
            ${label.onLabelCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
        }
        `
                : `
        const label_${label.title.replace(/\s+/g, '_')}_onLabel = () => {
        }
        `
        })

        result += `const labelsOnLabel = (labeltitle) => {
        `

        timeline.labels.forEach((label) => {
            result += `if (labeltitle === '${label.title}') { label_${label.title.replace(
                /\s+/g,
                '_'
            )}_onLabel() }
        `
        })

        result += `}
        `

        return result
    }

    const initAnimationCode = (animation: AnimationI) => {
        let animationCode: string = ''

        if (animation.onInitCode?.code) {
            animationCode += `const animation_${animation.title.replace(
                /\s+/g,
                '_'
            )}_onInit = () => {
                ${animation.onInitCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
            }
            `
        }
        if (animation.onUpdateCode?.code) {
            animationCode += `const animation_${animation.title.replace(
                /\s+/g,
                '_'
            )}_onUpdate = (time, lasttime, duration) => {
                ${animation.onUpdateCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
            }
            `
        }
        return animationCode
    }

    const triggerAnimationCode = (animation: AnimationI) => {
        let animationCode: string = ''

        if (animation.onInitCode?.code) {
            animationCode += `if ((lasttime === 0 && ${
                animation.delay
            } <= time) || (lasttime > 0 && ${animation.delay} > lasttime && ${
                animation.delay
            } <= time)) animation_${animation.title.replace(/\s+/g, '_')}_onInit()
                `
        }
        if (animation.onUpdateCode?.code) {
            animationCode += `if (${animation.delay} <= time && time <= ${
                animation.delay + animation.duration
            }) animation_${animation.title.replace(/\s+/g, '_')}_onUpdate(time - ${
                animation.delay
            }, lasttime - ${animation.delay}, ${animation.duration})
                `
        }
        return animationCode
    }

    const animationsCode = (): string => {
        var result: string = ''

        result = animations.map((animation) => initAnimationCode(animation)).join('')

        result += `
            const triggerAnimationsCode = (time, lasttime) => {
                `
        result += animations.map((animation) => triggerAnimationCode(animation)).join('')

        result += `
            }
        `

        return result
    }

    const onUpdateTrigger: string = `
        let timelineTime = 0
        let lastTimelineTime = 0
        let jumpToTimelineTime = 0

        const updateTimeline = () => {
            lastTimelineTime = timelineTime
            timelineTime = masterTimeline.time()

            lastTimelineTime = (lastTimelineTime > timelineTime) ? jumpToTimelineTime : lastTimelineTime

            try {
                timelineOnUpdate(timelineTime, lastTimelineTime, masterTimeline.duration())
            }
            catch(error) {}

            if ((timelineTime >= lastTimelineTime)) {
                const labels = timelineLabels.filter(
                    (lbl) => {
                        return (lastTimelineTime > 0 && lbl.time > lastTimelineTime && lbl.time <= timelineTime) ||
                            (lastTimelineTime === 0 && lbl.time <= timelineTime)
                    })
                if ((labels !== undefined) && (labels.length > 0)) {
                    labels.forEach((label) => {
                        try {
                            labelsOnLabel(label.title)
                        }
                        catch(error) {}
                        
                        if (label.type === 'pause') {
                            pauseAnimation(label.time)
                        }
                        else if ((label.type === 'jump') && (lastTimelineTime < timelineTime))
                        {
                            const jumpToLabel = timelineLabels.find((lbl) => lbl.title === label.jumpto)
                            if (jumpToLabel) {
                                jumpToTimelineTime = jumpToLabel.time
                                seekAnimation(jumpToLabel.time)
                                try {
                                    labelsOnLabel(jumpToLabel.title)
                                }
                                catch(error) {}
                            }
                        }
                    })
                }
            }

            try {
                triggerAnimationsCode(timelineTime, lastTimelineTime)
            }
            catch(error) {}
        }

        masterTimeline.eventCallback('onUpdate', updateTimeline)
    `
    // prettier-ignore
    const timelineOnInitCode: string = timeline.onInitCode ? `const timelineOnInit = () => {${timeline.onInitCode.code.replaceAll("&apos", "'").replaceAll("\\\"", "\"")}}` : `const timelineOnInit = () => {}`

    return `
        let masterTimeline = null;
        ${timelineOnInitCode}
        const initTimeline = () => {
            ${initializedMasterTimeline}
            ${masterTimelineOnInit}
            ${settingsTimeline.initState === 'initAndPlay' ? '' : 'masterTimeline.pause(0)'}
            ${initializedTweens}
            ${settingsTimeline.initState === 'initAndPlay' ? '' : 'masterTimeline.pause(0.000001)'}
            ${masterTimelineOnEnd}
            ${createLabels}
            ${timelineOnUpdateCode}
            ${labelsCode()}
            ${animationsCode()}
            ${onUpdateTrigger}
            try {timelineOnInit()} catch(error) {}
        };
    `
}

const getInitializedTweens = (animations: AnimationI[], isPreview: boolean) => {
    const initializedTweens: string[] = []
    animations.forEach((animation) => {
        const tween = initTweenForDownload(animation!, isPreview)
        initializedTweens.push(tween)

        if (animation.tween.name === 'sequence') {
            const nextDelay = roundNumber(animation.delay + animation.duration, 2)
            const nextAnimation = Object.values(animations).find(
                (a) =>
                    a &&
                    a.objectId === animation.objectId &&
                    roundNumber(a.delay, 2) === nextDelay &&
                    a.tween.name === 'sequence'
            )
            if (!nextAnimation) {
                const emptyTween = `masterTimeline.set("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload([
                    imageSrc(EMPTY_IMAGE_SRC),
                ])}}, ${nextDelay});
                `
                initializedTweens.push(emptyTween)
            }
        }
    })
    return initializedTweens.join(' ')
}

export const initMasterTimelineForThumbnailPreview = (animations: AnimationI[]): string => {
    const initializedMasterTimeline: string = `
        const masterTimeline = new gsap.timeline({
            repeat: -1,
        });
    `

    const masterTimelineOnInit: string = `
        masterTimeline.set("#canvas", { visibility: "visible" });
    `

    const images: string[] = []
    animations
        .filter((animation) => animation.tween.name === 'sequence')
        .forEach((animation) => {
            processTween(animation.tween.from).forEach((f) => {
                if (f.property === 'attr.src') {
                    images.push(f.value)
                }
            })
        })
    const preload = `
        const images = ${JSON.stringify(images)};
        images.forEach(src => {
            const image = new Image()
            image.src = src
        })
    `

    const initializedTweens: string = getInitializedTweens(animations, true)
    return initializedMasterTimeline + masterTimelineOnInit + initializedTweens + preload
}

export const masterTimelineScripts = (): string => {
    const playAnimation = `
        const playAnimation = time => {
           
            if(time === 0) {
                masterTimeline.set("#canvas", { visibility: "visible" }, 0);
                replayAnimation();
            }
            
            if(time) {
                masterTimeline.seek(time).play();
                masterTimeline.set("#canvas", { visibility: "visible" }, time);
            }
            else {
                masterTimeline.set("#canvas", { visibility: "visible" }, 0.000001);
                masterTimeline.play(0.000001);
            }
            return 'play animation from ' + masterTimeline.time();
        };`
    const pauseAnimation = `
        const pauseAnimation = time => {
            if(time) masterTimeline.pause(time);
            else masterTimeline.pause();
            return 'pause animation at ' + masterTimeline.time();
        };`
    const continueAnimation = `
        const continueAnimation = () => {
            masterTimeline.play();
            return 'play animation at ' + masterTimeline.time();
        };`
    const seekAnimation = `
        const seekAnimation = time => {
            if(time) masterTimeline.seek(time);
            else masterTimeline.seek(0);
            return 'pause animation at ' + masterTimeline.time();
        };`
    const stopAnimation = `
        const stopAnimation = () => {
            masterTimeline.seek(0).pause();
            return 'stop animation at ' + masterTimeline.time();
        };`
    const replayAnimation = `
        const replayAnimation = () => {
            masterTimeline.play(0);
            return 'replay animation from 0';
        };`
    const seekAnimationLabel = `
        const seekAnimationLabel = (title) => {
            const label = timelineLabels.find((lbl) => {
                return lbl.title === title
            })
            if (label) seekAnimation(label.time)
        };`
    const disableAnimationJumpLabels = `
        const disableAnimationJumpLabels = () => {
            if (timelineLabels)
                timelineLabels.forEach((lbl) => {
                    lbl.type = lbl.type === 'jump' ? 'continue' : lbl.type
                })
        };`
    const disableAnimationPauseLabels = `
        const disableAnimationPauseLabels = () => {
            if (timelineLabels)
                timelineLabels.forEach((lbl) => {
                    lbl.type = lbl.type === 'pause' ? 'continue' : lbl.type
                })
        };`
    const disableAnimationLabels = `
        const disableAnimationLabels = () => {
            if (timelineLabels)
                timelineLabels.forEach((lbl) => {
                    lbl.type = 'continue'
                })
        };`

    return (
        playAnimation +
        pauseAnimation +
        continueAnimation +
        seekAnimation +
        stopAnimation +
        replayAnimation +
        seekAnimationLabel +
        disableAnimationJumpLabels +
        disableAnimationPauseLabels +
        disableAnimationLabels
    )
}
