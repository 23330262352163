import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import InfoTooltip from './InfoTooltip'

interface Props {
    expanded: boolean
    title: string | null
    secondaryActionComponent?: JSX.Element
    tooltip?: string
    component: JSX.Element
}

const ExpandableSection = ({
    expanded,
    title,
    secondaryActionComponent,
    tooltip,
    component,
}: Props) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(expanded)

    if (!title)
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    py: 1,
                    pl: 1,
                    pr: 2,
                    borderRadius: 1,
                    marginBottom: 1,
                }}
            >
                {component}
            </Box>
        )

    return (
        <Box sx={{ marginBottom: 1, borderRadius: 1, overflow: 'hidden' }}>
            <ListItem
                button
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{ backgroundColor: theme.palette.background.paper }}
                secondaryAction={
                    <>
                        {tooltip && tooltip.length > 0 && <InfoTooltip text={tooltip} />}
                        {secondaryActionComponent}
                        {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </>
                }
            >
                <ListItemText primary={title} />
            </ListItem>
            {isExpanded && (
                <Box
                    p={1}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    {component}
                </Box>
            )}
        </Box>
    )
}

export default ExpandableSection
