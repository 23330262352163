import { createSlice } from '@reduxjs/toolkit'
import { AppStateT } from '../store'

const PeriodsEnum = [
    {
        label: 'Monthly',
        value: 1,
    },
    {
        label: 'Yearly',
        value: 12,
    },
]

const ActionsEnum = [
    {
        label: 'Purchase',
        value: 'purchase',
    },
    {
        label: 'Earn',
        value: 'earn',
    },
    {
        label: 'Renewal',
        value: 'renewal',
    },
]

const RolesEnum = [
    {
        label: 'User',
        value: 1,
    },
    {
        label: 'Admin',
        value: 2,
    },
]

interface EnumT {
    label: string
    value: number | string
}

interface EnumStoreT {
    [key: string]: EnumT[]
}

const initialState: EnumStoreT = {
    periods: PeriodsEnum,
    roles: RolesEnum,
    actions: ActionsEnum,
}

export const enumsSlice = createSlice({
    name: 'enums',
    initialState,
    reducers: {},
})

export const selectActionsEnum = (state: AppStateT) => state.enums.actions
export const selectPeriodsEnum = (state: AppStateT) => state.enums.periods
export const selectRolesEnum = (state: AppStateT) => state.enums.roles

export default enumsSlice.reducer
