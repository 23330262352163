import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QueryOptionsT } from '../../components/common/DataGrid'
import { Status } from '../../constants/status'
import { get, put } from '../../helpers/ajax.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { LicenseT } from './user.slice'

export interface UsersT {
    _id: string
    email: string
    permissions?: number
    firstName?: string
    lastName?: string
    isPasswordTemp?: boolean
    lastLogged?: Date
    isDeleted?: boolean
    license?: LicenseT
    loginAttempts?: number
    locked?: Date
}

interface UsersStateT {
    data: UsersT[]
    total: number
    status?: string
}

const initialState: UsersStateT = {
    data: [],
    total: 0,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersPending: (state: UsersStateT) => {
            state.status = undefined
        },
        getUsersFulfilled: (state: UsersStateT, action: PayloadAction<ListT<UsersT>>) => {
            state.status = Status.OK
            state.data = action.payload.data
            state.total = action.payload.total
        },
        getVerifyUsersFulfilled: (state: UsersStateT, action: PayloadAction<UsersT>) => {
            state.status = Status.OK
            state.data = state.data.map((x) => {
                if (x._id === action.payload._id)
                    x = {
                        ...x,
                        license: {
                            license: action.payload.license?.license,
                            expired: action.payload.license?.expired,
                            paidLicense: action.payload.license?.paidLicense,
                            period: action.payload.license?.period,
                        },
                    }
                return x
            })
        },
        getUsersRejected: (state: UsersStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getUsersFulfilled, getVerifyUsersFulfilled, getUsersPending, getUsersRejected } =
    usersSlice.actions

export const getUsersAsync =
    (queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUsersPending())
            const users = await get<ListT<UsersT>>('users', queryOptions)
            dispatch(getUsersFulfilled(users))
        } catch (error: any) {
            dispatch(getUsersRejected(error.message))
        }
    }

export const checkUsersAsync =
    (email: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUsersPending())
            const users = await get<ListT<UsersT>>('users/check', { email })
            dispatch(getUsersFulfilled(users))
        } catch (error: any) {
            dispatch(getUsersRejected(error.message))
        }
    }

export const verifyUsersAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getUsersPending())
            await put('companies/verification', { isVerified: true, userId: id })
            const user = await get<UsersT>(`users/${id}`)
            dispatch(getVerifyUsersFulfilled(user))
            const { success } = useToast()
            success('user:successMsg.verify')
        } catch (error: any) {
            dispatch(getUsersRejected(error.message))
        }
    }

export default usersSlice.reducer
