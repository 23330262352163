import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import IconButton from '@mui/material/IconButton'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux'
import { setPreferencesAsync } from '../../../../../store/slices/editor.slice'
import ButtonWithTooltip from '../../../../common/ButtonWithTooltip'

interface Props {
    disabled: boolean
}

const TimelineControlsRows = ({ disabled }: Props) => {
    // const { setTimelineRowsExpanded } = useEditorActions()
    const dispatch = useAppDispatch()
    const editor = useAppSelector((state) => state.editor)
    const rowsExpanded: boolean = editor.value.settings.timelineRowsExpanded

    const handleToggleIsExpanded = () =>
        dispatch(setPreferencesAsync('timelineRowsExpanded', !rowsExpanded))

    return (
        <ButtonWithTooltip
            tooltipTitle={rowsExpanded ? 'collapse timeline rows' : 'expand timeline rows'}
            buttonComponent={
                <IconButton onClick={handleToggleIsExpanded} disabled={disabled}>
                    {rowsExpanded && <CloseFullscreenRoundedIcon fontSize="small" />}
                    {!rowsExpanded && <OpenInFullRoundedIcon fontSize="small" />}
                </IconButton>
            }
            disabled={disabled}
        />
    )
}

export default TimelineControlsRows
