import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { get, put } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

export type TimelineSettings = {
    framerate: number
    timelineTweensPerSec: number
    timelineZoom: number
}

const initialTimelineSetting: TimelineSettings = {
    framerate: 1,
    timelineTweensPerSec: 1,
    timelineZoom: 100,
}

export type TimelineSettingsKey = keyof TimelineSettings

type TimelineSetting = {
    [key in TimelineSettingsKey]?: any
}

type StateT = {
    value: TimelineSettings
    status?: string
}
const initialState: StateT = {
    value: initialTimelineSetting,
    status: Status.OK,
}
export const timelinePreferencesSlice = createSlice({
    name: 'timelinePreferences',
    initialState,
    reducers: {
        setTimelinePreferencesPending: (state: StateT) => {
            state.status = undefined
        },
        setTimelinePreferencesFulfilled: (
            state: StateT,
            action: PayloadAction<TimelineSetting>
        ) => {
            state.status = Status.OK
            state.value = { ...state.value, ...action.payload }
        },
        setTimelinePreferencesRejected: (state: StateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        getTimelinePreferencesPending: (state: StateT) => {
            state.status = undefined
        },
        getTimelinePreferencesFulfilled: (
            state: StateT,
            action: PayloadAction<TimelineSettings>
        ) => {
            state.status = Status.OK
            state.value = { ...state.value, ...action.payload }
        },
        getTimelinePreferencesRejected: (state: StateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const getTimelinePreferencesAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(getTimelinePreferencesPending())
        const data = await get<{ _id: string; timelineSettings: TimelineSettings }>(
            `preferences/timelineSettings`
        )
        dispatch(getTimelinePreferencesFulfilled(data.timelineSettings))
    } catch (error: any) {
        dispatch(getTimelinePreferencesRejected(error.message))
    }
}

export const setTimelinePreferencesAsync =
    (key: TimelineSettingsKey, value: any): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(setTimelinePreferencesFulfilled({ [key]: value }))
            await put(`preferences/timelineSettings.${key}`, { data: value })
        } catch (error: any) {
            dispatch(setTimelinePreferencesRejected(error.message))
        }
    }

export const {
    getTimelinePreferencesPending,
    getTimelinePreferencesFulfilled,
    getTimelinePreferencesRejected,
    setTimelinePreferencesPending,
    setTimelinePreferencesFulfilled,
    setTimelinePreferencesRejected,
} = timelinePreferencesSlice.actions

export default timelinePreferencesSlice.reducer
