const OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
}

const OPTIONS_WITH_TIME: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
}

export const dateToString = (date: Date) => {
    return new Date(date).toLocaleDateString(undefined, OPTIONS)
}
export const dateToStringWithTime = (date: Date) => {
    return new Date(date).toLocaleDateString(undefined, OPTIONS_WITH_TIME)
}
export const getDateFromId = (id: string) => {
    return new Date(parseInt(id.substring(0, 8), 16) * 1000)
}
