import { MAX_INPUT_NUM } from "../../../constants"

const positiveDecimalInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 0.1,
		},
	},
}

const unlimitedUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: -MAX_INPUT_NUM,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const transformOrigin: SimpleStyleT = {
	property: "transformOrigin",
	value: "center",
	input: {
		type: "select",
		props: ["center", "top left", "top right", "bottom left", "bottom right"],
		label: "transform origin",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "transform-origin", value }
			: { property: "transformOrigin", value },
}

export const scaleX: GroupStyleT = {
	property: "scaleX",
	value: 1,
	type: "transform",
	input: {
		...positiveDecimalInput,
		label: "scale X",
	},
	render: (value) => ({ property: "transform", value: `scaleX(${value})` }),
}

export const scaleY: GroupStyleT = {
	property: "scaleY",
	value: 1,
	type: "transform",
	input: {
		...positiveDecimalInput,
		label: "scale Y",
	},
	render: (value) => ({ property: "transform", value: `scaleY(${value})` }),
}

export const translateX: GroupStyleT = {
	property: "translateX",
	value: "0",
	unit: "px",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "translate X",
	},
	render: (value, unit) => ({ property: "transform", value: `translateX(${value + unit})` }),
}

export const translateY: GroupStyleT = {
	property: "translateY",
	value: "0",
	unit: "px",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "translate Y",
	},
	render: (value, unit) => ({ property: "transform", value: `translateY(${value + unit})` }),
}

export const rotateX: GroupStyleT = {
	property: "rotateX",
	value: 0,
	unit: "deg",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "rotate X",
	},
	render: (value, unit) => ({ property: "transform", value: `rotateX(${value + unit})` }),
}

export const rotateY: GroupStyleT = {
	property: "rotateY",
	value: 0,
	unit: "deg",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "rotate Y",
	},
	render: (value, unit) => ({ property: "transform", value: `rotateY(${value + unit})` }),
}

export const rotateZ: GroupStyleT = {
	property: "rotateZ",
	value: 0,
	unit: "deg",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "rotate Z",
	},
	render: (value, unit) => ({ property: "transform", value: `rotateZ(${value + unit})` }),
}

export const skewX: GroupStyleT = {
	property: "skewX",
	value: 45,
	unit: "deg",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "skew X",
	},
	render: (value, unit) => ({ property: "transform", value: `skewX(${value + unit})` }),
}

export const skewY: GroupStyleT = {
	property: "skewY",
	value: 45,
	unit: "deg",
	type: "transform",
	input: {
		...unlimitedUnitaryInput,
		label: "skew Y",
	},
	render: (value, unit) => ({ property: "transform", value: `skewY(${value + unit})` }),
}

export const matrix: GroupStyleT = {
	property: "matrix",
	value: "1, 0, 0, 1, 0, 0",
	type: "transform",
	input: {
		type: "string",
		props: "",
		tooltipTitle: "matrix(a, b, c, d, e, f) = scale(a, d); skew(b, c); translate(e, f)",
	},
	render: (value) => ({ property: "transform", value: `matrix(${value})` }),
}

export const transforms = [
	scaleX,
	scaleY,
	translateX,
	translateY,
	rotateX,
	rotateY,
	rotateZ,
	// skewX,
	// skewY,
	matrix,
]
export const transformNames = [
	"scaleX",
	"scaleY",
	"translateX",
	"translateY",
	"rotateX",
	"rotateY",
	"rotateZ",
	// "skewX",
	// "skewY",
	"matrix",
]
