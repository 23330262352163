import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RouteT } from '../../hooks/router/useRoute'
import { useSession } from '../../hooks/useSession'

interface Props {
    route: RouteT
    onClick: () => void
}

function Route({ route, onClick }: Props) {
    const session = useSession()
    const { t } = useTranslation()

    return (
        <MenuItem
            sx={{ p: 1.75 }}
            key={route.path}
            onClick={onClick}
            disabled={!session.hasPermission(route.permission) || route.disabled}>
            <ListItemIcon sx={{ ml: 1.75, mr: 1 }}>{route.icon}</ListItemIcon>
            <ListItemText primary={route.label || t(`menu:${route.path}`)} />
        </MenuItem>
    )
}

export default Route
