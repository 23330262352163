import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAppDispatch } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { updatePasswordAsync } from '../../store/slices/user.slice'
import LoadingButton from '../common/LoadingButton'
import TextField from '../common/form/TextField'

const changePasswordSchema = yup
    .object({
        password: yup.string().required('passwordRequired').min(6, 'passwordMin'),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), null], 'passwordMatch')
            .required('passwordRequired'),
    })
    .required()

const DEFAULT_VALUES = {
    password: '',
    passwordConfirmation: '',
}

export type ChangePasswordT = yup.InferType<typeof changePasswordSchema>

interface Props {
    onClose?: () => void
}

const ChangePassword = ({ onClose }: Props) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        mode: 'onBlur',
        schema: changePasswordSchema,
        defaultValues: DEFAULT_VALUES,
    })

    const dispatch = useAppDispatch()
    const onChangePassword = async (data: ChangePasswordT) => {
        data.passwordConfirmation = ''
        await dispatch(updatePasswordAsync(data))
        onClose?.()
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onChangePassword)}
            noValidate
            sx={{
                display: 'auto',
                flexDirection: 'column',
                alignItems: 'left',
                backgroundColor: theme.palette.background.paper,
                padding: 4,
                maxWidth: '100%',
                borderRadius: 1,
            }}
        >
            <TextField
                control={control}
                name="password"
                label={t('newPassword')}
                required
                fullWidth
                type="password"
                autoComplete="current-password"
                error={errors.password}
            />
            <TextField
                control={control}
                name="passwordConfirmation"
                label={t('newPasswordConfirmation')}
                required
                fullWidth
                type="password"
                error={errors.passwordConfirmation}
            />
            {onClose ? (
                <Grid container spacing={1} mt={1} py={0} px={0} justifyContent="flex-end">
                    <Grid item md={2} sm={2} xs={2}>
                        <Button
                            variant="text"
                            color="inherit"
                            disabled={isSubmitting}
                            onClick={() => onClose()}
                            fullWidth
                        >
                            {t('disagree')}
                        </Button>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            fullWidth
                            loadingPosition="end"
                            endIcon={<></>}
                        >
                            {t('submit')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            ) : (
                <Box sx={{ marginTop: 3 }}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="end"
                        endIcon={<></>}
                    >
                        {t('submit')}
                    </LoadingButton>
                </Box>
            )}
        </Box>
    )
}
export default ChangePassword
