import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'

interface Props {
    text: string
}

const InfoTooltip = ({ text }: Props) => {
    return (
        <Tooltip title={text}>
            <IconButton color="default" component="span">
                <HelpRoundedIcon />
            </IconButton>
        </Tooltip>
    )
}

export default InfoTooltip
