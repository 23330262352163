import dayjs from 'dayjs'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import config from '../config'
import { Status } from '../constants/status'
import { SessionT } from '../store/slices/session.slice'
import { pushFaroEvent, setFaroSession } from './faro.helpers'
import { getItem, removeItem, setItem } from './storage.helpers'

dayjs.extend(customParseFormat)

export const SessionKey = 'session'

export class AjaxError extends Error {
    statusCode: number

    constructor(statusCode: number, message: string) {
        super(message)
        this.statusCode = statusCode
    }
}

function jsonReviver(key: string, value: any) {
    if (typeof value === 'string') {
        if (dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid()) {
            return new Date(value)
        }
    }
    return value
}

export const login = async (email: string, password: string) => {
    const url = `${config.url}/auth/login`

    let response
    try {
        response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
            }),
        })
    } catch (error: any) {
        throw new AjaxError(503, Status.SERVICE_UNAVAILABLE)
    }

    if (response.status === 201) {
        const session = await response.json()
        setItem(SessionKey, session)
        setFaroSession(session)
        return session
    } else if (response.status === 401) {
        throw new AjaxError(401, Status.INCORRECT_PASSWORD)
    } else {
        const res = await response.json()
        throw new AjaxError(response.status, res.message || response.statusText)
    }
}

const refreshToken = async () => {
    const url = `${config.url}/auth/refresh`
    const session = getItem<SessionT>(SessionKey)
    if (!session) {
        return false
    }

    let response
    try {
        response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session.refresh_token}`,
            },
        })
    } catch (error) {
        return false
    }

    if (response.status === 200 || response.status === 201) {
        const u = await response.json()
        setItem(SessionKey, u)
        return true
    } else {
        removeItem(SessionKey)
        return false
    }
}

export const getAuthHeader = () => {
    const session = getItem<SessionT>(SessionKey)
    return session ? `Bearer  ${session.access_token}` : ''
}

interface ParamsT {
    [key: string]: any
}

const parseValue = (value: any): string => {
    if (typeof value === 'object') {
        return JSON.stringify(value)
    } else {
        return value
    }
}

export const buildParams = (params: ParamsT | undefined) => {
    if (!params) {
        return ''
    }

    const p = new URLSearchParams()

    Object.keys(params).forEach((key) => {
        const value = params[key]
        if (Array.isArray(value)) {
            value.forEach((v) => {
                p.append(key, parseValue(v))
            })
        } else {
            p.append(key, parseValue(value))
        }
    })

    return p.toString()
}

export const get = <T>(url: string, params: ParamsT | undefined = undefined): Promise<T> =>
    ajax<T>('GET', url, undefined, params)

export const ajax = async <T>(
    method: string,
    url: string,
    data: any,
    params: ParamsT | undefined
): Promise<T> => {
    let response
    try {
        response = await fetch(`${config.url}/${url}?${buildParams(params)}`, {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthHeader(),
            },
            body: JSON.stringify(data),
        })
    } catch (error: any) {
        throw new AjaxError(503, Status.SERVICE_UNAVAILABLE)
    }

    if (response.status === 200 || response.status === 201) {
        const data = await response.text()
        return JSON.parse(data, jsonReviver)
    } else if (response.status === 401 && (await refreshToken())) {
        return await ajax<T>(method, url, data, params)
    } else {
        const res = await response.json()
        throw new AjaxError(response.status, res.message || response.statusText)
    }
}

export type Storage = 'fs' | 's3'

interface FileParams {
    storage: Storage
    key?: string
    isPublic?: boolean
    hasFilename?: boolean
    thumbnailWidth?: number
}

export interface FileResponse {
    _id: string
    filename: string
    uploadDate: Date
    length: number
    chunkSize?: number
    metadata: {
        mimeType?: string
        storage: Storage
        userId?: string
        key?: string
        isPublic?: boolean
        thumbnailWidth?: number
        hasFilename?: boolean
    }
    publicUrl?: string
}

export const postFile = async (file: File, params: FileParams): Promise<FileResponse> => {
    const formData = new FormData()
    formData.append('file', file, file.name)
    Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value)
    })

    const response = await fetch(`${config.url}/files`, {
        method: 'POST',
        headers: {
            Authorization: getAuthHeader(),
        },
        body: formData,
    })

    if (response.status === 200 || response.status === 201) {
        return await response.json()
    } else if (response.status === 401 && (await refreshToken())) {
        return await postFile(file, params)
    } else {
        const res = await response.json()
        throw new AjaxError(response.status, res.message || response.statusText)
    }
}

export const getFile = async (id: string): Promise<Blob> => {
    const response = await fetch(`${config.url}/files/${id}`, {
        method: 'GET',
        headers: {
            Authorization: getAuthHeader(),
        },
    })

    if (response.status === 200) {
        return await response.blob()
    } else if (response.status === 401 && (await refreshToken())) {
        return await getFile(id)
    } else {
        const res = await response.json()
        throw new AjaxError(response.status, res.message || response.statusText)
    }
}

type Type = {
    _id: string
}
export const post = async <T extends Type>(
    url: string,
    data: any,
    params: ParamsT | undefined = undefined
): Promise<T> => {
    const res = await ajax<T>('POST', url, data, params)
    pushFaroEvent('POST', { url, _id: res._id })
    return res
}

export const put = <T>(
    url: string,
    data: any,
    params: ParamsT | undefined = undefined
): Promise<T> => {
    pushFaroEvent('PUT', { url })
    return ajax<T>('PUT', url, data, params)
}

export const del = <T>(
    url: string,
    data: any,
    params: ParamsT | undefined = undefined
): Promise<T> => {
    pushFaroEvent('DELETE', { url })
    return ajax<T>('DELETE', url, data, params)
}
