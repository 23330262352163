import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './i18n'
import './index.css'

import { initFaro } from './helpers/faro.helpers'

initFaro()
const root = createRoot(document.getElementById('root')!)
root.render(
    <React.StrictMode>
        <div onContextMenu={(event) => event.preventDefault()}>
            <App />
        </div>
    </React.StrictMode>
)
