import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { useAppDispatch } from '../hooks/useRedux'
import { Step } from '../hooks/useWizard'
import { setPreferencesAsync } from '../store/slices/editor.slice'

const Wizard = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const steps: Step[] = useMemo(() => t('wizard:steps', { returnObjects: true }), [t])

    useEffect(() => {
        dispatch(setPreferencesAsync('currentStep', steps[0].key))

        navigate('/app/graphics')
    }, [dispatch, navigate, steps])

    return <LoadingBackdrop open />
}

export default Wizard
