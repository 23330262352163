import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QueryOptionsT } from '../../components/common/DataGrid'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'
import { UserT } from './user.slice'

export const ARCHIVED_QUERY_OPTIONS = {
    filter: {},
    sort: { _id: -1 },
    skip: 0,
    limit: 10,
}
export interface ArchivedGraphicT {
    _id: string
    graphicId: string
    fileId: string
    updatedById?: string
    updatedBy?: UserT | null
    updated?: Date
    actualVersion?: boolean
}

interface ArchivedGraphicStateT {
    data: ArchivedGraphicT[]
    total: number
    status?: string
}

const initialState: ArchivedGraphicStateT = {
    data: [],
    total: 0,
    status: Status.OK,
}

export const archivedGraphicsSlice = createSlice({
    name: 'archivedGraphics',
    initialState,
    reducers: {
        getArchivedGraphicPending: (state: ArchivedGraphicStateT) => {
            state.status = undefined
        },
        getArchivedGraphicFulfilled: (
            state: ArchivedGraphicStateT,
            action: PayloadAction<ListT<ArchivedGraphicT> | undefined>
        ) => {
            state.data = action.payload?.data ?? initialState.data
            state.total = action.payload?.total ?? initialState.total
            state.status = Status.OK
        },
        getArchivedGraphicNextPageFulfilled: (
            state: ArchivedGraphicStateT,
            action: PayloadAction<ListT<ArchivedGraphicT>>
        ) => {
            state.data = [...state.data, ...action.payload.data]
            state.total = action.payload.total
            state.status = Status.OK
        },
        getArchivedGraphicRejected: (
            state: ArchivedGraphicStateT,
            action: PayloadAction<string>
        ) => {
            state.status = action.payload
        },
    },
})

export const {
    getArchivedGraphicPending,
    getArchivedGraphicFulfilled,
    getArchivedGraphicNextPageFulfilled,
    getArchivedGraphicRejected,
} = archivedGraphicsSlice.actions

export const getArchivedGraphicsAsync =
    (id: string, queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getArchivedGraphicPending())
            const archivedGraphics = await get<ListT<ArchivedGraphicT>>(
                `graphics/${id}/archived`,
                queryOptions
            )
            if (queryOptions.skip === 0) {
                dispatch(getArchivedGraphicFulfilled(archivedGraphics))
            } else {
                dispatch(getArchivedGraphicNextPageFulfilled(archivedGraphics))
            }
        } catch (error: any) {
            dispatch(getArchivedGraphicRejected(error.message))
        }
    }

export default archivedGraphicsSlice.reducer
