import { Control, Controller } from 'react-hook-form'
import { Turnstile as TurnstileComponent } from '@marsidev/react-turnstile'
import { Box } from '@mui/material'
import config from '../../../config'

interface Props {
    control: Control<any, any>
    name: string
    action: string
}

const Turnstile = ({ control, name, action }: Props) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TurnstileComponent
                        options={{ theme: 'dark', action: action, appearance: 'interaction-only' }}
                        siteKey={config.turnstile}
                        onError={() => field.onChange('')}
                        onExpire={() => field.onChange('')}
                        onSuccess={field.onChange}
                    />
                )}
            />
        </Box>
    )
}

export default Turnstile
