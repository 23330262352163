import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SignUpT } from '../../components/forms/SignupForm'
import config from '../../config.dev.json'
import { Status } from '../../constants/status'
import { SessionKey, login, post, put } from '../../helpers/ajax.helpers'
import { setFaroSession } from '../../helpers/faro.helpers'
import { getItem, removeItem } from '../../helpers/storage.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { getUserAsync } from './user.slice'

export interface SessionT {
    sessionId: string
    userId: string
    permissions: number
    access_token: string
    refresh_token: string
}

interface SessionStateT {
    data?: SessionT
    status?: string
}

const initialState: SessionStateT = {
    data: getItem<SessionT>(SessionKey),
    status: Status.OK,
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        sessionPending: (state: SessionStateT) => {
            state.status = undefined
        },
        sessionFulfilled: (state: SessionStateT, action: PayloadAction<SessionT | undefined>) => {
            state.status = Status.OK
            state.data = action.payload
        },
        sessionRejected: (state: SessionStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { sessionPending, sessionFulfilled, sessionRejected } = sessionSlice.actions

export const resetAsync =
    (email: string, cb: () => void): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(sessionPending())
            await post('auth/reset', { email })
            dispatch(sessionFulfilled())
            cb()
        } catch (error: any) {
            dispatch(
                sessionRejected(error.statusCode === 404 ? Status.EMAIL_NOT_EXISTS : error.message)
            )
        }
    }

export const loginAsync =
    (email: string, password: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(sessionPending())
            const session = await login(email, password)
            await dispatch(getUserAsync())
            dispatch(sessionFulfilled(session))
        } catch (error: any) {
            dispatch(sessionRejected(error.message))
        }
    }

export const resetConfirmAsync =
    (email: string, otp: string, password: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(sessionPending())
            const session = await login(email, otp)
            await put('users/password', { password })
            await dispatch(getUserAsync())
            dispatch(sessionFulfilled(session))
        } catch (error: any) {
            dispatch(sessionRejected(error.message))
        }
    }

export const signupAsync =
    (data: SignUpT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(sessionPending())
            await post('users', data)
            const session = await login(data.email, data.password)
            await dispatch(getUserAsync())
            await post(`companies`, {
                name: config.nameOfPersonalCompany,
                isPrivate: true,
            })
            const { success } = useToast()
            success('signupSuccessful')
            dispatch(sessionFulfilled(session))
        } catch (error: any) {
            const { info } = useToast()
            dispatch(sessionRejected(error.message))
            info('tryAnotherEmail')
        }
    }

export const logoutAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(sessionPending())
        await post('auth/logout', undefined)
        setFaroSession(null)
        removeItem('session')
        removeItem('user')
        dispatch(sessionFulfilled())
    } catch (error: any) {
        dispatch(sessionRejected(error.message))
    }
}

export default sessionSlice.reducer
