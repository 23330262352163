import { Button, MenuItem, useTheme } from '@mui/material'
import Menu from '@mui/material/Menu'
import React, { ChangeEvent, MouseEventHandler, MutableRefObject, useState } from 'react'
import LoadImageAction from '../../Editor/ObjectsTree/LoadImageAction'
import ButtonWithTooltip from '../ButtonWithTooltip'

interface Item {
    onClick: MouseEventHandler<HTMLLIElement>
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    title: string
    disabled?: boolean
    type?: 'file'
    multiple?: boolean
}

interface Props {
    data: Item[]
    title: string
    disabled?: boolean
    tooltip?: string
    icon?: JSX.Element
}

const ButtonContextMenu = ({ data, icon, title, disabled, tooltip }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const hiddenFileInput: MutableRefObject<HTMLInputElement | null> = React.useRef(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const theme = useTheme()

    return (
        <>
            <ButtonWithTooltip
                tooltipTitle={tooltip}
                buttonComponent={
                    <Button
                        id={`${title}-button`}
                        aria-controls={open ? `${title}-menu` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={icon}
                        variant="text"
                        sx={{ color: theme.palette.success.light }}
                        disabled={disabled}
                    >
                        {title}
                    </Button>
                }
                disabled={disabled}
            />

            <Menu
                id={`${title}-menu`}
                aria-labelledby={`${title}-button`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                {data.map((item) => (
                    <MenuItem
                        key={item.title}
                        onClick={(event) => {
                            if (item.type === 'file') {
                                hiddenFileInput.current?.click()
                            } else {
                                item.onClick(event)
                                handleClose()
                            }
                        }}
                        disabled={item.disabled}
                    >
                        {item.title}
                        {item.type === 'file' && (
                            <LoadImageAction
                                hiddenFileInputRef={hiddenFileInput}
                                multiple={item.multiple}
                                onChange={(event) => {
                                    item.onChange?.(event)
                                    handleClose()
                                }}
                            />
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default ButtonContextMenu
